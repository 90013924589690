const _fc_props = {};
_fc_props.ante_meridiem = 'AM (matin)';
_fc_props.post_meridiem = 'PM (après-midi)';
_fc_props.retry = 'Réessayer';
_fc_props.default = 'Défaut';
_fc_props.origin = 'Origine';
_fc_props.loading = 'Chargement';
_fc_props.list = 'Liste';
_fc_props.send = 'Envoyer';
_fc_props.error = 'Erreur';
_fc_props.save = 'Sauvegarder';
_fc_props.delete = 'Supprimer';
_fc_props.message = 'Message';
_fc_props.logout = 'Déconnexion';
_fc_props.add = 'Ajouter';
_fc_props.remove = 'Effacer';
_fc_props.done = 'Terminé';
_fc_props.other = 'Autre';
_fc_props.interval = 'Intervalle';
_fc_props.time = 'Heure';
_fc_props.speed = 'Vitesse';
_fc_props.longitude = 'Longitude';
_fc_props.latitude = 'Latitude';
_fc_props.x_minutes = '{0} Minutes';
_fc_props.general = 'Générale';
_fc_props.messages = 'Messages';
_fc_props.gps_fixes = 'Coordonnées GPS';
_fc_props.device_id = 'Coordonnées de l\'Appareil';
_fc_props.vehicle = 'Véhicule';
_fc_props.employee = 'Employé';
_fc_props.version = 'Versions';
_fc_props.statuses = 'Statut';
_fc_props.edit = 'Editer';
_fc_props.cancel = 'Annuler';
_fc_props.yes = 'Oui';
_fc_props.none = 'Aucun';
_fc_props.never = 'Jamais';
_fc_props.name = 'Nom';
_fc_props.true = 'Vrai';
_fc_props.status = 'Statut';
_fc_props.distance = 'Distance';
_fc_props.ok = 'Ok';
_fc_props.login = 'Connexion';
_fc_props.next = 'Suivant';
_fc_props.menu = 'Menu';
_fc_props.not_available = 'Indisponible';
_fc_props.back = 'Retour';
_fc_props.select = 'Sélectionner';
_fc_props.dispatch = 'Expédition';
_fc_props.messaging = 'Messagerie';
_fc_props.plants = 'Usines';
_fc_props.last_plants = 'Dernières Usines';
_fc_props.vehicle_types = 'Types de Véhicules';
_fc_props.employee_types = 'Types d\'salariés';
_fc_props.employee_groups = 'Groupes d\'salariés';
_fc_props.equipment_groups = 'Groupes d\'Equipements';
_fc_props.job = 'Tâche';
_fc_props.order = 'Commande';
_fc_props.ticket = 'ticket #';
_fc_props.small = 'Petit';
_fc_props.medium = 'Moyen';
_fc_props.large = 'Grand';
_fc_props.reports = 'Rapports';
_fc_props.payroll = 'Paie';
_fc_props.support = 'Support';
_fc_props.username = 'Nom d\'Utilisateur';
_fc_props.all = 'Tous';
_fc_props.question = 'Question';
_fc_props.select_employee = 'Sélectionner Salarié';
_fc_props.phone = 'Téléphone';
_fc_props.january = 'janvier';
_fc_props.february = 'février';
_fc_props.march = 'mars';
_fc_props.april = 'avril';
_fc_props.may = 'mai';
_fc_props.june = 'juin';
_fc_props.july = 'juillet';
_fc_props.august = 'août';
_fc_props.september = 'septembre';
_fc_props.october = 'octobre';
_fc_props.november = 'novembre';
_fc_props.december = 'decembre';
_fc_props.are_you_sure_you_want_to_delete = 'Voulez-vous vraiment supprimer?';
_fc_props.unknown = 'Inconnu';
_fc_props.select_all = 'Tout sélectionner';
_fc_props.equipment = 'Equipement';
_fc_props.region = 'Région';
_fc_props.no_data = 'Pas de Données';
_fc_props.hotspot = 'Gardiennage';
_fc_props.address = 'Adresse';
_fc_props.retrieving_address = 'Recherche d\'Adresse';
_fc_props.address_not_found = 'Adresse introuvable';
_fc_props.active_time = 'Temps Actif';
_fc_props.destination_time = 'Heure d\'Arrivée à Destination';
_fc_props.complete_time = 'Temps Total';
_fc_props.status_report = 'Rapport d\'Etat';
_fc_props.export_report = 'Exporter Rapport';
_fc_props.download_report = 'Télécharger Rapport';
_fc_props.view_report = 'Afficher Rapport';
_fc_props.duration = 'Durée';
_fc_props.min = 'Minutes';
_fc_props.start_date_time = 'Date/Heure de Début';
_fc_props.select_start_date = 'Sélectionner Date de Début';
_fc_props.end_date_time = 'Fin Date/Heure';
_fc_props.select_end_date = 'Sélectionner Date fin';
_fc_props.trip_time = 'Temps de Trajet';
_fc_props.travel_time = 'Temps de Route';
_fc_props.description = 'Déscription';
_fc_props.map_controls = 'Commandes Carte';
_fc_props.road = 'Route';
_fc_props.aerial = 'Aérien';
_fc_props.hybrid = 'Hybride';
_fc_props.trip = 'Trajet';
_fc_props.map = 'Carte';
_fc_props.plant = 'Usine';
_fc_props.select_date = 'Sélectionner Date';
_fc_props.apply = 'Appliquer';
_fc_props.save_settings = 'Sauvegarder Règlages';
_fc_props.false = 'Faux';
_fc_props.delete_confirmation = 'Supprimer Confirmation';
_fc_props.last_name = 'Nom de Famille';
_fc_props.none_selected = 'Aucune Sélection';
_fc_props.start_date = 'Date de Démarrage';
_fc_props.group_by = 'Regrouper par';
_fc_props.equipment_employee = 'Equipment / Salarié';
_fc_props.inactive = 'Inactif';
_fc_props.active = 'Actif';
_fc_props.note_checking_may_affect_performance = 'Note: Cocher peut affecter la performance';
_fc_props.show_distancefuel_used = 'Afficher Distance/Consommation de Carburant';
_fc_props.mins = 'Mins (minutes)';
_fc_props.threshold_time = 'Heure Seuil';
_fc_props.include_voided_tickets = 'Joindre les Tickets Invalidés';
_fc_props.note_all_times_listed_are_in_minutes = 'Note: Toutes les heures listées sont en minutes';
_fc_props.please_select_a_job_order_or_equipment = 'Veuillez sélectionner un emploi, une commande, une usine, un équipement ou un employé';
_fc_props.device = 'Appareil';
_fc_props.select_equipment = 'Sélectionner Equipment';
_fc_props.all_equipment_types = 'Tous Types d\'Equipements';
_fc_props.units = {};
_fc_props.units.liters = 'Litres';
_fc_props.type = 'Type';
_fc_props.no_data_available = 'Aucune donnée disponibile';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Distance et Carburant par Région';
_fc_props.menu_settings_title_settings = 'Règlages';
_fc_props.menu_settings_title_alerts = 'Alertes';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Équipement';
_fc_props.menu_settings_title_hotspots_regions = 'Gardiennage & Régions';
_fc_props.days = '{0} jour(s)';
_fc_props.home = 'Accueil';
_fc_props.find = 'Chercher';
_fc_props.close_this_panel = 'fermer ce panneau';
_fc_props.filters = 'Filtres';
_fc_props.start = 'Commencer';
_fc_props.end = 'Fin';
_fc_props.location_details = 'Détails Location';
_fc_props.first_name = 'Prénom';
_fc_props.material_per_hour = 'Matériel par Heure';
_fc_props.equipment_pound = 'Numéro d\'Equipement';
_fc_props.equipment_type = 'Type des Equipements';
_fc_props.options = 'Options';
_fc_props.zoom_on_cursor_position = 'Position Curseur Zoom On';
_fc_props.display_equipment_identifier = 'Affichage Identifiant Equipments';
_fc_props.view_equipment = 'Afficher Equipements';
_fc_props.view_hotspots = 'Afficher Points Hotspot';
_fc_props.view_regions = 'Afficher Régions';
_fc_props.use_standard_filters = 'Utiliser Filtres Standards';
_fc_props.home_plant = 'Usine d\'Origine';
_fc_props.last_plant = 'Dernière Usine';
_fc_props.equip_types = 'Types d\'Equipements';
_fc_props.equip_groups = 'Groupes d\'Equipements';
_fc_props.orders = 'Commandes';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Utiliser le filtre Equipements';
_fc_props.all_selected = 'Tout Sélectionné';
_fc_props.of = 'de';
_fc_props.your_map_session_has_expired = 'Votre Session Carte a expiré, SVP rafraîchir la page.';
_fc_props.equipment_short = 'Equip.';
_fc_props.job_info_for = 'Info Tâche pour';
_fc_props.order_info_for = 'Info commande pour';
_fc_props.ticket_info_for_vehicle = 'Info Ticket pour Vahicule';
_fc_props.inbox = 'Boîte de Réception';
_fc_props.outbox = 'Boîte d\'Envoi';
_fc_props.alerts = 'Alertes';
_fc_props.critical = 'Critique';
_fc_props.important = 'Important';
_fc_props.informational = 'Informationel';
_fc_props.view_routes = 'Afficher Routes';
_fc_props.hours_size = 'Taille Heures';
_fc_props.used_count = 'Décompte Utilisé';
_fc_props.last_login = 'Dernière Connexion';
_fc_props.last_vehicle = 'dernier véhicule';
_fc_props.regions = 'Régions';
_fc_props.driver = 'Chauffeur';
_fc_props.js_delete = 'Supprimer';
_fc_props.move = 'Déplacer';
_fc_props.edit_hotspot = 'Editer Point Hotspot';
_fc_props.adjust_shape = 'Ajuster Forme';
_fc_props.edit_region = 'Editer Région';
_fc_props.enter_a_start_address = 'Veuillez saisir une Adresse de début';
_fc_props.select_a_start_hotspot = 'Veuillez sélectionner un point Hotspot de début';
_fc_props.failed_to_find_start_hotspot = 'Impossible de trouver le point Hotspot de début.';
_fc_props.invalid_latitudelongitude = 'Latitude/Longitude Invalide';
_fc_props.enter_an_end_address = 'Veuillez saisir une Adresse de fin';
_fc_props.select_an_end_hotspot = 'Veuillez sélectionner un point Hotspot de fin';
_fc_props.failed_to_find_end_hotspot = 'Impossible de trouver le point Hotspot de fin.';
_fc_props.print = 'Imprimer';
_fc_props.these_directions_are_for_informational_purposes = 'Ces directions sont fournies à des fins d\'information seulement. Il n\'y a pas de garanti quant à leur précision ou leur caractère complet. Des travaux publics, une circulation dense, ou d\'autres événements peuvent occasionner des conditions de circulation différentes.';
_fc_props.enter_an_address = 'Veuillez saisir une Adresse';
_fc_props.select_a_hotspot = 'Veuillez sélectionner un point Hotspot';
_fc_props.failed_to_find_hotspot = 'Point Hotspot introuvable.';
_fc_props.select_a_vehicle = 'Veuillez sélectionner un véhicule';
_fc_props.failed_to_find_vehicle = 'Véhicule introuvable.';
_fc_props.failed_to_find_address = 'Adresse introuvable.';
_fc_props.failed_to_find_vehicles = 'Impossible de trouver de véhicule dans les critères de recherche.';
_fc_props.get_address = 'Chercher Adresse';
_fc_props.results_for = 'Résultats pour';
_fc_props.error_retrieving_results = 'Erreur de Récupération de Résultats';
_fc_props.no_results_found = 'Pas de Résultat';
_fc_props.address_is_required = 'Adresse Obligatoire';
_fc_props.city_is_required = 'Ville Obligatoire';
_fc_props.set_location = 'Régler Location';
_fc_props.set_area_of_interest = 'Régler Zone d\'Intérêt';
_fc_props.center_map_here = 'Centrer Carte Ici';
_fc_props.zoom_to_street_level = 'Zoom sur la Rue';
_fc_props.set_start = 'Régler Début';
_fc_props.set_end = 'Régler Fin';
_fc_props.delete_node = 'Supprimer Noeud';
_fc_props.create_a_hotspot = 'Créer un Hotspot';
_fc_props.create_a_region = 'Créer une Région';
_fc_props.toggle_shapes = 'Basculer les Formes';
_fc_props.refresh_map = 'Rafraîchir Carte';
_fc_props.more_options = 'Plus d\'Options';
_fc_props.recenter_map = 'Recentrer Carte';
_fc_props.zoom_to_vehicle = 'Zoom sur Véhicule';
_fc_props.apply_filters = 'Appliquer Filtres';
_fc_props.grid_view = 'Affichage Grille';
_fc_props.list_view = 'Affichage de Liste';
_fc_props.click_to_sort = 'Cliquer pour trier';
_fc_props.message_history = 'Historique Messages';
_fc_props.equipment_types = 'Types d\'Equipments';
_fc_props.send_message = 'Envoyer Message';
_fc_props.last_load = 'Dernier Chargement';
_fc_props.last_load_on = 'Dernier Chargement le';
_fc_props.manual_logout = 'Déconnexion Manuelle';
_fc_props.manual_login = 'Connexion Manuelle';
_fc_props.no_employees_logged_in = 'Pas de Salarié Connecté';
_fc_props.select_phone = 'Sélectionner Téléphone';
_fc_props.you_must_select_a_phone = 'Vous devez Sélectionner un Téléphone, des Equipements et un Salarié';
_fc_props.are_you_sure_you_want_to_logout = 'Etes-vous certain de vouloir vous déconnecter?';
_fc_props.fix_time = 'Corriger Heure';
_fc_props.to_next_fix = 'Correction Suivante';
_fc_props.minutes_to_next_fix = 'Minutes avant Correction Suivante';
_fc_props.liters = 'Litres';
_fc_props.destination = 'Destination';
_fc_props.return = 'Retour';
_fc_props.product = 'Produit';
_fc_props.no_records_found = 'Aucun fichier trouvé.';
_fc_props.vehicle_type = 'Type de Véhicule';
_fc_props.view_in_map = 'Tout Afficher sur la Carte';
_fc_props.uploads = 'Téléchargements entrants';
_fc_props.upload_csv_file = 'Télécharger le Ficher CSV';
_fc_props.permanent = 'Permanent';
_fc_props.expire_time = 'Heure d\'Expiration';
_fc_props.please_select_x_for_tickets_marked_active = 'Veuillez Sélectionner {0} pour les Tickets indiqués commme ACTIVE';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Les Tickets Multiples Tickets indiqués comme ACTIVE pour le même {0}';
_fc_props.failure_connecting_to_servlet = 'Echec de Connexion au Servelet.';
_fc_props.status_time_summary = 'Résumé Temps Statut';
_fc_props.view_in_map_one = 'Affacher sur la Carte';
_fc_props.question_report = 'Rapport des Questions';
_fc_props.end_date = 'Date de Fin';
_fc_props.trailer_number = 'Numéro de remorque';
_fc_props.region_panel = 'Panel Région';
_fc_props.hotspot_panel = 'Panel Hotspot';
_fc_props.shape = 'Forme';
_fc_props.circle = 'Cercle';
_fc_props.polygon = 'Polygone';
_fc_props.click_the_map_to_set_the_location = 'Cliquer sur la carte pour régler la location souhaitée.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Cliquer sur la carte pour régler le centre du point hotspot.';
_fc_props.click_the_map_to_set_the_region_center = 'Cliquer sur la carte pour régler le centre de la région.';
_fc_props.click_and_drag_points = 'Effectuer un cliquer-déplacer sur les points pour modifier la forme. Cliquer sur SAVE pour sauvegarder les modifications.';
_fc_props.size = 'Taille';
_fc_props.are_you_sure_you_want_to_move_this = 'Etes-vous certain de vouloir déplacer ceci';
_fc_props.are_you_sure_you_want_to_delete_this = 'Etes-vous certain de vouloir supprimer ceci';
_fc_props.please_enter_a_valid_size = 'S\'il vous plaît entrer une taille valide';
_fc_props.failed_to_save = 'Echec de sauvegarde.';
_fc_props.failed_to_move = 'Echec de déplacement.';
_fc_props.failed_to_delete = 'Echec de la suppression.';
_fc_props.x_hours = '{0} heures';
_fc_props.hide_hotspots = 'Cacher les Points Hotspot';
_fc_props.show_regions = 'Afficher Régions';
_fc_props.show_hotspots = 'Afficher Points Hotspot';
_fc_props.hide_regions = 'Cacher Régions';
_fc_props.numeric = 'Numérique';
_fc_props.message_type = 'Type de Message';
_fc_props.text = 'Texte';
_fc_props.yes_no = 'Oui/non';
_fc_props.responses = 'réponses';
_fc_props.disabled = 'Désactivé';
_fc_props.group_name = 'Nom de Groupe';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Etes-vous certain de vouloir supprimer le format \'{0}\'?';
_fc_props.phone_number = 'Numéro de Téléphone';
_fc_props.interface_pound = '# Interface';
_fc_props.field = 'Champ';
_fc_props.default_label = 'etiquette Défaut';
_fc_props.custom_label = 'Etiquette personnalisée';
_fc_props.you_must_enter_a_label = 'Vous devez entrer une etiquette.';
_fc_props.enabled = 'Activé';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Intégration ticket';
_fc_props.settings.geofence_off_open_hotspots = 'Gardiennage sur Points Hotspot ouverts';
_fc_props.settings.associate_open_hotspots_wticket = 'Associer des Points Hotspot Ouverts avec/Ticket';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Autoriser le gardiennage sur des points hotspot ouverts ou seulement des points hotspot associés au ticekt du chauffeur';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'S\'il faut ou non associer les Points Hotspot ouverts aux Tickets quand un véhicule y entre.';
_fc_props.settings.void_ticket_without_destination = 'Annuler le ticket s\'il n\'y a pas de destination';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'si un ticket est attribué alors qu\'un autre est actif, si le ticket actif est sans destination il sera annulé.';
_fc_props.settings.autocreate_hotspots = 'Auto-généré des points Hotspot';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'S\'il faut ou non auto-générer des points Hotspot à parti de l\'adresse de destination quant un ticket est attribué';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Activer le ticket dés la réception';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Est-ce que le ticket est actif quand il est reçu par TrackIt (Défaut \'vrai\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Annuler automatiquement un ticket actif sur réception de minutes';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Combien de temps d\'activité faut-il au ticket couramment actif avant de l\'annuler automatiquement(Défaut \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Maximum Age d\'Activation d\'un (Minutes)';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Quel âge, en minutes, peut avoir un ticket lors de son activation dans TrackIt? (Défault \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Nb de jours maxi de non-utilisation d\'un point Hotspot';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Règle le nombre maximum de jours qu\'un point hotspot peut rester dormant et être néanmoins réactivé';
_fc_props.settings.radius_multiplier = 'Multiplicateur de rayon';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Quelle par quel chiffre multiplier le rayon quand un ticket est reçu de l\'expédition';
_fc_props.settings.ticket_message_date_format = 'Format de Date De Message Ticket';
_fc_props.am = 'Matin';
_fc_props.pm = 'après-midi/soirée';
_fc_props.settings.ticket_message = 'Message Ticket';
_fc_props.settings.add_ticket_element = 'Ajouter elément Ticket';
_fc_props.driver_performance = 'Performance Chauffeur';
_fc_props.update_failed_can_not_connect = 'Echec de Mise a jour: Impossible de se connecter au servelet.';
_fc_props.unknown_error = 'Erreur Inconnu';
_fc_props.selected = 'Sélectioné';
_fc_props.web_service = 'Service Web';
_fc_props.error_processing_request = 'Erreur de traitement de requête';
_fc_props.email_address = 'Adresse Email';
_fc_props.id = 'ID';
_fc_props.last_used = 'Dernière Utiliisation';
_fc_props.error_invalid_vehiclenum = 'Erreur Numéro de véhicule invalide';
_fc_props.error_vehicle_is_not_logged_in = 'Erreur: Véhicule non Connecté.';
_fc_props.users = 'Utilisateurs';
_fc_props.you_dont_have_access_to_this_page = 'Vous n\'avez pas l\'accès pour voir cette page. Veuillez contacter votre administrateur système pour y accéder.';
_fc_props.previous_week = 'Semaine Précédente';
_fc_props.status_breakdown = 'Détail de Statut';
_fc_props.select_plant_or_employee_group = 'Vous devez d\'abord sélectionner soit une usine, soit un groupe d\'employés.';
_fc_props.cannot_select_plant_and_employee_group = 'Vous ne pouvez pas sélectionner simultanément une usine et un groupe d\'employés.';
_fc_props.odometer_must_be_a_number = 'Le début du compteur kilométrique doit être exprimé en chiffres';
_fc_props.ending_odometer_must_be_a_number = 'La fin du compteur kilométrique doit être exprimée en chiffres';
_fc_props.beginning_hour_meter_must_be_a_number = 'Le début du compteur horaire doit être exprimé en chiffres';
_fc_props.ending_hour_meter_must_be_a_number = 'La fin du compteur horaire doit être exprimée en chiffres';
_fc_props.error_loading_status_change_page = 'Erreur de chargement de la page de changement de statut.';
_fc_props.x_is_an_invalid_time_format = '{0} est un format d\'horaire invalide. Le format doit être YYYY-MM-JJ HH:MM:SS';
_fc_props.x_for_schedule_login = '{0} pour connexion à l\'emploi du temps';
_fc_props.x_for_schedule_logout = '{0} pour déconnexion de l\'emploi du temps';
_fc_props.x_for_actual_login = '{0} pour la connexion véritable';
_fc_props.x_for_actual_logout = '{0} pour la déconnexion véritable';
_fc_props.by_refreshing_the_time_card_detail = 'Rafraîchir les détails de la fiche d\'heures vous fera perdre toutes les modifications. Continuer?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Etes-vous certain de vouloir supprimer {0}?';
_fc_props.settings.command_mobile_ticket_url = 'URL Commande Ticket Mobile';
_fc_props.year = 'Année';
_fc_props.make = 'Marque';
_fc_props.model = 'Modèle';
_fc_props.settings.command_mobile_ticket_password = 'Force mobile Ticket Mot de passe';
_fc_props.settings.command_mobile_ticket_auth_token = 'Force mobile Ticket Auth Token';
_fc_props.target = 'Cible';
_fc_props.number_of_digits = 'Nombre de chiffres';
_fc_props.number_of_integer_digits = 'Nombre de chiffres entiers';
_fc_props.number_of_fractional_digits = 'Nombre de chiffres fractionnaires';
_fc_props.search = 'Recherche';
_fc_props.settings.command_mobile_ticket_date_order = 'Force mobile billet Date Ordre';
_fc_props.settings.command_mobile_ticket_date_length = 'Force mobile billet Date Longueur';
_fc_props.settings.command_mobile_ticket_date_separator = 'Force mobile billet Date Separator';
_fc_props.settings.command_mobile_ticket_slash = '/ (Slash)';
_fc_props.settings.command_mobile_ticket_dash = '- (Tiret)';
_fc_props.settings.command_mobile_ticket_period = '. (Période)';
_fc_props.settings.command_mobile_ticket_space = '(Espace)';
_fc_props.settings.command_mobile_ticket_time_length = 'Force mobile Ticket Temps Longueur';
_fc_props.settings.command_mobile_ticket_time_separator = 'Force mobile Ticket Temps Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (Deux points)';
_fc_props.settings.command_mobile_ticket_time_format = 'Force mobile Ticket Format de l\'heure';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Nombre utilisation Interface';
_fc_props.mechanic = 'Mécanicien';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Poll Interval (secondes)';
_fc_props.driving_time = 'Le temps de conduite';
_fc_props.find_near_plants = 'Trouver à proximité des usines';
_fc_props.please_create_hot_spot_first = 'S\'il vous plaît créer hotspot premier';
_fc_props.please_enter_hot_spot_description = 'S\'il vous plaît entrez la description de point chaud';
_fc_props.please_select_a_hot_spot_type = 'S\'il vous plaît sélectionner un type de point chaud';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Envoyer pilote Email';
_fc_props.directions_not_found = 'Non valide {0} Adresse. Indications introuvables.';
_fc_props.ws_user = 'Utilisateur';
_fc_props.ws_service = 'Un service';
_fc_props.ws_method = 'la méthode';
_fc_props.ws_params = 'params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Origine et destination';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Aidez-moi';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Utilisez Alt Nombre des employés';
_fc_props.settings.command_mobile_ticket_android_url = 'Android mobile Ticket URL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android mobile Ticket secret';
_fc_props.employee_x_has_no_logout_on_y = 'Employé {0} n\'a pas la déconnexion d\'un changement à partir de la {1}.';
_fc_props.phone_number_has_duplicates = 'Les numéros de téléphone ont des entrées en double';
_fc_props.ounce = 'Once';
_fc_props.water_extra = 'eau supplémentaire';
_fc_props.always = 'Toujours';
_fc_props.work_types_must_be_unique = 'Types de travail doivent être uniques';
_fc_props.manufacturer = 'Fabricant';
_fc_props.need_to_enter_seconds = 'Besoin d\'entrer des secondes.';
_fc_props.copy = 'Copie';
_fc_props.create_in_existing_required = 'La création en existant doit être sélectionnée pour créer un point d\'accès itinérant.';
_fc_props.break = 'Pause';
_fc_props.add_export_data_type = 'Ajouter un type de données d\'exportation';
_fc_props.edit_export_data_type = 'Modifier le type de données d\'exportation';
_fc_props.delete_export_data_type = 'Supprimer l\'exportation du type de données';
_fc_props.export_data_type = 'Type de données d\'exportation';
_fc_props.error_updating_export_data_type = 'Erreur lors de la mise à jour du type de données d\'exportation';
_fc_props.settings.address_key = 'Clé d\'adresse';
_fc_props.settings.address_key_hover = 'Options de la clé d\'adresse';
_fc_props.settings.address_key_options = 'Options de la clé d\'adresse';
_fc_props.more_filter_options = 'Plus d\'options de filtre';
_fc_props.unauthorized_mapit_app = 'Cette version de MapIt n\'est pas autorisée, mettez MapIt à jour dès que possible.';
_fc_props.zello_timeout = 'Timeout s\'est produit en contactant Zello.';
_fc_props.user_agreement_text = 'En vous connectant, vous acceptez le';
_fc_props.user_agreement = 'Accord de l\'utilisateur';
_fc_props.upload = 'Télécharger';
_fc_props.x_mins_left = '{0} minute (s) restante';
_fc_props.continuous_location_tracking_colon = 'Suivi de localisation continu:';
_fc_props.hired_hauler_cannot_register_device = 'Hanger Hauler ne peut pas enregistrer de nouveau périphérique.';
_fc_props.timecard_does_not_contain_shift = 'La carte de temps des employés ne contient pas le quart (informations sur la carte de temps) fourni';
_fc_props.timecard_not_found = 'Carte de temps des employés introuvable';
_fc_props.vehicle_must_be_assigned_for_clt = 'Le véhicule doit être affecté à la localisation des lieux.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Véhicule non affecté le suivi continu sera désactivé.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Perte de connexion avec EDC. Veuillez vérifier que votre WiFi et / ou votre Bluetooth sont activés.';
_fc_props.acknowledge_all = 'Reconnaître tout';
_fc_props.expand = 'Développer';
_fc_props.collapse = 'Effondrer';
_fc_props.expand_all = 'Développer tout';
_fc_props.collapse_all = 'Collapse All';
_fc_props.last_update = 'Dernière mise à jour';
_fc_props.acknowledge_alert = 'Reconnaître l\'alerte';
_fc_props.acknowledge_message = 'Accuser réception du message';
_fc_props.acknowledge_all_alerts = 'Reconnaître toutes les alertes';
_fc_props.acknowledge_all_messages = 'Reconnaître tous les messages';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'La tolérance doit être un entier valide compris entre -99 et 99 (les deux inclus)';
_fc_props.gps_permission_required = 'Permission GPS requise';
_fc_props.permissions_required = 'Autorisations requises';
_fc_props.change_user = 'Changer d\'utilisateur';
_fc_props.account_not_registered_with_carrier = 'Ce compte n\'est pas enregistré auprès d\'un transporteur';
_fc_props.slump_loss = 'Perte d\'affaissement';
_fc_props.slump_loss_help = 'Aide de perte d\'affaissement';
_fc_props.did_not_receive_any_edc_devices = 'N\'a reçu aucun appareil EDC.';
_fc_props.edc_record_not_found = 'Enregistrement EDC introuvable';
_fc_props.disable_hotspot_prompt = 'S\'il vous plaît appuyez sur "OK" lorsque vous êtes invité à désactiver votre point d\'accès.';
_fc_props.edc_device_is_in_use = 'EDC {0} est en cours d\'utilisation.';
_fc_props.an_unknown_exception_has_occurred = 'Une exception inconnue s\'est produite';
_fc_props.login_has_timed_out = 'La connexion a expiré';
_fc_props.use_paper_logs = 'Utiliser des journaux papier';
_fc_props.eld_login_failed = 'Échec de la connexion aux heures de service';
_fc_props.setup_time_off_type = 'Veuillez définir un type de congé.';
_fc_props.view_load_properties = 'Afficher les propriétés de charge';
_fc_props.couldnt_download_edc_firmware = 'Impossible de télécharger le micrologiciel EDC.';
_fc_props.password_incorrect = 'Mot de passe incorrect';
_fc_props.edc_configuration_password_prompt = 'En raison de l\'état de ce périphérique, vous devez fournir le mot de passe du programme d\'installation à configurer.';
_fc_props.edc_configuration_check_failed = 'La validation des enregistrements du serveur EDC a échoué. S\'il vous plaît vérifier les messages d\'erreur pour plus de détails.';
_fc_props.configuration_issues_prompt = 'Les problèmes suivants ont été détectés lors de la configuration de l\'EDC sélectionné pour le chariot spécifié';
_fc_props.checking_password = 'Vérification du mot de passe';
_fc_props.reset_password_instructions_sent = 'Instructions pour réinitialiser votre mot de passe envoyé à l\'adresse e-mail {0}.';
_fc_props.reset_password_failed = 'Réinitialiser le mot de passe a échoué, essayez à nouveau.';
_fc_props.forgot_password = 'Mot de passe oublié?';
_fc_props.enter_valid_email = 'Adresse e-mail invalide, veuillez entrer une adresse e-mail valide et réessayer.';
_fc_props.issues_while_updating_edc_record = 'L\'EDC a été configuré avec succès. Cependant, les enregistrements de l\'EDC sur le serveur TrackIt posent des problèmes qui pourraient l\'empêcher de fonctionner. Veuillez appeler le support pour corriger les informations.';
_fc_props.sending = 'Envoi...';
_fc_props.minimum_ratio = 'Ratio minimum';
_fc_props.vin = 'NIV';
_fc_props.probe_rpm_not_in_range = 'Le régime du tambour n\'est pas dans la plage';
_fc_props.probe_failure = 'Échec de la sonde';
_fc_props.unknown_probe_data_failure = 'Échec des données de sonde inconnues';
_fc_props.no_loaded_status_meaning = 'Statut non chargé';
_fc_props.no_depart_status_meaning = 'Aucun statut de départ';
_fc_props.no_arrive_status_meaning = 'Aucun statut d\'arrivée';
_fc_props.no_begin_work_status_meaning = 'Non Commencer Statut Travail';
_fc_props.no_fully_mixed_status_meaning = 'Pas de statut totalement mixte';
_fc_props.no_begin_work_or_at_job_status = 'Statut de début de travail ou de travail non présent';
_fc_props.driver_didnt_follow_procedure = 'Le conducteur n\'a pas suivi la procédure (ne pas ralentir le tambour)';
_fc_props.probe_malfunction = 'Dysfonctionnement de la sonde (défaillance interne, etc.)';
_fc_props.no_data_from_probe = 'Aucune donnée de la sonde (perte de données)';
_fc_props.missing_probe_calibration = 'Aucune donnée d\'étalonnage de sonde';
_fc_props.probe_pressure_not_in_range = 'La pression de la sonde n\'est pas dans la plage';
_fc_props.arrival = 'Arrivée';
_fc_props.small_load = 'PETITE CHARGE';
_fc_props.dry_slump = 'SLUMP SEC';
_fc_props.probe_remixing_turns = 'Remixage';
_fc_props.turns_needed_to_remix = 'Eau ajoutée - Mélange supplémentaire nécessaire';
_fc_props.probe_remixing_finished = 'Remixage terminé';
_fc_props.probe_battery_low_alert = 'Alerte de pile de sonde faible';
_fc_props.probe_battery_low_message = 'Batterie de sonde basse';
_fc_props.probe_battery_low = 'Batterie de sonde basse';
_fc_props.alert_when_probe_battery_low = 'Alerte lorsque la pile de la sonde est faible';
_fc_props.probe_sensor_fatal_failure = 'Défaillance fatale du capteur';
_fc_props.probe_thermistor_failure = 'Défaillance de la thermistance';
_fc_props.probe_sensor_stuck_in_concrete = 'Capteur coincé dans le béton';
_fc_props.probe_sensor_over_weight = 'Capteur sur le poids';
_fc_props.probe_batteries_low = 'Batterie faible';
_fc_props.no_probe_data = 'Aucune donnée de sonde:';
_fc_props.check_probe = 'Sonde de contrôle';
_fc_props.service_probe = 'Sonde de service';
_fc_props.below_x = 'En dessous de {0}';
_fc_props.above_x = 'Au-dessus de {0}';
_fc_props.probe_serial_number_invalid_format = 'Le numéro de série de la sonde n’est pas au format valide, comme indiqué dans l’espace réservé pour le champ';
_fc_props.talk_dnd_permission_prompt = 'Talk nécessite l\'autorisation NE PAS DERANGER.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'Statut changé trop rapidement';
_fc_props.is_empty = 'Est vide';
_fc_props.slump_increased_plasticizer_question = 'Un plastifiant a-t-il été ajouté ?';
_fc_props.slump_increased_water_added_question = 'Quelle quantité d’eau non mesurée a été ajoutée ?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'La surveillance de la sonde et du cycle de livraison ne sont pas prises en charge ensemble sur le même appareil.';
_fc_props.no_data_from_sensor = 'Aucune donnée du capteur (perte de données)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Type de système';
_fc_props.magnet_count = 'Nombre d\'aimants';
_fc_props.sensors_setup = 'Configuration des capteurs';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM nécessite le réglage de l\'adresse MAC du boîtier d\'E / S, du type de tambour, du type DCM et du nombre d\'aimants.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Volume avec billet';
_fc_props.drs_current_volume = 'Volume actuel';
_fc_props.drs_current_water_cement_ratio = 'Rapport eau / ciment actuel';
_fc_props.drs_target_water_cement_ratio = 'Rapport cible eau / ciment';
_fc_props.drs_total_revolutions = 'Total';
_fc_props.drs_current_volume_lp_small = 'Actuel';
_fc_props.drum_revolutions = 'Révolutions du tambour';
_fc_props.drs_total_revolutions_lp_small = 'Total';
_fc_props.drs_charging_revolutions_lp_small = 'Mise en charge';
_fc_props.drs_discharging_revolutions_lp_small = 'Déchargement';
_fc_props.drs_target_wc_lp_small = 'Cible W / C';
_fc_props.drs_current_wc_lp_small = 'Courant W / C';
_fc_props.drs_ticketed_volume_lp_small = 'Billetterie';
_fc_props.android_location_disclosure = 'TrackIt utilise une localisation fine et grossière pour collecter des données de localisation en arrière-plan même lorsqu\'il n\'est pas utilisé, afin de permettre l\'état automatique et la visibilité de la carte en temps réel pour l\'expédition.';
_fc_props.android_location_usage = 'Divulgation de l\'emplacement';
_fc_props.end_pour_trigger_threshold = 'Seuil de déclenchement de fin de coulée';
_fc_props.drs_wiring_error = 'Erreur de câblage';
_fc_props.drs_wiring_pulse_error = 'Erreur d\'impulsion de câblage';
_fc_props.drs_wiring_magnet_error = 'Erreur d\'aimant de câblage';
_fc_props.drs_magnet_missing_error = 'Erreur d\'aimant manquant';
_fc_props.android_location_disclosure_3p = 'TrackIt collecte les données de localisation en arrière-plan, pour permettre le statut automatique et la visibilité de la carte en temps réel pour l\'expédition. Lorsqu\'il est pointé par l\'expéditeur, l\'emplacement n\'est pas partagé.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Veuillez sélectionner Full MDM ou Trackit Remote Support';
_fc_props.move_up = 'Déplacer vers le haut';
_fc_props.move_down = 'Descendre';
_fc_props.driver_not_found = 'Pilote introuvable.';
_fc_props.multiple = 'Plusieurs';
_fc_props.water_meter_installed = 'Compteur d\'eau installé';
_fc_props.delete_waypoint = 'Supprimer un waypoint';
_fc_props.wash_sensor_installed = 'Capteur de lavage installé';
_fc_props.washing_start_time = 'Heure de début du lavage';
_fc_props.end_washing = 'Fin du lavage';
_fc_props.variable_rpms = 'Var RPM';
_fc_props.no_slump = 'Pas de marasme';
_fc_props.end_washing_duration = 'Terminer la durée du lavage';
_fc_props.privacy_policy = 'Politique de confidentialité';
_fc_props.privacy_policy_text = 'J\'ai lu et accepte le';
_fc_props.login_fail_accept_privacy_policy = 'La politique de confidentialité doit être acceptée pour continuer.';
_fc_props.rotation_sensor = 'Capteur de rotation';
_fc_props.pto_sensor = 'Capteur de prise de force';
_fc_props.pto_sensor_installed = 'Capteur de prise de force installé';
_fc_props.polarity_reversed = 'Polarité inversée';
_fc_props.pto_sensor_type = 'Type de capteur de prise de force';
_fc_props.sensor_type = 'Type de capteur';
_fc_props.prompt_select_pto_sensor_type = 'Vous devez sélectionner un "Type de capteur PTO" si vous avez sélectionné un "Capteur PTO" comme type de capteur principal.';
_fc_props.priming_turns_at_full_load = 'L\'amorçage tourne à pleine charge';
_fc_props.priming_turns_at_ten_percent_load = 'L\'amorçage tourne à 10 % de charge';
_fc_props.weight_units = 'Unités de poids';
_fc_props.settings.send_status = 'Envoyer l\'état/l\'emplacement au pont d\'événements';
_fc_props.volume_difference = 'Différence de volume';
_fc_props.mobile_ticket_iframe_url = 'URL de l\'IFrame du ticket mobile';
_fc_props.mobile_ticket_user = 'Utilisateur de billet mobile';
_fc_props.mobile_ticket_password = 'Mot de passe du billet mobile';
_fc_props.current_truck_status = 'Statut actuel du camion';
_fc_props.estimated_symbol = '??';
_fc_props.almost_equal_to_symbol = '??';
_fc_props.triggered_by_max_revolutions = 'Déclenché par des révolutions max';
_fc_props.alerts_by_type = 'Alertes par type';
_fc_props.water_flow_meter = 'Débitmètre d\'eau';
_fc_props.flow_meter_no_pulses_warning = 'Pas de pouls - Avertissement';
_fc_props.flow_meter_no_pulses_critical = 'Pas d\'impulsions - Critique';
_fc_props.flow_meter_excessive_pulses = 'Impulsions excessives';
_fc_props.flow_meter_constantly_flowing = 'Circuler en permanence';
_fc_props.flow_meter_good = 'Bon';
_fc_props.whats_new = 'Quoi de neuf';
_fc_props.whats_new_widget_text = 'Saviez-vous que nous mettons à jour TrackIt au moins une fois par mois ? Recevez les dernières mises à jour et astuces.';
_fc_props.get_the_scoop = 'Obtenez le scoop';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Des analyses commerciales qui améliorent vos résultats, désormais incluses avec TrackIt';
_fc_props.find_out_more = 'En savoir plus';
_fc_props.daily_breakdown = 'Répartition quotidienne';
_fc_props.question_summary = 'Résumé des questions';
_fc_props.trip_summary = 'Résumé du voyage';
_fc_props.manage_employees = 'Gérer les employés';
_fc_props.manage_hotspots = 'Gérer les points d\'accès';
_fc_props.manage_devices = 'Gérer les appareils';
_fc_props.manage_equipment = 'Gérer l\'équipement';
_fc_props.manage_users = 'gérer les utilisateurs';
_fc_props.manage_statuses = 'Gérer les statuts';
_fc_props.manage_alerts = 'Gérer les alertes';
_fc_props.popular_report_links = 'Liens de rapport populaires';
_fc_props.popular_settings_links = 'Liens de paramètres populaires';
_fc_props.sidemenu_settings = 'Paramètres';
_fc_props.loads_per_driver = 'Charges par pilote';
_fc_props.please_select_application = 'Veuillez sélectionner au moins une candidature';
_fc_props.external_app_processing = 'Traitement sur une application externe';
_fc_props.external_app_sent = 'Envoyé à une application externe';
_fc_props.external_app_received = 'Reçu par l\'application externe';
_fc_props.wait_time_at_job = 'Temps d\'attente au travail';
_fc_props.wait_time_at_plant = 'Temps d\'attente à l\'usine';
_fc_props.loading_time = 'Temps de chargement';
_fc_props.unloading_time = 'Temps de déchargement';
_fc_props.pre_post_trip = 'Pre/Post Trajet';
_fc_props.update_thresholds = 'Mettre à jour les seuils';
_fc_props.green = 'Vert';
_fc_props.yellow = 'Jaune';
_fc_props.red = 'rouge';
_fc_props.between = 'Entre';
_fc_props.num_of_loads = '# de charges';
_fc_props.num_of_drivers = '# de pilotes';
_fc_props.status_meaning_not_setup = 'Statut Signification non configuré';
_fc_props.or_less = 'Ou moins';
_fc_props.or_greater = 'Ou plus grand';
_fc_props.probe_self_diagnostic_state = 'État d\'autodiagnostic de la sonde';
_fc_props.update_current_truck_status_settings = 'Mettre à jour les paramètres actuels de l\'état du camion';
_fc_props.default_behavior = 'Par défaut (10 principaux statuts)';
_fc_props.combine_similar_statuses = 'Combinez les mêmes statuts de tous les types (boucles)';
_fc_props.use_other_category = 'Utiliser la catégorie \'Autre\'';
_fc_props.selected_statuses = 'Statuts sélectionnés';
_fc_props.ideas = 'Idées';
_fc_props.air_content = 'Contenu aérien';
_fc_props.air_measured_time = 'Temps mesuré dans l\'air';
_fc_props.ten_max = '10 maximum';
_fc_props.loads_per_plant = 'Charges par plante';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Impulsion du débitmètre non valide par volume.';
_fc_props.coming_soon_title = 'Plus de widgets à venir';
_fc_props.submit_your_ideas = 'Soumettez vos idées à notre portail d\'idées en utilisant l\'ampoule sur votre menu de navigation de gauche.';
_fc_props.selected_plants = 'Plantes sélectionnées';
_fc_props.update_loads_per_plant_settings = 'Mettre à jour les paramètres de charges par usine';
_fc_props.default_top_10_plants = 'Par défaut (10 principaux sites)';
_fc_props.phone_number_will_be_uploaded = 'L\'ID de l\'appareil sera téléchargé à des fins d\'enregistrement.';
_fc_props.include_deleted = 'Inclure supprimé';
_fc_props.primary_status_type = 'Type de statut principal';
_fc_props.additional_status_trigger = 'Déclencheurs d\'état supplémentaires';
_fc_props.update_tickets_destination = 'Mettre à jour la destination sur tous les tickets associés';
_fc_props.settings.send_destination_changes_to_connex = 'Envoyer les changements de destination à Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Le ticket est-il actif lorsqu\'il est reçu par TrackIt (par défaut \'toujours\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Activer automatiquement le ticket suivant lors de l\'achèvement du ticket précédent';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Devrait activer automatiquement le ticket suivant à la fin du ticket (\'true\' par défaut)';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Statut à l\'état inactif lorsqu\'aucun ticket actif';
_fc_props.break_down = 'Panne';
_fc_props.idle = 'Inactif';
_fc_props.pto_work_maximum_speed_limit = 'Limite de vitesse maximale de travail';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Associer le changement de statut qui complète un ticket avec ce ticket';
_fc_props.approaching = 'Approchant';
_fc_props.performing_task = 'Exécution d\'une tâche';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Êtes-vous sûr de vouloir supprimer Geogate {0} ?';
_fc_props.trackit_destination_changed_to_0 = 'Mettre à jour le ticket {0}. Destination modifiée.';
_fc_props.trackit_return_destination_changed_to_0 = 'Mettre à jour le ticket {0}. Retour modifié.';
_fc_props.menu_settings_title_geogate = 'Géogate';
_fc_props.add_geogate = 'Ajouter Geogate';
_fc_props.driver_enabled = 'Pilote activé';
_fc_props.dispatcher_enabled = 'Répartiteur activé';
_fc_props.runsheet_number = 'Numéro de feuille de route';
_fc_props.manual_load = 'Chargement manuel';
_fc_props.edit_geogate = 'Modifier Geogate';
_fc_props.handling = 'Manutention';
_fc_props.geogate_name = 'Géogate';
_fc_props.geogate_panel = 'Panneau Geogate';
_fc_props.vehicle_is_not_logged_in = 'Message non envoyé (le véhicule n\'est pas connecté)';
_fc_props.air = 'Air';
_fc_props.create_a_geogate = 'Créer un Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Cliquez sur la carte pour définir les points géogate.';
_fc_props.clock_out = 'Fin de l\'horloge';
_fc_props.handling_operation = 'Opération de manutention';
_fc_props.view_geogates = 'Voir les géogates';
_fc_props.select_a_geogate = 'Veuillez sélectionner un Geogate';
_fc_props.failed_to_find_geogate = 'Impossible de trouver Geogate';
_fc_props.adjust_geogate = 'Ajuster Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Date d\'expiration Heure Jours supplémentaires par défaut';
_fc_props.menu_settings_title_retention = 'La conservation des données';
_fc_props.category = 'Catégorie';
_fc_props.telemetry = 'Télémétrie';
_fc_props.ticket_data = 'Données de billet';
_fc_props._30_days = '30 jours';
_fc_props._6_months = '6 mois';
_fc_props._1_year = '1 an';
_fc_props._3_years = '3 années';
_fc_props.new_group = 'Nouveau groupe';
_fc_props.selected_vehicles = 'Véhicules sélectionnés';
_fc_props.group_name_is_required_field = 'Le nom du groupe est un champ obligatoire';
_fc_props.at_least_vehicle_should_be_selected = 'Au moins 1 véhicule doit être sélectionné';
_fc_props.groups = 'Groupes';
_fc_props.trucks = 'Camions';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate a été supprimé. Veuillez actualiser la page.';
_fc_props.air_timestamp = 'Horodatage aérien';
_fc_props.fail = 'ÉCHOUER';
_fc_props.stuck = 'BLOQUÉ';
_fc_props.dirty = 'SALE';
_fc_props.seasonal = 'Saisonnier';
_fc_props.category_details = 'Détails de la catégorie';
_fc_props.alert_events = 'Événements d\'alerte';
_fc_props.driver_performance_infractions = 'Infractions aux performances du conducteur';
_fc_props.telemetry_details = 'Données de localisation, points d\'accès, heure dans les points d\'accès, heure dans les régions, données DCM, données de sonde, données du moteur';
_fc_props.ticket_data_details = 'Données de billet';
_fc_props.general_details = 'Changements de statut, fiches de pointage, horaires';
_fc_props.wash = 'Lavage';
_fc_props.filter_options = 'Options de filtrage';
_fc_props.home_plants = 'Plantes d\'intérieur';
_fc_props.dcm = 'MCD';
_fc_props.pto_requires_pto_sensor_type_value = 'La prise de force DCM nécessite que le type de capteur de prise de force soit défini.';
_fc_props.invalid_io_box_mac_address = 'Adresse Mac du boîtier d\'E/S invalide';
_fc_props.access_denied = 'Accès refusé';
_fc_props.wash_events = 'Événements de lavage';
_fc_props.min_speed = 'Vitesse minimale';
_fc_props.temperature_change = 'Changement de température';
_fc_props.degrees_cap = 'Degrés';
_fc_props.washout_tracking = 'Suivi des lavages';
_fc_props.approaching_wash = 'Lavage à l\'approche';
_fc_props.arrival_wash = 'Arrivée Lavage';
_fc_props.performing_wash = 'Effectuer un lavage';
_fc_props.wash_complete = 'Lavage terminé';
_fc_props.must_be_positive_or_zero = 'Doit être positif ou nul !';
_fc_props.min_speed_greater_than_max = 'La vitesse minimale doit être inférieure à la vitesse maximale !';
_fc_props.clock_in = 'Horloge d\'entrée';
_fc_props.mix_water_at_x_to_y_for_t = 'Mélanger l\'eau à {0} à {1} tr/min pendant {2} tours.';
_fc_props.discharge_water_for_x_revolutions = 'Déchargez l\'eau pendant {0} tours.';
_fc_props.eod_drum_wash_complete = 'Lavage du tambour terminé ! <br> Stop Drum avec sonde en haut.';
_fc_props.washing_complete = 'Lavage guidé terminé';
_fc_props.eod_washout_no_data_available = 'Données du capteur indisponibles, veuillez terminer le processus de lavage.';
_fc_props.eod_washout_no_data_available_title = 'Lavage guidé non disponible';
_fc_props.menu_reports_title_ticket_time_summary = 'Résumé de l\'heure du ticket';
_fc_props.menu_reports_description_ticket_time_summary = 'Afficher les informations sur l\'heure du billet pour une commande ou un véhicule';
_fc_props.menu_reports_title_summary = 'Résumé';
_fc_props.menu_reports_title_ticket_summary = 'Résumé du billet';
_fc_props.menu_reports_description_summary1 = 'Afficher un résumé du temps passé dans les hotspots';
_fc_props.menu_dispatch_title_main = 'Principal';
_fc_props.menu_dispatch_title_login_registry = 'Registre de connexion';
_fc_props.menu_reports_title_main = 'Principal';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Voulez-vous vraiment supprimer ce type de données d\'exportation ?';
_fc_props.dvir_status_paper_logs = 'Bûches papier';
_fc_props.menu_reports_title_billing_report = 'Rapport de facturation';
_fc_props.menu_reports_description_billing_report = 'Rapport de facturation';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Statut au statut du ticket lors de l\'activation du travail';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Statut vers le statut du ticket lors de l\'activation du travail (par défaut « Faux »)';
_fc_props.status_change_type_drum_rotation_sensor = 'MCD';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Livres sterling';
_fc_props.units_weight_kilogram_label = 'Kilogrammes';
_fc_props.settings_weight = 'Lester';
_fc_props.units_pounds = 'Livres sterling';
_fc_props.units_kilograms = 'Kilogrammes';
_fc_props.geo_gate_edit_modal_description = 'Description';
_fc_props.geo_gate_edit_modal_start_point = 'Point de départ';
_fc_props.geo_gate_edit_modal_end_point = 'Point final';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Type d\'équipement';
_fc_props.geo_gate_edit_modal_heading_south = 'Du sud au nord';
_fc_props.geo_gate_edit_modal_heading_north = 'Du nord au sud';
_fc_props.geo_gate_edit_modal_driver_message = 'Message conducteur';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Message d\'expédition';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Date d\'expiration Heure';
_fc_props.geo_gate_edit_modal_permanent = 'Permanent';
_fc_props.geo_gate_edit_modal_critical = 'Critique';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Erreur lors de l\'enregistrement de Geogate : le champ Description doit être renseigné.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Erreur lors de l\'enregistrement de Geogate : le champ Point de départ doit être renseigné.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Erreur lors de l\'enregistrement de Geogate : le champ Point de départ contient un format de données non valide.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Erreur lors de l\'enregistrement de Geogate : le champ End Point contient un format de données non valide.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Erreur lors de l\'enregistrement de Geogate : le champ Date d\'expiration doit être renseigné.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Erreur lors de l\'enregistrement de Geogate : le champ Date d\'expiration contient un format de données non valide.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Erreur lors de l\'enregistrement de Geogate : Nord vers Sud - Le champ Message de répartition doit être rempli';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Erreur lors de l\'enregistrement de Geogate : aucune modification détectée';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Erreur lors de l\'enregistrement de Geogate : le champ Date d\'expiration contient une date et une heure expirées';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Erreur lors de l\'enregistrement de Geogate : le champ de description ne doit contenir que des lettres et des chiffres';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Erreur lors de l\'enregistrement de Geogate : le champ Type d\'équipement doit être renseigné';
_fc_props.geo_gate_table_message_label_north_to_south = 'Du nord au sud';
_fc_props.geo_gate_table_message_label_south_to_north = 'Du sud au nord';
_fc_props.geo_gate_table_message_label_both = 'Les deux';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Latitude Longitude';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Cocher cette case remplacera la configuration de la restriction des messages de mouvement de l\'appareil';
_fc_props.geo_gate_edit_modal_seasonal = 'Saisonnier';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Erreur lors de l\'enregistrement de Geogate : les dates saisonnières doivent être renseignées';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Erreur lors de l\'enregistrement de Geogate : les champs de date saisonnière contiennent un format de données non valide';
_fc_props.test_js_test_javascript = 'Testez Javascript';
_fc_props.units_miles_per_hour_abbr = 'mi/h';
_fc_props.units_kilometers_per_hour_abbr = 'km/h';
_fc_props.units_kilometers = 'Kilomètres';
_fc_props.units_miles = 'Milles';
_fc_props.units_gallons_abbr = 'FILLE';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Erreur lors de l\'enregistrement de Geogate : Sud vers Nord - Le champ Message du conducteur doit être rempli';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Erreur lors de l\'enregistrement de Geogate : Sud vers Nord - Le champ Message de répartition doit être rempli';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Erreur lors de l\'enregistrement de Geogate : Nord vers sud - Le champ Message du conducteur doit être rempli';
_fc_props.geo_gate_heading = 'Titre';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Erreur lors de l\'enregistrement de Geogate : le champ End Point doit être renseigné';
_fc_props.units_mile = 'Mile';
_fc_props.units_kilometer = 'Kilomètre';
_fc_props.cloud_dvir__exit = 'Sortie';
_fc_props.cloud_dvir__signature = 'Signature';
_fc_props.cloud_dvir__inspection = 'Inspection';
_fc_props.cloud_dvir__add_photos_notes = 'Ajouter des photos/notes';
_fc_props.cloud_dvir__odometer_reading = 'Relevé du compteur kilométrique';
_fc_props.cloud_dvir__engine_hours = 'Heures de moteur';
_fc_props.cloud_dvir__truck_details = 'Détails du camion';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Déterminer l\'état général du véhicule';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Nom complet et signature du conducteur';
_fc_props.cloud_dvir__sign = 'Signe';
_fc_props.cloud_dvir__driver_note_from = 'Note du conducteur de';
_fc_props.cloud_dvir__add_notes = 'Ajouter des remarques';
_fc_props.cloud_dvir__driver_notes = 'Remarques sur le conducteur';
_fc_props.cloud_dvir__done = 'Fait';
_fc_props.cloud_dvir__needs_repair = 'A besoin de réparation';
_fc_props.cloud_dvir__not_applicable = 'N\'est pas applicable';
_fc_props.cloud_dvir__review_and_sign = 'Réviser et signer';
_fc_props.cloud_dvir__add_photos = 'Ajouter une photo';
_fc_props.cloud_dvir__upload_photo_limit = 'Vous pouvez télécharger jusqu\'à 3 photos';
_fc_props.cloud_dvir__mismatch_title = 'Alerte de non-concordance';
_fc_props.cloud_dvir__ok = 'D\'accord';
_fc_props.cloud_dvir_template = 'Modèle Cloud DVIR';
_fc_props.cloud_dvir__needs_review = 'Le DVIR doit être révisé';
_fc_props.cloud_dvir__submitted = 'Soumis';
_fc_props.cloud_dvir__completed_by = 'Terminé par';
_fc_props.cloud_dvir__review_btn = 'Examen';
_fc_props.cloud_dvir__truck_number = 'Camion {0}';
_fc_props.cloud_dvir__pending_review = 'En attendant l\'examen';
_fc_props.cloud_dvir__start_dvir = 'Démarrer DVIR';
_fc_props.cloud_dvir__dvir_history = 'Historique du DVIR';
_fc_props.cloud_dvir__view_all = 'Voir tout';
_fc_props.load_zone = 'Zone de chargement';
_fc_props.view_load_zones = 'Afficher les zones de chargement';
_fc_props.edit_load_zone = 'Modifier la zone de chargement';
_fc_props.create_a_load_zone = 'Créer une zone de chargement';
_fc_props.load_zone_panel = 'Panneau de zone de charge';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Cliquez sur la carte pour définir le centre de la zone de chargement.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Cette inspection a indiqué des défauts et vous avez sélectionné que ce véhicule peut être utilisé en toute sécurité. Merci de valider avant de poursuivre';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Cette inspection n\'a révélé aucun défaut et vous avez déterminé que ce véhicule n\'est pas sécuritaire à utiliser. Merci de valider avant de poursuivre.';
_fc_props._90_days = '90 jours';
_fc_props.cloud_dvir = 'Nuage DVIR';
_fc_props.cloud_dvir_details = 'Soumissions Cloud DVIR';
_fc_props.show_load_zones = 'afficher les zones de charge';
_fc_props.hide_load_zones = 'masquer les zones de charge';
_fc_props.no = 'Non';
_fc_props.cloud_dvir__reset = 'Réinitialiser';
_fc_props.cloud_dvir__clear = 'Clair';
_fc_props.mark_read = 'Marquer comme lu';
_fc_props.mark_unread = 'Marquer comme non lu';
_fc_props.read = 'Lire';
_fc_props.unread = 'Non lu';
_fc_props.air_sensor_serial_number = 'Numéro de série du capteur d\'air';
_fc_props.air_sensor_mac_address = 'Adresse MAC du capteur d\'air';
_fc_props.air_sensor_serial_number_invalid_format = 'Le numéro de série du capteur d\'air n\'est pas dans un format valide : (alphanumérique et au maximum 32 caractères avec des tirets)';
_fc_props.air_sensor_mac_address_invalid_format = 'L\'adresse MAC du capteur d\'air n\'est pas au format hexadécimal valide, tel qu\'affiché dans l\'espace réservé pour le champ.';
_fc_props.air_sensor_license = 'Capteur d\'air';
_fc_props.adjust_route_point = 'Ajuster le point d\'itinéraire';
_fc_props.route_point_panel = 'Panneau de points d\'itinéraire';
_fc_props.route_point = 'Point d\'itinéraire';
_fc_props.cloud_dvir__pre_trip = 'Pré voyage';
_fc_props.cloud_dvir__post_trip = 'Après le voyage';
_fc_props.cloud_dvir__other_insction_type = 'Autre';
_fc_props.cloud_dvir__dvir_completed = 'Complété';
_fc_props.cloud_dvir__awaiting_mechanic = 'En attente d\'un mécanicien';
_fc_props.cloud_dvir__awaiting_driver = 'En attente de l\'approbation du conducteur';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Impossible de vérifier le numéro de série du capteur d\'air, contactez l\'assistance pour obtenir une assistance supplémentaire.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Ce numéro de série de capteur d\'air existe déjà, veuillez utiliser un autre numéro de série';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Impossible de vérifier l\'adresse MAC du capteur d\'air, veuillez contacter l\'assistance pour obtenir de l\'aide.';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Erreur lors de la soumission du DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Veuillez réessayer';
_fc_props.ticket_integration_descr = 'L\'intégration des tickets permet de définir les paramètres d\'intégration des tickets ainsi que l\'écran d\'accueil/l\'écran de chargement/l\'affichage sur support dur.';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR soumis avec succès';
_fc_props.cloud_dvir__inspection_trip_type = 'Type de voyage d\'inspection';
_fc_props.menu_dvir_title_mechanic = 'Mécanicien';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Micrologiciel du capteur d\'air';
_fc_props.when_no_active_ticket = 'Lorsqu\'aucun ticket n\'est actif';
_fc_props.used_by = 'Utilisé par';
_fc_props.fields = 'Des champs';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Êtes-vous sûr de vouloir supprimer la présentation du ticket « {0} » ?';
_fc_props.menu_settings_title_ticket_layout = 'Disposition des billets';
_fc_props.add_ticket_layout = 'Ajouter une présentation de ticket';
_fc_props.edit_ticket_layout = 'Modifier la présentation du ticket';
_fc_props.ticket_layout_edit_modal_name = 'Nom';
_fc_props.ticket_layout_edit_modal_default = 'Défaut';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Erreur lors de l\'enregistrement de la présentation du ticket : le champ Nom doit être renseigné.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Erreur lors de l\'enregistrement de la présentation du ticket : le champ Nom doit contenir uniquement des lettres et des chiffres.';
_fc_props.ticket_layout_edit_modal_fields = 'Des champs';
_fc_props.ticket_layout_edit_modal_usage = 'Usage';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'La présentation du ticket a été supprimée. Veuillez actualiser la page.';
_fc_props.more_lower_case = 'plus';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Erreur lors de l\'enregistrement de la présentation du ticket : un ou plusieurs champs doivent être sélectionnés.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Erreur lors de l\'enregistrement de la présentation du ticket : un ou plusieurs statuts doivent être sélectionnés.';
_fc_props.cloud_dvir__ad_hoc = 'Ad hoc';
_fc_props.radius_multiplier_should_be = 'Le multiplicateur de rayon doit être un nombre flottant';
_fc_props.ticket_max_activate_age_err = 'Ticket Max Activate Age Minutes doit être un nombre entier.';
_fc_props.max_hotspot_unused_days_errs = 'Le nombre maximum de jours inutilisés du point d\'accès doit être un nombre entier.';
_fc_props.begin_pour_air_content = 'Commencer à verser le contenu de l\'air';
_fc_props.arrival_air_content = 'Contenu aérien à l’arrivée';
_fc_props.fully_mixed_air_content = 'Contenu d\'air entièrement mélangé';
_fc_props.departure_air_content = 'Contenu aérien de départ';
_fc_props.popup_enabled = 'Popup activé';
_fc_props.popup_disabled = 'Fenêtre contextuelle désactivée';
_fc_props.cloud_dvir__leave_confirmation_title = 'Avertissement';
_fc_props.cloud_dvir__leave_confirmation_description = 'Quitter la page maintenant entraînera la perte de toutes les modifications.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Confirmer';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Annuler';
_fc_props.air_bt_ratio = 'Mesure du capteur d\'air';
_fc_props.cloud_dvir__view_photos_notes = 'Afficher les photos/notes';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'L’ajout du statut créera une utilisation vide dans une autre présentation de ticket. Souhaitez-vous procéder à l’ajout ?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Le véhicule n\'a pas de licence de capteur d\'air.';
_fc_props.air_sensor_installed = 'Capteur d\'air installé';
_fc_props.load_card = 'Charger la carte';
_fc_props.cloud_dvir__inspection_profile = 'Profil d\'inspection';
_fc_props.cloud_dvir__view_details = 'Voir les détails';
_fc_props.cloud_dvir__certify_repairs = 'Certifier les réparations';
_fc_props.cloud_dvir__repairs_made = 'Réparations effectuées';
_fc_props.cloud_dvir__repairs_not_necessary = 'Réparations non nécessaires';
_fc_props.cloud_dvir__mechanic_note_from = 'Note du mécanicien';
_fc_props.cloud_dvir__mechanic_assignment = 'Affectation de mécanicien';
_fc_props.cloud_dvir__mechanic_signature_description = 'Marquez tous les éléments applicables ci-dessous et signez indiquant que le véhicule a été correctement inspecté.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'L\'état de ce véhicule est satisfaisant';
_fc_props.cloud_dvir__defects_corrected_label = 'Les défauts ont été corrigés';
_fc_props.cloud_dvir__repair_not_required_label = 'Réparation non nécessaire pour une utilisation sécuritaire du véhicule';
_fc_props.safety = 'Sécurité';
_fc_props.cloud_dvir__awaiting_sign = 'En attente de signe';
_fc_props.cloud_dvir__dvir_review = 'Examen du DVIR';
_fc_props.settings.mobile_auto_start_navigation = 'Démarrage automatique de la navigation sur mobile';
_fc_props.cloud_dvir__edit = 'Modifier';
_fc_props.delete_custom_label = 'Supprimer l\'étiquette personnalisée';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Votre version de TrackIt est obsolète et certaines fonctionnalités DVIR peuvent ne pas fonctionner correctement. Veuillez mettre à jour TrackIt avant de continuer.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Une erreur s\'est produite lors du téléchargement de la signature. Veuillez réessayer';
_fc_props.max_hotspot_unused_days_error = 'Le nombre maximum de jours inutilisés du point d\'accès a un maximum de 1 000 jours.';
_fc_props.menu_dvir_title_admin_templates = 'Modèles';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Administrateur';
_fc_props.cloud_dvir__admin_templates = 'Modèles';
_fc_props.form_name = 'Nom de forme';
_fc_props.form_status = 'Statut du formulaire';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Télécharger';
_fc_props.cloud_dvir__option_view = 'Voir';
_fc_props.cloud_dvir__inspectortype_driver = 'Conducteur';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Afficher/Modifier/Créer des formulaires et des soumissions DVIR personnalisés';
_fc_props.menu.dvir.description.mechanic = 'Afficher/modifier les soumissions DVIR exploitables';
_fc_props.not_in_use = 'Hors service';
_fc_props.logged_in = 'connecté';
_fc_props.reviewing_driver = 'Révision du pilote';
_fc_props.cloud_dvir__report_title = 'Rapport d’inspection du véhicule du conducteur';
_fc_props.cloud_dvir__report_signed = 'signé';
_fc_props.cloud_dvir__report_page_counter = 'Page {0} sur {1}';
_fc_props.cloud_dvir__report_defects = 'Défauts';
_fc_props.cloud_dvir__report_comments = 'commentaires';
_fc_props.cloud_dvir__report_inspection_table_title = 'Inspection de véhicule';
_fc_props.cloud_dvir__report_driver_table_title = 'Informations sur le conducteur';
_fc_props.cloud_dvir__report_carrier = 'Transporteur';
_fc_props.cloud_dvir__report_truck = 'Camion';
_fc_props.cloud_dvir__report_location = 'Emplacement';
_fc_props.cloud_dvir__report_odometer = 'Odomètre';
_fc_props.cloud_dvir__report_hours = 'Heures';
_fc_props.cloud_dvir__report_no_comments = 'Sans commentaires';
_fc_props.include_unlicensed_vehicles = 'Inclure les véhicules sans permis';
_fc_props.cloud_dvir__search_by_form_name = 'Rechercher par nom de formulaire';
_fc_props.cloud_dvir__create_dvir = 'Créer un DVIR';
_fc_props.cloud_dvir__update_dvir = 'Mettre à jour le DVIR';
_fc_props.cloud_dvir__form_name = 'Nom de forme';
_fc_props.cloud_dvir__form_description = 'Description du formulaire';
_fc_props.cloud_dvir__form_status = 'Statut du formulaire';
_fc_props.information = 'Information';
_fc_props.cloud_dvir__search_categories = 'Catégories de recherche';
_fc_props.cloud_dvir__create_new_category = 'Créer une nouvelle catégorie';
_fc_props.category_name = 'Nom de catégorie';
_fc_props.sub_category = 'Sous-catégorie';
_fc_props.sub_category_name = 'Nom de la sous-catégorie';
_fc_props.delete_category = 'Supprimer la catégorie';
_fc_props.cloud_dvir__dvir_information = 'Informations DVIR';
_fc_props.cloud_dvir__dvir_choose_categories = 'Choisissez des catégories';
_fc_props.cloud_dvir__create_category = 'Créer une catégorie';
_fc_props.cloud_dvir__update_category = 'Catégorie de mise à jour';
_fc_props.delete_route_point = 'Supprimer un point d\'itinéraire';
_fc_props.view_route_points = 'Afficher les points d\'itinéraire';
_fc_props.ignore_cancel_preload = 'Ignorez l\'annulation, vous êtes préchargé. Votre home run sera bientôt affiché';
_fc_props.cloud_dvir__assigned_mechanic = 'Mécanicien attitré';
_fc_props.cloud_dvir__add_mechanic = 'Ajouter un mécanicien';
_fc_props.contact_type = 'Type de contact';
_fc_props.cloud_dvir__send_email_notifications = 'Envoyer des notifications par e-mail';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Erreur lors de la récupération des enregistrements de l\'historique DVIR.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Erreur lors de la récupération de l\'enregistrement DVIR.';
_fc_props.cloud_dvir__error_saving_submission = 'Erreur lors de l\'enregistrement de la soumission. Essayer à nouveau.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Erreur lors de la récupération de la liste des mécaniciens.';
_fc_props.cloud_dvir__template_saved = 'Modèle enregistré';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Erreur lors de la récupération de la liste des modèles.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Erreur lors de l\'enregistrement du modèle. Essayer à nouveau.';
_fc_props.dispatch_group = 'Groupe de répartition';
_fc_props.save_adjust = 'Enregistrer et ajuster le point d\'itinéraire';
_fc_props.reset_route_point = 'Réinitialiser le point d\'itinéraire';
_fc_props.no_records_stub_adjust_filters = 'Essayez d\'ajuster vos paramètres de recherche ou de filtre pour augmenter le nombre de résultats.';
_fc_props.no_dvir_records = 'Tous rattrapés par les DVIR !';
_fc_props.no_dvir_records_sub_title = 'Vous avez accompli beaucoup de choses';
_fc_props.settings.master_data_connex_x_api_key = 'Clé x-api de Master Data Connex';
_fc_props.slump_not_obtained_for_x = 'Aucune lecture d\'affaissement obtenue pour : {0}';
_fc_props.please_select_a_sensor_status_type = 'Veuillez sélectionner les types d\'état du capteur pour déclencher cette alerte.';
_fc_props.settings.master_data_connex_token = 'Jeton de connexion de données de base';
_fc_props.error_validating_master_data_connex_credentials = 'Erreur lors de la validation des informations d\'identification Master Data Connex';
_fc_props.settings.master_data_connex_base_url = 'URL de base de connexion de données de base';
_fc_props.settings.master_data_connex_exp_time = 'Délai d\'expiration des données de base Connex';
_fc_props.download_template = 'Télécharger le modèle';
_fc_props.menu_settings_title_dispatch_group = 'Groupes de répartition';
_fc_props.settings.dispatch_groups = 'Groupes de répartition';
_fc_props.dispatch_group_name = 'Nom du groupe de répartition';
_fc_props.dispatch_group_plants = 'Usines du groupe de répartition';
_fc_props.dispatch_group_plants_mapping_message = 'Indiquez quelles usines feront partie de ce groupe de répartition.';
_fc_props.dispatch_group_users_mapping_message = 'Indiquez quels utilisateurs feront partie de ce groupe de répartition.';
_fc_props.dispatch_group_plant_disassociation_message = 'L\'usine doit appartenir à un seul groupe de répartition à la fois.';
_fc_props.dispatch_group_search_by_plant = 'Recherche par nom de plante';
_fc_props.dispatch_group_search_by_username = 'Rechercher par nom d\'utilisateur';
_fc_props.search_by_dispatch_group_name = 'Rechercher par nom de groupe de répartition';
_fc_props.create_dispatch_group = 'Créer un groupe de répartition';
_fc_props.update_dispatch_group = 'Mettre à jour le groupe de répartition';
_fc_props.dispatch_group_saved = 'Groupe de répartition enregistré';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'La suppression de ce groupe de répartition créera des groupes de répartition par défaut pour les usines associées.';
_fc_props.error_saving_dispatch_group = 'Erreur lors de l\'enregistrement du groupe de répartition. Essayer à nouveau.';
_fc_props.please_enter_a_valid_plant_num = 'Veuillez saisir un numéro d\'usine valide.';
_fc_props.cloud_dvir__report_no_defects = 'Aucun défaut';
_fc_props.shoud_select_dispatch_group = 'Vous devez sélectionner au moins un groupe de répartition';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Suppression impossible, car ce groupe de répartition contient des utilisateurs qui sont uniquement associés à ce groupe de répartition.';
_fc_props.cloud_dvir__username = 'Nom d\'utilisateur';
_fc_props.cloud_dvir__password = 'Mot de passe';
_fc_props.cloud_dvir__login = 'Se connecter';
_fc_props.cloud_dvir__mechanic_sign_in = 'Connexion mécanicien';
_fc_props.delete_dispatch_group = 'Supprimer le groupe de répartition';
_fc_props.cloud_dvir__driver = 'Conducteur';
_fc_props.cloud_dvir__equipment_pound = 'Équipement #';
_fc_props.cloud_dvir__mechanic = 'Mécanicien';
_fc_props.dispatch_group_user_only_unassociate_message = 'Impossible de supprimer l\'utilisateur car les utilisateurs doivent être associés à au moins un groupe de répartition.';
_fc_props.cloud_dvir__time_period_filter_today = 'Aujourd\'hui';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Hier';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Les 7 derniers jours';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Les 30 derniers jours';
_fc_props.cloud_dvir__date_range_filter = 'Filtrer par date';
_fc_props.cloud_dvir__download = 'Télécharger';
_fc_props.dispatch_group_is_empty = 'Le groupe de répartition ne peut pas être vide.';
_fc_props.dispatch_group_with_empty_plants = 'Au moins une division doit être associée au groupe de répartition.';
_fc_props.dispatch_group_with_empty_users = 'Au moins un utilisateur doit être associé au groupe de répartition.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Impossible de supprimer le groupe de répartition car il contient des utilisateurs qui sont uniquement associés à ce groupe de répartition.';
_fc_props.dispatch_group_not_exists = 'Le groupe de répartition n\'existe pas.';
_fc_props.dispatch_group_contains_one_plant = 'Suppression impossible car ce groupe de répartition ne contient qu\'une seule usine.';
_fc_props.dispatch_group_users = 'Utilisateurs du groupe de répartition';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Erreur lors de la récupération de l\'enregistrement DVIR';
_fc_props.cloud_dvir__assign_mechanic = 'Attribuer un mécanicien';
_fc_props.cloud_dvir__submit_review = 'Poster le commentaire';
_fc_props.error_deleting_dispatch_group = 'Erreur lors de la suppression du groupe de répartition.';
_fc_props.error_retrieving_dispatch_groups = 'Erreur lors de la récupération de la liste des groupes de répartition.';
_fc_props.cloud_dvir__undo_selection = 'Annuler la sélection';
_fc_props.enable_master_data = 'Activer les données de base';
_fc_props.manually_sync = 'Synchroniser manuellement';
_fc_props.master_data_connex_token_expiration = 'Expiration du jeton Connex des données de base';
_fc_props.cloud_dvir__time_period_filter_custom = 'Coutume';
_fc_props.settings.zello_session_timeout_minutes = 'Délai d\'expiration de la session Zello (minutes)';
_fc_props.menu_settings_title_master_data = 'Données de base';
_fc_props.settings.master_data = 'Données de base';
_fc_props.error_retrieving_master_data = 'Erreur lors de la récupération des données de base';
_fc_props.master_data_sync_completed = 'Synchronisation des données de base terminée';
_fc_props.master_data_successfully_updated = 'Données mises à jour avec succès';
_fc_props.master_data_failed_to_update = 'Échec de l\'enregistrement.';
_fc_props.master_data_sync_initiated = 'Synchronisation des données de base lancée';
_fc_props.master_data_sync_failed = 'Échec de la synchronisation des données principales';
_fc_props.enabled_excluding_origin_on_deliveries = 'Vrai (hors origine sur les bons de livraison)';
_fc_props.enabled_including_origin_on_deliveries = 'Vrai (y compris l\'origine sur les bons de livraison)';
_fc_props.cloud_dvir__i_will_decide_later = 'je déciderai plus tard';
_fc_props.returning = 'De retour';
_fc_props.leftover_concrete = 'Restes de béton';
_fc_props.leftover_concrete_on_vehicle_x = 'Restes de béton sur le véhicule {0}';
_fc_props.leftover_concrete_alert = 'Alerte restes de béton';
_fc_props.wc_ratio_exceeded = 'Rapport W/C dépassé';
_fc_props.search_by_site_name = 'Rechercher par nom de site';
_fc_props.sites = 'Des sites';
_fc_props.token_expiration = 'Expiration du jeton';
_fc_props.menu_settings_title_connex_api_key_management = 'Gestion des clés API Connex';
_fc_props.settings.connex_api_key_management = 'Gestion des clés API Connex';
_fc_props.error_retrieving_connex_api_key_data = 'Erreur lors de la récupération des données de la clé API connex';
_fc_props.qr_code = 'QR Code';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informer le conducteur et l\'interface lors de l\'entrée dans une mauvaise usine';
_fc_props.enable_driver_message = 'Activer le message du pilote';
_fc_props.enable_dispatch_message = 'Activer le message de répartition';
_fc_props.enable_sending_message_to_interface = 'Activer l\'envoi d\'un message à l\'interface';
_fc_props.ticket_qr_code = 'Code QR du billet';
_fc_props.leftover_concrete_alert_description = 'Alerte lorsque des restes de béton sont trouvés après la coulée.';
_fc_props.minimum_leftover_concrete_amount = 'Quantité minimale de béton restant';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Erreur lors de la validation des informations d\'identification Master Data Connex non autorisées';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Erreur lors de la validation des autorisations des informations d\'identification Master Data Connex';
_fc_props.error_validating_master_data_connex_url_not_found = 'Erreur lors de la validation de l\'URL Master Data Connex introuvable';
_fc_props.error_validating_master_data_connex_unknown_error = 'Erreur lors de la validation de Master Data Connex Erreur inconnue';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Échec de la synchronisation des données principales Synchronisez le type de véhicule.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Échec de la synchronisation des données principales Synchronisez le véhicule.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Échec de la synchronisation des données de base Synchronisation de l\'installation.';
_fc_props.master_data_sync_failed_data_base_connection = 'La synchronisation des données principales a échoué lors de la connexion à la base de données.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Erreur lors de la validation des informations d\'identification Master Data Connex. L\'autorisation du jeton du porteur est insuffisante.';
_fc_props.settings.back_date_arrive_job = 'Retour à la date d\'arrivée du travail';
_fc_props.device_notifications = 'Notifications';
_fc_props.loading_number = 'Numéro de chargement';
_fc_props.leftover_concrete_volume_temp_age = 'Volume restant = {0}, Temp = {1}, Âge = {2}';
_fc_props.total_ordered = 'Quantité totale commandée';
_fc_props.total_ticketed = 'Livré Commandé';
_fc_props.load_number = 'Numéro de charge';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Le minimum de béton restant doit être rempli et supérieur à {0}.';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Erreur lors de la validation des données de base. Les informations d\'identification ne peuvent pas être les mêmes.';
_fc_props.use_aws_for_talk = 'Utiliser AWS pour Talk';
_fc_props.air_readings_not_supported = 'Mesure d\'air non pris en charge';
_fc_props.air_not_supported = 'Air non pris en charge';
_fc_props.mix_not_supported = 'Mélange non pris en charge';
_fc_props.air_default_and_none_not_supported = 'Impossible de sélectionner par défaut et non pris en charge en même temps !';
_fc_props.notifications_enabled = 'Notifications activées';
_fc_props.prevent_status_change = 'Empêcher le changement de statut';
_fc_props.message_to_interface = 'Message à l\'interface';
_fc_props.prevent_wrong_plant = 'Prévenir une mauvaise plante';
_fc_props.previous_tickets = 'Billets précédents';
_fc_props.audit_ticket_number = 'Numéro de billet';
_fc_props.audit_date_created = 'date créée';
_fc_props.audit_vehicle_number = 'Numéro de véhicule';
_fc_props.cloud_dvir__table_ok = 'Utilisation sûre';
_fc_props.cloud_dvir__table_needs_repair = 'Utilisation dangereuse';
_fc_props.missing_air_calibration = 'Aucune donnée d\'étalonnage de l\'air';
_fc_props.reported_defects = 'Défauts signalés';
_fc_props.address_latitude = 'Adresse Latitude';
_fc_props.address_longitude = 'Longitude de l\'adresse';
_fc_props.routing_latitude = 'Latitude de routage';
_fc_props.routing_longitude = 'Longitude d\'itinéraire';
_fc_props.category_name_must_be_unique = 'Le nom de la catégorie doit être unique';
_fc_props.subcategory_name_must_be_unique = 'Le nom de la sous-catégorie doit être unique';
_fc_props.menu_settings_tracking_device = 'Dispositif de pistage';
_fc_props.menu_settings_title_tracking_device = 'Dispositif de pistage';
_fc_props.menu_settings_title_personal_device_registration = 'Enregistrement d\'un appareil personnel';
_fc_props.registration_code = 'Code d\'enregistrement';
_fc_props.creation_date = 'Création';
_fc_props.expiration_date = 'Expiration';
_fc_props.trackitadmin_personal_device_registration = 'Enregistrement d\'un appareil personnel';
_fc_props.create_registration_code = 'Ajouter un code d\'enregistrement';
_fc_props.remove_registration_code = 'Supprimer le code d\'enregistrement';
_fc_props.add_a_registration_code = 'Ajouter un code d\'enregistrement';
_fc_props.select_number_of_registration_codes_to_generate = 'Sélectionnez le nombre de codes d\'enregistrement que vous souhaitez générer.';
_fc_props.registration_code_count = 'Nombre de codes d\'enregistrement';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Êtes-vous sûr de vouloir supprimer le code enregistré sélectionné ?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Êtes-vous sûr de vouloir supprimer les codes enregistrés sélectionnés ?';
_fc_props.add_personal_device = 'Ajouter un appareil personnel';
_fc_props.menu_settings_title_personal_device = 'Appareil personnel';
_fc_props.cloud_dvir__approval_critical_alert = 'Le véhicule présente un défaut critique. Ne peut pas être marqué comme étant sûr à utiliser.';
_fc_props.export_registration_codes = 'Codes d\'enregistrement d\'exportation';
_fc_props.download_format = 'Format de téléchargement';
_fc_props.specify_download_format_x = 'Spécifiez le format de téléchargement pour exporter {0} codes d\'enregistrement';
_fc_props.personal_device_download = 'Télécharger';
_fc_props.add_personal_device_input_message = 'Saisissez un identifiant d’appareil personnel ci-dessous. L\'expiration sera automatiquement appliquée après la sauvegarde';
_fc_props.error_creating_personal_device = 'Erreur lors de la création d\'un appareil personnel';
_fc_props.device_already_exists = 'L\'appareil personnel existe déjà';
_fc_props.export = 'Exporter';
_fc_props.error_can_not_change_connex_eid = 'Impossible de modifier l\'eid de connexion car il est actuellement utilisé pour les données de base';
_fc_props.remove_personal_device = 'Supprimer un appareil personnel';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Êtes-vous sûr de vouloir supprimer l\'appareil personnel sélectionné ?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Êtes-vous sûr de vouloir supprimer les appareils personnels sélectionnés ?';
_fc_props.error_deleting_personal_device = 'Erreur lors de la suppression de l\'appareil personnel';
_fc_props.customerid = 'N ° de client';
_fc_props.customername = 'Nom du client';
_fc_props.ordernumber = 'Numéro de commande';
_fc_props.orderdescription = 'Description de la commande';
_fc_props.locationid = 'Identifiant du lieu';
_fc_props.locationdescription = 'Description de l\'emplacement';
_fc_props.productid = 'Identifiant du produit';
_fc_props.productdescription = 'Description du produit';
_fc_props.vehicleid = 'Identifiant du véhicule';
_fc_props.vehicledescription = 'Description du véhicule';
_fc_props.ticketnumber = 'Billet #';
_fc_props.ticketdate = 'Date/heure du billet';
_fc_props.drivername = 'Nom du conducteur';
_fc_props.arriveregion = 'Région d\'arrivée (heure)';
_fc_props.leaveregion = 'Quitter la région (TIME)';
_fc_props.regionduration = 'Région Durée';
_fc_props.menu_reports_title_prevailing_wage = 'Salaire en vigueur';
_fc_props.slump_calibration_x_not_found_for_y = 'Calibrage d\'affaissement "{0}" introuvable pour le ticket {1}';
_fc_props.slump_calibration_not_found = 'Calibrage d\'affaissement introuvable';
_fc_props.slump_calibration_not_found_description = 'Alerte lorsque l\'étalonnage d\'affaissement est introuvable';
_fc_props.error_getting_personal_device_list = 'Erreur lors de l\'obtention de la liste des appareils personnels';
_fc_props.batch_summary_date_range_note = '*REMARQUE : Ce rapport est limité à 3 jours si aucun filtre n\'est appliqué. La plage de dates peut aller jusqu\'à 31 jours lorsqu\'elle est filtrée par équipement (10 maximum), usine (1 maximum), produit (1 maximum) ou tâche (1 maximum).';
_fc_props.select_registration_code_expiration_time_days = 'Sélectionnez le nombre de jours avant l\'expiration des codes d\'enregistrement, puis cliquez sur Enregistrer.';
_fc_props.add_personal_device_expiration = 'Expiration (en jours)';
_fc_props.cloud_dvir__vehicle_no = 'Numéro de véhicule';
_fc_props.cloud_dvir__trailer_no = 'Remorque n°';
_fc_props.cloud_dvir__vehicle = 'Véhicule';
_fc_props.cloud_dvir__trailer = 'Bande-annonce';
_fc_props.cloud_dvir__truck_number_label = 'Numéro de camion';
_fc_props.cloud_dvir__trailer_number = 'Numéro de remorque';
_fc_props.cloud_dvir__has_trailer = 'Ne pas conduire avec une remorque';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Entrez votre numéro de remorque ici';
_fc_props.work_order = 'Bon de travail';
_fc_props.unable_to_determine_slump = 'Impossible de déterminer l\'effondrement';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Déterminer l\'état général de la remorque';
_fc_props.defaultTrailer = 'Bande-annonce par défaut';
_fc_props.trailer_type_column_billable = 'Facturable';
_fc_props.trailer_type_column_notes = 'Remarques';
_fc_props.trailer_type_column_date = 'Date';
_fc_props.trailer_type_column_order = 'Commande';
_fc_props.trailer_type_column_ticket = 'Billet';
_fc_props.trailer_type_column_project = 'Projet';
_fc_props.trailer_type_column_customer = 'Client';
_fc_props.trailer_type_column_location = 'Emplacement';
_fc_props.trailer_type_column_vehicle_type = 'Type de véhicule';
_fc_props.trailer_type_column_product = 'Produit';
_fc_props.trailer_type_action_rebill = 'Refacturation';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'La synchronisation des données principales a échoué, aucun type d\'équipement par défaut n\'a été défini';
_fc_props.trailer_type = 'Type de remorque';
_fc_props.trailer_type_is_required = 'Le type de remorque est requis';
_fc_props.trailer_number_already_exists = 'Le numéro de remorque existe déjà';
_fc_props.plate_number_already_exists = 'Le numéro de plaque existe déjà';
_fc_props.trailer_vin_already_exists = 'La remorque Vin existe déjà';
_fc_props.trailer_does_not_exist = 'La bande annonce n\'existe pas';
_fc_props.trailer_number_is_required = 'Le numéro de remorque est requis';
_fc_props.plate_number_is_required = 'Le numéro de plaque d\'immatriculation est requis';
_fc_props.plate_number = 'Numéro de plaque d\'immatriculation';
_fc_props.menu_settings_title_trailer = 'Bande-annonce';
_fc_props.add_trailer = 'Ajouter une bande-annonce';
_fc_props.edit_trailer = 'Modifier la bande-annonce';
_fc_props.menu_settings_title_equipment = 'Équipement';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorer les nouveaux tickets de Command Cloud datant de plus de X minutes';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignorer les nouveaux tickets de Command Cloud avec une date et une heure d\'envoi antérieures à X minutes (la valeur par défaut est vide et désactivée)';
_fc_props.edit_load_card = 'Modifier la carte de chargement';
_fc_props.default_layout = 'Disposition par défaut';
_fc_props.load_card_empty_fields = 'Erreur lors de l\'enregistrement de la carte de chargement : un ou plusieurs champs doivent être sélectionnés.';
_fc_props.trailers = 'Remorques';
_fc_props.error_failed_to_save_data = 'Erreur : échec de l\'enregistrement des données';
_fc_props.slump_increased_plasticizer_added_question = 'Quelle quantité de plastifiant a été ajoutée ?';
_fc_props.add_trailer_type = 'Ajouter un type de remorque';
_fc_props.menu_settings_title_trailer_type = 'Type de remorque';
_fc_props.edit_trailer_type = 'Modifier le type de remorque';
_fc_props.single_use_codes = 'Codes à usage unique';
_fc_props.multiple_use_codes = 'Codes à usage multiple';
_fc_props.trailer_type_description_is_required = 'La description du type de remorque est obligatoire';
_fc_props.trailer_saved = 'Bande-annonce enregistrée';
_fc_props.cloud_dvir__trailer_only_dvir = 'Remorque uniquement DVIR';
_fc_props.choose_file = 'Choisir le fichier';
_fc_props.no_file_chosen = 'Aucun fichier n\'a été choisi';
_fc_props.please_choose_file = 'Veuillez choisir un fichier';
_fc_props.no_filters = 'Aucun filtre sélectionné';
_fc_props.remaining_defects = 'Défauts restants';
_fc_props.load_more = 'Charger plus';
_fc_props.pagination_label = '{0}-{1} de {2}';
_fc_props.extend = 'Étendre';
_fc_props.cloud_dvir__notes_or_photo_required = 'Des notes ou une photo sont requises.';

export default _fc_props;
