const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Tentar Novamente';
_fc_props.default = 'Padrão';
_fc_props.origin = 'Origem';
_fc_props.loading = 'A Carregar';
_fc_props.list = 'Listar';
_fc_props.send = 'Enviar';
_fc_props.error = 'Erro';
_fc_props.save = 'Guardar';
_fc_props.delete = 'Eliminar';
_fc_props.message = 'Mensagem';
_fc_props.logout = 'Sair';
_fc_props.add = 'Adicionar';
_fc_props.remove = 'Remover';
_fc_props.done = 'OK';
_fc_props.other = 'Outro';
_fc_props.interval = 'Intervalo';
_fc_props.time = 'Tempo';
_fc_props.speed = 'Velocidade';
_fc_props.longitude = 'Longitude';
_fc_props.latitude = 'Latitude';
_fc_props.x_minutes = '{0} Min';
_fc_props.general = 'Geral';
_fc_props.messages = 'Mensagens';
_fc_props.gps_fixes = 'GPS Fixa';
_fc_props.device_id = 'ID do Dispositivo Inválida';
_fc_props.vehicle = 'Veículo';
_fc_props.employee = 'Funcionário';
_fc_props.version = 'Versão';
_fc_props.statuses = 'Estados';
_fc_props.edit = 'Editar';
_fc_props.cancel = 'Cancelar';
_fc_props.yes = 'Sim';
_fc_props.none = 'Nenhum';
_fc_props.never = 'Nunca';
_fc_props.name = 'Nome';
_fc_props.true = 'Verdadeiro';
_fc_props.status = 'Estado';
_fc_props.distance = 'Distância';
_fc_props.ok = 'Ok';
_fc_props.login = 'Login';
_fc_props.next = 'Seguinte';
_fc_props.menu = 'Menu';
_fc_props.not_available = 'N/A';
_fc_props.back = 'Atrás';
_fc_props.select = 'Selecionar';
_fc_props.dispatch = 'Envio';
_fc_props.messaging = 'Mensagens';
_fc_props.plants = 'Plantas';
_fc_props.last_plants = 'Últimas Plantas';
_fc_props.vehicle_types = 'Tipos de Veículo';
_fc_props.employee_types = 'Tipos de Funcionários';
_fc_props.employee_groups = 'Grupos de Funcionários';
_fc_props.equipment_groups = 'Grupos de Equipmento';
_fc_props.job = 'Trabalho';
_fc_props.order = 'Pedido';
_fc_props.ticket = 'Bilhete #';
_fc_props.small = 'Pequeno';
_fc_props.medium = 'Médio';
_fc_props.large = 'Largo';
_fc_props.reports = 'Relatórios';
_fc_props.payroll = 'Pagamento';
_fc_props.support = 'Apoio';
_fc_props.username = 'Nome de Utilizador';
_fc_props.all = 'Todos';
_fc_props.question = 'Pergunta';
_fc_props.select_employee = 'Selecionar Funcionários';
_fc_props.phone = 'Telefone';
_fc_props.january = 'Janeiro';
_fc_props.february = 'Fevereiro';
_fc_props.march = 'Março';
_fc_props.april = 'Abril';
_fc_props.may = 'Maio';
_fc_props.june = 'Junho';
_fc_props.july = 'Julho';
_fc_props.august = 'Agosto';
_fc_props.september = 'Setembro';
_fc_props.october = 'Outubro';
_fc_props.november = 'Novembro';
_fc_props.december = 'Decembro';
_fc_props.are_you_sure_you_want_to_delete = 'Tem a certeza que pretende eliminar';
_fc_props.unknown = 'Desconhecido';
_fc_props.select_all = 'Selecionar Todos';
_fc_props.equipment = 'Equipmento';
_fc_props.region = 'Região';
_fc_props.no_data = 'Sem Dados';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Endereço';
_fc_props.retrieving_address = 'A recuperar o endereço...';
_fc_props.address_not_found = 'Endereço não encontrado';
_fc_props.active_time = 'Tempo Activo';
_fc_props.destination_time = 'Tempo de Destino';
_fc_props.complete_time = 'Tempo Completo';
_fc_props.status_report = 'Relatório de Estado';
_fc_props.export_report = 'Exportar Relatório';
_fc_props.download_report = 'Descarregar Relatório';
_fc_props.view_report = 'Ver Relatório';
_fc_props.duration = 'Duração';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Dados/Tempo Início';
_fc_props.select_start_date = 'Selecionar Dados Início';
_fc_props.end_date_time = 'Dados/Tempo Fim';
_fc_props.select_end_date = 'Selecionar Dados Fim';
_fc_props.trip_time = 'Tempo de Viagem';
_fc_props.travel_time = 'Tempo de Viagem';
_fc_props.description = 'Descrição';
_fc_props.map_controls = 'Controlos de Mapa';
_fc_props.road = 'Estrada';
_fc_props.aerial = 'Aéreo';
_fc_props.hybrid = 'Híbrido';
_fc_props.trip = 'Viagem';
_fc_props.map = 'Mapa';
_fc_props.plant = 'Planta';
_fc_props.select_date = 'Selecionar Dados';
_fc_props.apply = 'Aplicar';
_fc_props.save_settings = 'Guardar Definições';
_fc_props.false = 'Falso';
_fc_props.delete_confirmation = 'Apagar Confirmação';
_fc_props.last_name = 'Último Nome';
_fc_props.none_selected = 'Nenhum Selecionado';
_fc_props.start_date = 'Dados Início';
_fc_props.group_by = 'Grupo Por';
_fc_props.equipment_employee = 'Equipmento / Funcionário';
_fc_props.inactive = 'Inativo';
_fc_props.active = 'Ativo';
_fc_props.note_checking_may_affect_performance = 'Nota: Verificação pode afetar o desempenho';
_fc_props.show_distancefuel_used = 'Mostrar Distância/Combustível Usado';
_fc_props.mins = 'Mins';
_fc_props.threshold_time = 'Tempo Limite';
_fc_props.include_voided_tickets = 'Incluir Bilhetes Eliminados';
_fc_props.note_all_times_listed_are_in_minutes = 'Nota: Todos os tempos são listados em minutos';
_fc_props.please_select_a_job_order_or_equipment = 'Selecione um Trabalho, Pedido, Fábrica, Equipamento ou Funcionário';
_fc_props.device = 'Dispositivo';
_fc_props.select_equipment = 'Selecionar o Equipmento';
_fc_props.all_equipment_types = 'Todos os Tipos de Equipmento';
_fc_props.units = {};
_fc_props.units.liters = 'Litros';
_fc_props.type = 'Tipo';
_fc_props.no_data_available = 'Nenhum dado disponível';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Distância e Combustível por Região';
_fc_props.menu_settings_title_settings = 'Definições';
_fc_props.menu_settings_title_alerts = 'Alertas';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Equipamento';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspots & Regiões';
_fc_props.days = '{0} dia(s)';
_fc_props.home = 'Início';
_fc_props.find = 'Encontrar';
_fc_props.close_this_panel = 'fechar este painel';
_fc_props.filters = 'Filtros';
_fc_props.start = 'Iniciar';
_fc_props.end = 'Fim';
_fc_props.location_details = 'Detalhes Local';
_fc_props.first_name = 'Primeiro Nome';
_fc_props.material_per_hour = 'Material Por Hora';
_fc_props.equipment_pound = 'Equipmento #';
_fc_props.equipment_type = 'Tipo de Equipamento';
_fc_props.options = 'Opções';
_fc_props.zoom_on_cursor_position = 'Zoom na Posição do Cursor';
_fc_props.display_equipment_identifier = 'Mostrar o Identificador de Equipamento';
_fc_props.view_equipment = 'Ver Equipamento';
_fc_props.view_hotspots = 'Ver Hotspots';
_fc_props.view_regions = 'Ver Regiões';
_fc_props.use_standard_filters = 'Usar Filtros Padrão';
_fc_props.home_plant = 'Planta Principal';
_fc_props.last_plant = 'Última Planta';
_fc_props.equip_types = 'Equip. Tipos';
_fc_props.equip_groups = 'Equip. Grupos';
_fc_props.orders = 'Ordens';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Usar Filtro do Equipamento';
_fc_props.all_selected = 'Todos Selecionados';
_fc_props.of = 'De';
_fc_props.your_map_session_has_expired = 'A sua Sessão de Mapa expirou, P.f. Atualize a página.';
_fc_props.equipment_short = 'Equip.';
_fc_props.job_info_for = 'Info Trabalho para';
_fc_props.order_info_for = 'Info Pedido para';
_fc_props.ticket_info_for_vehicle = 'Info Bilhete para Veículo';
_fc_props.inbox = 'Caixa de Entrada';
_fc_props.outbox = 'Caixa de Saída';
_fc_props.alerts = 'Alertas';
_fc_props.critical = 'Crítico';
_fc_props.important = 'Importante';
_fc_props.informational = 'Informativo';
_fc_props.view_routes = 'Ver Percursos';
_fc_props.hours_size = 'Tamanho Horas';
_fc_props.used_count = 'Usado Contagem';
_fc_props.last_login = 'Último Acesso';
_fc_props.last_vehicle = 'Último Veículo';
_fc_props.regions = 'Regiões';
_fc_props.driver = 'Condutor';
_fc_props.js_delete = 'Eliminar';
_fc_props.move = 'Mover';
_fc_props.edit_hotspot = 'Editar Hotspot';
_fc_props.adjust_shape = 'Ajustar Forma';
_fc_props.edit_region = 'Editar Região';
_fc_props.enter_a_start_address = 'P.f. inserir um Endereço inicial';
_fc_props.select_a_start_hotspot = 'P.f. selecione um Hotspot inicial';
_fc_props.failed_to_find_start_hotspot = 'Erro ao localizar o Hotspot inicial.';
_fc_props.invalid_latitudelongitude = 'Latitude/Longitude inválida';
_fc_props.enter_an_end_address = 'P.f. insira um Endereço final';
_fc_props.select_an_end_hotspot = 'P.f. selecione um Hotspot final';
_fc_props.failed_to_find_end_hotspot = 'Erro ao localizar o Hotspot final.';
_fc_props.print = 'Imprimir';
_fc_props.these_directions_are_for_informational_purposes = 'Estas instruções são apenas para fins informativos. Nenhuma garantia é feita sobre a sua exatidão ou completude. Projetos de construção, trânsito ou outros eventos podem causar condições reais que diferem destes resultados.';
_fc_props.enter_an_address = 'P.f. insira um Endereço';
_fc_props.select_a_hotspot = 'P.f. selecione um Hotspot';
_fc_props.failed_to_find_hotspot = 'Erro ao localizar o Hotspot.';
_fc_props.select_a_vehicle = 'P.f., selecione um Veículo';
_fc_props.failed_to_find_vehicle = 'Erro ao localizar o Veículo.';
_fc_props.failed_to_find_address = 'Erro ao localizar o endereço';
_fc_props.failed_to_find_vehicles = 'Erro ao localizar veículos em critérios de pesquisa.';
_fc_props.get_address = 'Obter Endereço';
_fc_props.results_for = 'Resultados para';
_fc_props.error_retrieving_results = 'Erro a Recuperar os Resultados';
_fc_props.no_results_found = 'Nenhum Resultado Encontrado';
_fc_props.address_is_required = 'O endereço é necessário';
_fc_props.city_is_required = 'A Cidade é Necessária';
_fc_props.set_location = 'Definir Localização';
_fc_props.set_area_of_interest = 'Definir Área de Interesse';
_fc_props.center_map_here = 'Centrar Mapa Aqui';
_fc_props.zoom_to_street_level = 'Zoom ao Nível da Rua';
_fc_props.set_start = 'Definir Início';
_fc_props.set_end = 'Definir Fim';
_fc_props.delete_node = 'Eliminar Nó';
_fc_props.create_a_hotspot = 'Criar um Hotspot';
_fc_props.create_a_region = 'Criar uma Região';
_fc_props.toggle_shapes = 'Alternar Formas';
_fc_props.refresh_map = 'Atualizar Mapa';
_fc_props.more_options = 'Mais Opções';
_fc_props.recenter_map = 'Recentrar Mapa';
_fc_props.zoom_to_vehicle = 'Zoom para Veículo';
_fc_props.apply_filters = 'Aplicar Filtros';
_fc_props.grid_view = 'Ver Grelha';
_fc_props.list_view = 'Ver Lista';
_fc_props.click_to_sort = 'Clique para ordenar';
_fc_props.message_history = 'Histórico Mensagem';
_fc_props.equipment_types = 'Tipo de Equipamentos';
_fc_props.send_message = 'Enviar Mensagem';
_fc_props.last_load = 'Última Carga';
_fc_props.last_load_on = 'Última Carga Em';
_fc_props.manual_logout = 'Manual Saída';
_fc_props.manual_login = 'Manual Entrada';
_fc_props.no_employees_logged_in = 'Sem Funcionários Em Sistema';
_fc_props.select_phone = 'Selecionar Telefone';
_fc_props.you_must_select_a_phone = 'Deve selecionar um Telefone, Equipmento e Funcionários';
_fc_props.are_you_sure_you_want_to_logout = 'Tem a certeza que pretende sair';
_fc_props.fix_time = 'Reparar Tempo';
_fc_props.to_next_fix = 'para Próxima Reparação';
_fc_props.minutes_to_next_fix = 'Minutos para Próxima Reparação';
_fc_props.liters = 'Litros';
_fc_props.destination = 'Destino';
_fc_props.return = 'Volta';
_fc_props.product = 'Produto';
_fc_props.no_records_found = 'Nenhum registo encontrado.';
_fc_props.vehicle_type = 'Tipo de Veículo';
_fc_props.view_in_map = 'Ver Todos no Mapa';
_fc_props.uploads = 'Carregamentos';
_fc_props.upload_csv_file = 'Carregar Ficheiro CSV';
_fc_props.permanent = 'Permanente';
_fc_props.expire_time = 'Expirará em';
_fc_props.please_select_x_for_tickets_marked_active = 'P.f. selecione {0} para Bilhetes marcado ATIVOS';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Múltiplos Bilhetes marcados como ATIVOS para os mesmos {0}';
_fc_props.failure_connecting_to_servlet = 'Erro a ligar ao servlet.';
_fc_props.status_time_summary = 'Resumo do Tempo de Estado';
_fc_props.view_in_map_one = 'Ver no Mapa';
_fc_props.question_report = 'Relatório de Perguntas';
_fc_props.end_date = 'Data Fim';
_fc_props.trailer_number = 'Número do Trailer';
_fc_props.region_panel = 'Painel Região';
_fc_props.hotspot_panel = 'Painel Hotspot';
_fc_props.shape = 'Forma';
_fc_props.circle = 'Círculo';
_fc_props.polygon = 'Polígono';
_fc_props.click_the_map_to_set_the_location = 'Clique no mapa para definir o local desejado.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Clique no mapa para definir o centro do hotspot.';
_fc_props.click_the_map_to_set_the_region_center = 'Clique no mapa para definir o centro da região.';
_fc_props.click_and_drag_points = 'Clique e arraste os pontos para editar a forma. Clique em GUARDAR para salvar as alterações.';
_fc_props.size = 'Tamanho';
_fc_props.are_you_sure_you_want_to_move_this = 'Tem certeza que quer mudar isto?';
_fc_props.are_you_sure_you_want_to_delete_this = 'Tem certeza que deseja eliminar isto?';
_fc_props.please_enter_a_valid_size = 'Por favor, indique um tamanho válido';
_fc_props.failed_to_save = 'Erro ao salvar.';
_fc_props.failed_to_move = 'Erro ao mover.';
_fc_props.failed_to_delete = 'Erro a eliminar.';
_fc_props.x_hours = '{0} horas';
_fc_props.hide_hotspots = 'Esconder Hotspots';
_fc_props.show_regions = 'Mostrar Regiões';
_fc_props.show_hotspots = 'Mostrar Hotspots';
_fc_props.hide_regions = 'Esconder Regiões';
_fc_props.numeric = 'Numérico';
_fc_props.message_type = 'Tipo de Mensagem';
_fc_props.text = 'Texto';
_fc_props.yes_no = 'Sim/Não';
_fc_props.responses = 'Respostas';
_fc_props.disabled = 'Desativado';
_fc_props.group_name = 'Nome Grupo';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Tem certeza que deseja eliminar o formato \'{0}\'?';
_fc_props.phone_number = 'Número de Telefone';
_fc_props.interface_pound = 'Interface #';
_fc_props.field = 'Campo';
_fc_props.default_label = 'Etiqueta Padrão';
_fc_props.custom_label = 'Etiqueta Personalizada';
_fc_props.you_must_enter_a_label = 'Tem de inserir uma Etiqueta.';
_fc_props.enabled = 'Ativo';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Integração Bilhete';
_fc_props.settings.geofence_off_open_hotspots = 'Geocerca desligado Hotspots abertos';
_fc_props.settings.associate_open_hotspots_wticket = 'Hotspots Abertos Associados c/bilhetes';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Permitir geofencing fora de hotspots abertos ou hotspots associados apenas com Bilhete de Condutor';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Seja ou não por associar Hotspots abertos com bilhetes quando um veículo entra neles';
_fc_props.settings.void_ticket_without_destination = 'Bilhete vazio sem destino';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'Se um bilhete for recebido enquanto outro está ativo, se o bilhete ativo não tem um destino, o bilhete será anulado.';
_fc_props.settings.autocreate_hotspots = 'Auto-criação de Hotspots';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Se deve ou não criar hotspots automaticamente a partir do endereço de destino quando um bilhete for recebido';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Auto-Ativar Bilhete Aquando da Recepção';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Se o bilhete é ativado quando é recebido por TrackIt (padrão \'verdadeiro\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Auto-Encerramento de Bilhete Ativo Após Recepção em Minutos';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Quanto tempo demora o bilhete ativo atual a ser ativado antes de auto-encerramento (padrão\'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Bilhete Máx Ativa Idade em Minutos';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Qual é que pode ser a idade em minutos de um bilhete quando for ativado em TrackIt (Padrão\'\')';
_fc_props.settings.max_hotspot_unused_days = 'Dias não utilizados Hotspot Máx';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Define os dias máximos para Hotspot poder não ser utilizado e ainda reviver';
_fc_props.settings.radius_multiplier = 'Multiplicador de Raio';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'O que multiplicar o raio quando um bilhete é recebido a partir do sistema de envio';
_fc_props.settings.ticket_message_date_format = 'Formato Data Mensagem Bilhete';
_fc_props.am = 'am';
_fc_props.pm = 'pm';
_fc_props.settings.ticket_message = 'Mensagem Bilhete';
_fc_props.settings.add_ticket_element = 'Adicionar Elemento Bilhete';
_fc_props.driver_performance = 'Desempenho de Condutor';
_fc_props.update_failed_can_not_connect = 'Atualização Falhada: Impossível ligar ao servlet.';
_fc_props.unknown_error = 'Erro Desconhecido';
_fc_props.selected = 'Selecionado';
_fc_props.web_service = 'Serviço Web';
_fc_props.error_processing_request = 'Erro a processar o pedido.';
_fc_props.email_address = 'Endereço de E-mail';
_fc_props.id = 'identidade';
_fc_props.last_used = 'Usado em';
_fc_props.error_invalid_vehiclenum = 'Erro no número do veículo inválido';
_fc_props.error_vehicle_is_not_logged_in = 'Erro: Veículo ainda não entrou.';
_fc_props.users = 'Utilizadores';
_fc_props.you_dont_have_access_to_this_page = 'Voce não tem acesso para visualizar esta página. Entre em contato com o administrador do sistema para obter acesso.';
_fc_props.previous_week = 'Semana Anterior';
_fc_props.status_breakdown = 'Estado Quebra';
_fc_props.select_plant_or_employee_group = 'Tem de selecionar uma planta ou um grupo de empregados.';
_fc_props.cannot_select_plant_and_employee_group = 'Não pode selecionar ambas uma planta ou um grupo de empregados.';
_fc_props.odometer_must_be_a_number = 'Início de Conta-Quilómetros deve ser um número';
_fc_props.ending_odometer_must_be_a_number = 'Fim de Conta-Quilómetros deve ser um número';
_fc_props.beginning_hour_meter_must_be_a_number = 'Início de Cronómetro deve ser um número';
_fc_props.ending_hour_meter_must_be_a_number = 'Fim de Cronómetro deve ser um número';
_fc_props.error_loading_status_change_page = 'Erro a carregar a página Mudar Estado.';
_fc_props.x_is_an_invalid_time_format = '{0} é um formato de tempo inválido, deve ser AAAA-MM-DD HH:MM:SS';
_fc_props.x_for_schedule_login = '{0} para programar entrada';
_fc_props.x_for_schedule_logout = '{0} para programar saída';
_fc_props.x_for_actual_login = '{0} para entrada atual';
_fc_props.x_for_actual_logout = '{0} para saída atual';
_fc_props.by_refreshing_the_time_card_detail = 'Ao atualizar o Detalhe Cartão Tempo vai perder todas as alterações, continuar?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Tem certeza de que deseja eliminar {0}?';
_fc_props.settings.command_mobile_ticket_url = 'Comando Bilhete Móvel URL';
_fc_props.year = 'Ano';
_fc_props.make = 'Marca';
_fc_props.model = 'Modelo';
_fc_props.settings.command_mobile_ticket_password = 'Senha Comando Ticket Móvel';
_fc_props.settings.command_mobile_ticket_auth_token = 'Comando Móvel Ticket Auth token';
_fc_props.target = 'Alvo';
_fc_props.number_of_digits = 'Número de dígitos';
_fc_props.number_of_integer_digits = 'Número de Integer Digits';
_fc_props.number_of_fractional_digits = 'Número de dígitos fracionários';
_fc_props.search = 'Pesquisa';
_fc_props.settings.command_mobile_ticket_date_order = 'Command Ticket Móvel ordem de data';
_fc_props.settings.command_mobile_ticket_date_length = 'Command Ticket Móvel Data Comprimento';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command Ticket Móvel Data Separator';
_fc_props.settings.command_mobile_ticket_slash = '/ (Barra)';
_fc_props.settings.command_mobile_ticket_dash = '- (Traço)';
_fc_props.settings.command_mobile_ticket_period = '. (Período)';
_fc_props.settings.command_mobile_ticket_space = '(Espaço)';
_fc_props.settings.command_mobile_ticket_time_length = 'Comando Móvel Tempo Ticket Comprimento';
_fc_props.settings.command_mobile_ticket_time_separator = 'Comando Móvel Ticket Tempo Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (Dois pontos)';
_fc_props.settings.command_mobile_ticket_time_format = 'Comando Móvel Tempo Ticket Format';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Use número de interface';
_fc_props.mechanic = 'Mecânico';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Intervalo de sondagem (segundos)';
_fc_props.driving_time = 'Tempo de automóvel';
_fc_props.find_near_plants = 'Encontre plantas perto';
_fc_props.please_create_hot_spot_first = 'Por favor, crie hotspot primeiro';
_fc_props.please_enter_hot_spot_description = 'Por favor, indique descrição Hot Spot';
_fc_props.please_select_a_hot_spot_type = 'Selecione um tipo de ponto quente';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Enviar E-mail driver';
_fc_props.directions_not_found = 'Inválido {0} endereços. Como chegar não encontrado.';
_fc_props.ws_user = 'Do utilizador';
_fc_props.ws_service = 'Serviço';
_fc_props.ws_method = 'Método';
_fc_props.ws_params = 'parâmetros';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Origem e Destino';
_fc_props.liter_abbr = 'eu';
_fc_props.help = 'Socorro';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Use Alt Número do Funcionário';
_fc_props.settings.command_mobile_ticket_android_url = 'URL Ticket móvel Android';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Ticket Segredo Móvel';
_fc_props.employee_x_has_no_logout_on_y = 'Employee {0} não tem logout de uma mudança a partir de {1}.';
_fc_props.phone_number_has_duplicates = 'Os números de telefone ter entradas duplicadas';
_fc_props.ounce = 'Onça';
_fc_props.water_extra = 'A água extra';
_fc_props.always = 'Sempre';
_fc_props.work_types_must_be_unique = 'Tipos de trabalho deve ser exclusivo';
_fc_props.manufacturer = 'Fabricante';
_fc_props.need_to_enter_seconds = 'Precisa inserir segundos.';
_fc_props.copy = 'cópia de';
_fc_props.create_in_existing_required = 'Criar em existente deve ser selecionado para criar ponto de acesso móvel.';
_fc_props.break = 'Pausa';
_fc_props.add_export_data_type = 'Adicionar tipo de dados de exportação';
_fc_props.edit_export_data_type = 'Editar tipo de dados de exportação';
_fc_props.delete_export_data_type = 'Excluir tipo de dados de exportação';
_fc_props.export_data_type = 'Exportar tipo de dados';
_fc_props.error_updating_export_data_type = 'Erro ao atualizar o tipo de dados de exportação';
_fc_props.settings.address_key = 'Chave de endereço';
_fc_props.settings.address_key_hover = 'Opções de chave de endereço';
_fc_props.settings.address_key_options = 'Opções de chave de endereço';
_fc_props.more_filter_options = 'Mais opções de filtro';
_fc_props.unauthorized_mapit_app = 'Esta versão do MapIt não está autorizada, atualize MapIt o mais rápido possível.';
_fc_props.zello_timeout = 'O tempo limite ocorreu em contato com Zello.';
_fc_props.user_agreement_text = 'Ao fazer o login, você concorda com o';
_fc_props.user_agreement = 'Termo de Acordo do Usuário';
_fc_props.upload = 'Envio';
_fc_props.x_mins_left = '{0} min (s) restantes';
_fc_props.continuous_location_tracking_colon = 'Acompanhamento contínuo de localização:';
_fc_props.hired_hauler_cannot_register_device = 'O Hauler contratado não pode registrar um novo dispositivo.';
_fc_props.timecard_does_not_contain_shift = 'O Cartão de Empregado não contém o turno (Informações do Cartão de Tempo) fornecido';
_fc_props.timecard_not_found = 'Cartão de tempo do funcionário não encontrado';
_fc_props.vehicle_must_be_assigned_for_clt = 'Veículo deve ser atribuído para rastrear locais.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'O rastreamento contínuo do veículo não foi atribuído será desativado.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Conexão perdida com o EDC. Por favor, verifique o seu WiFi e / ou Bluetooth estão habilitados.';
_fc_props.acknowledge_all = 'Reconhecer tudo';
_fc_props.expand = 'Expandir';
_fc_props.collapse = 'Colapso';
_fc_props.expand_all = 'Expandir todos';
_fc_props.collapse_all = 'Recolher todos';
_fc_props.last_update = 'Última atualização';
_fc_props.acknowledge_alert = 'Confirmar alerta';
_fc_props.acknowledge_message = 'Reconhecer Mensagem';
_fc_props.acknowledge_all_alerts = 'Reconheça todos os alertas';
_fc_props.acknowledge_all_messages = 'Reconheça todas as mensagens';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'A tolerância deve ser um número inteiro válido entre -99 e 99 (ambos incluídos)';
_fc_props.gps_permission_required = 'Permissão GPS necessária';
_fc_props.permissions_required = 'Permissões necessárias';
_fc_props.change_user = 'Mudar usuário';
_fc_props.account_not_registered_with_carrier = 'Esta conta não está registrada em uma operadora';
_fc_props.slump_loss = 'Perda de queda';
_fc_props.slump_loss_help = 'Ajuda para perda de queda';
_fc_props.did_not_receive_any_edc_devices = 'Não recebeu nenhum dispositivo EDC.';
_fc_props.edc_record_not_found = 'O registro EDC não foi encontrado';
_fc_props.disable_hotspot_prompt = 'Por favor, toque em "OK" quando solicitado a desativar seu hotspot.';
_fc_props.edc_device_is_in_use = 'EDC {0} está em uso.';
_fc_props.an_unknown_exception_has_occurred = 'Uma exceção desconhecida ocorreu';
_fc_props.login_has_timed_out = 'Login expirou';
_fc_props.use_paper_logs = 'Use registros de papel';
_fc_props.eld_login_failed = 'Falha no login de horas de serviço';
_fc_props.setup_time_off_type = 'Por favor, configure um tempo fora do tipo.';
_fc_props.view_load_properties = 'Visualizar as propriedades de carregamento';
_fc_props.couldnt_download_edc_firmware = 'Não foi possível baixar o firmware do EDC.';
_fc_props.password_incorrect = 'Senha incorreta';
_fc_props.edc_configuration_password_prompt = 'Devido ao estado deste dispositivo, você deve fornecer a senha do instalador para configurar.';
_fc_props.edc_configuration_check_failed = 'A validação de registros do servidor EDC falhou. Por favor, verifique as mensagens de erro para mais detalhes.';
_fc_props.configuration_issues_prompt = 'Os seguintes problemas foram detectados durante a configuração do EDC selecionado para o caminhão especificado';
_fc_props.checking_password = 'Verificando a senha';
_fc_props.reset_password_instructions_sent = 'Instruções para redefinir sua senha enviada para o endereço de e-mail {0}.';
_fc_props.reset_password_failed = 'Redefinir senha falhou, tente novamente.';
_fc_props.forgot_password = 'Esqueceu a senha?';
_fc_props.enter_valid_email = 'Endereço de e-mail inválido, insira um endereço de e-mail válido e tente novamente.';
_fc_props.issues_while_updating_edc_record = 'O EDC foi configurado com sucesso. No entanto, há problemas com os registros do EDC no servidor TrackIt que podem fazer com que ele não funcione. Por favor, ligue para o suporte para corrigir as informações.';
_fc_props.sending = 'Enviando ...';
_fc_props.minimum_ratio = 'Relação Mínima';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'RPM do cilindro fora do alcance';
_fc_props.probe_failure = 'Falha na sonda';
_fc_props.unknown_probe_data_failure = 'Falha nos dados do probe desconhecida';
_fc_props.no_loaded_status_meaning = 'Status sem carga';
_fc_props.no_depart_status_meaning = 'Sem status de partida';
_fc_props.no_arrive_status_meaning = 'Status de Chegada';
_fc_props.no_begin_work_status_meaning = 'Status de trabalho não iniciado';
_fc_props.no_fully_mixed_status_meaning = 'Sem status totalmente misto';
_fc_props.no_begin_work_or_at_job_status = 'Os status Iniciar trabalho ou No trabalho não estão presentes';
_fc_props.driver_didnt_follow_procedure = 'O motorista não seguiu o procedimento (Não está desacelerando o tambor)';
_fc_props.probe_malfunction = 'Mau funcionamento da sonda (falha interna, etc.)';
_fc_props.no_data_from_probe = 'Nenhum dado do probe (perda de dados)';
_fc_props.missing_probe_calibration = 'Dados de calibração do probe';
_fc_props.probe_pressure_not_in_range = 'Pressão da sonda fora da faixa';
_fc_props.arrival = 'Chegada';
_fc_props.small_load = 'CARGA PEQUENA';
_fc_props.dry_slump = 'SLUMP SECO';
_fc_props.probe_remixing_turns = 'Remisturando';
_fc_props.turns_needed_to_remix = 'Água adicionada - mistura adicional necessária';
_fc_props.probe_remixing_finished = 'Remixagem concluída';
_fc_props.probe_battery_low_alert = 'Alerta de bateria fraca da sonda';
_fc_props.probe_battery_low_message = 'Bateria da sonda fraca';
_fc_props.probe_battery_low = 'Bateria da sonda fraca';
_fc_props.alert_when_probe_battery_low = 'Alerta quando a bateria da sonda está fraca';
_fc_props.probe_sensor_fatal_failure = 'Falha fatal do sensor';
_fc_props.probe_thermistor_failure = 'Falha no termistor';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensor preso no concreto';
_fc_props.probe_sensor_over_weight = 'Sensor acima do peso';
_fc_props.probe_batteries_low = 'Bateria fraca';
_fc_props.no_probe_data = 'Não há dados do probe:';
_fc_props.check_probe = 'Verificar Sonda';
_fc_props.service_probe = 'Sonda de serviço';
_fc_props.below_x = 'Abaixo de {0}';
_fc_props.above_x = 'Acima de {0}';
_fc_props.probe_serial_number_invalid_format = 'O número de série da sonda não está no formato válido, conforme exibido no espaço reservado para o campo';
_fc_props.talk_dnd_permission_prompt = 'O Talk requer a permissão NÃO PERTURBE.';
_fc_props.max = 'Máx.';
_fc_props.status_changed_quickly = 'Status alterado muito rapidamente';
_fc_props.is_empty = 'Está vazia';
_fc_props.slump_increased_plasticizer_question = 'Foi adicionado plastificante?';
_fc_props.slump_increased_water_added_question = 'Quanta água não medida foi adicionada?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'O Probe e o monitoramento do ciclo de entrega não são suportados juntos no mesmo dispositivo.';
_fc_props.no_data_from_sensor = 'Sem dados do sensor (perda de dados)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Tipo de sistema';
_fc_props.magnet_count = 'Contagem de ímãs';
_fc_props.sensors_setup = 'Configuração dos sensores';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'O DCM requer que o endereço MAC da caixa de E / S, o tipo de tambor, o tipo de DCM e a contagem de ímãs sejam definidos.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Volume do bilhete';
_fc_props.drs_current_volume = 'Volume Atual';
_fc_props.drs_current_water_cement_ratio = 'Razão Água / Cimento Atual';
_fc_props.drs_target_water_cement_ratio = 'Razão Água / Cimento Alvo';
_fc_props.drs_total_revolutions = 'Total';
_fc_props.drs_current_volume_lp_small = 'Atual';
_fc_props.drum_revolutions = 'Revoluções do tambor';
_fc_props.drs_total_revolutions_lp_small = 'Total';
_fc_props.drs_charging_revolutions_lp_small = 'Carregando';
_fc_props.drs_discharging_revolutions_lp_small = 'Descarregando';
_fc_props.drs_target_wc_lp_small = 'Alvo W / C';
_fc_props.drs_current_wc_lp_small = 'W / C atual';
_fc_props.drs_ticketed_volume_lp_small = 'Com bilhete';
_fc_props.android_location_disclosure = 'TrackIt usa localização fina e grosseira para coletar dados de localização em segundo plano, mesmo quando não estiver em uso, para habilitar o status automático e a visibilidade do mapa em tempo real para despacho.';
_fc_props.android_location_usage = 'Divulgação de localização';
_fc_props.end_pour_trigger_threshold = 'Limiar do gatilho de término de derramamento';
_fc_props.drs_wiring_error = 'Erro de fiação';
_fc_props.drs_wiring_pulse_error = 'Erro de pulso de fiação';
_fc_props.drs_wiring_magnet_error = 'Erro de ímã de fiação';
_fc_props.drs_magnet_missing_error = 'Erro de ímã ausente';
_fc_props.android_location_disclosure_3p = 'TrackIt coleta dados de localização em segundo plano, para permitir o status automático e a visibilidade do mapa em tempo real para despacho. Quando fora do expedidor, a localização não é compartilhada.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Selecione Full MDM ou Trackit Remote Support';
_fc_props.move_up = 'Subir';
_fc_props.move_down = 'Mover para Baixo';
_fc_props.driver_not_found = 'Driver não encontrado.';
_fc_props.multiple = 'Múltiplo';
_fc_props.water_meter_installed = 'Medidor de água instalado';
_fc_props.delete_waypoint = 'Apagar Waypoint';
_fc_props.wash_sensor_installed = 'Sensor de lavagem instalado';
_fc_props.washing_start_time = 'Hora de início da lavagem';
_fc_props.end_washing = 'Fim da lavagem';
_fc_props.variable_rpms = 'Var RPMs';
_fc_props.no_slump = 'Sem queda';
_fc_props.end_washing_duration = 'Duração final da lavagem';
_fc_props.privacy_policy = 'Política de Privacidade';
_fc_props.privacy_policy_text = 'Eu li e aceito o';
_fc_props.login_fail_accept_privacy_policy = 'A política de privacidade deve ser aceita para prosseguir.';
_fc_props.rotation_sensor = 'Sensor de rotação';
_fc_props.pto_sensor = 'Sensor PTO';
_fc_props.pto_sensor_installed = 'Sensor PTO instalado';
_fc_props.polarity_reversed = 'Polaridade reversa';
_fc_props.pto_sensor_type = 'Tipo de sensor PTO';
_fc_props.sensor_type = 'Tipo de Sensor';
_fc_props.prompt_select_pto_sensor_type = 'Você deve selecionar um "Tipo de sensor PTO" se tiver selecionado um "Sensor PTO" como seu tipo de sensor principal.';
_fc_props.priming_turns_at_full_load = 'A preparação gira em carga total';
_fc_props.priming_turns_at_ten_percent_load = 'O priming gira com 10% de carga';
_fc_props.weight_units = 'Unidades de peso';
_fc_props.settings.send_status = 'Enviar status / localização para ponte de evento';
_fc_props.volume_difference = 'Diferença de Volume';
_fc_props.mobile_ticket_iframe_url = 'URL do iFrame do tíquete móvel';
_fc_props.mobile_ticket_user = 'Usuário de tíquete móvel';
_fc_props.mobile_ticket_password = 'Senha do tíquete móvel';
_fc_props.current_truck_status = 'Status atual do caminhão';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Disparado por revoluções máximas';
_fc_props.alerts_by_type = 'Alertas por tipo';
_fc_props.water_flow_meter = 'Medidor de fluxo de água';
_fc_props.flow_meter_no_pulses_warning = 'Sem Pulsos - Aviso';
_fc_props.flow_meter_no_pulses_critical = 'Sem Pulsos - Crítico';
_fc_props.flow_meter_excessive_pulses = 'Pulsos Excessivos';
_fc_props.flow_meter_constantly_flowing = 'Fluindo constantemente';
_fc_props.flow_meter_good = 'Bom';
_fc_props.whats_new = 'O que há de novo';
_fc_props.whats_new_widget_text = 'Você sabia que atualizamos o TrackIt pelo menos uma vez por mês? Obtenha as últimas atualizações e dicas.';
_fc_props.get_the_scoop = 'Obtenha a colher';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Análise de negócios que melhora seus resultados financeiros, agora incluída no TrackIt';
_fc_props.find_out_more = 'Descubra mais';
_fc_props.daily_breakdown = 'Repartição Diária';
_fc_props.question_summary = 'Resumo da pergunta';
_fc_props.trip_summary = 'Resumo da viagem';
_fc_props.manage_employees = 'Gerenciar funcionários';
_fc_props.manage_hotspots = 'Gerenciar pontos de acesso';
_fc_props.manage_devices = 'Gerenciar dispositivos';
_fc_props.manage_equipment = 'Gerenciar Equipamentos';
_fc_props.manage_users = 'Gerenciar usuários';
_fc_props.manage_statuses = 'Gerenciar status';
_fc_props.manage_alerts = 'Gerenciar Alertas';
_fc_props.popular_report_links = 'Links de relatórios populares';
_fc_props.popular_settings_links = 'Links de configurações populares';
_fc_props.sidemenu_settings = 'Definições';
_fc_props.loads_per_driver = 'Cargas por driver';
_fc_props.please_select_application = 'Selecione pelo menos um aplicativo';
_fc_props.external_app_processing = 'Processando em aplicativo externo';
_fc_props.external_app_sent = 'Enviado para aplicativo externo';
_fc_props.external_app_received = 'Recebido por aplicativo externo';
_fc_props.wait_time_at_job = 'Tempo de espera no trabalho';
_fc_props.wait_time_at_plant = 'Tempo de espera na fábrica';
_fc_props.loading_time = 'Tempo de carregamento';
_fc_props.unloading_time = 'Tempo de Descarga';
_fc_props.pre_post_trip = 'Viagem Pré/Pós';
_fc_props.update_thresholds = 'Limites de atualização';
_fc_props.green = 'Verde';
_fc_props.yellow = 'Amarelo';
_fc_props.red = 'vermelho';
_fc_props.between = 'Entre';
_fc_props.num_of_loads = '# de cargas';
_fc_props.num_of_drivers = 'nº de motoristas';
_fc_props.status_meaning_not_setup = 'Status Significado não configurado';
_fc_props.or_less = 'Ou menos';
_fc_props.or_greater = 'Ou melhor';
_fc_props.probe_self_diagnostic_state = 'Estado de autodiagnóstico da sonda';
_fc_props.update_current_truck_status_settings = 'Atualizar as configurações de status do caminhão atual';
_fc_props.default_behavior = 'Padrão (10 principais status)';
_fc_props.combine_similar_statuses = 'Combine os mesmos status de todos os tipos (loops)';
_fc_props.use_other_category = 'Use a categoria \'Outro\'';
_fc_props.selected_statuses = 'Status Selecionados';
_fc_props.ideas = 'Ideias';
_fc_props.air_content = 'Conteúdo Aéreo';
_fc_props.air_measured_time = 'Tempo medido do ar';
_fc_props.ten_max = '10 Máx.';
_fc_props.loads_per_plant = 'Cargas por planta';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Pulso inválido do medidor de vazão por volume.';
_fc_props.coming_soon_title = 'Mais widgets em breve';
_fc_props.submit_your_ideas = 'Envie suas ideias para o nosso Portal de Ideias usando a Lâmpada no Menu de Navegação à esquerda.';
_fc_props.selected_plants = 'Plantas selecionadas';
_fc_props.update_loads_per_plant_settings = 'Atualizar Configurações de Cargas por Planta';
_fc_props.default_top_10_plants = 'Padrão (10 principais plantas)';
_fc_props.phone_number_will_be_uploaded = 'O ID do dispositivo será carregado para fins de registro.';
_fc_props.include_deleted = 'Incluir excluído';
_fc_props.primary_status_type = 'Tipo de status principal';
_fc_props.additional_status_trigger = 'Acionadores de status adicionais';
_fc_props.update_tickets_destination = 'Atualizar destino em todos os tickets associados';
_fc_props.settings.send_destination_changes_to_connex = 'Enviar alterações de destino para o Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'O ticket está ativo quando é recebido pelo TrackIt (padrão \'sempre\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Ativar automaticamente o próximo ticket na conclusão do ticket anterior';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Deve ativar automaticamente o próximo ticket na conclusão do ticket (padrão \'true\')';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Status para status ocioso quando nenhum ticket ativo';
_fc_props.break_down = 'Discriminação';
_fc_props.idle = 'Parado';
_fc_props.pto_work_maximum_speed_limit = 'Limite de velocidade máxima de trabalho';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Associar alteração de status que conclui um ticket com esse ticket';
_fc_props.approaching = 'Aproximando';
_fc_props.performing_task = 'Executando Tarefa';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Tem certeza de que deseja excluir o Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Atualizar tíquete {0}. Destino alterado.';
_fc_props.trackit_return_destination_changed_to_0 = 'Atualizar tíquete {0}. Retorno alterado.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Adicionar Geogate';
_fc_props.driver_enabled = 'Driver habilitado';
_fc_props.dispatcher_enabled = 'Despachante ativado';
_fc_props.runsheet_number = 'Número da planilha';
_fc_props.manual_load = 'Carga Manual';
_fc_props.edit_geogate = 'Editar Geogate';
_fc_props.handling = 'Manipulação';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Painel Geogate';
_fc_props.vehicle_is_not_logged_in = 'Mensagem não enviada (o veículo não está logado)';
_fc_props.air = 'Ar';
_fc_props.create_a_geogate = 'Criar um Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Clique no mapa para definir os pontos de geogate.';
_fc_props.clock_out = 'Saída';
_fc_props.handling_operation = 'Operação de manuseio';
_fc_props.view_geogates = 'Ver Geogates';
_fc_props.select_a_geogate = 'Selecione um Geogate';
_fc_props.failed_to_find_geogate = 'Falha ao encontrar o Geogate';
_fc_props.adjust_geogate = 'Ajustar Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Dias extras padrão da data de expiração do Geogate';
_fc_props.menu_settings_title_retention = 'Retenção de dados';
_fc_props.category = 'Categoria';
_fc_props.telemetry = 'Telemetria';
_fc_props.ticket_data = 'Dados do Bilhete';
_fc_props._30_days = '30 dias';
_fc_props._6_months = '6 meses';
_fc_props._1_year = '1 ano';
_fc_props._3_years = '3 anos';
_fc_props.new_group = 'Novo grupo';
_fc_props.selected_vehicles = 'Veículos selecionados';
_fc_props.group_name_is_required_field = 'O nome do grupo é um campo obrigatório';
_fc_props.at_least_vehicle_should_be_selected = 'Pelo menos 1 veículo deve ser selecionado';
_fc_props.groups = 'Grupos';
_fc_props.trucks = 'Caminhões';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate foi removido. Por favor, atualize a página.';
_fc_props.air_timestamp = 'Carimbo de data e hora do ar';
_fc_props.fail = 'FALHOU';
_fc_props.stuck = 'GRUDOU';
_fc_props.dirty = 'SUJO';
_fc_props.seasonal = 'Sazonal';
_fc_props.category_details = 'Detalhes da categoria';
_fc_props.alert_events = 'Eventos de Alerta';
_fc_props.driver_performance_infractions = 'Infrações de desempenho do motorista';
_fc_props.telemetry_details = 'Dados de localização, pontos de acesso, tempo nos pontos de acesso, tempo nas regiões, dados do DCM, dados da sonda, dados do mecanismo';
_fc_props.ticket_data_details = 'Dados do Bilhete';
_fc_props.general_details = 'Alterações de status, cartões de ponto, horários';
_fc_props.wash = 'Lavagem';
_fc_props.filter_options = 'Opções de filtro';
_fc_props.home_plants = 'Plantas caseiras';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'O DCM PTO requer que o tipo de sensor PTO seja definido.';
_fc_props.invalid_io_box_mac_address = 'Endereço MAC inválido da caixa de E/S';
_fc_props.access_denied = 'Acesso negado';
_fc_props.wash_events = 'Eventos de Lavagem';
_fc_props.min_speed = 'velocidade mínima';
_fc_props.temperature_change = 'Mudança de temperatura';
_fc_props.degrees_cap = 'Graus';
_fc_props.washout_tracking = 'Rastreamento de esmaecimento';
_fc_props.approaching_wash = 'Lavagem Aproximada';
_fc_props.arrival_wash = 'lavagem de chegada';
_fc_props.performing_wash = 'Lavagem';
_fc_props.wash_complete = 'Lavagem Completa';
_fc_props.must_be_positive_or_zero = 'Deve ser positivo ou zero!';
_fc_props.min_speed_greater_than_max = 'A velocidade mínima deve ser menor que a velocidade máxima!';
_fc_props.clock_in = 'Relógio em';
_fc_props.mix_water_at_x_to_y_for_t = 'Misture água de {0} a {1} RPMs para {2} revoluções.';
_fc_props.discharge_water_for_x_revolutions = 'Descarregue a água por {0} revoluções.';
_fc_props.eod_drum_wash_complete = 'Lavagem de tambor concluída! <br> Pare o Tambor com a Sonda no topo.';
_fc_props.washing_complete = 'Lavagem guiada completa';
_fc_props.eod_washout_no_data_available = 'Dados do sensor indisponíveis, conclua o processo de lavagem.';
_fc_props.eod_washout_no_data_available_title = 'Lavagem guiada indisponível';
_fc_props.menu_reports_title_ticket_time_summary = 'Resumo do tempo do bilhete';
_fc_props.menu_reports_description_ticket_time_summary = 'Exibir informações de tempo de passagem para um pedido ou veículo';
_fc_props.menu_reports_title_summary = 'Resumo';
_fc_props.menu_reports_title_ticket_summary = 'Resumo do bilhete';
_fc_props.menu_reports_description_summary1 = 'Ver um resumo do tempo em hotspots';
_fc_props.menu_dispatch_title_main = 'Principal';
_fc_props.menu_dispatch_title_login_registry = 'Registro de login';
_fc_props.menu_reports_title_main = 'Principal';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Tem certeza de que deseja excluir este tipo de dados de exportação?';
_fc_props.dvir_status_paper_logs = 'Registros de papel';
_fc_props.menu_reports_title_billing_report = 'Relatório de Faturamento';
_fc_props.menu_reports_description_billing_report = 'Relatório de Faturamento';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status para status com ticket ao ativar o trabalho';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status para status com ticket ao ativar trabalho (padrão \'Falso\')';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Libras';
_fc_props.units_weight_kilogram_label = 'Quilogramas';
_fc_props.settings_weight = 'Peso';
_fc_props.units_pounds = 'Libras';
_fc_props.units_kilograms = 'Quilogramas';
_fc_props.geo_gate_edit_modal_description = 'Descrição';
_fc_props.geo_gate_edit_modal_start_point = 'Ponto de partida';
_fc_props.geo_gate_edit_modal_end_point = 'Ponto Final';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Tipo de equipamento';
_fc_props.geo_gate_edit_modal_heading_south = 'Sul a Norte';
_fc_props.geo_gate_edit_modal_heading_north = 'norte a sul';
_fc_props.geo_gate_edit_modal_driver_message = 'Mensagem do motorista';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Mensagem de Despacho';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Data de expiração Hora';
_fc_props.geo_gate_edit_modal_permanent = 'Permanente';
_fc_props.geo_gate_edit_modal_critical = 'Crítico';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Erro ao salvar Geogate: O campo Descrição deve ser preenchido.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Erro ao salvar Geogate: O campo Ponto inicial deve ser preenchido.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Erro ao salvar Geogate: o campo Ponto inicial contém formato de dados inválido.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Erro ao salvar Geogate: campo End Point contém formato de dados inválido.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Erro ao salvar Geogate: O campo Data e Hora de Expiração deve ser preenchido.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Erro ao salvar Geogate: o campo Data e hora de expiração contém formato de dados inválido.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Erro ao salvar Geogate: Norte para Sul - O campo Mensagem de Despacho deve ser preenchido';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Erro ao salvar Geogate: Nenhuma alteração detectada';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Erro ao salvar Geogate: o campo Data e hora de expiração contém data e hora expiradas';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Erro ao salvar Geogate: o campo de descrição deve conter apenas letras e números';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Erro ao salvar Geogate: o campo Tipo de equipamento deve ser preenchido';
_fc_props.geo_gate_table_message_label_north_to_south = 'norte a sul';
_fc_props.geo_gate_table_message_label_south_to_north = 'Sul a Norte';
_fc_props.geo_gate_table_message_label_both = 'Ambos';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Latitude longitude';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Marcar isso substituirá a configuração para restrição de mensagem de movimento do dispositivo';
_fc_props.geo_gate_edit_modal_seasonal = 'Sazonal';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Erro ao salvar Geogate: datas sazonais devem ser preenchidas';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Erro ao salvar Geogate: os campos de data sazonal contêm formato de dados inválido';
_fc_props.test_js_test_javascript = 'Testar Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'quilômetros';
_fc_props.units_miles = 'milhas';
_fc_props.units_gallons_abbr = 'GAROTA';
_fc_props.units_liters_abbr = 'eu';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Erro ao salvar Geogate: Sul para Norte - O campo Mensagem do Motorista deve ser preenchido';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Erro ao salvar Geogate: Sul para Norte - O campo Mensagem de Despacho deve ser preenchido';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Erro ao salvar Geogate: Norte para Sul - O campo Mensagem do Motorista deve ser preenchido';
_fc_props.geo_gate_heading = 'Cabeçalho';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Erro ao salvar Geogate: o campo End Point deve ser preenchido';
_fc_props.units_mile = 'Milha';
_fc_props.units_kilometer = 'Quilômetro';
_fc_props.cloud_dvir__exit = 'Saída';
_fc_props.cloud_dvir__signature = 'Assinatura';
_fc_props.cloud_dvir__inspection = 'Inspeção';
_fc_props.cloud_dvir__add_photos_notes = 'Adicionar fotos/notas';
_fc_props.cloud_dvir__odometer_reading = 'Leitura de odômetro';
_fc_props.cloud_dvir__engine_hours = 'Horas do motor';
_fc_props.cloud_dvir__truck_details = 'Detalhes do caminhão';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Determinar o estado geral do veículo';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Nome completo e assinatura do motorista';
_fc_props.cloud_dvir__sign = 'Sinal';
_fc_props.cloud_dvir__driver_note_from = 'Nota do motorista de';
_fc_props.cloud_dvir__add_notes = 'Adicionar notas';
_fc_props.cloud_dvir__driver_notes = 'Notas do Motorista';
_fc_props.cloud_dvir__done = 'Feito';
_fc_props.cloud_dvir__needs_repair = 'Precisa de reparo';
_fc_props.cloud_dvir__not_applicable = 'Não aplicável';
_fc_props.cloud_dvir__review_and_sign = 'Revise e assine';
_fc_props.cloud_dvir__add_photos = 'Adicionar foto';
_fc_props.cloud_dvir__upload_photo_limit = 'Você pode enviar até 3 fotos';
_fc_props.cloud_dvir__mismatch_title = 'Alerta de incompatibilidade';
_fc_props.cloud_dvir__ok = 'OK';
_fc_props.cloud_dvir_template = 'Modelo de nuvem DVIR';
_fc_props.cloud_dvir__needs_review = 'DVIR precisa de revisão';
_fc_props.cloud_dvir__submitted = 'Submetido';
_fc_props.cloud_dvir__completed_by = 'Completado por';
_fc_props.cloud_dvir__review_btn = 'Análise';
_fc_props.cloud_dvir__truck_number = 'Caminhão {0}';
_fc_props.cloud_dvir__pending_review = 'Revisão pendente';
_fc_props.cloud_dvir__start_dvir = 'Iniciar DVIR';
_fc_props.cloud_dvir__dvir_history = 'Histórico do DVIR';
_fc_props.cloud_dvir__view_all = 'Ver tudo';
_fc_props.load_zone = 'Zona de Carga';
_fc_props.view_load_zones = 'Exibir Zonas de Carregamento';
_fc_props.edit_load_zone = 'Editar Zona de Carregamento';
_fc_props.create_a_load_zone = 'Criar uma zona de carga';
_fc_props.load_zone_panel = 'Carregar Painel de Zona';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Clique no mapa para definir o centro da zona de carga.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Esta inspeção tem defeitos indicados e você selecionou que este veículo é seguro para operar. Valide antes de prosseguir';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Esta inspeção não tem defeitos indicados e você selecionou que este veículo não é seguro para operar. Valide antes de prosseguir.';
_fc_props._90_days = '90 dias';
_fc_props.cloud_dvir = 'Nuvem DVIR';
_fc_props.cloud_dvir_details = 'Envios de Cloud DVIR';
_fc_props.show_load_zones = 'mostrar zonas de carga';
_fc_props.hide_load_zones = 'esconder zonas de carga';
_fc_props.no = 'Não';
_fc_props.cloud_dvir__reset = 'Reiniciar';
_fc_props.cloud_dvir__clear = 'Claro';
_fc_props.mark_read = 'Marque lido';
_fc_props.mark_unread = 'Marcar não lido';
_fc_props.read = 'Ler';
_fc_props.unread = 'Não lida';
_fc_props.air_sensor_serial_number = 'Número de série do sensor de ar';
_fc_props.air_sensor_mac_address = 'Endereço MAC do sensor de ar';
_fc_props.air_sensor_serial_number_invalid_format = 'O número de série do sensor de ar não está em formato válido: (alfanumérico e com no máximo 32 caracteres com traços)';
_fc_props.air_sensor_mac_address_invalid_format = 'O endereço MAC do sensor de ar não está no formato hexadecimal válido, conforme exibido no espaço reservado para o campo';
_fc_props.air_sensor_license = 'Sensor de ar';
_fc_props.adjust_route_point = 'Ajustar ponto de rota';
_fc_props.route_point_panel = 'Painel de pontos de rota';
_fc_props.route_point = 'Ponto de rota';
_fc_props.cloud_dvir__pre_trip = 'Pré viagem';
_fc_props.cloud_dvir__post_trip = 'Pós-viagem';
_fc_props.cloud_dvir__other_insction_type = 'Outro';
_fc_props.cloud_dvir__dvir_completed = 'Concluído';
_fc_props.cloud_dvir__awaiting_mechanic = 'Aguardando Mecânico';
_fc_props.cloud_dvir__awaiting_driver = 'Aguardando aprovação do motorista';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Não foi possível verificar o número de série do sensor de ar. Entre em contato com o suporte para obter assistência adicional.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Este número de série do sensor de ar já existe, use outro número de série';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Não foi possível verificar o endereço MAC do sensor de ar. Entre em contato com o suporte para obter assistência';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Erro ao enviar DVR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Por favor, tente novamente';
_fc_props.ticket_integration_descr = 'A integração de tickets permite definir configurações de integração de tickets, bem como tela inicial/tela de carregamento/exibição de montagem rígida';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVR enviado com sucesso';
_fc_props.cloud_dvir__inspection_trip_type = 'Tipo de viagem de inspeção';
_fc_props.menu_dvir_title_mechanic = 'Mecânico';
_fc_props.sidemenu_dvir = 'DVR';
_fc_props.air_sensor_firmware = 'Firmware do sensor de ar';
_fc_props.when_no_active_ticket = 'Quando não há ticket ativo';
_fc_props.used_by = 'Usado por';
_fc_props.fields = 'Campos';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Tem certeza de que deseja excluir o layout do ticket "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Layout do ingresso';
_fc_props.add_ticket_layout = 'Adicionar layout de ingresso';
_fc_props.edit_ticket_layout = 'Editar layout do ticket';
_fc_props.ticket_layout_edit_modal_name = 'Nome';
_fc_props.ticket_layout_edit_modal_default = 'Padrão';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Erro ao salvar o layout do ticket: o campo Nome deve ser preenchido.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Erro ao salvar o layout do ticket: o campo Nome deve conter apenas letras e números';
_fc_props.ticket_layout_edit_modal_fields = 'Campos';
_fc_props.ticket_layout_edit_modal_usage = 'Uso';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'O layout do ticket foi removido. Por favor, atualize a página.';
_fc_props.more_lower_case = 'mais';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Erro ao salvar o layout do ticket: um ou mais campos devem ser selecionados.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Erro ao salvar o layout do ticket: um ou mais status devem ser selecionados.';
_fc_props.cloud_dvir__ad_hoc = 'Ad hoc';
_fc_props.radius_multiplier_should_be = 'O multiplicador de raio deve ser um número flutuante';
_fc_props.ticket_max_activate_age_err = 'Ticket Max Activate Age Minutes deve ser um número inteiro.';
_fc_props.max_hotspot_unused_days_errs = 'O máximo de dias não utilizados do hotspot deve ser um número inteiro.';
_fc_props.begin_pour_air_content = 'Comece a despejar conteúdo no ar';
_fc_props.arrival_air_content = 'Conteúdo aéreo de chegada';
_fc_props.fully_mixed_air_content = 'Conteúdo de ar totalmente misturado';
_fc_props.departure_air_content = 'Conteúdo aéreo de partida';
_fc_props.popup_enabled = 'Pop-up ativado';
_fc_props.popup_disabled = 'Pop-up desativado';
_fc_props.cloud_dvir__leave_confirmation_title = 'Aviso';
_fc_props.cloud_dvir__leave_confirmation_description = 'Sair da página agora fará com que todas as alterações sejam perdidas.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'confirme';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Cancelar';
_fc_props.air_bt_ratio = 'Medição do sensor de ar';
_fc_props.cloud_dvir__view_photos_notes = 'Ver fotos/notas';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Adicionar o status criará um uso em branco em outro layout de ticket. Deseja prosseguir com a adição?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Veículo não possui licença de sensor de ar.';
_fc_props.air_sensor_installed = 'Sensor de ar instalado';
_fc_props.load_card = 'Carregar cartão';
_fc_props.cloud_dvir__inspection_profile = 'Perfil de inspeção';
_fc_props.cloud_dvir__view_details = 'Ver detalhes';
_fc_props.cloud_dvir__certify_repairs = 'Certificar reparos';
_fc_props.cloud_dvir__repairs_made = 'Reparos feitos';
_fc_props.cloud_dvir__repairs_not_necessary = 'Reparos não necessários';
_fc_props.cloud_dvir__mechanic_note_from = 'Nota mecânica de';
_fc_props.cloud_dvir__mechanic_assignment = 'Atribuição Mecânica';
_fc_props.cloud_dvir__mechanic_signature_description = 'Marque todos os itens aplicáveis abaixo e assine indicando que o veículo foi devidamente inspecionado.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'A condição deste veículo é satisfatória';
_fc_props.cloud_dvir__defects_corrected_label = 'Os defeitos foram corrigidos';
_fc_props.cloud_dvir__repair_not_required_label = 'Reparo não necessário para operação segura do veículo';
_fc_props.safety = 'Segurança';
_fc_props.cloud_dvir__awaiting_sign = 'Aguardando Sinal';
_fc_props.cloud_dvir__dvir_review = 'Revisão DVR';
_fc_props.settings.mobile_auto_start_navigation = 'Iniciar navegação automaticamente no celular';
_fc_props.cloud_dvir__edit = 'Editar';
_fc_props.delete_custom_label = 'Excluir rótulo personalizado';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Sua versão do TrackIt está desatualizada e alguns recursos do DVR podem não funcionar corretamente. Atualize o TrackIt antes de continuar.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Algo deu errado durante o upload da assinatura. Por favor, tente novamente';
_fc_props.max_hotspot_unused_days_error = 'O máximo de dias não utilizados do hotspot tem no máximo 1.000 dias.';
_fc_props.menu_dvir_title_admin_templates = 'Modelos';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Administrador';
_fc_props.cloud_dvir__admin_templates = 'Modelos';
_fc_props.form_name = 'Nome do formulario';
_fc_props.form_status = 'Status do formulário';
_fc_props.menu.dvir.title = 'DVR';
_fc_props.cloud_dvir__option_download = 'Download';
_fc_props.cloud_dvir__option_view = 'Visualizar';
_fc_props.cloud_dvir__inspectortype_driver = 'Motorista';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Visualizar/editar/criar formulários e envios DVR personalizados';
_fc_props.menu.dvir.description.mechanic = 'Visualizar/editar envios de DVR acionáveis';
_fc_props.not_in_use = 'Fora de uso';
_fc_props.logged_in = 'logado';
_fc_props.reviewing_driver = 'Revendo o driver';
_fc_props.cloud_dvir__report_title = 'Relatório de inspeção veicular do motorista';
_fc_props.cloud_dvir__report_signed = 'assinado';
_fc_props.cloud_dvir__report_page_counter = 'Página {0} de {1}';
_fc_props.cloud_dvir__report_defects = 'Defeitos';
_fc_props.cloud_dvir__report_comments = 'Comentários';
_fc_props.cloud_dvir__report_inspection_table_title = 'Inspeção veicular';
_fc_props.cloud_dvir__report_driver_table_title = 'Informações do motorista';
_fc_props.cloud_dvir__report_carrier = 'Operadora';
_fc_props.cloud_dvir__report_truck = 'Caminhão';
_fc_props.cloud_dvir__report_location = 'Localização';
_fc_props.cloud_dvir__report_odometer = 'Odômetro';
_fc_props.cloud_dvir__report_hours = 'Horas';
_fc_props.cloud_dvir__report_no_comments = 'Sem comentários';
_fc_props.include_unlicensed_vehicles = 'Incluir veículos não licenciados';
_fc_props.cloud_dvir__search_by_form_name = 'Pesquisar por nome do formulário';
_fc_props.cloud_dvir__create_dvir = 'Crie um DVR';
_fc_props.cloud_dvir__update_dvir = 'Atualizar DVR';
_fc_props.cloud_dvir__form_name = 'Nome do formulario';
_fc_props.cloud_dvir__form_description = 'Descrição do formulário';
_fc_props.cloud_dvir__form_status = 'Status do formulário';
_fc_props.information = 'Informação';
_fc_props.cloud_dvir__search_categories = 'Categorias de pesquisa';
_fc_props.cloud_dvir__create_new_category = 'Criar nova categoria';
_fc_props.category_name = 'Nome da Categoria';
_fc_props.sub_category = 'Subcategoria';
_fc_props.sub_category_name = 'Nome da subcategoria';
_fc_props.delete_category = 'Excluir categoria';
_fc_props.cloud_dvir__dvir_information = 'Informações DVR';
_fc_props.cloud_dvir__dvir_choose_categories = 'Escolha categorias';
_fc_props.cloud_dvir__create_category = 'Criar categoria';
_fc_props.cloud_dvir__update_category = 'Atualizar categoria';
_fc_props.delete_route_point = 'Excluir ponto de rota';
_fc_props.view_route_points = 'Ver pontos de rota';
_fc_props.ignore_cancel_preload = 'Ignore o cancelamento, você está pré-carregado. Seu home run será mostrado em breve';
_fc_props.cloud_dvir__assigned_mechanic = 'Mecânico designado';
_fc_props.cloud_dvir__add_mechanic = 'Adicionar um mecânico';
_fc_props.contact_type = 'Tipo de Contato';
_fc_props.cloud_dvir__send_email_notifications = 'Enviar notificações por e-mail';
_fc_props.menu_dvir_title_admin_dvir = 'DVR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Erro ao recuperar registros do histórico DVR.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Erro ao recuperar o registro DVR.';
_fc_props.cloud_dvir__error_saving_submission = 'Erro ao salvar o envio. Tente novamente.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Erro ao recuperar a lista de mecânicos.';
_fc_props.cloud_dvir__template_saved = 'Modelo salvo';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Erro ao recuperar lista de modelos.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Erro ao salvar o modelo. Tente novamente.';
_fc_props.dispatch_group = 'Grupo de Despacho';
_fc_props.save_adjust = 'Salvar e ajustar ponto de rota';
_fc_props.reset_route_point = 'Redefinir ponto de rota';
_fc_props.no_records_stub_adjust_filters = 'Tente ajustar suas configurações de pesquisa ou filtro para aumentar o número de resultados.';
_fc_props.no_dvir_records = 'Todos atualizados em DVRs!';
_fc_props.no_dvir_records_sub_title = 'Você conquistou muito';
_fc_props.settings.master_data_connex_x_api_key = 'Chave x-api do Master Data Connex';
_fc_props.slump_not_obtained_for_x = 'Nenhuma leitura de queda obtida para: {0}';
_fc_props.please_select_a_sensor_status_type = 'Selecione os tipos de status do sensor para acionar este alerta';
_fc_props.settings.master_data_connex_token = 'Token Master Data Connex';
_fc_props.error_validating_master_data_connex_credentials = 'Erro ao validar credenciais do Master Data Connex';
_fc_props.settings.master_data_connex_base_url = 'URL base do Master Data Connex';
_fc_props.settings.master_data_connex_exp_time = 'Prazo de expiração do Master Data Connex';
_fc_props.download_template = 'Baixar modelo';
_fc_props.menu_settings_title_dispatch_group = 'Grupos de Despacho';
_fc_props.settings.dispatch_groups = 'Grupos de Despacho';
_fc_props.dispatch_group_name = 'Nome do Grupo de Despacho';
_fc_props.dispatch_group_plants = 'Plantas do Grupo de Despacho';
_fc_props.dispatch_group_plants_mapping_message = 'Indicar quais plantas farão parte deste Grupo de Despacho.';
_fc_props.dispatch_group_users_mapping_message = 'Indique quais usuários farão parte deste Grupo de Despacho.';
_fc_props.dispatch_group_plant_disassociation_message = 'A planta deve estar sob um grupo de despacho por vez';
_fc_props.dispatch_group_search_by_plant = 'Pesquisar por nome da planta';
_fc_props.dispatch_group_search_by_username = 'Pesquisar por nome de usuário';
_fc_props.search_by_dispatch_group_name = 'Pesquisar por nome do grupo de despacho';
_fc_props.create_dispatch_group = 'Crie um grupo de envio';
_fc_props.update_dispatch_group = 'Atualizar grupo de despacho';
_fc_props.dispatch_group_saved = 'Grupo de despacho salvo';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'A exclusão deste grupo de despacho criará grupos de despacho padrão para as plantas associadas.';
_fc_props.error_saving_dispatch_group = 'Erro ao salvar o grupo de despacho. Tente novamente.';
_fc_props.please_enter_a_valid_plant_num = 'Insira um número de planta válido.';
_fc_props.cloud_dvir__report_no_defects = 'Sem defeitos';
_fc_props.shoud_select_dispatch_group = 'Você deve selecionar pelo menos um Grupo de Envio';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Não é possível excluir porque este Grupo de Envio contém usuários associados apenas a este Grupo de Envio.';
_fc_props.cloud_dvir__username = 'Nome de usuário';
_fc_props.cloud_dvir__password = 'Senha';
_fc_props.cloud_dvir__login = 'Conecte-se';
_fc_props.cloud_dvir__mechanic_sign_in = 'Login do mecânico';
_fc_props.delete_dispatch_group = 'Excluir grupo de envio';
_fc_props.cloud_dvir__driver = 'Motorista';
_fc_props.cloud_dvir__equipment_pound = 'Equipamento #';
_fc_props.cloud_dvir__mechanic = 'Mecânico';
_fc_props.dispatch_group_user_only_unassociate_message = 'Não é possível excluir o usuário porque os usuários devem estar associados a pelo menos um Grupo de Despacho.';
_fc_props.cloud_dvir__time_period_filter_today = 'Hoje';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Ontem';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Últimos 7 dias';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Últimos 30 dias';
_fc_props.cloud_dvir__date_range_filter = 'Filtrar por data';
_fc_props.cloud_dvir__download = 'Download';
_fc_props.dispatch_group_is_empty = 'O grupo de despacho não pode estar vazio.';
_fc_props.dispatch_group_with_empty_plants = 'Pelo menos um centro deve estar associado ao grupo de expedição.';
_fc_props.dispatch_group_with_empty_users = 'Pelo menos um usuário deve estar associado ao grupo de despacho.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Não é possível excluir o Grupo de Envio, pois ele contém usuários associados apenas a este Grupo de Envio.';
_fc_props.dispatch_group_not_exists = 'O grupo de despacho não existe.';
_fc_props.dispatch_group_contains_one_plant = 'Não é possível excluir porque este Grupo de Despacho contém apenas uma planta.';
_fc_props.dispatch_group_users = 'Despachar usuários do grupo';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Erro ao recuperar registro DVR';
_fc_props.cloud_dvir__assign_mechanic = 'Atribuir Mecânico';
_fc_props.cloud_dvir__submit_review = 'Enviar revisão';
_fc_props.error_deleting_dispatch_group = 'Erro ao excluir o grupo de despacho.';
_fc_props.error_retrieving_dispatch_groups = 'Erro ao recuperar a lista de grupos de despacho.';
_fc_props.cloud_dvir__undo_selection = 'Desfazer seleção';
_fc_props.enable_master_data = 'Habilitar dados mestre';
_fc_props.manually_sync = 'Sincronizar manualmente';
_fc_props.master_data_connex_token_expiration = 'Expiração do token Master Data Connex';
_fc_props.cloud_dvir__time_period_filter_custom = 'Personalizado';
_fc_props.settings.zello_session_timeout_minutes = 'Tempo limite da sessão Zello (minutos)';
_fc_props.menu_settings_title_master_data = 'Dados mestre';
_fc_props.settings.master_data = 'Dados mestre';
_fc_props.error_retrieving_master_data = 'Erro ao recuperar dados mestre';
_fc_props.master_data_sync_completed = 'Sincronização de dados mestre concluída';
_fc_props.master_data_successfully_updated = 'Dados atualizados com sucesso';
_fc_props.master_data_failed_to_update = 'Falha ao salvar.';
_fc_props.master_data_sync_initiated = 'Sincronização de dados mestre iniciada';
_fc_props.master_data_sync_failed = 'Falha na sincronização de dados mestre';
_fc_props.enabled_excluding_origin_on_deliveries = 'Verdadeiro (excluindo origem nos tickets de entrega)';
_fc_props.enabled_including_origin_on_deliveries = 'Verdadeiro (incluindo origem nos tickets de entrega)';
_fc_props.cloud_dvir__i_will_decide_later = 'Eu decidirei mais tarde';
_fc_props.returning = 'Retornando';
_fc_props.leftover_concrete = 'Sobras de concreto';
_fc_props.leftover_concrete_on_vehicle_x = 'Sobras de concreto no veículo {0}';
_fc_props.leftover_concrete_alert = 'Alerta de sobras de concreto';
_fc_props.wc_ratio_exceeded = 'Relação W/C excedida';
_fc_props.search_by_site_name = 'Pesquise por nome do site';
_fc_props.sites = 'Locais';
_fc_props.token_expiration = 'Expiração do token';
_fc_props.menu_settings_title_connex_api_key_management = 'Gerenciamento de chaves API Connex';
_fc_props.settings.connex_api_key_management = 'Gerenciamento de chaves API Connex';
_fc_props.error_retrieving_connex_api_key_data = 'Erro ao recuperar dados da chave da API Connex';
_fc_props.qr_code = 'Código QR';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informando o Motorista e a Interface ao entrar na Planta errada';
_fc_props.enable_driver_message = 'Ativar mensagem do motorista';
_fc_props.enable_dispatch_message = 'Ativar mensagem de envio';
_fc_props.enable_sending_message_to_interface = 'Habilitar envio de mensagem para Interface';
_fc_props.ticket_qr_code = 'Código QR do ingresso';
_fc_props.leftover_concrete_alert_description = 'Alerta quando sobras de concreto são encontradas após o vazamento.';
_fc_props.minimum_leftover_concrete_amount = 'Quantidade mínima de sobra de concreto';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Erro ao validar credenciais do Master Data Connex não autorizadas';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Erro ao validar permissões de credenciais do Master Data Connex';
_fc_props.error_validating_master_data_connex_url_not_found = 'Erro ao validar o URL do Master Data Connex não encontrado';
_fc_props.error_validating_master_data_connex_unknown_error = 'Erro ao validar o Master Data Connex Erro desconhecido';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Falha na sincronização dos dados mestre. Sincronize o tipo de veículo.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Falha na sincronização dos dados mestre. Sincronize o veículo.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Falha na sincronização dos dados mestre. Sincronização da planta.';
_fc_props.master_data_sync_failed_data_base_connection = 'A sincronização de dados mestre falhou na conexão com o banco de dados.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Erro ao validar a permissão do token do portador das credenciais do Master Data Connex é insuficiente';
_fc_props.settings.back_date_arrive_job = 'Data anterior de chegada do trabalho';
_fc_props.device_notifications = 'Notificações';
_fc_props.loading_number = 'Número de carregamento';
_fc_props.leftover_concrete_volume_temp_age = 'Volume restante={0}, Temp={1}, Idade={2}';
_fc_props.total_ordered = 'Quantidade total encomendada';
_fc_props.total_ticketed = 'Entregue encomendado';
_fc_props.load_number = 'Número de carga';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'As sobras mínimas de concreto devem ser preenchidas e maiores que {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Erro ao validar dados mestre. As credenciais não podem ser iguais.';
_fc_props.use_aws_for_talk = 'Usar AWS para Talk';
_fc_props.air_readings_not_supported = 'Leituras de ar não suportadas';
_fc_props.air_not_supported = 'Ar não suportado';
_fc_props.mix_not_supported = 'Mistura não suportada';
_fc_props.air_default_and_none_not_supported = 'Não é possível selecionar padrão e não compatível ao mesmo tempo!';
_fc_props.notifications_enabled = 'Notificações ativadas';
_fc_props.prevent_status_change = 'Impedir mudança de status';
_fc_props.message_to_interface = 'Mensagem para interface';
_fc_props.prevent_wrong_plant = 'Evite planta errada';
_fc_props.previous_tickets = 'Ingressos Anteriores';
_fc_props.audit_ticket_number = 'Número do bilhete';
_fc_props.audit_date_created = 'Data Criada';
_fc_props.audit_vehicle_number = 'Número do veículo';
_fc_props.cloud_dvir__table_ok = 'Seguro para operar';
_fc_props.cloud_dvir__table_needs_repair = 'Inseguro para operar';
_fc_props.missing_air_calibration = 'Sem dados de calibração de ar';
_fc_props.reported_defects = 'Defeitos relatados';
_fc_props.address_latitude = 'Endereço Latitude';
_fc_props.address_longitude = 'Longitude do endereço';
_fc_props.routing_latitude = 'Latitude de roteamento';
_fc_props.routing_longitude = 'Longitude de rota';
_fc_props.category_name_must_be_unique = 'O nome da categoria deve ser exclusivo';
_fc_props.subcategory_name_must_be_unique = 'O nome da subcategoria deve ser exclusivo';
_fc_props.menu_settings_tracking_device = 'Aparelho de rastreamento';
_fc_props.menu_settings_title_tracking_device = 'Aparelho de rastreamento';
_fc_props.menu_settings_title_personal_device_registration = 'Registro de dispositivo pessoal';
_fc_props.registration_code = 'Código de Registo';
_fc_props.creation_date = 'Criação';
_fc_props.expiration_date = 'Expiração';
_fc_props.trackitadmin_personal_device_registration = 'Registro de dispositivo pessoal';
_fc_props.create_registration_code = 'Adicionar código de registro';
_fc_props.remove_registration_code = 'Remover código de registro';
_fc_props.add_a_registration_code = 'Adicione um código de registro';
_fc_props.select_number_of_registration_codes_to_generate = 'Selecione o número de códigos de registro que você gostaria de gerar.';
_fc_props.registration_code_count = 'Contagem de códigos de registro';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Tem certeza de que deseja excluir o código registrado selecionado?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Tem certeza de que deseja excluir os códigos cadastrados selecionados?';
_fc_props.add_personal_device = 'Adicionar dispositivo pessoal';
_fc_props.menu_settings_title_personal_device = 'Dispositivo pessoal';
_fc_props.cloud_dvir__approval_critical_alert = 'O veículo apresenta um defeito crítico. Não pode ser marcado como Seguro para Operação.';
_fc_props.export_registration_codes = 'Exportar códigos de registro';
_fc_props.download_format = 'Baixar formato';
_fc_props.specify_download_format_x = 'Especifique o formato de download para exportar {0} códigos de registro';
_fc_props.personal_device_download = 'Download';
_fc_props.add_personal_device_input_message = 'Insira um ID de dispositivo pessoal abaixo. A expiração será aplicada automaticamente após salvar';
_fc_props.error_creating_personal_device = 'Erro ao criar um dispositivo pessoal';
_fc_props.device_already_exists = 'O dispositivo pessoal já existe';
_fc_props.export = 'Exportar';
_fc_props.error_can_not_change_connex_eid = 'Não é possível alterar o connex eid, pois ele está sendo usado atualmente para dados mestre';
_fc_props.remove_personal_device = 'Remover dispositivo pessoal';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Tem certeza de que deseja excluir o dispositivo pessoal selecionado?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Tem certeza de que deseja excluir os dispositivos pessoais selecionados?';
_fc_props.error_deleting_personal_device = 'Erro ao excluir dispositivo pessoal';
_fc_props.customerid = 'Identificação do Cliente';
_fc_props.customername = 'nome do cliente';
_fc_props.ordernumber = 'Número do pedido';
_fc_props.orderdescription = 'Descrição do pedido';
_fc_props.locationid = 'ID do local';
_fc_props.locationdescription = 'Descrição do local';
_fc_props.productid = 'ID do produto';
_fc_props.productdescription = 'Descrição do produto';
_fc_props.vehicleid = 'ID do veículo';
_fc_props.vehicledescription = 'Descrição do veículo';
_fc_props.ticketnumber = 'Bilhete #';
_fc_props.ticketdate = 'Data/Hora do Bilhete';
_fc_props.drivername = 'Nome do motorista';
_fc_props.arriveregion = 'Região de chegada (TEMPO)';
_fc_props.leaveregion = 'Sair da região (TIME)';
_fc_props.regionduration = 'Duração da região';
_fc_props.menu_reports_title_prevailing_wage = 'Prevalecente salário';
_fc_props.slump_calibration_x_not_found_for_y = 'Calibração de queda "{0}" não encontrada para ticket {1}';
_fc_props.slump_calibration_not_found = 'Calibração de queda não encontrada';
_fc_props.slump_calibration_not_found_description = 'Alerta quando a calibração de queda não for encontrada';
_fc_props.error_getting_personal_device_list = 'Erro ao obter lista de dispositivos pessoais';
_fc_props.batch_summary_date_range_note = '*NOTA: Este relatório é limitado a 3 dias se nenhum filtro for aplicado. O intervalo de datas pode ir até 31 dias quando filtrado por Equipamento (10 no máximo), Planta (1 no máximo), Produto (1 no máximo) ou Trabalho (1 no máximo)';
_fc_props.select_registration_code_expiration_time_days = 'Selecione o número de dias até que os códigos de registro expirem e clique em Salvar.';
_fc_props.add_personal_device_expiration = 'Expiração (em dias)';
_fc_props.cloud_dvir__vehicle_no = 'Número do veículo';
_fc_props.cloud_dvir__trailer_no = 'Trailer Não';
_fc_props.cloud_dvir__vehicle = 'Veículo';
_fc_props.cloud_dvir__trailer = 'Reboque';
_fc_props.cloud_dvir__truck_number_label = 'Número do caminhão';
_fc_props.cloud_dvir__trailer_number = 'Número do trailer';
_fc_props.cloud_dvir__has_trailer = 'Não dirigir com reboque';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Insira o número do seu trailer aqui';
_fc_props.work_order = 'Ordem de serviço';
_fc_props.unable_to_determine_slump = 'Não é possível determinar a queda';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Determinar a condição geral do trailer';
_fc_props.defaultTrailer = 'Trailer Padrão';
_fc_props.trailer_type_column_billable = 'Faturável';
_fc_props.trailer_type_column_notes = 'Notas';
_fc_props.trailer_type_column_date = 'Data';
_fc_props.trailer_type_column_order = 'Ordem';
_fc_props.trailer_type_column_ticket = 'Bilhete';
_fc_props.trailer_type_column_project = 'Projeto';
_fc_props.trailer_type_column_customer = 'Cliente';
_fc_props.trailer_type_column_location = 'Localização';
_fc_props.trailer_type_column_vehicle_type = 'Tipo de veículo';
_fc_props.trailer_type_column_product = 'Produto';
_fc_props.trailer_type_action_rebill = 'Refaturamento';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Falha na sincronização dos dados mestres. Nenhum tipo de equipamento padrão foi definido.';
_fc_props.trailer_type = 'Tipo de reboque';
_fc_props.trailer_type_is_required = 'O tipo de reboque é obrigatório';
_fc_props.trailer_number_already_exists = 'O número do trailer já existe';
_fc_props.plate_number_already_exists = 'O número da placa já existe';
_fc_props.trailer_vin_already_exists = 'Trailer Vin já existe';
_fc_props.trailer_does_not_exist = 'Trailer não existe';
_fc_props.trailer_number_is_required = 'Número do trailer é obrigatório';
_fc_props.plate_number_is_required = 'O número da placa é obrigatório';
_fc_props.plate_number = 'Número da placa';
_fc_props.menu_settings_title_trailer = 'Reboque';
_fc_props.add_trailer = 'Adicionar Trailer';
_fc_props.edit_trailer = 'Editar Trailer';
_fc_props.menu_settings_title_equipment = 'Equipamento';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorar novos tickets do Command Cloud com mais de X minutos';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignorar novos tickets do Command Cloud com data e hora de despacho anteriores a X minutos (o padrão é vazio e desativado)';
_fc_props.edit_load_card = 'Editar Cartão de Carga';
_fc_props.default_layout = 'Layout padrão';
_fc_props.load_card_empty_fields = 'Erro ao salvar o cartão de carga: um ou mais campos devem ser selecionados.';
_fc_props.trailers = 'Reboques';
_fc_props.error_failed_to_save_data = 'Erro: Falha ao salvar dados';
_fc_props.slump_increased_plasticizer_added_question = 'Quanto plastificante foi adicionado?';
_fc_props.add_trailer_type = 'Adicionar tipo de trailer';
_fc_props.menu_settings_title_trailer_type = 'Tipo de reboque';
_fc_props.edit_trailer_type = 'Editar Tipo de Trailer';
_fc_props.single_use_codes = 'Códigos de uso único';
_fc_props.multiple_use_codes = 'Códigos de uso múltiplo';
_fc_props.trailer_type_description_is_required = 'Descrição do tipo de reboque é obrigatória';
_fc_props.trailer_saved = 'Trailer salvo';
_fc_props.cloud_dvir__trailer_only_dvir = 'Somente Trailer DVIR';
_fc_props.choose_file = 'Escolha o arquivo';
_fc_props.no_file_chosen = 'Nenhum arquivo escolhido';
_fc_props.please_choose_file = 'Por favor, escolha o arquivo';
_fc_props.no_filters = 'Nenhum filtro selecionado';
_fc_props.remaining_defects = 'Defeitos restantes';
_fc_props.load_more = 'Carregar mais';
_fc_props.pagination_label = '{0}-{1} de {2}';
_fc_props.extend = 'Estender';
_fc_props.cloud_dvir__notes_or_photo_required = 'São necessárias notas ou uma foto.';

export default _fc_props;
