const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Riprova';
_fc_props.default = 'Predefinito';
_fc_props.origin = 'Origine';
_fc_props.loading = 'Caricamento in corso';
_fc_props.list = 'Elenca';
_fc_props.send = 'Invia';
_fc_props.error = 'Errore';
_fc_props.save = 'Salva';
_fc_props.delete = 'Cancella';
_fc_props.message = 'Messaggio';
_fc_props.logout = 'Chiudi sessione';
_fc_props.add = 'Aggiungi';
_fc_props.remove = 'Elimina';
_fc_props.done = 'Fatto';
_fc_props.other = 'Altro';
_fc_props.interval = 'Intervallo';
_fc_props.time = 'Tempo';
_fc_props.speed = 'Velocità';
_fc_props.longitude = 'Longitudine';
_fc_props.latitude = 'Latitudine';
_fc_props.x_minutes = '{0} min';
_fc_props.general = 'Generale';
_fc_props.messages = 'Messaggi';
_fc_props.gps_fixes = 'Segnali GPS';
_fc_props.device_id = 'ID dispositivo';
_fc_props.vehicle = 'Veicolo';
_fc_props.employee = 'Dipendente';
_fc_props.version = 'Versione';
_fc_props.statuses = 'Stati';
_fc_props.edit = 'Modifica';
_fc_props.cancel = 'Annulla';
_fc_props.yes = 'Sì';
_fc_props.none = 'Nessuno';
_fc_props.never = 'Mai';
_fc_props.name = 'Nome';
_fc_props.true = 'Vero';
_fc_props.status = 'Stato';
_fc_props.distance = 'Distanza';
_fc_props.ok = 'OK';
_fc_props.login = 'Accesso';
_fc_props.next = 'Seguente';
_fc_props.menu = 'Menu';
_fc_props.not_available = 'N/A';
_fc_props.back = 'Indietro';
_fc_props.select = 'Seleziona';
_fc_props.dispatch = 'Spedizione';
_fc_props.messaging = 'Invio messaggi';
_fc_props.plants = 'Stabilimenti';
_fc_props.last_plants = 'Ultimi stabilimenti';
_fc_props.vehicle_types = 'Tipi di veicoli';
_fc_props.employee_types = 'Tipi di dipendenti';
_fc_props.employee_groups = 'Gruppi di dipendenti';
_fc_props.equipment_groups = 'Gruppi di attrezzature';
_fc_props.job = 'Incarico';
_fc_props.order = 'Ordine';
_fc_props.ticket = 'Ticket #';
_fc_props.small = 'Piccolo';
_fc_props.medium = 'Medio';
_fc_props.large = 'Grande';
_fc_props.reports = 'Rapporti';
_fc_props.payroll = 'Paghe';
_fc_props.support = 'Assistenza';
_fc_props.username = 'Nome utente';
_fc_props.all = 'Tutti';
_fc_props.question = 'Domanda';
_fc_props.select_employee = 'Seleziona dipendente';
_fc_props.phone = 'Telefono';
_fc_props.january = 'Gennaio';
_fc_props.february = 'Febbraio';
_fc_props.march = 'Marzo';
_fc_props.april = 'Aprile';
_fc_props.may = 'Maggio';
_fc_props.june = 'Giugno';
_fc_props.july = 'Luglio';
_fc_props.august = 'Agosto';
_fc_props.september = 'Settembre';
_fc_props.october = 'Ottobre';
_fc_props.november = 'Novembre';
_fc_props.december = 'Dicembre';
_fc_props.are_you_sure_you_want_to_delete = 'Confermi di voler eliminare';
_fc_props.unknown = 'Sconosciuto';
_fc_props.select_all = 'Seleziona tutti';
_fc_props.equipment = 'Attrezzatura';
_fc_props.region = 'Zona';
_fc_props.no_data = 'Non ci sono dati';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Indirizzo';
_fc_props.retrieving_address = 'Ricerca indirizzo...';
_fc_props.address_not_found = 'Indirizzo non trovato';
_fc_props.active_time = 'Tempo attivo';
_fc_props.destination_time = 'Tempo a destinazione';
_fc_props.complete_time = 'Tempo completamento';
_fc_props.status_report = 'Rapporto di stato';
_fc_props.export_report = 'Esporta rapporto';
_fc_props.download_report = 'Scarica rapporto';
_fc_props.view_report = 'Visualizza rapporto';
_fc_props.duration = 'Durata';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Data/Ora di inizio';
_fc_props.select_start_date = 'Seleziona data di inizio';
_fc_props.end_date_time = 'Data/Ora di fine';
_fc_props.select_end_date = 'Seleziona data di fine';
_fc_props.trip_time = 'Termpo viaggio';
_fc_props.travel_time = 'Tempo di percorrenza';
_fc_props.description = 'Descrizione';
_fc_props.map_controls = 'Controlli mappa';
_fc_props.road = 'Strada';
_fc_props.aerial = 'Aereo';
_fc_props.hybrid = 'Ibrido';
_fc_props.trip = 'Viaggio';
_fc_props.map = 'Mappa';
_fc_props.plant = 'Stabilimento';
_fc_props.select_date = 'Seleziona data';
_fc_props.apply = 'Applica';
_fc_props.save_settings = 'Salva impostazioni';
_fc_props.false = 'Falso';
_fc_props.delete_confirmation = 'Cancella configurazione';
_fc_props.last_name = 'Cognome';
_fc_props.none_selected = 'Nessuno selezionato';
_fc_props.start_date = 'Data di inizio';
_fc_props.group_by = 'Raggruppa per';
_fc_props.equipment_employee = 'Attrezzatura/Dipendente';
_fc_props.inactive = 'Inattivo';
_fc_props.active = 'Attivo';
_fc_props.note_checking_may_affect_performance = 'Nota: Il controllo può influenzare le prestazioni';
_fc_props.show_distancefuel_used = 'Mostra distanza/carburante usato';
_fc_props.mins = 'Min';
_fc_props.threshold_time = 'Orario soglia';
_fc_props.include_voided_tickets = 'Includi ticket annullati';
_fc_props.note_all_times_listed_are_in_minutes = 'Nota: Tutti gli orari elencati sono in minuti';
_fc_props.please_select_a_job_order_or_equipment = 'Seleziona un lavoro, un ordine, un impianto, un\'attrezzatura o un dipendente';
_fc_props.device = 'Dispositivo';
_fc_props.select_equipment = 'Seleziona attrezzatura';
_fc_props.all_equipment_types = 'Tutti i tipi di attrezzatura';
_fc_props.units = {};
_fc_props.units.liters = 'Litri';
_fc_props.type = 'Tipo';
_fc_props.no_data_available = 'Nessun dato disponibile';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Distanza e carburante per zona';
_fc_props.menu_settings_title_settings = 'Impostazioni';
_fc_props.menu_settings_title_alerts = 'Avvisi';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Attrezzatura';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspot e Zone';
_fc_props.days = '{0} giorno/i';
_fc_props.home = 'Home';
_fc_props.find = 'Trova';
_fc_props.close_this_panel = 'chiudi questo pannello';
_fc_props.filters = 'Filtri';
_fc_props.start = 'Inizio';
_fc_props.end = 'Fine';
_fc_props.location_details = 'Dettagli posizione';
_fc_props.first_name = 'Nome';
_fc_props.material_per_hour = 'Materiale all’ora';
_fc_props.equipment_pound = 'N. attrezzatura';
_fc_props.equipment_type = 'Tipo attrezzatura';
_fc_props.options = 'Opzioni';
_fc_props.zoom_on_cursor_position = 'Zooma su posizione cursore';
_fc_props.display_equipment_identifier = 'Identificatore attrezzatura visualizzazione';
_fc_props.view_equipment = 'Visualizza attrezzatura';
_fc_props.view_hotspots = 'Visualizza hotspot';
_fc_props.view_regions = 'Visualizza regioni';
_fc_props.use_standard_filters = 'Usa filtri standard';
_fc_props.home_plant = 'Stabilimento di origine';
_fc_props.last_plant = 'Ultimo stabilimento';
_fc_props.equip_types = 'Tipi attrez.';
_fc_props.equip_groups = 'Gruppi attrez.';
_fc_props.orders = 'Ordini';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Usa filtro attrezzatura';
_fc_props.all_selected = 'Tutti i selezionati';
_fc_props.of = 'di';
_fc_props.your_map_session_has_expired = 'La sessione della mappa è scaduta, aggiornare la pagina.';
_fc_props.equipment_short = 'Attrez.';
_fc_props.job_info_for = 'Info incarico per';
_fc_props.order_info_for = 'Info ordine per';
_fc_props.ticket_info_for_vehicle = 'Info ticket per veicolo';
_fc_props.inbox = 'Posta in arrivo';
_fc_props.outbox = 'Posta in uscita';
_fc_props.alerts = 'Avvisi';
_fc_props.critical = 'Critico';
_fc_props.important = 'Importante';
_fc_props.informational = 'Informazioni';
_fc_props.view_routes = 'Visualizza percorsi';
_fc_props.hours_size = 'Dimensione ore';
_fc_props.used_count = 'Conteggio usato';
_fc_props.last_login = 'Ultimo accesso';
_fc_props.last_vehicle = 'Ultimo veicolo';
_fc_props.regions = 'Zone';
_fc_props.driver = 'Autista';
_fc_props.js_delete = 'Elimina';
_fc_props.move = 'Sposta';
_fc_props.edit_hotspot = 'Modifica hotspot';
_fc_props.adjust_shape = 'Correggi forma';
_fc_props.edit_region = 'Modifica regione';
_fc_props.enter_a_start_address = 'Inserisci un indirizzo iniziale';
_fc_props.select_a_start_hotspot = 'Inserisci un hotspot iniziale';
_fc_props.failed_to_find_start_hotspot = 'Impossibile trovare hotspot iniziale.';
_fc_props.invalid_latitudelongitude = 'Latitudine/longitudine non valida';
_fc_props.enter_an_end_address = 'Inserisci un indirizzo finale';
_fc_props.select_an_end_hotspot = 'Seleziona un hotspot finale';
_fc_props.failed_to_find_end_hotspot = 'Impossibile trovare hotspot finale.';
_fc_props.print = 'Stampa';
_fc_props.these_directions_are_for_informational_purposes = 'Queste indicazioni hanno solo uno scopo informativo. Non ci sono garanzie della loro completezza o precisione. Lavori in corso, traffico o altri eventi possono provocare differenze tra la situazione reale e questi risultati.';
_fc_props.enter_an_address = 'Inserisci un indirizzo';
_fc_props.select_a_hotspot = 'Seleziona un hotspot';
_fc_props.failed_to_find_hotspot = 'Impossibile trovare hotspot.';
_fc_props.select_a_vehicle = 'Seleziona un veicolo';
_fc_props.failed_to_find_vehicle = 'Impossibile trovare veicolo.';
_fc_props.failed_to_find_address = 'Impossibile trovare indirizzo';
_fc_props.failed_to_find_vehicles = 'Impossibile trovare veicoli nei criteri di ricerca.';
_fc_props.get_address = 'Ottieni indirizzo';
_fc_props.results_for = 'Risultati per';
_fc_props.error_retrieving_results = 'Errore nel recupero dei risultati';
_fc_props.no_results_found = 'Nessun risultato trovato';
_fc_props.address_is_required = 'L\'indirizzo è obbligatorio';
_fc_props.city_is_required = 'La città è obbligatoria';
_fc_props.set_location = 'Imposta posizione';
_fc_props.set_area_of_interest = 'Imposta area di interesse';
_fc_props.center_map_here = 'Centra la mappa qui';
_fc_props.zoom_to_street_level = 'Ingrandisci a livello stradale';
_fc_props.set_start = 'Imposta inizio';
_fc_props.set_end = 'Imposta fine';
_fc_props.delete_node = 'Elimina nodo';
_fc_props.create_a_hotspot = 'Crea un hotspot';
_fc_props.create_a_region = 'Crea una regione';
_fc_props.toggle_shapes = 'Seleziona/deseleziona forme';
_fc_props.refresh_map = 'Aggiorna mappa';
_fc_props.more_options = 'Altre opzioni';
_fc_props.recenter_map = 'Ricentra la mappa';
_fc_props.zoom_to_vehicle = 'Zooma sul veicolo';
_fc_props.apply_filters = 'Applica filtri';
_fc_props.grid_view = 'Visualizzazione griglia';
_fc_props.list_view = 'Visualizzazione elenco';
_fc_props.click_to_sort = 'Clicca per ordinare';
_fc_props.message_history = 'Cronologia messaggi';
_fc_props.equipment_types = 'Tipi di attrezzatura';
_fc_props.send_message = 'Invia messaggio';
_fc_props.last_load = 'Ultimo carico';
_fc_props.last_load_on = 'Ultimo carico il';
_fc_props.manual_logout = 'Chiusura sessione manuale';
_fc_props.manual_login = 'Accesso manuale';
_fc_props.no_employees_logged_in = 'Nessun dipendente collegato';
_fc_props.select_phone = 'Seleziona telefono';
_fc_props.you_must_select_a_phone = 'Devi selezionare un telefono, un’attrezzatura e un dipendente';
_fc_props.are_you_sure_you_want_to_logout = 'Confermi di voler chiudere la sessione';
_fc_props.fix_time = 'Orario del fix';
_fc_props.to_next_fix = 'al prossimo fix';
_fc_props.minutes_to_next_fix = 'minuti al prossimo fix';
_fc_props.liters = 'Litri';
_fc_props.destination = 'Destinazione';
_fc_props.return = 'Ritorno';
_fc_props.product = 'Prodotto';
_fc_props.no_records_found = 'Nessun dato trovato.';
_fc_props.vehicle_type = 'Tipo veicolo';
_fc_props.view_in_map = 'Vedi tutti sulla mappa';
_fc_props.uploads = 'Caricamenti';
_fc_props.upload_csv_file = 'Carica file CSV';
_fc_props.permanent = 'Permanente';
_fc_props.expire_time = 'Ora scadenza';
_fc_props.please_select_x_for_tickets_marked_active = 'Seleziona {0} per i ticket contrassegnati come ATTIVI';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Ticket multipli contrassegnati come ATTIVI per stesso {0}';
_fc_props.failure_connecting_to_servlet = 'Errore nella connessione a servlet.';
_fc_props.status_time_summary = 'Riepilogo orario stato';
_fc_props.view_in_map_one = 'Visualizza sulla mappa';
_fc_props.question_report = 'Rapporto domande';
_fc_props.end_date = 'Data fine';
_fc_props.trailer_number = 'Numero rimorchio';
_fc_props.region_panel = 'Panello regione';
_fc_props.hotspot_panel = 'Panello hotspot';
_fc_props.shape = 'Forma';
_fc_props.circle = 'Cerchio';
_fc_props.polygon = 'Poligono';
_fc_props.click_the_map_to_set_the_location = 'Clicca sulla mappa per impostare la località  desiderata.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Clicca sulla mappa per impostare il centro dell\'hotspot.';
_fc_props.click_the_map_to_set_the_region_center = 'Clicca sulla mappa per impostare il centro della regione.';
_fc_props.click_and_drag_points = 'Clicca e trascina i punti per modificare la forma. Clicca SALVA per salvare le modifiche.';
_fc_props.size = 'Dimensione';
_fc_props.are_you_sure_you_want_to_move_this = 'Confermi di voler spostare questo';
_fc_props.are_you_sure_you_want_to_delete_this = 'Confermi di voler eliminare questo';
_fc_props.please_enter_a_valid_size = 'Si prega di inserire una dimensione valida';
_fc_props.failed_to_save = 'Impossibile salvare.';
_fc_props.failed_to_move = 'Impossibile spostare.';
_fc_props.failed_to_delete = 'Eliminazione non riuscita.';
_fc_props.x_hours = '{0} ore';
_fc_props.hide_hotspots = 'Nascondi hotspot';
_fc_props.show_regions = 'Mostra regioni';
_fc_props.show_hotspots = 'Mostra hotspot';
_fc_props.hide_regions = 'Nascondi regioni';
_fc_props.numeric = 'Numerico';
_fc_props.message_type = 'Tipo messaggio';
_fc_props.text = 'Testo';
_fc_props.yes_no = 'Sì/No';
_fc_props.responses = 'Risposte';
_fc_props.disabled = 'Disabilitato';
_fc_props.group_name = 'Nome gruppo';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Confermi di voler eliminare il formato "{0}"?';
_fc_props.phone_number = 'Numero di telefono';
_fc_props.interface_pound = 'N. interfaccia';
_fc_props.field = 'Campo';
_fc_props.default_label = 'Etichetta predefinita';
_fc_props.custom_label = 'Etichetta personalizzata';
_fc_props.you_must_enter_a_label = 'Devi inserire un’etichetta.';
_fc_props.enabled = 'Abilitato';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Integrazione ticket';
_fc_props.settings.geofence_off_open_hotspots = 'Disattiva geofence hotspot aperti';
_fc_props.settings.associate_open_hotspots_wticket = 'Associa hotspot aperti con ticket';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Disattiva geofence hotspot aperti o solo degli hotspot associati con il ticket autista';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Se associare o meno gli hotspot aperti con i ticket quando un veicolo vi entra';
_fc_props.settings.void_ticket_without_destination = 'Annulla ticket privi di destinazione';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'se si riceve un ticket mentre c’è un altro attivo, se il ticket attivo non ha una destinazione, il ticket verrà annullato.';
_fc_props.settings.autocreate_hotspots = 'Crea hotspot automaticamente';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Se creare automaticamente o meno gli hotspot dall’indirizzo di destinazione quando si riceve un ticket';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Attiva automaticamente ticket alla ricezione';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Se il ticket è attivo quando TrackIt lo riceve (Predefinito \"vero\")';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Chiudi automaticamente ticket attivo dopo il ricevimento (minuti)';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Da quanto deve essere attivo il ticket attuale prima che venga chiuso automaticamente (Predefinito \"\")';
_fc_props.settings.ticket_max_activate_age_minutes = 'Minuti max tempo attivazione ticket';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Dopo quanti minuti al massimo un ticket può essere attivato in TrackIt (Predefinito \"\")';
_fc_props.settings.max_hotspot_unused_days = 'Giorni max inutilizzo hotspot';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Imposta i giorni massimi da cui un hotspot inutilizzato può ancora essere riattivato';
_fc_props.settings.radius_multiplier = 'Moltiplicatore raggio';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Il fattore per cui moltiplicare il raggio quando si riceve un ticket dal sistema di invio';
_fc_props.settings.ticket_message_date_format = 'Formato data messaggio ticket';
_fc_props.am = 'am';
_fc_props.pm = 'pm';
_fc_props.settings.ticket_message = 'Messaggio ticket';
_fc_props.settings.add_ticket_element = 'Aggiungi elemento ticket';
_fc_props.driver_performance = 'Performance autista';
_fc_props.update_failed_can_not_connect = 'Aggiornamento non riuscito: Impossibile connettersi a servlet.';
_fc_props.unknown_error = 'Errore sconosciuto';
_fc_props.selected = 'Selezionato';
_fc_props.web_service = 'Servizio web';
_fc_props.error_processing_request = 'Errore nell’elaborazione della richiesta.';
_fc_props.email_address = 'Indirizzo email';
_fc_props.id = 'ID';
_fc_props.last_used = 'Ultimo usato';
_fc_props.error_invalid_vehiclenum = 'Errore Numero di veicolo non valido';
_fc_props.error_vehicle_is_not_logged_in = 'Errore: Il veicolo non è connesso.';
_fc_props.users = 'Utenti';
_fc_props.you_dont_have_access_to_this_page = 'Non hai accesso per visualizzare questa pagina. Si prega di contattare l\'amministratore di sistema per l\'accesso.';
_fc_props.previous_week = 'Settimana precedente';
_fc_props.status_breakdown = 'Interruzione stato';
_fc_props.select_plant_or_employee_group = 'Devi selezionare uno stabilimento o un gruppo di dipendenti.';
_fc_props.cannot_select_plant_and_employee_group = 'Non puoi selezionare sia uno stabilimento che un gruppo di dipendenti. [[[]]]';
_fc_props.odometer_must_be_a_number = 'Il contachilometri iniziale deve essere un numero';
_fc_props.ending_odometer_must_be_a_number = 'Il contachilometri finale deve essere un numero';
_fc_props.beginning_hour_meter_must_be_a_number = 'Il misuratore ore iniziale deve essere un numero';
_fc_props.ending_hour_meter_must_be_a_number = 'Il misuratore ore finale deve essere un numero';
_fc_props.error_loading_status_change_page = 'Errore nel caricamento della pagina di modifica di stato.';
_fc_props.x_is_an_invalid_time_format = '{0} è un formato orario non valido, deve essere AAAA-MM-GG HH:MM:SS';
_fc_props.x_for_schedule_login = '{0} per accesso programma';
_fc_props.x_for_schedule_logout = '{0} per uscita programma';
_fc_props.x_for_actual_login = '{0} per accesso effettivo';
_fc_props.x_for_actual_logout = '{0} per uscita effettiva';
_fc_props.by_refreshing_the_time_card_detail = 'Aggiornando i dati del cartellino orario perderai tutte le modifiche, continuare? [[[]]]';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Confermi di voler cancellare {0}?';
_fc_props.settings.command_mobile_ticket_url = 'URL per Mobile Ticket Command';
_fc_props.year = 'Anno';
_fc_props.make = 'Marca';
_fc_props.model = 'Modello';
_fc_props.settings.command_mobile_ticket_password = 'Password Comando mobile Ticket';
_fc_props.settings.command_mobile_ticket_auth_token = 'Command mobile Ticket Auth Token';
_fc_props.target = 'Bersaglio';
_fc_props.number_of_digits = 'Numero di cifre';
_fc_props.number_of_integer_digits = 'Numero di Integer cifre';
_fc_props.number_of_fractional_digits = 'Numero di cifre frazionarie';
_fc_props.search = 'Ricerca';
_fc_props.settings.command_mobile_ticket_date_order = 'Command mobile Ticket Data Ordine';
_fc_props.settings.command_mobile_ticket_date_length = 'Command mobile Ticket Data Length';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command mobile Ticket Data Separator';
_fc_props.settings.command_mobile_ticket_slash = '/ (Barra)';
_fc_props.settings.command_mobile_ticket_dash = '- (Trattino)';
_fc_props.settings.command_mobile_ticket_period = '. (Punto)';
_fc_props.settings.command_mobile_ticket_space = '(Spazio)';
_fc_props.settings.command_mobile_ticket_time_length = 'Command mobile Ticket Tempo Lunghezza';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command mobile Ticket Tempo Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (Due punti)';
_fc_props.settings.command_mobile_ticket_time_format = 'Command mobile Ticket Formato ora';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Usa numero Interface';
_fc_props.mechanic = 'Meccanico';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Intervallo di polling (secondi)';
_fc_props.driving_time = 'Driving Tempo';
_fc_props.find_near_plants = 'Trova Vicino Piante';
_fc_props.please_create_hot_spot_first = 'Si prega di creare hotspot primo';
_fc_props.please_enter_hot_spot_description = 'Inserisci descrizione hot spot';
_fc_props.please_select_a_hot_spot_type = 'Si prega di selezionare un tipo di hot spot';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Invia driver email';
_fc_props.directions_not_found = 'Non valido {0} Indirizzo. Indicazioni Not Found.';
_fc_props.ws_user = 'Utente';
_fc_props.ws_service = 'Servizio';
_fc_props.ws_method = 'Metodo';
_fc_props.ws_params = 'Parametri';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Origine e destinazione';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Aiuto';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Utilizzare Alt Numero dei dipendenti';
_fc_props.settings.command_mobile_ticket_android_url = 'URL Android mobile biglietteria';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android mobile biglietteria Segreto';
_fc_props.employee_x_has_no_logout_on_y = 'Employee {0} non ha disconnessione per un cambiamento a partire dal {1}.';
_fc_props.phone_number_has_duplicates = 'I numeri di telefono sono le voci duplicate';
_fc_props.ounce = 'Oncia';
_fc_props.water_extra = 'acqua Extra';
_fc_props.always = 'Sempre';
_fc_props.work_types_must_be_unique = 'Tipi di lavoro devono essere univoci';
_fc_props.manufacturer = 'fabbricante';
_fc_props.need_to_enter_seconds = 'È necessario immettere secondi.';
_fc_props.copy = 'copia';
_fc_props.create_in_existing_required = 'La creazione in esistente deve essere selezionata per creare hotspot in roaming.';
_fc_props.break = 'Rompere';
_fc_props.add_export_data_type = 'Aggiungi esporta il tipo di dati';
_fc_props.edit_export_data_type = 'Modifica dell\'esportazione del tipo di dati';
_fc_props.delete_export_data_type = 'Elimina il tipo di dati di esportazione';
_fc_props.export_data_type = 'Esporta il tipo di dati';
_fc_props.error_updating_export_data_type = 'Errore durante l\'aggiornamento del tipo di dati di esportazione';
_fc_props.settings.address_key = 'Indirizzo chiave';
_fc_props.settings.address_key_hover = 'Opzioni chiave di indirizzo';
_fc_props.settings.address_key_options = 'Opzioni chiave di indirizzo';
_fc_props.more_filter_options = 'Altre opzioni di filtro';
_fc_props.unauthorized_mapit_app = 'Questa versione di MapIt non è autorizzata, aggiornare MapIt il più presto possibile.';
_fc_props.zello_timeout = 'Si è verificato un timeout contattando Zello.';
_fc_props.user_agreement_text = 'Effettuando il login, accetti il';
_fc_props.user_agreement = 'Accordo per gli utenti';
_fc_props.upload = 'Caricare';
_fc_props.x_mins_left = '{0} minuto / i rimasti';
_fc_props.continuous_location_tracking_colon = 'Monitoraggio della posizione continua:';
_fc_props.hired_hauler_cannot_register_device = 'Il trasportatore noleggiato non può registrare un nuovo dispositivo.';
_fc_props.timecard_does_not_contain_shift = 'Employee Time Card non contiene lo shift (Time Card Info) fornito';
_fc_props.timecard_not_found = 'Employee Time Card non trovato';
_fc_props.vehicle_must_be_assigned_for_clt = 'Il veicolo deve essere assegnato per tracciare le posizioni.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Il veicolo non è stato assegnato, il tracciamento continuo verrà disabilitato.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Connessione persa con EDC. Si prega di verificare il WiFi e / o il Bluetooth sono abilitati.';
_fc_props.acknowledge_all = 'Riconosci tutto';
_fc_props.expand = 'Espandere';
_fc_props.collapse = 'Crollo';
_fc_props.expand_all = 'Espandi tutto';
_fc_props.collapse_all = 'Comprimi tutto';
_fc_props.last_update = 'Ultimo aggiornamento';
_fc_props.acknowledge_alert = 'Avviso di conferma';
_fc_props.acknowledge_message = 'Conferma messaggio';
_fc_props.acknowledge_all_alerts = 'Riconosca tutti gli avvisi';
_fc_props.acknowledge_all_messages = 'Riconosci tutti i messaggi';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'La tolleranza deve essere un numero intero valido tra -99 e 99 (entrambi inclusi)';
_fc_props.gps_permission_required = 'Autorizzazione GPS richiesta';
_fc_props.permissions_required = 'Autorizzazioni richieste';
_fc_props.change_user = 'Cambia utente';
_fc_props.account_not_registered_with_carrier = 'Questo account non è registrato con un corriere';
_fc_props.slump_loss = 'Perdita di crisi';
_fc_props.slump_loss_help = 'Aiuto per la perdita di perdite';
_fc_props.did_not_receive_any_edc_devices = 'Non ho ricevuto alcun dispositivo EDC.';
_fc_props.edc_record_not_found = 'Record EDC non è stato trovato';
_fc_props.disable_hotspot_prompt = 'Tocca "OK" quando ti viene richiesto di disattivare il tuo hotspot.';
_fc_props.edc_device_is_in_use = 'EDC {0} è in uso.';
_fc_props.an_unknown_exception_has_occurred = 'Si è verificata un\'eccezione sconosciuta';
_fc_props.login_has_timed_out = 'Il login è scaduto';
_fc_props.use_paper_logs = 'Utilizzare i registri carta';
_fc_props.eld_login_failed = 'Accesso al servizio di ore non riuscito';
_fc_props.setup_time_off_type = 'Si prega di impostare un tipo di pausa.';
_fc_props.view_load_properties = 'Visualizza Proprietà del carico';
_fc_props.couldnt_download_edc_firmware = 'Impossibile scaricare il firmware EDC.';
_fc_props.password_incorrect = 'Password non corretta';
_fc_props.edc_configuration_password_prompt = 'A causa dello stato di questo dispositivo, è necessario fornire la password del programma di installazione per la configurazione.';
_fc_props.edc_configuration_check_failed = 'Convalida dei record del server EDC non riuscita. Si prega di controllare i messaggi di errore per i dettagli.';
_fc_props.configuration_issues_prompt = 'Sono stati rilevati i seguenti problemi durante la configurazione dell\'EDC selezionato per il camion specificato';
_fc_props.checking_password = 'Verifica password';
_fc_props.reset_password_instructions_sent = 'Istruzioni per reimpostare la password inviata all\'indirizzo di posta elettronica {0}.';
_fc_props.reset_password_failed = 'Reimpostazione password non riuscita, riprovare.';
_fc_props.forgot_password = 'Ha dimenticato la password?';
_fc_props.enter_valid_email = 'Indirizzo e-mail non valido, inserisci un indirizzo e-mail valido e riprova.';
_fc_props.issues_while_updating_edc_record = 'L\'EDC è stato configurato correttamente. Tuttavia, ci sono problemi con i record per l\'EDC sul server TrackIt che potrebbero non funzionare. Si prega di chiamare l\'assistenza per correggere le informazioni.';
_fc_props.sending = 'Invio ...';
_fc_props.minimum_ratio = 'Rapporto minimo';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'RPM del tamburo non compreso nell\'intervallo';
_fc_props.probe_failure = 'Guasto della sonda';
_fc_props.unknown_probe_data_failure = 'Errore dati sonda sconosciuto';
_fc_props.no_loaded_status_meaning = 'Stato non caricato';
_fc_props.no_depart_status_meaning = 'Nessuno stato di partenza';
_fc_props.no_arrive_status_meaning = 'Nessuno stato di arrivo';
_fc_props.no_begin_work_status_meaning = 'No Begin Work Status';
_fc_props.no_fully_mixed_status_meaning = 'Nessuno stato completamente misto';
_fc_props.no_begin_work_or_at_job_status = 'Gli stati Inizia lavoro o Al lavoro non sono presenti';
_fc_props.driver_didnt_follow_procedure = 'Il conducente non ha seguito la procedura (non rallenta il tamburo)';
_fc_props.probe_malfunction = 'Malfunzionamento della sonda (guasto interno, ecc.)';
_fc_props.no_data_from_probe = 'Nessun dato dalla sonda (perdita di dati)';
_fc_props.missing_probe_calibration = 'Nessun dato di calibrazione della sonda';
_fc_props.probe_pressure_not_in_range = 'La pressione della sonda non è nell\'intervallo';
_fc_props.arrival = 'Arrivo';
_fc_props.small_load = 'PICCOLO CARICO';
_fc_props.dry_slump = 'SLUMP SECCO';
_fc_props.probe_remixing_turns = 'remixing';
_fc_props.turns_needed_to_remix = 'Acqua aggiunta - Necessaria miscelazione aggiuntiva';
_fc_props.probe_remixing_finished = 'Remixing terminato';
_fc_props.probe_battery_low_alert = 'Avviso batteria scarica';
_fc_props.probe_battery_low_message = 'Batteria della sonda scarica';
_fc_props.probe_battery_low = 'Batteria della sonda scarica';
_fc_props.alert_when_probe_battery_low = 'Avviso quando la batteria della sonda è scarica';
_fc_props.probe_sensor_fatal_failure = 'Errore fatale del sensore';
_fc_props.probe_thermistor_failure = 'Guasto del termistore';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensore bloccato nel calcestruzzo';
_fc_props.probe_sensor_over_weight = 'Sensore di peso eccessivo';
_fc_props.probe_batteries_low = 'Batteria scarica';
_fc_props.no_probe_data = 'Nessun dato sulla sonda:';
_fc_props.check_probe = 'Controlla la sonda';
_fc_props.service_probe = 'Sonda di servizio';
_fc_props.below_x = 'Sotto {0}';
_fc_props.above_x = 'Sopra {0}';
_fc_props.probe_serial_number_invalid_format = 'Il numero di serie della sonda non è in formato valido come visualizzato nel segnaposto per il campo';
_fc_props.talk_dnd_permission_prompt = 'La conversazione richiede l\'autorizzazione NON DISTURBARE.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'Lo stato è cambiato troppo rapidamente';
_fc_props.is_empty = 'È vuoto';
_fc_props.slump_increased_plasticizer_question = 'È stato aggiunto un plastificante?';
_fc_props.slump_increased_water_added_question = 'Quanta acqua non misurata è stata aggiunta?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Probe e Delivery Cycle Monitoring non sono supportati insieme sullo stesso dispositivo.';
_fc_props.no_data_from_sensor = 'Nessun dato dal sensore (perdita di dati)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Tipo di sistema';
_fc_props.magnet_count = 'Conteggio dei magneti';
_fc_props.sensors_setup = 'Impostazione dei sensori';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM richiede l\'impostazione dell\'indirizzo MAC del box I / O, del tipo di tamburo, del tipo DCM e del conteggio del magnete.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Volume con biglietto';
_fc_props.drs_current_volume = 'Volume corrente';
_fc_props.drs_current_water_cement_ratio = 'Rapporto acqua / cemento attuale';
_fc_props.drs_target_water_cement_ratio = 'Rapporto target acqua / cemento';
_fc_props.drs_total_revolutions = 'Totale';
_fc_props.drs_current_volume_lp_small = 'attuale';
_fc_props.drum_revolutions = 'Rivoluzioni di tamburi';
_fc_props.drs_total_revolutions_lp_small = 'Totale';
_fc_props.drs_charging_revolutions_lp_small = 'Ricarica';
_fc_props.drs_discharging_revolutions_lp_small = 'Scarico';
_fc_props.drs_target_wc_lp_small = 'Obiettivo W / C';
_fc_props.drs_current_wc_lp_small = 'W / C corrente';
_fc_props.drs_ticketed_volume_lp_small = 'Biglietto';
_fc_props.android_location_disclosure = 'TrackIt utilizza la posizione fine e approssimativa per raccogliere i dati sulla posizione in background anche quando non è in uso, per consentire l\'auto-statazione e la visibilità della mappa in tempo reale per la spedizione.';
_fc_props.android_location_usage = 'Divulgazione della posizione';
_fc_props.end_pour_trigger_threshold = 'End Pour Trigger Threshold';
_fc_props.drs_wiring_error = 'Errore di cablaggio';
_fc_props.drs_wiring_pulse_error = 'Errore di impulso di cablaggio';
_fc_props.drs_wiring_magnet_error = 'Errore del magnete di cablaggio';
_fc_props.drs_magnet_missing_error = 'Errore mancante del magnete';
_fc_props.android_location_disclosure_3p = 'TrackIt raccoglie i dati sulla posizione in background, per abilitare lo stato automatico e la visibilità della mappa in tempo reale per l\'invio. Quando è scaduto dal mittente, la posizione non viene condivisa.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Seleziona MDM completo o Trackit Remote Support';
_fc_props.move_up = 'Andare avanti';
_fc_props.move_down = 'Abbassati';
_fc_props.driver_not_found = 'Driver non trovato.';
_fc_props.multiple = 'Molteplici';
_fc_props.water_meter_installed = 'Contatore dell\'acqua installato';
_fc_props.delete_waypoint = 'Elimina waypoint';
_fc_props.wash_sensor_installed = 'Sensore di lavaggio installato';
_fc_props.washing_start_time = 'Ora inizio lavaggio';
_fc_props.end_washing = 'Fine lavaggio';
_fc_props.variable_rpms = 'Var RPM';
_fc_props.no_slump = 'No Slump';
_fc_props.end_washing_duration = 'Durata lavaggio finale';
_fc_props.privacy_policy = 'politica sulla riservatezza';
_fc_props.privacy_policy_text = 'Ho letto e accetto il';
_fc_props.login_fail_accept_privacy_policy = 'L\'Informativa sulla privacy deve essere accettata per procedere.';
_fc_props.rotation_sensor = 'Sensore di rotazione';
_fc_props.pto_sensor = 'Sensore PTO';
_fc_props.pto_sensor_installed = 'Sensore PTO installato';
_fc_props.polarity_reversed = 'Polarità inversa';
_fc_props.pto_sensor_type = 'Tipo di sensore della presa di forza';
_fc_props.sensor_type = 'Tipo di sensore';
_fc_props.prompt_select_pto_sensor_type = 'È necessario selezionare un "Tipo sensore PTO" se è stato selezionato un "Sensore PTO" come tipo di sensore principale.';
_fc_props.priming_turns_at_full_load = 'Virate di adescamento a pieno carico';
_fc_props.priming_turns_at_ten_percent_load = 'Giri di adescamento al 10% di carico';
_fc_props.weight_units = 'Unità di peso';
_fc_props.settings.send_status = 'Invia stato/posizione a Event Bridge';
_fc_props.volume_difference = 'Differenza di volume';
_fc_props.mobile_ticket_iframe_url = 'URL IFrame biglietto mobile';
_fc_props.mobile_ticket_user = 'Utente Biglietto Mobile';
_fc_props.mobile_ticket_password = 'Password del biglietto mobile';
_fc_props.current_truck_status = 'Stato attuale del camion';
_fc_props.estimated_symbol = '?';
_fc_props.almost_equal_to_symbol = '?';
_fc_props.triggered_by_max_revolutions = 'Attivato da giri massimi';
_fc_props.alerts_by_type = 'Avvisi per tipo';
_fc_props.water_flow_meter = 'Misuratore di portata d\'acqua';
_fc_props.flow_meter_no_pulses_warning = 'Nessun impulso - Avvertenza';
_fc_props.flow_meter_no_pulses_critical = 'Nessun impulso - Critico';
_fc_props.flow_meter_excessive_pulses = 'Polsi eccessivi';
_fc_props.flow_meter_constantly_flowing = 'Scorre costantemente';
_fc_props.flow_meter_good = 'Buona';
_fc_props.whats_new = 'Cosa c\'è di nuovo';
_fc_props.whats_new_widget_text = 'Sapevi che aggiorniamo TrackIt almeno una volta al mese? Ricevi gli ultimi aggiornamenti e suggerimenti.';
_fc_props.get_the_scoop = 'Prendi lo scoop';
_fc_props.trackit_insights = 'Approfondimenti Trackit';
_fc_props.business_analytics_blurb = 'Analisi aziendali che migliorano i tuoi profitti, ora incluse con TrackIt';
_fc_props.find_out_more = 'Scopri di più';
_fc_props.daily_breakdown = 'Ripartizione giornaliera';
_fc_props.question_summary = 'Riepilogo domanda';
_fc_props.trip_summary = 'Riepilogo del viaggio';
_fc_props.manage_employees = 'Gestire i dipendenti';
_fc_props.manage_hotspots = 'Gestisci hotspot';
_fc_props.manage_devices = 'Gestisci dispositivi';
_fc_props.manage_equipment = 'Gestisci le attrezzature';
_fc_props.manage_users = 'Gestisci utenti';
_fc_props.manage_statuses = 'Gestisci gli stati';
_fc_props.manage_alerts = 'Gestire gli avvisi';
_fc_props.popular_report_links = 'Link ai rapporti popolari';
_fc_props.popular_settings_links = 'Collegamenti alle impostazioni popolari';
_fc_props.sidemenu_settings = 'Impostazioni';
_fc_props.loads_per_driver = 'Carichi per conducente';
_fc_props.please_select_application = 'Seleziona almeno un\'applicazione';
_fc_props.external_app_processing = 'Elaborazione su app esterna';
_fc_props.external_app_sent = 'Inviato a un\'app esterna';
_fc_props.external_app_received = 'Ricevuto da un\'app esterna';
_fc_props.wait_time_at_job = 'Tempo di attesa al lavoro';
_fc_props.wait_time_at_plant = 'Tempo di attesa allo stabilimento';
_fc_props.loading_time = 'Tempo di caricamento';
_fc_props.unloading_time = 'Tempo di scarico';
_fc_props.pre_post_trip = 'Controllo Pre/Post viaggio';
_fc_props.update_thresholds = 'Soglie di aggiornamento';
_fc_props.green = 'Verde';
_fc_props.yellow = 'Giallo';
_fc_props.red = 'rosso';
_fc_props.between = 'Tra';
_fc_props.num_of_loads = 'N. di carichi';
_fc_props.num_of_drivers = '# di conducenti';
_fc_props.status_meaning_not_setup = 'Stato Significato non impostato';
_fc_props.or_less = 'O meno';
_fc_props.or_greater = 'o maggiore';
_fc_props.probe_self_diagnostic_state = 'Stato di autodiagnosi della sonda';
_fc_props.update_current_truck_status_settings = 'Aggiorna le impostazioni attuali dello stato del camion';
_fc_props.default_behavior = 'Predefinito (primi 10 stati)';
_fc_props.combine_similar_statuses = 'Combina gli stessi stati da tutti i tipi (loop)';
_fc_props.use_other_category = 'Usa la categoria "Altro"';
_fc_props.selected_statuses = 'Stati selezionati';
_fc_props.ideas = 'Idee';
_fc_props.air_content = 'Contenuto d\'aria';
_fc_props.air_measured_time = 'Tempo misurato dall\'aria';
_fc_props.ten_max = '10 max';
_fc_props.loads_per_plant = 'Carichi per pianta';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Impulso flussometro per volume non valido.';
_fc_props.coming_soon_title = 'Altri widget in arrivo';
_fc_props.submit_your_ideas = 'Invia le tue idee al nostro Portale delle idee utilizzando la lampadina sul menu di navigazione a sinistra.';
_fc_props.selected_plants = 'Piante selezionate';
_fc_props.update_loads_per_plant_settings = 'Aggiorna le impostazioni dei carichi per impianto';
_fc_props.default_top_10_plants = 'Predefinito (Prime 10 piante)';
_fc_props.phone_number_will_be_uploaded = 'L\'ID del dispositivo verrà caricato a scopo di registrazione.';
_fc_props.include_deleted = 'Includi eliminati';
_fc_props.primary_status_type = 'Tipo di stato primario';
_fc_props.additional_status_trigger = 'Trigger di stato aggiuntivi';
_fc_props.update_tickets_destination = 'Aggiorna la destinazione su tutti i biglietti associati';
_fc_props.settings.send_destination_changes_to_connex = 'Invia le modifiche alla destinazione a Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Il biglietto è attivo quando viene ricevuto da TrackIt (predefinito \'sempre\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Attiva automaticamente il biglietto successivo al completamento del biglietto precedente';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Dovrebbe attivare automaticamente il biglietto successivo al completamento del biglietto (impostazione predefinita "true")';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Stato allo stato inattivo quando nessun ticket attivo';
_fc_props.break_down = 'Guasto';
_fc_props.idle = 'Oziare';
_fc_props.pto_work_maximum_speed_limit = 'Limite massimo di velocità di lavoro';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Modifica dello stato associato che completa un ticket con quel ticket';
_fc_props.approaching = 'Avvicinandosi';
_fc_props.performing_task = 'Compito di esecuzione';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Sei sicuro di voler eliminare Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Aggiorna biglietto {0}. Destinazione cambiata.';
_fc_props.trackit_return_destination_changed_to_0 = 'Aggiorna biglietto {0}. Ritorno cambiato.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Aggiungi Geogate';
_fc_props.driver_enabled = 'Driver abilitato';
_fc_props.dispatcher_enabled = 'Dispacciatore abilitato';
_fc_props.runsheet_number = 'Numero del foglio di lavoro';
_fc_props.manual_load = 'Carico manuale';
_fc_props.edit_geogate = 'Modifica Geogate';
_fc_props.handling = 'Gestione';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Pannello Geogate';
_fc_props.vehicle_is_not_logged_in = 'Messaggio non inviato (il veicolo non è loggato)';
_fc_props.air = 'Aria';
_fc_props.create_a_geogate = 'Crea un geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Fare clic sulla mappa per impostare i punti di geogate.';
_fc_props.clock_out = 'Scadenza';
_fc_props.handling_operation = 'Operazione di manipolazione';
_fc_props.view_geogates = 'Visualizza Geogate';
_fc_props.select_a_geogate = 'Seleziona un Geogate';
_fc_props.failed_to_find_geogate = 'Impossibile trovare Geogate';
_fc_props.adjust_geogate = 'Regola Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Data di scadenza Ora Giorni extra predefiniti';
_fc_props.menu_settings_title_retention = 'Conservazione dei dati';
_fc_props.category = 'Categoria';
_fc_props.telemetry = 'Telemetria';
_fc_props.ticket_data = 'Dati del biglietto';
_fc_props._30_days = '30 giorni';
_fc_props._6_months = '6 mesi';
_fc_props._1_year = '1 anno';
_fc_props._3_years = '3 anni';
_fc_props.new_group = 'Nuovo gruppo';
_fc_props.selected_vehicles = 'Veicoli selezionati';
_fc_props.group_name_is_required_field = 'Il nome del gruppo è campo obbligatorio';
_fc_props.at_least_vehicle_should_be_selected = 'È necessario selezionare almeno 1 veicolo';
_fc_props.groups = 'Gruppi';
_fc_props.trucks = 'Camion';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Il geogate è stato rimosso. Si prega di aggiornare la pagina.';
_fc_props.air_timestamp = 'Timestamp aereo';
_fc_props.fail = 'FALLIRE';
_fc_props.stuck = 'INCOLLATO';
_fc_props.dirty = 'SPORCO';
_fc_props.seasonal = 'di stagione';
_fc_props.category_details = 'Categoria Dettagli';
_fc_props.alert_events = 'Eventi di avviso';
_fc_props.driver_performance_infractions = 'Infrazioni nelle prestazioni del conducente';
_fc_props.telemetry_details = 'Dati sulla posizione, hotspot, tempo negli hotspot, tempo nelle regioni, dati DCM, dati sonda, dati motore';
_fc_props.ticket_data_details = 'Dati del biglietto';
_fc_props.general_details = 'Modifiche di stato, cartellini, orari';
_fc_props.wash = 'Lavare';
_fc_props.filter_options = 'Opzioni filtro';
_fc_props.home_plants = 'Piante domestiche';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO richiede l\'impostazione del tipo di sensore PTO.';
_fc_props.invalid_io_box_mac_address = 'Indirizzo Mac casella I/O non valido';
_fc_props.access_denied = 'Accesso negato';
_fc_props.wash_events = 'Eventi di lavaggio';
_fc_props.min_speed = 'Velocità minima';
_fc_props.temperature_change = 'Cambio di temperatura';
_fc_props.degrees_cap = 'Gradi';
_fc_props.washout_tracking = 'Monitoraggio del lavaggio';
_fc_props.approaching_wash = 'Lavaggio in avvicinamento';
_fc_props.arrival_wash = 'Lavaggio all\'arrivo';
_fc_props.performing_wash = 'Esecuzione del lavaggio';
_fc_props.wash_complete = 'Lavaggio completato';
_fc_props.must_be_positive_or_zero = 'Deve essere positivo o zero!';
_fc_props.min_speed_greater_than_max = 'La velocità minima deve essere inferiore alla velocità massima!';
_fc_props.clock_in = 'Timbrare';
_fc_props.mix_water_at_x_to_y_for_t = 'Mescola acqua a {0} a {1} RPM per {2} giri.';
_fc_props.discharge_water_for_x_revolutions = 'Scaricare acqua per {0} giri.';
_fc_props.eod_drum_wash_complete = 'Lavaggio cestello completato! <br> Arresta il tamburo con la sonda in alto.';
_fc_props.washing_complete = 'Lavaggio guidato completato';
_fc_props.eod_washout_no_data_available = 'Dati del sensore non disponibili, completare il processo di lavaggio.';
_fc_props.eod_washout_no_data_available_title = 'Lavaggio guidato Non disponibile';
_fc_props.menu_reports_title_ticket_time_summary = 'Riepilogo orario biglietto';
_fc_props.menu_reports_description_ticket_time_summary = 'Visualizza le informazioni sull\'orario del biglietto per un ordine o un veicolo';
_fc_props.menu_reports_title_summary = 'Riepilogo';
_fc_props.menu_reports_title_ticket_summary = 'Riepilogo del biglietto';
_fc_props.menu_reports_description_summary1 = 'Visualizza un riepilogo del tempo trascorso negli hotspot';
_fc_props.menu_dispatch_title_main = 'Principale';
_fc_props.menu_dispatch_title_login_registry = 'Registro di accesso';
_fc_props.menu_reports_title_main = 'Principale';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Sei sicuro di voler eliminare questo tipo di dati di esportazione?';
_fc_props.dvir_status_paper_logs = 'Registri cartacei';
_fc_props.menu_reports_title_billing_report = 'Rapporto di fatturazione';
_fc_props.menu_reports_description_billing_report = 'Rapporto di fatturazione';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Da stato a stato con ticket su Attiva lavoro';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Da stato a stato con ticket all\'attivazione del lavoro (predefinito \'False\')';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connetti EID';
_fc_props.units_weight_pound_label = 'Sterline';
_fc_props.units_weight_kilogram_label = 'Chilogrammi';
_fc_props.settings_weight = 'Peso';
_fc_props.units_pounds = 'Sterline';
_fc_props.units_kilograms = 'Chilogrammi';
_fc_props.geo_gate_edit_modal_description = 'Descrizione';
_fc_props.geo_gate_edit_modal_start_point = 'Punto di partenza';
_fc_props.geo_gate_edit_modal_end_point = 'Punto finale';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Tipo di apparecchiatura';
_fc_props.geo_gate_edit_modal_heading_south = 'Da sud a nord';
_fc_props.geo_gate_edit_modal_heading_north = 'Da nord a sud';
_fc_props.geo_gate_edit_modal_driver_message = 'Messaggio del conducente';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Messaggio di spedizione';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Ora della data di scadenza';
_fc_props.geo_gate_edit_modal_permanent = 'Permanente';
_fc_props.geo_gate_edit_modal_critical = 'Critico';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Errore durante il salvataggio di Geogate: il campo Descrizione deve essere compilato.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Errore durante il salvataggio di Geogate: il campo Punto di partenza deve essere popolato.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Errore durante il salvataggio di Geogate: il campo Punto di partenza contiene un formato dati non valido.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Errore durante il salvataggio di Geogate: il campo End Point contiene un formato dati non valido.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Errore durante il salvataggio di Geogate: il campo Data di scadenza Ora deve essere compilato.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Errore durante il salvataggio di Geogate: il campo Data di scadenza Ora contiene un formato dati non valido.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Errore durante il salvataggio di Geogate: da nord a sud - Il campo Messaggio di spedizione deve essere compilato';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Errore durante il salvataggio di Geogate: nessuna modifica rilevata';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Errore durante il salvataggio di Geogate: il campo Data e ora di scadenza contiene la data e l\'ora scadute';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Errore durante il salvataggio di Geogate: il campo Descrizione deve contenere solo lettere e numeri';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Errore durante il salvataggio di Geogate: il campo Tipo di attrezzatura deve essere compilato';
_fc_props.geo_gate_table_message_label_north_to_south = 'Da nord a sud';
_fc_props.geo_gate_table_message_label_south_to_north = 'Da sud a nord';
_fc_props.geo_gate_table_message_label_both = 'Entrambi';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Latitudine Longitudine';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'La selezione di questa opzione sovrascriverà l\'impostazione per la restrizione dei messaggi di movimento del dispositivo';
_fc_props.geo_gate_edit_modal_seasonal = 'di stagione';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Errore durante il salvataggio di Geogate: le date stagionali devono essere inserite';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Errore durante il salvataggio di Geogate: i campi della data stagionale contengono un formato dati non valido';
_fc_props.test_js_test_javascript = 'Prova Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Chilometri';
_fc_props.units_miles = 'Miglia';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'l';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Errore durante il salvataggio di Geogate: da sud a nord - Il campo del messaggio del conducente deve essere compilato';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Errore durante il salvataggio di Geogate: da sud a nord - Il campo del messaggio di spedizione deve essere compilato';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Errore durante il salvataggio di Geogate: da nord a sud - Il campo Messaggio del conducente deve essere compilato';
_fc_props.geo_gate_heading = 'Intestazione';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Errore durante il salvataggio di Geogate: il campo End Point deve essere popolato';
_fc_props.units_mile = 'Miglio';
_fc_props.units_kilometer = 'Chilometro';
_fc_props.cloud_dvir__exit = 'Uscita';
_fc_props.cloud_dvir__signature = 'Firma';
_fc_props.cloud_dvir__inspection = 'Ispezione';
_fc_props.cloud_dvir__add_photos_notes = 'Aggiungi foto/note';
_fc_props.cloud_dvir__odometer_reading = 'Lettura del contachilometri';
_fc_props.cloud_dvir__engine_hours = 'Ore del motore';
_fc_props.cloud_dvir__truck_details = 'Dettagli del camion';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Determinare le condizioni generali del veicolo';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Nome completo e firma del conducente';
_fc_props.cloud_dvir__sign = 'Cartello';
_fc_props.cloud_dvir__driver_note_from = 'Nota del conducente da';
_fc_props.cloud_dvir__add_notes = 'Aggiungi note';
_fc_props.cloud_dvir__driver_notes = 'Note del conducente';
_fc_props.cloud_dvir__done = 'Fatto';
_fc_props.cloud_dvir__needs_repair = 'Ha bisogno di riparazione';
_fc_props.cloud_dvir__not_applicable = 'Non applicabile';
_fc_props.cloud_dvir__review_and_sign = 'Rivedi e firma';
_fc_props.cloud_dvir__add_photos = 'Aggiungi foto';
_fc_props.cloud_dvir__upload_photo_limit = 'Puoi caricare fino a 3 immagini';
_fc_props.cloud_dvir__mismatch_title = 'Avviso di mancata corrispondenza';
_fc_props.cloud_dvir__ok = 'OK';
_fc_props.cloud_dvir_template = 'Modello Cloud DVR';
_fc_props.cloud_dvir__needs_review = 'DVIR ha bisogno di revisione';
_fc_props.cloud_dvir__submitted = 'Inviato';
_fc_props.cloud_dvir__completed_by = 'Completato da';
_fc_props.cloud_dvir__review_btn = 'Revisione';
_fc_props.cloud_dvir__truck_number = 'Camion {0}';
_fc_props.cloud_dvir__pending_review = 'Revisione in atto';
_fc_props.cloud_dvir__start_dvir = 'Avvia DVR';
_fc_props.cloud_dvir__dvir_history = 'Cronologia DVIR';
_fc_props.cloud_dvir__view_all = 'Mostra tutto';
_fc_props.load_zone = 'Zona di carico';
_fc_props.view_load_zones = 'Visualizza le zone di carico';
_fc_props.edit_load_zone = 'Modifica zona di carico';
_fc_props.create_a_load_zone = 'Crea una zona di carico';
_fc_props.load_zone_panel = 'Carico Zona Pannello';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Fare clic sulla mappa per impostare il centro della zona di carico.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Questa ispezione ha difetti indicati e hai selezionato che questo veicolo è sicuro da usare. Si prega di convalidare prima di procedere';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Questa ispezione non ha difetti indicati e hai selezionato che questo veicolo non è sicuro da usare. Si prega di convalidare prima di procedere.';
_fc_props._90_days = '90 giorni';
_fc_props.cloud_dvir = 'DVR cloud';
_fc_props.cloud_dvir_details = 'Invii Cloud DVIR';
_fc_props.show_load_zones = 'mostra le zone di carico';
_fc_props.hide_load_zones = 'nascondere le zone di carico';
_fc_props.no = 'No';
_fc_props.cloud_dvir__reset = 'Ripristina';
_fc_props.cloud_dvir__clear = 'Chiaro';
_fc_props.mark_read = 'Marco Leggi';
_fc_props.mark_unread = 'Segna come da leggere';
_fc_props.read = 'Leggere';
_fc_props.unread = 'Non letto';
_fc_props.air_sensor_serial_number = 'N. di serie del sensore dell\'aria';
_fc_props.air_sensor_mac_address = 'Indirizzo MAC del sensore dell\'aria';
_fc_props.air_sensor_serial_number_invalid_format = 'Il numero di serie del sensore dell\'aria non è in un formato valido: (alfanumerico e lungo al massimo 32 caratteri con trattini)';
_fc_props.air_sensor_mac_address_invalid_format = 'L\'indirizzo MAC del sensore dell\'aria non è nel formato esadecimale valido come visualizzato nel segnaposto per il campo';
_fc_props.air_sensor_license = 'Sensore dell\'aria';
_fc_props.adjust_route_point = 'Regola il punto del percorso';
_fc_props.route_point_panel = 'Pannello dei punti del percorso';
_fc_props.route_point = 'Punto del percorso';
_fc_props.cloud_dvir__pre_trip = 'Pre-viaggio';
_fc_props.cloud_dvir__post_trip = 'Dopo il viaggio';
_fc_props.cloud_dvir__other_insction_type = 'Altro';
_fc_props.cloud_dvir__dvir_completed = 'Completato';
_fc_props.cloud_dvir__awaiting_mechanic = 'In attesa del meccanico';
_fc_props.cloud_dvir__awaiting_driver = 'In attesa dell\'approvazione del conducente';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Impossibile controllare il numero di serie del sensore dell\'aria, contattare l\'assistenza per ulteriore assistenza.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Il numero di serie del sensore dell\'aria esiste già, utilizza un altro numero di serie';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Impossibile controllare l\'indirizzo MAC del sensore dell\'aria, contattare l\'assistenza';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Errore durante l\'invio del DVR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Per favore riprova';
_fc_props.ticket_integration_descr = 'L\'integrazione del ticket consente di configurare le impostazioni di integrazione del ticket nonché la schermata iniziale/schermata di caricamento/visualizzazione a montaggio fisso';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR inviato con successo';
_fc_props.cloud_dvir__inspection_trip_type = 'Tipo di viaggio di ispezione';
_fc_props.menu_dvir_title_mechanic = 'Meccanico';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Firmware del sensore dell\'aria';
_fc_props.when_no_active_ticket = 'Quando nessun biglietto attivo';
_fc_props.used_by = 'Usato da';
_fc_props.fields = 'Campi';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Sei sicuro di voler eliminare il layout del ticket "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Disposizione del biglietto';
_fc_props.add_ticket_layout = 'Aggiungi layout biglietto';
_fc_props.edit_ticket_layout = 'Modifica layout biglietto';
_fc_props.ticket_layout_edit_modal_name = 'Nome';
_fc_props.ticket_layout_edit_modal_default = 'Predefinito';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Errore durante il salvataggio del layout del ticket: il campo Nome deve essere compilato.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Errore durante il salvataggio del layout del ticket: il campo Nome deve contenere solo lettere e numeri';
_fc_props.ticket_layout_edit_modal_fields = 'Campi';
_fc_props.ticket_layout_edit_modal_usage = 'Utilizzo';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Il layout del ticket è stato rimosso. Per favore, aggiorna la pagina.';
_fc_props.more_lower_case = 'Di più';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Errore durante il salvataggio del layout del ticket: è necessario selezionare uno o più campi.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Errore durante il salvataggio del layout del ticket: è necessario selezionare uno o più stati.';
_fc_props.cloud_dvir__ad_hoc = 'Ad hoc';
_fc_props.radius_multiplier_should_be = 'Il moltiplicatore del raggio dovrebbe essere un numero mobile';
_fc_props.ticket_max_activate_age_err = 'I minuti di età massima di attivazione del biglietto devono essere un numero intero.';
_fc_props.max_hotspot_unused_days_errs = 'Il numero massimo di giorni inutilizzati dell\'hotspot deve essere un numero intero.';
_fc_props.begin_pour_air_content = 'Inizia a versare contenuto d\'aria';
_fc_props.arrival_air_content = 'Contenuto dell\'aria di arrivo';
_fc_props.fully_mixed_air_content = 'Contenuto d\'aria completamente miscelato';
_fc_props.departure_air_content = 'Contenuto dell\'aria di partenza';
_fc_props.popup_enabled = 'Popup abilitato';
_fc_props.popup_disabled = 'Popup disabilitato';
_fc_props.cloud_dvir__leave_confirmation_title = 'Avvertimento';
_fc_props.cloud_dvir__leave_confirmation_description = 'Uscendo dalla pagina adesso tutte le modifiche andranno perse.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Confermare';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Annulla';
_fc_props.air_bt_ratio = 'Misurazione del sensore dell\'aria';
_fc_props.cloud_dvir__view_photos_notes = 'Visualizza foto/note';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'L\'aggiunta dello stato creerà un utilizzo vuoto in un altro layout del ticket. Vuoi procedere con l\'aggiunta?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Il veicolo non ha la licenza per il sensore dell\'aria.';
_fc_props.air_sensor_installed = 'Sensore dell\'aria installato';
_fc_props.load_card = 'Carica carta';
_fc_props.cloud_dvir__inspection_profile = 'Profilo di ispezione';
_fc_props.cloud_dvir__view_details = 'Visualizza dettagli';
_fc_props.cloud_dvir__certify_repairs = 'Certificare le riparazioni';
_fc_props.cloud_dvir__repairs_made = 'Riparazioni effettuate';
_fc_props.cloud_dvir__repairs_not_necessary = 'Riparazioni non necessarie';
_fc_props.cloud_dvir__mechanic_note_from = 'Nota del meccanico da';
_fc_props.cloud_dvir__mechanic_assignment = 'Incarico meccanico';
_fc_props.cloud_dvir__mechanic_signature_description = 'Contrassegnare tutti gli elementi applicabili di seguito e firmare indicando che il veicolo è stato adeguatamente ispezionato.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Le condizioni di questo veicolo sono soddisfacenti';
_fc_props.cloud_dvir__defects_corrected_label = 'I difetti sono stati corretti';
_fc_props.cloud_dvir__repair_not_required_label = 'Riparazione non necessaria per il funzionamento sicuro del veicolo';
_fc_props.safety = 'Sicurezza';
_fc_props.cloud_dvir__awaiting_sign = 'In attesa del segno';
_fc_props.cloud_dvir__dvir_review = 'Recensione DVIR';
_fc_props.settings.mobile_auto_start_navigation = 'Avvio automatico della navigazione sul cellulare';
_fc_props.cloud_dvir__edit = 'Modificare';
_fc_props.delete_custom_label = 'Elimina etichetta personalizzata';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'La tua versione di TrackIt non è aggiornata e alcune funzionalità DVIR potrebbero non funzionare correttamente. Aggiorna TrackIt prima di procedere.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Qualcosa è andato storto durante il caricamento della firma. Per favore riprova';
_fc_props.max_hotspot_unused_days_error = 'Il numero massimo di giorni inutilizzati dell\'hotspot ha un massimo di 1000 giorni.';
_fc_props.menu_dvir_title_admin_templates = 'Modelli';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Ammin';
_fc_props.cloud_dvir__admin_templates = 'Modelli';
_fc_props.form_name = 'Nome del modulo';
_fc_props.form_status = 'Stato del modulo';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Scaricamento';
_fc_props.cloud_dvir__option_view = 'Visualizzazione';
_fc_props.cloud_dvir__inspectortype_driver = 'Autista';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Visualizza/modifica/crea moduli e invii DVRIR personalizzati';
_fc_props.menu.dvir.description.mechanic = 'Visualizza/modifica gli invii DVRIR utilizzabili';
_fc_props.not_in_use = 'Non in uso';
_fc_props.logged_in = 'connesso';
_fc_props.reviewing_driver = 'Revisione del conducente';
_fc_props.cloud_dvir__report_title = 'Rapporto di ispezione del veicolo del conducente';
_fc_props.cloud_dvir__report_signed = 'firmato';
_fc_props.cloud_dvir__report_page_counter = 'Pagina {0} di {1}';
_fc_props.cloud_dvir__report_defects = 'Difetti';
_fc_props.cloud_dvir__report_comments = 'Commenti';
_fc_props.cloud_dvir__report_inspection_table_title = 'Ispezione del veicolo';
_fc_props.cloud_dvir__report_driver_table_title = 'Informazioni sul conducente';
_fc_props.cloud_dvir__report_carrier = 'Vettore';
_fc_props.cloud_dvir__report_truck = 'Camion';
_fc_props.cloud_dvir__report_location = 'Posizione';
_fc_props.cloud_dvir__report_odometer = 'Odometro';
_fc_props.cloud_dvir__report_hours = 'Ore';
_fc_props.cloud_dvir__report_no_comments = 'Non ci sono commenti';
_fc_props.include_unlicensed_vehicles = 'Includere veicoli senza licenza';
_fc_props.cloud_dvir__search_by_form_name = 'Cerca per nome modulo';
_fc_props.cloud_dvir__create_dvir = 'Crea un DVR';
_fc_props.cloud_dvir__update_dvir = 'Aggiorna DVR';
_fc_props.cloud_dvir__form_name = 'Nome del modulo';
_fc_props.cloud_dvir__form_description = 'Descrizione del modulo';
_fc_props.cloud_dvir__form_status = 'Stato del modulo';
_fc_props.information = 'Informazione';
_fc_props.cloud_dvir__search_categories = 'Categorie di ricerca';
_fc_props.cloud_dvir__create_new_category = 'Crea nuova categoria';
_fc_props.category_name = 'Nome della categoria';
_fc_props.sub_category = 'Sottocategoria';
_fc_props.sub_category_name = 'Nome della sottocategoria';
_fc_props.delete_category = 'Elimina categoria';
_fc_props.cloud_dvir__dvir_information = 'Informazioni DVR';
_fc_props.cloud_dvir__dvir_choose_categories = 'Scegli Categorie';
_fc_props.cloud_dvir__create_category = 'Crea categoria';
_fc_props.cloud_dvir__update_category = 'Aggiorna categoria';
_fc_props.delete_route_point = 'Elimina punto del percorso';
_fc_props.view_route_points = 'Visualizza punti del percorso';
_fc_props.ignore_cancel_preload = 'Ignora la cancellazione, sei precaricato. Il tuo fuoricampo verrà mostrato presto';
_fc_props.cloud_dvir__assigned_mechanic = 'Meccanico assegnato';
_fc_props.cloud_dvir__add_mechanic = 'Aggiungi un meccanico';
_fc_props.contact_type = 'Tipo di contatto';
_fc_props.cloud_dvir__send_email_notifications = 'Invia notifiche e-mail';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Errore durante il recupero dei record della cronologia DVRIR.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Errore durante il recupero del record DVIR.';
_fc_props.cloud_dvir__error_saving_submission = 'Errore durante il salvataggio dell\'invio. Riprova.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Errore durante il recupero dell\'elenco delle meccaniche.';
_fc_props.cloud_dvir__template_saved = 'Modello salvato';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Errore durante il recupero dell\'elenco dei modelli.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Errore durante il salvataggio del modello. Riprova.';
_fc_props.dispatch_group = 'Gruppo di spedizione';
_fc_props.save_adjust = 'Salva e modifica il punto del percorso';
_fc_props.reset_route_point = 'Reimposta punto del percorso';
_fc_props.no_records_stub_adjust_filters = 'Prova a modificare le impostazioni di ricerca o di filtro per aumentare il numero di risultati.';
_fc_props.no_dvir_records = 'Tutti presi dai DVR!';
_fc_props.no_dvir_records_sub_title = 'Hai realizzato molto';
_fc_props.settings.master_data_connex_x_api_key = 'Chiave anagrafica Connex x-api';
_fc_props.slump_not_obtained_for_x = 'Nessuna lettura di crollo ottenuta per: {0}';
_fc_props.please_select_a_sensor_status_type = 'Seleziona i tipi di stato del sensore per attivare questo avviso';
_fc_props.settings.master_data_connex_token = 'Token connessione dati anagrafici';
_fc_props.error_validating_master_data_connex_credentials = 'Errore durante la convalida delle credenziali Master Data Connex';
_fc_props.settings.master_data_connex_base_url = 'URL del database Connex dei dati anagrafici';
_fc_props.settings.master_data_connex_exp_time = 'Scadenza del Master Data Connex';
_fc_props.download_template = 'Scarica modello';
_fc_props.menu_settings_title_dispatch_group = 'Gruppi di invio';
_fc_props.settings.dispatch_groups = 'Gruppi di invio';
_fc_props.dispatch_group_name = 'Nome del gruppo di spedizione';
_fc_props.dispatch_group_plants = 'Piante del gruppo di spedizione';
_fc_props.dispatch_group_plants_mapping_message = 'Indicare quali impianti faranno parte di questo Gruppo di Dispacciamento.';
_fc_props.dispatch_group_users_mapping_message = 'Indicare quali utenti faranno parte di questo Gruppo Dispatch.';
_fc_props.dispatch_group_plant_disassociation_message = 'L\'impianto dovrebbe rientrare in un gruppo di spedizione alla volta';
_fc_props.dispatch_group_search_by_plant = 'Cerca per nome della pianta';
_fc_props.dispatch_group_search_by_username = 'Cerca per nome utente';
_fc_props.search_by_dispatch_group_name = 'Cerca per nome del gruppo di spedizione';
_fc_props.create_dispatch_group = 'Crea un gruppo di spedizione';
_fc_props.update_dispatch_group = 'Aggiorna gruppo di spedizione';
_fc_props.dispatch_group_saved = 'Gruppo di invio salvato';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'L\'eliminazione di questo gruppo di spedizione creerà gruppi di spedizione predefiniti per gli impianti associati.';
_fc_props.error_saving_dispatch_group = 'Errore durante il salvataggio del gruppo di spedizione. Riprova.';
_fc_props.please_enter_a_valid_plant_num = 'Inserisci un numero di impianto valido.';
_fc_props.cloud_dvir__report_no_defects = 'Nessun difetto';
_fc_props.shoud_select_dispatch_group = 'Devi selezionare almeno un Gruppo di Spedizione';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Impossibile eliminare perché questo gruppo di distribuzione contiene utenti associati solo a questo gruppo di distribuzione.';
_fc_props.cloud_dvir__username = 'Nome utente';
_fc_props.cloud_dvir__password = 'Parola d\'ordine';
_fc_props.cloud_dvir__login = 'Login';
_fc_props.cloud_dvir__mechanic_sign_in = 'Accesso meccanico';
_fc_props.delete_dispatch_group = 'Elimina gruppo di spedizione';
_fc_props.cloud_dvir__driver = 'Autista';
_fc_props.cloud_dvir__equipment_pound = 'Attrezzatura #';
_fc_props.cloud_dvir__mechanic = 'Meccanico';
_fc_props.dispatch_group_user_only_unassociate_message = 'Impossibile eliminare l\'utente perché gli utenti devono essere associati ad almeno un gruppo di distribuzione.';
_fc_props.cloud_dvir__time_period_filter_today = 'Oggi';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Ieri';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Ultimi 7 giorni';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Ultimi 30 giorni';
_fc_props.cloud_dvir__date_range_filter = 'Filtra per data';
_fc_props.cloud_dvir__download = 'Scaricamento';
_fc_props.dispatch_group_is_empty = 'Il gruppo di spedizione non può essere vuoto.';
_fc_props.dispatch_group_with_empty_plants = 'Almeno un impianto deve essere associato al gruppo di spedizione.';
_fc_props.dispatch_group_with_empty_users = 'Almeno un utente deve essere associato al gruppo di invio.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Impossibile eliminare il gruppo di invio poiché contiene utenti associati solo a questo gruppo di invio.';
_fc_props.dispatch_group_not_exists = 'Il gruppo di spedizione non esiste.';
_fc_props.dispatch_group_contains_one_plant = 'Impossibile eliminare perché questo gruppo di spedizione contiene solo un impianto.';
_fc_props.dispatch_group_users = 'Utenti del gruppo di invio';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Errore durante il recupero del record DVIR';
_fc_props.cloud_dvir__assign_mechanic = 'Assegna meccanico';
_fc_props.cloud_dvir__submit_review = 'invia recensione';
_fc_props.error_deleting_dispatch_group = 'Errore durante l\'eliminazione del gruppo di spedizione.';
_fc_props.error_retrieving_dispatch_groups = 'Errore durante il recupero dell\'elenco dei gruppi di invio.';
_fc_props.cloud_dvir__undo_selection = 'Annulla selezione';
_fc_props.enable_master_data = 'Abilita dati anagrafici';
_fc_props.manually_sync = 'Sincronizza manualmente';
_fc_props.master_data_connex_token_expiration = 'Scadenza del token Master Data Connex';
_fc_props.cloud_dvir__time_period_filter_custom = 'Costume';
_fc_props.settings.zello_session_timeout_minutes = 'Timeout sessione Zello (minuti)';
_fc_props.menu_settings_title_master_data = 'Dati anagrafici';
_fc_props.settings.master_data = 'Dati anagrafici';
_fc_props.error_retrieving_master_data = 'Errore durante il recupero dei dati anagrafici';
_fc_props.master_data_sync_completed = 'Sincronizzazione dei dati principali completata';
_fc_props.master_data_successfully_updated = 'Dati aggiornati con successo';
_fc_props.master_data_failed_to_update = 'Impossibile salvare.';
_fc_props.master_data_sync_initiated = 'Sincronizzazione dei dati principali avviata';
_fc_props.master_data_sync_failed = 'Sincronizzazione dei dati principali non riuscita';
_fc_props.enabled_excluding_origin_on_deliveries = 'Vero (esclusa l\'origine sui ticket di consegna)';
_fc_props.enabled_including_origin_on_deliveries = 'Vero (inclusa l\'origine sui ticket di consegna)';
_fc_props.cloud_dvir__i_will_decide_later = 'Deciderò più tardi';
_fc_props.returning = 'Ritorno';
_fc_props.leftover_concrete = 'Cemento rimasto';
_fc_props.leftover_concrete_on_vehicle_x = 'Cemento rimasto sul veicolo {0}';
_fc_props.leftover_concrete_alert = 'Avviso di calcestruzzo rimasto';
_fc_props.wc_ratio_exceeded = 'Rapporto A/C superato';
_fc_props.search_by_site_name = 'Cerca per nome del sito';
_fc_props.sites = 'Siti';
_fc_props.token_expiration = 'Scadenza del token';
_fc_props.menu_settings_title_connex_api_key_management = 'Gestione delle chiavi API Connex';
_fc_props.settings.connex_api_key_management = 'Gestione delle chiavi API Connex';
_fc_props.error_retrieving_connex_api_key_data = 'Errore durante il recupero dei dati della chiave API Connex';
_fc_props.qr_code = 'QR Code';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informare il conducente e l\'interfaccia quando si inserisce l\'impianto sbagliato';
_fc_props.enable_driver_message = 'Abilita messaggio conducente';
_fc_props.enable_dispatch_message = 'Abilita messaggio di spedizione';
_fc_props.enable_sending_message_to_interface = 'Abilita l\'invio di messaggi all\'interfaccia';
_fc_props.ticket_qr_code = 'Codice QR del biglietto';
_fc_props.leftover_concrete_alert_description = 'Avvisa quando vengono trovati residui di cemento dopo il getto.';
_fc_props.minimum_leftover_concrete_amount = 'Quantità minima di calcestruzzo rimanente';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Errore durante la convalida delle credenziali Master Data Connex non autorizzate';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Errore durante la convalida delle autorizzazioni delle credenziali Master Data Connex';
_fc_props.error_validating_master_data_connex_url_not_found = 'Errore durante la convalida dell\'URL Connex dati anagrafici non trovato';
_fc_props.error_validating_master_data_connex_unknown_error = 'Errore durante la convalida di Master Data Connex Errore sconosciuto';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Sincronizzazione dei dati principali non riuscita Sincronizza il tipo di veicolo.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Sincronizzazione dei dati principali non riuscita Sincronizzare il veicolo.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Sincronizzazione dati anagrafici fallita Sincronizzare l\'impianto.';
_fc_props.master_data_sync_failed_data_base_connection = 'La sincronizzazione dei dati principali non è riuscita a connettersi al database.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Errore durante la convalida dell\'autorizzazione del token al portatore delle credenziali Master Data Connex non sufficiente';
_fc_props.settings.back_date_arrive_job = 'Lavoro di arrivo con data arretrata';
_fc_props.device_notifications = 'Notifiche';
_fc_props.loading_number = 'Numero di caricamento';
_fc_props.leftover_concrete_volume_temp_age = 'Volume residuo={0}, Temp={1}, Età={2}';
_fc_props.total_ordered = 'Quantità totale ordinata';
_fc_props.total_ticketed = 'Consegnato ordinato';
_fc_props.load_number = 'Numero di caricamento';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Il calcestruzzo residuo minimo deve essere riempito e deve essere maggiore di {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Errore durante la convalida dei dati anagrafici. Le credenziali non possono essere le stesse.';
_fc_props.use_aws_for_talk = 'Utilizza AWS per Talk';
_fc_props.air_readings_not_supported = 'Letture aeree non supportate';
_fc_props.air_not_supported = 'Aria non supportata';
_fc_props.mix_not_supported = 'Miscela non supportata';
_fc_props.air_default_and_none_not_supported = 'Impossibile selezionare predefinito e non supportato allo stesso tempo!';
_fc_props.notifications_enabled = 'Notifiche abilitate';
_fc_props.prevent_status_change = 'Prevenire il cambiamento di stato';
_fc_props.message_to_interface = 'Messaggio all\'interfaccia';
_fc_props.prevent_wrong_plant = 'Prevenire la pianta sbagliata';
_fc_props.previous_tickets = 'Biglietti precedenti';
_fc_props.audit_ticket_number = 'Numero del biglietto';
_fc_props.audit_date_created = 'Data di creazione';
_fc_props.audit_vehicle_number = 'Numero del veicolo';
_fc_props.cloud_dvir__table_ok = 'Sicuro da usare';
_fc_props.cloud_dvir__table_needs_repair = 'Non sicuro da usare';
_fc_props.missing_air_calibration = 'Nessun dato di calibrazione dell\'aria';
_fc_props.reported_defects = 'Difetti segnalati';
_fc_props.address_latitude = 'Indirizzo Latitudine';
_fc_props.address_longitude = 'Longitudine dell\'indirizzo';
_fc_props.routing_latitude = 'Latitudine di percorso';
_fc_props.routing_longitude = 'Longitudine del percorso';
_fc_props.category_name_must_be_unique = 'Il nome della categoria deve essere univoco';
_fc_props.subcategory_name_must_be_unique = 'Il nome della sottocategoria deve essere univoco';
_fc_props.menu_settings_tracking_device = 'Dispositivo di localizzazione';
_fc_props.menu_settings_title_tracking_device = 'Dispositivo di localizzazione';
_fc_props.menu_settings_title_personal_device_registration = 'Registrazione del dispositivo personale';
_fc_props.registration_code = 'Codice di registrazione';
_fc_props.creation_date = 'Creazione';
_fc_props.expiration_date = 'Scadenza';
_fc_props.trackitadmin_personal_device_registration = 'Registrazione del dispositivo personale';
_fc_props.create_registration_code = 'Aggiungi il codice di registrazione';
_fc_props.remove_registration_code = 'Rimuovi il codice di registrazione';
_fc_props.add_a_registration_code = 'Aggiungi un codice di registrazione';
_fc_props.select_number_of_registration_codes_to_generate = 'Seleziona il numero di codici di registrazione che desideri generare.';
_fc_props.registration_code_count = 'Conteggio codici di registrazione';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Sei sicuro di voler eliminare il codice registrato selezionato?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Sei sicuro di voler eliminare i codici registrati selezionati?';
_fc_props.add_personal_device = 'Aggiungi dispositivo personale';
_fc_props.menu_settings_title_personal_device = 'Dispositivo personale';
_fc_props.cloud_dvir__approval_critical_alert = 'Il veicolo ha un difetto critico. Non può essere contrassegnato come sicuro da usare.';
_fc_props.export_registration_codes = 'Codici di registrazione dell\'esportazione';
_fc_props.download_format = 'Scarica il formato';
_fc_props.specify_download_format_x = 'Specifica il formato di download per esportare i codici di registrazione {0}';
_fc_props.personal_device_download = 'Scaricamento';
_fc_props.add_personal_device_input_message = 'Inserisci un ID dispositivo personale di seguito. La scadenza verrà applicata automaticamente dopo il salvataggio';
_fc_props.error_creating_personal_device = 'Errore durante la creazione di un dispositivo personale';
_fc_props.device_already_exists = 'Il dispositivo personale esiste già';
_fc_props.export = 'Esportare';
_fc_props.error_can_not_change_connex_eid = 'Impossibile modificare l\'eid connex poiché è attualmente utilizzato per i dati master';
_fc_props.remove_personal_device = 'Rimuovi dispositivo personale';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Sei sicuro di voler eliminare il dispositivo personale selezionato?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Vuoi eliminare i dispositivi personali selezionati?';
_fc_props.error_deleting_personal_device = 'Errore durante l\'eliminazione del dispositivo personale';
_fc_props.customerid = 'identificativo del cliente';
_fc_props.customername = 'Nome del cliente';
_fc_props.ordernumber = 'Numero d\'ordine';
_fc_props.orderdescription = 'Descrizione dell\'ordine';
_fc_props.locationid = 'Identificativo della posizione';
_fc_props.locationdescription = 'Descrizione della posizione';
_fc_props.productid = 'Codice prodotto';
_fc_props.productdescription = 'Descrizione del prodotto';
_fc_props.vehicleid = 'Identificativo del veicolo';
_fc_props.vehicledescription = 'Descrizione del veicolo';
_fc_props.ticketnumber = 'Biglietto n.';
_fc_props.ticketdate = 'Data/ora del biglietto';
_fc_props.drivername = 'Nome del conducente';
_fc_props.arriveregion = 'Regione di arrivo (ORA)';
_fc_props.leaveregion = 'Lascia la regione (TIME)';
_fc_props.regionduration = 'Durata della regione';
_fc_props.menu_reports_title_prevailing_wage = 'Salario prevalente';
_fc_props.slump_calibration_x_not_found_for_y = 'Calibrazione del crollo "{0}" non trovata per il ticket {1}';
_fc_props.slump_calibration_not_found = 'Calibrazione slump non trovata';
_fc_props.slump_calibration_not_found_description = 'Avviso quando la calibrazione dello slump non viene trovata';
_fc_props.error_getting_personal_device_list = 'Errore durante il recupero dell\'elenco dei dispositivi personali';
_fc_props.batch_summary_date_range_note = '*NOTA: questo rapporto è limitato a 3 giorni se non vengono applicati filtri. L\'intervallo di date può arrivare fino a 31 giorni se filtrato per Attrezzatura (massimo 10), Impianto (massimo 1), Prodotto (massimo 1) o Lavoro (massimo 1)';
_fc_props.select_registration_code_expiration_time_days = 'Seleziona il numero di giorni fino alla scadenza dei codici di registrazione, quindi fai clic su Salva.';
_fc_props.add_personal_device_expiration = 'Scadenza (in giorni)';
_fc_props.cloud_dvir__vehicle_no = 'Numero del veicolo';
_fc_props.cloud_dvir__trailer_no = 'Rimorchio n.';
_fc_props.cloud_dvir__vehicle = 'Veicolo';
_fc_props.cloud_dvir__trailer = 'Rimorchio';
_fc_props.cloud_dvir__truck_number_label = 'Numero del camion';
_fc_props.cloud_dvir__trailer_number = 'Numero del rimorchio';
_fc_props.cloud_dvir__has_trailer = 'Non guidare con un rimorchio';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Inserisci qui il numero del tuo rimorchio';
_fc_props.work_order = 'Ordine di lavoro';
_fc_props.unable_to_determine_slump = 'Impossibile determinare il crollo';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Determinare le condizioni generali del rimorchio';
_fc_props.defaultTrailer = 'Trailer predefinito';
_fc_props.trailer_type_column_billable = 'Fatturabile';
_fc_props.trailer_type_column_notes = 'Appunti';
_fc_props.trailer_type_column_date = 'Data';
_fc_props.trailer_type_column_order = 'Ordine';
_fc_props.trailer_type_column_ticket = 'Biglietto';
_fc_props.trailer_type_column_project = 'Progetto';
_fc_props.trailer_type_column_customer = 'Cliente';
_fc_props.trailer_type_column_location = 'Posizione';
_fc_props.trailer_type_column_vehicle_type = 'Tipo di veicolo';
_fc_props.trailer_type_column_product = 'Prodotto';
_fc_props.trailer_type_action_rebill = 'Rifatturare';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Sincronizzazione dati master non riuscita, non è stato impostato alcun tipo di apparecchiatura predefinita';
_fc_props.trailer_type = 'Tipo di rimorchio';
_fc_props.trailer_type_is_required = 'È richiesto il tipo di rimorchio';
_fc_props.trailer_number_already_exists = 'Il numero del trailer esiste già';
_fc_props.plate_number_already_exists = 'Il numero di targa esiste già';
_fc_props.trailer_vin_already_exists = 'Il trailer Vin esiste già';
_fc_props.trailer_does_not_exist = 'Il trailer non esiste';
_fc_props.trailer_number_is_required = 'Il numero del rimorchio è obbligatorio';
_fc_props.plate_number_is_required = 'Il numero di targa è obbligatorio';
_fc_props.plate_number = 'Numero di targa';
_fc_props.menu_settings_title_trailer = 'Rimorchio';
_fc_props.add_trailer = 'Aggiungi trailer';
_fc_props.edit_trailer = 'Modifica trailer';
_fc_props.menu_settings_title_equipment = 'Attrezzatura';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignora i nuovi ticket da Command Cloud più vecchi di X minuti';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignora i nuovi ticket da Command Cloud con data e ora di invio più vecchie di X minuti (l\'impostazione predefinita è vuoto e disattivato)';
_fc_props.edit_load_card = 'Modifica scheda di carico';
_fc_props.default_layout = 'Layout predefinito';
_fc_props.load_card_empty_fields = 'Errore durante il salvataggio della scheda di carico: è necessario selezionare uno o più campi.';
_fc_props.trailers = 'Rimorchi';
_fc_props.error_failed_to_save_data = 'Errore: impossibile salvare i dati';
_fc_props.slump_increased_plasticizer_added_question = 'Quanto plastificante è stato aggiunto?';
_fc_props.add_trailer_type = 'Aggiungi tipo di rimorchio';
_fc_props.menu_settings_title_trailer_type = 'Tipo di rimorchio';
_fc_props.edit_trailer_type = 'Modifica tipo di trailer';
_fc_props.single_use_codes = 'Codici monouso';
_fc_props.multiple_use_codes = 'Codici multiuso';
_fc_props.trailer_type_description_is_required = 'La descrizione del tipo di rimorchio è obbligatoria';
_fc_props.trailer_saved = 'Trailer salvato';
_fc_props.cloud_dvir__trailer_only_dvir = 'Solo rimorchio DVIR';
_fc_props.choose_file = 'Scegli file';
_fc_props.no_file_chosen = 'Nessun file scelto';
_fc_props.please_choose_file = 'Si prega di scegliere il file';
_fc_props.no_filters = 'Nessun filtro selezionato';
_fc_props.remaining_defects = 'Difetti rimanenti';
_fc_props.load_more = 'Carica altro';
_fc_props.pagination_label = '{0}-{1} di {2}';
_fc_props.extend = 'Estendere';
_fc_props.cloud_dvir__notes_or_photo_required = 'Sono richiesti appunti o una foto.';

export default _fc_props;
