const _fc_props = {};
_fc_props.ante_meridiem = 'prije podne';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Pokušajte ponovo';
_fc_props.default = 'Zadano';
_fc_props.origin = 'Podrijetlo';
_fc_props.loading = 'Učitavam';
_fc_props.list = 'Popis';
_fc_props.send = 'Poslati';
_fc_props.error = 'Greška';
_fc_props.save = 'Uštedjeti';
_fc_props.delete = 'Izbrisati';
_fc_props.message = 'Poruka';
_fc_props.logout = 'Odjavite se';
_fc_props.add = 'Dodati';
_fc_props.remove = 'Ukloniti';
_fc_props.done = 'Gotovo';
_fc_props.other = 'Ostalo';
_fc_props.interval = 'Interval';
_fc_props.time = 'Vrijeme';
_fc_props.speed = 'Ubrzati';
_fc_props.longitude = 'Geografska dužina';
_fc_props.latitude = 'Geografska širina';
_fc_props.x_minutes = '{0} min';
_fc_props.general = 'Općenito';
_fc_props.messages = 'Poruke';
_fc_props.gps_fixes = 'GPS popravci';
_fc_props.device_id = 'ID uređaja';
_fc_props.vehicle = 'Vozilo';
_fc_props.employee = 'Zaposlenik';
_fc_props.version = 'Verzija';
_fc_props.statuses = 'Statusi';
_fc_props.edit = 'Uredi';
_fc_props.cancel = 'Otkazati';
_fc_props.yes = 'Da';
_fc_props.none = 'Nijedan';
_fc_props.never = 'Nikada';
_fc_props.name = 'Ime';
_fc_props.true = 'Pravi';
_fc_props.status = 'Status';
_fc_props.distance = 'Udaljenost';
_fc_props.ok = 'U redu';
_fc_props.login = 'Prijaviti se';
_fc_props.next = 'Sljedeći';
_fc_props.menu = 'Jelovnik ';
_fc_props.not_available = 'N/A';
_fc_props.back = 'leđa';
_fc_props.select = 'Izaberi';
_fc_props.dispatch = 'Otpremanje';
_fc_props.messaging = 'Razmjena poruka';
_fc_props.plants = 'Bilje';
_fc_props.last_plants = 'Posljednje biljke';
_fc_props.vehicle_types = 'Vrste vozila';
_fc_props.employee_types = 'Vrste zaposlenika';
_fc_props.employee_groups = 'Grupe zaposlenika';
_fc_props.equipment_groups = 'Grupe opreme';
_fc_props.job = 'Posao';
_fc_props.order = 'Narudžba';
_fc_props.ticket = 'ulaznica #';
_fc_props.small = 'Mali';
_fc_props.medium = 'Srednji';
_fc_props.large = 'Velika';
_fc_props.reports = 'Izvještaji';
_fc_props.payroll = 'Platni spisak';
_fc_props.support = 'Podrška';
_fc_props.username = 'Korisničko ime';
_fc_props.all = 'svi';
_fc_props.question = 'Pitanje';
_fc_props.select_employee = 'Odaberite Zaposlenik';
_fc_props.phone = 'Telefon';
_fc_props.january = 'siječnja';
_fc_props.february = 'veljača';
_fc_props.march = 'ožujak';
_fc_props.april = 'travanj';
_fc_props.may = 'svibanj';
_fc_props.june = 'lipanj';
_fc_props.july = 'srpanj';
_fc_props.august = 'kolovoz';
_fc_props.september = 'rujan';
_fc_props.october = 'listopad';
_fc_props.november = 'studeni';
_fc_props.december = 'prosinac';
_fc_props.are_you_sure_you_want_to_delete = 'Jeste li sigurni da želite izbrisati';
_fc_props.unknown = 'nepoznato';
_fc_props.select_all = 'Odaberi sve';
_fc_props.equipment = 'Oprema';
_fc_props.region = 'Regija';
_fc_props.no_data = 'Nema podataka';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Adresa';
_fc_props.retrieving_address = 'Dohvaćanje adrese...';
_fc_props.address_not_found = 'Adresa nije pronađena';
_fc_props.active_time = 'Aktivno vrijeme';
_fc_props.destination_time = 'Vrijeme odredišta';
_fc_props.complete_time = 'Potpuno vrijeme';
_fc_props.status_report = 'Izvješće o statusu';
_fc_props.export_report = 'Izvješće o izvozu';
_fc_props.download_report = 'Preuzmite izvješće';
_fc_props.view_report = 'Pogledaj izvještaj';
_fc_props.duration = 'Trajanje';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Datum/vrijeme početka';
_fc_props.select_start_date = 'Odaberite Datum početka';
_fc_props.end_date_time = 'Datum/vrijeme završetka';
_fc_props.select_end_date = 'Odaberite Datum završetka';
_fc_props.trip_time = 'Vrijeme putovanja';
_fc_props.travel_time = 'Vrijeme putovanja';
_fc_props.description = 'Opis';
_fc_props.map_controls = 'Kontrole karte';
_fc_props.road = 'Cesta';
_fc_props.aerial = 'Zračna';
_fc_props.hybrid = 'Hibrid';
_fc_props.trip = 'Putovanje';
_fc_props.map = 'Karta';
_fc_props.plant = 'Biljka';
_fc_props.select_date = 'Odaberite Datum';
_fc_props.apply = 'Prijavite se';
_fc_props.save_settings = 'Spremi postavke';
_fc_props.false = 'Netočno';
_fc_props.delete_confirmation = 'Brisanje potvrde';
_fc_props.last_name = 'Prezime';
_fc_props.none_selected = 'Ništa nije odabrano';
_fc_props.start_date = 'Početni datum';
_fc_props.group_by = 'Grupiraj po';
_fc_props.equipment_employee = 'Oprema / Zaposlenik';
_fc_props.inactive = 'Neaktivan';
_fc_props.active = 'Aktivan';
_fc_props.note_checking_may_affect_performance = 'Napomena: Provjera može utjecati na performanse';
_fc_props.show_distancefuel_used = 'Prikaži udaljenost/potrošeno gorivo';
_fc_props.mins = 'min';
_fc_props.threshold_time = 'Vrijeme praga';
_fc_props.include_voided_tickets = 'Uključuje poništene ulaznice';
_fc_props.note_all_times_listed_are_in_minutes = 'Napomena: Sva navedena vremena su u minutama';
_fc_props.please_select_a_job_order_or_equipment = 'Odaberite posao, narudžbu, postrojenje, opremu ili zaposlenika';
_fc_props.device = 'Uređaj';
_fc_props.select_equipment = 'Odaberite Oprema';
_fc_props.all_equipment_types = 'Sve vrste opreme';
_fc_props.units = {};
_fc_props.units.liters = 'Litara';
_fc_props.type = 'Tip';
_fc_props.no_data_available = 'Nema dostupnih podataka';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Udaljenost i gorivo po regijama';
_fc_props.menu_settings_title_settings = 'Postavke';
_fc_props.menu_settings_title_alerts = 'Upozorenja';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Oprema';
_fc_props.menu_settings_title_hotspots_regions = 'Vruće točke i regije';
_fc_props.days = '{0} dan(a)';
_fc_props.home = 'Dom';
_fc_props.find = 'Pronaći';
_fc_props.close_this_panel = 'zatvorite ovu ploču';
_fc_props.filters = 'Filtri';
_fc_props.start = 'Početak';
_fc_props.end = 'Kraj';
_fc_props.location_details = 'Detalji lokacije';
_fc_props.first_name = 'Ime';
_fc_props.material_per_hour = 'Materijal po satu';
_fc_props.equipment_pound = 'Oprema #';
_fc_props.equipment_type = 'Vrsta opreme';
_fc_props.options = 'Mogućnosti';
_fc_props.zoom_on_cursor_position = 'Zumiraj na poziciji kursora';
_fc_props.display_equipment_identifier = 'Identifikator opreme za prikaz';
_fc_props.view_equipment = 'Pogledajte opremu';
_fc_props.view_hotspots = 'Prikaži Hotspots';
_fc_props.view_regions = 'Pregledajte regije';
_fc_props.use_standard_filters = 'Koristite standardne filtere';
_fc_props.home_plant = 'Kućna biljka';
_fc_props.last_plant = 'Posljednja biljka';
_fc_props.equip_types = 'Opremiti. Vrste';
_fc_props.equip_groups = 'Opremiti. Grupe';
_fc_props.orders = 'Narudžbe';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Koristite filter opreme';
_fc_props.all_selected = 'Sve Odabrano';
_fc_props.of = 'Od';
_fc_props.your_map_session_has_expired = 'Vaša sesija na karti je istekla, osvježite stranicu.';
_fc_props.equipment_short = 'Opremiti.';
_fc_props.job_info_for = 'Informacije o poslu za';
_fc_props.order_info_for = 'Informacije o narudžbi za';
_fc_props.ticket_info_for_vehicle = 'Informacije o ulaznicama za vozilo';
_fc_props.inbox = 'Inbox';
_fc_props.outbox = 'Outbox';
_fc_props.alerts = 'Upozorenja';
_fc_props.critical = 'Kritično';
_fc_props.important = 'Važno';
_fc_props.informational = 'Informativno';
_fc_props.view_routes = 'Pregledajte rute';
_fc_props.hours_size = 'Veličina sati';
_fc_props.used_count = 'Rabljeni Count';
_fc_props.last_login = 'Zadnja prijava';
_fc_props.last_vehicle = 'Posljednje vozilo';
_fc_props.regions = 'Regije';
_fc_props.driver = 'Vozač';
_fc_props.js_delete = 'Izbrisati';
_fc_props.move = 'Potez';
_fc_props.edit_hotspot = 'Uredi Hotspot';
_fc_props.adjust_shape = 'Prilagodite oblik';
_fc_props.edit_region = 'Uredi regiju';
_fc_props.enter_a_start_address = 'Unesite početnu adresu';
_fc_props.select_a_start_hotspot = 'Odaberite početnu Hotspot';
_fc_props.failed_to_find_start_hotspot = 'Nije uspjelo pronaći startnu Hotspot.';
_fc_props.invalid_latitudelongitude = 'Nevažeća zemljopisna širina/dužina';
_fc_props.enter_an_end_address = 'Unesite krajnju adresu';
_fc_props.select_an_end_hotspot = 'Odaberite krajnju žarišnu točku';
_fc_props.failed_to_find_end_hotspot = 'Nije uspjelo pronaći krajnju žarišnu točku.';
_fc_props.print = 'Ispis';
_fc_props.these_directions_are_for_informational_purposes = 'Ove upute služe samo u informativne svrhe. Ne daje se nikakva jamstva za njihovu potpunost ili točnost. Građevinski projekti, promet ili drugi događaji mogu uzrokovati da se stvarni uvjeti razlikuju od ovih rezultata.';
_fc_props.enter_an_address = 'Unesite adresu';
_fc_props.select_a_hotspot = 'Molimo odaberite Hotspot';
_fc_props.failed_to_find_hotspot = 'Hotspot nije pronađen.';
_fc_props.select_a_vehicle = 'Molimo odaberite vozilo';
_fc_props.failed_to_find_vehicle = 'Nije uspjelo pronaći vozilo.';
_fc_props.failed_to_find_address = 'Pronalaženje adrese nije uspjelo';
_fc_props.failed_to_find_vehicles = 'Nije uspjelo pronaći vozila u kriterijima pretraživanja.';
_fc_props.get_address = 'Dobiti adresu';
_fc_props.results_for = 'Rezultati za';
_fc_props.error_retrieving_results = 'Pogreška prilikom dohvaćanja rezultata';
_fc_props.no_results_found = 'Nema rezultata';
_fc_props.address_is_required = 'Adresa je obavezna';
_fc_props.city_is_required = 'Grad je obavezan';
_fc_props.set_location = 'Postavite lokaciju';
_fc_props.set_area_of_interest = 'Postavite područje interesa';
_fc_props.center_map_here = 'Središnja karta ovdje';
_fc_props.zoom_to_street_level = 'Zumirajte na razinu ulice';
_fc_props.set_start = 'Postavite Start';
_fc_props.set_end = 'Postavite kraj';
_fc_props.delete_node = 'Izbriši čvor';
_fc_props.create_a_hotspot = 'Stvorite Hotspot';
_fc_props.create_a_region = 'Napravite regiju';
_fc_props.toggle_shapes = 'Prebaci oblike';
_fc_props.refresh_map = 'Osvježi kartu';
_fc_props.more_options = 'Više mogućnosti';
_fc_props.recenter_map = 'Povratna karta';
_fc_props.zoom_to_vehicle = 'Zumiraj na vozilo';
_fc_props.apply_filters = 'Primijenite filtere';
_fc_props.grid_view = 'Prikaz rešetke';
_fc_props.list_view = 'Prikaz popisa';
_fc_props.click_to_sort = 'Kliknite za sortiranje';
_fc_props.message_history = 'Povijest poruka';
_fc_props.equipment_types = 'Vrste opreme';
_fc_props.send_message = 'Pošalji poruku';
_fc_props.last_load = 'Posljednje opterećenje';
_fc_props.last_load_on = 'Posljednje učitavanje uključeno';
_fc_props.manual_logout = 'Ručna odjava';
_fc_props.manual_login = 'Ručna prijava';
_fc_props.no_employees_logged_in = 'Nema prijavljenih zaposlenika';
_fc_props.select_phone = 'Odaberite Telefon';
_fc_props.you_must_select_a_phone = 'Morate odabrati telefon, opremu i zaposlenika';
_fc_props.are_you_sure_you_want_to_logout = 'Jeste li sigurni da se želite odjaviti';
_fc_props.fix_time = 'Popravi vrijeme';
_fc_props.to_next_fix = 'na sljedeći popravak';
_fc_props.minutes_to_next_fix = 'Minute do sljedećeg popravka';
_fc_props.liters = 'Litara';
_fc_props.destination = 'Odredište';
_fc_props.return = 'Povratak';
_fc_props.product = 'Proizvod';
_fc_props.no_records_found = 'Nije pronađen nijedan zapis.';
_fc_props.vehicle_type = 'tip vozila';
_fc_props.view_in_map = 'Prikaži sve na karti';
_fc_props.uploads = 'Prijenosi';
_fc_props.upload_csv_file = 'Prenesite CSV datoteku';
_fc_props.permanent = 'Trajno';
_fc_props.expire_time = 'Vrijeme isteka';
_fc_props.please_select_x_for_tickets_marked_active = 'Odaberite {0} za ulaznice označene kao AKTIVNE';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Više ulaznica označenih AKTIVNO za istu {0}';
_fc_props.failure_connecting_to_servlet = 'Neuspjeh pri povezivanju sa servletom.';
_fc_props.status_time_summary = 'Sažetak vremena statusa';
_fc_props.view_in_map_one = 'Pogledaj na karti';
_fc_props.question_report = 'Izvješće o pitanju';
_fc_props.end_date = 'Datum završetka';
_fc_props.trailer_number = 'Broj prikolice';
_fc_props.region_panel = 'Regionalna ploča';
_fc_props.hotspot_panel = 'Hotspot Panel';
_fc_props.shape = 'Oblik';
_fc_props.circle = 'Krug';
_fc_props.polygon = 'Poligon';
_fc_props.click_the_map_to_set_the_location = 'Kliknite kartu za postavljanje željene lokacije.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Kliknite kartu da biste postavili centar žarišne točke.';
_fc_props.click_the_map_to_set_the_region_center = 'Kliknite kartu da biste postavili središte regije.';
_fc_props.click_and_drag_points = 'Kliknite i povucite točke da biste uredili oblik. Kliknite SPREMI za spremanje promjena.';
_fc_props.size = 'Veličina';
_fc_props.are_you_sure_you_want_to_move_this = 'Jeste li sigurni da ovo želite premjestiti';
_fc_props.are_you_sure_you_want_to_delete_this = 'Jeste li sigurni da želite ovo izbrisati';
_fc_props.please_enter_a_valid_size = 'Unesite valjanu veličinu';
_fc_props.failed_to_save = 'Spremanje nije uspjelo.';
_fc_props.failed_to_move = 'Premještanje nije uspjelo.';
_fc_props.failed_to_delete = 'Brisanje nije uspjelo.';
_fc_props.x_hours = '{0} sati';
_fc_props.hide_hotspots = 'Sakrij žarišne točke';
_fc_props.show_regions = 'Prikaži regije';
_fc_props.show_hotspots = 'Prikaži Hotspots';
_fc_props.hide_regions = 'Sakrij regije';
_fc_props.numeric = 'Numerički';
_fc_props.message_type = 'Vrsta poruke';
_fc_props.text = 'Tekst';
_fc_props.yes_no = 'Da ne';
_fc_props.responses = 'Odgovori';
_fc_props.disabled = 'Onemogućeno';
_fc_props.group_name = 'Grupno ime';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Jeste li sigurni da želite izbrisati format \'{0}\'?';
_fc_props.phone_number = 'Broj telefona';
_fc_props.interface_pound = 'Sučelje #';
_fc_props.field = 'Polje';
_fc_props.default_label = 'Zadana oznaka';
_fc_props.custom_label = 'Prilagođena oznaka';
_fc_props.you_must_enter_a_label = 'Morate unijeti oznaku.';
_fc_props.enabled = 'Omogućeno';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Integracija ulaznica';
_fc_props.settings.geofence_off_open_hotspots = 'Geofence off otvorene žarišne točke';
_fc_props.settings.associate_open_hotspots_wticket = 'Otvorene pristupne točke suradnika s ulaznicom';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Dopustite geoograđivanje otvorenih žarišnih točaka ili samo žarišnih točaka povezanih s vozačkom kartom';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Treba li ili ne povezivati otvorene žarišne točke s ulaznicama kada vozilo uđe u njih';
_fc_props.settings.void_ticket_without_destination = 'Poništava kartu bez odredišta';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'ako je karta primljena dok je druga aktivna, ako aktivna karta nema odredište karta će se poništiti.';
_fc_props.settings.autocreate_hotspots = 'Automatski stvori žarišne točke';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Treba li automatski stvoriti žarišne točke s odredišne adrese kada se primi karta';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Automatsko aktiviranje ulaznice po primitku';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Je li ulaznica aktivna kada je TrackIt primi (zadano \'true\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Automatsko zatvaranje aktivne ulaznice nakon primitka minuta';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Koliko dugo trenutna aktivna ulaznica mora biti aktivna prije automatskog zatvaranja (zadano \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Ulaznica Maks. Aktivirajte dobne minute';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Koliko u minutama karta može biti stara kada je aktivirate u TrackIt-u (zadano \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Max Hotspot neiskorištenih dana';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Postavlja maksimalni broj dana za koje Hotspot može biti neiskorišten i još uvijek oživljen';
_fc_props.settings.radius_multiplier = 'Multiplikator radijusa';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'S čim pomnožiti radijus kada se karta primi iz dispečerskog sustava';
_fc_props.settings.ticket_message_date_format = 'Format datuma poruke o ulaznici';
_fc_props.am = 'prijepodne';
_fc_props.pm = 'poslijepodne';
_fc_props.settings.ticket_message = 'Poruka o ulaznici';
_fc_props.settings.add_ticket_element = 'Dodajte element ulaznice';
_fc_props.driver_performance = 'Performanse vozača';
_fc_props.update_failed_can_not_connect = 'Ažuriranje nije uspjelo: Nije moguće povezati se sa servletom.';
_fc_props.unknown_error = 'Nepoznata pogreška';
_fc_props.selected = 'Odabran';
_fc_props.web_service = 'Web usluga';
_fc_props.error_processing_request = 'Pogreška u obradi zahtjeva.';
_fc_props.email_address = 'Email adresa';
_fc_props.id = 'iskaznica';
_fc_props.last_used = 'Zadnje korišteno';
_fc_props.error_invalid_vehiclenum = 'Greška Nevažeći broj vozila';
_fc_props.error_vehicle_is_not_logged_in = 'Pogreška: Vozilo nije prijavljeno.';
_fc_props.users = 'Korisnici';
_fc_props.you_dont_have_access_to_this_page = 'Nemate pristup za pregled ove stranice. Za pristup se obratite administratoru sustava.';
_fc_props.previous_week = 'Prošli tjedan';
_fc_props.status_breakdown = 'Raspad statusa';
_fc_props.select_plant_or_employee_group = 'Morate odabrati ili postrojenje ili grupu zaposlenika.';
_fc_props.cannot_select_plant_and_employee_group = 'Ne možete odabrati i postrojenje i grupu zaposlenika.';
_fc_props.odometer_must_be_a_number = 'Početni odometar mora biti broj';
_fc_props.ending_odometer_must_be_a_number = 'Završni odometar mora biti broj';
_fc_props.beginning_hour_meter_must_be_a_number = 'Mjerač početnih sati mora biti broj';
_fc_props.ending_hour_meter_must_be_a_number = 'Mjerač radnih sati mora biti broj';
_fc_props.error_loading_status_change_page = 'Pogreška pri učitavanju stranice za promjenu statusa.';
_fc_props.x_is_an_invalid_time_format = '{0} je nevažeći format vremena, mora biti GGGG-MM-DD HH:MM:SS';
_fc_props.x_for_schedule_login = '{0} za zakazivanje prijave';
_fc_props.x_for_schedule_logout = '{0} za zakazivanje odjave';
_fc_props.x_for_actual_login = '{0} za stvarnu prijavu';
_fc_props.x_for_actual_logout = '{0} za stvarnu odjavu';
_fc_props.by_refreshing_the_time_card_detail = 'Osvježavanjem pojedinosti vremenske kartice izgubit ćete sve promjene, nastaviti?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Jeste li sigurni da želite izbrisati {0}?';
_fc_props.settings.command_mobile_ticket_url = 'URL za naredbu mobilne karte';
_fc_props.year = 'Godina';
_fc_props.make = 'Napraviti';
_fc_props.model = 'Model';
_fc_props.settings.command_mobile_ticket_password = 'Command Mobile Ticket Password';
_fc_props.settings.command_mobile_ticket_auth_token = 'Command Mobile Ticket Auth Token';
_fc_props.target = 'Cilj';
_fc_props.number_of_digits = 'Broj znamenki';
_fc_props.number_of_integer_digits = 'Broj cijelih znamenki';
_fc_props.number_of_fractional_digits = 'Broj razlomaka';
_fc_props.search = 'traži';
_fc_props.settings.command_mobile_ticket_date_order = 'Naredba mobilne karte Datum Redoslijed';
_fc_props.settings.command_mobile_ticket_date_length = 'Command Mobile Ticket Datum Dužina';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command Mobile Ticket Date Separator';
_fc_props.settings.command_mobile_ticket_slash = '/ (kosa crta)';
_fc_props.settings.command_mobile_ticket_dash = '- (crtica)';
_fc_props.settings.command_mobile_ticket_period = '. (razdoblje)';
_fc_props.settings.command_mobile_ticket_space = '(prostor)';
_fc_props.settings.command_mobile_ticket_time_length = 'Command Mobile Ticket Time Length';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command Mobile Ticket Time Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (dvorijevo)';
_fc_props.settings.command_mobile_ticket_time_format = 'Command Mobile Ticket Time Format';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Koristite broj sučelja';
_fc_props.mechanic = 'Mehaničar';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Interval ankete (sekunde)';
_fc_props.driving_time = 'Vrijeme vožnje';
_fc_props.find_near_plants = 'Pronađite biljke u blizini';
_fc_props.please_create_hot_spot_first = 'Molimo prvo stvorite hotspot';
_fc_props.please_enter_hot_spot_description = 'Unesite opis vruće točke';
_fc_props.please_select_a_hot_spot_type = 'Molimo odaberite vrstu vruće točke';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Pošaljite e-poštu vozaču';
_fc_props.directions_not_found = 'Nevažeća {0} adresa. Upute nisu pronađene.';
_fc_props.ws_user = 'Korisnik';
_fc_props.ws_service = 'Servis';
_fc_props.ws_method = 'Metoda';
_fc_props.ws_params = 'Params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Porijeklo i odredište';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Pomozite';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Koristite Alt broj zaposlenika';
_fc_props.settings.command_mobile_ticket_android_url = 'URL mobilne karte za Android';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Ticket Secret';
_fc_props.employee_x_has_no_logout_on_y = 'Zaposlenik {0} nema odjavu za smjenu koja počinje {1}.';
_fc_props.phone_number_has_duplicates = 'Telefonski brojevi imaju duple unose';
_fc_props.ounce = 'Unca';
_fc_props.water_extra = 'Voda ekstra';
_fc_props.always = 'Stalno';
_fc_props.work_types_must_be_unique = 'Vrste posla moraju biti jedinstvene';
_fc_props.manufacturer = 'Proizvođač';
_fc_props.need_to_enter_seconds = 'Potrebno je unijeti sekunde.';
_fc_props.copy = 'Kopirati';
_fc_props.create_in_existing_required = 'Za stvaranje roaming hotspota morate odabrati opciju Create in postojeće.';
_fc_props.break = 'Pauza';
_fc_props.add_export_data_type = 'Dodajte vrstu podataka za izvoz';
_fc_props.edit_export_data_type = 'Uredite vrstu podataka za izvoz';
_fc_props.delete_export_data_type = 'Izbriši vrstu podataka za izvoz';
_fc_props.export_data_type = 'Izvoz vrste podataka';
_fc_props.error_updating_export_data_type = 'Pogreška pri ažuriranju vrste podataka za izvoz';
_fc_props.settings.address_key = 'Ključ adrese';
_fc_props.settings.address_key_hover = 'Opcije ključa adrese';
_fc_props.settings.address_key_options = 'Opcije ključa adrese';
_fc_props.more_filter_options = 'Više opcija filtra';
_fc_props.unauthorized_mapit_app = 'Ova verzija MapIta nije ovlaštena, ažurirajte MapIt što je prije moguće.';
_fc_props.zello_timeout = 'Isteklo je vrijeme za kontaktiranje Zella.';
_fc_props.user_agreement_text = 'Prijavom pristajete na';
_fc_props.user_agreement = 'korisnički ugovor';
_fc_props.upload = 'Učitaj';
_fc_props.x_mins_left = 'Još {0} min';
_fc_props.continuous_location_tracking_colon = 'Kontinuirano praćenje lokacije:';
_fc_props.hired_hauler_cannot_register_device = 'Unajmljeni prijevoznik ne može registrirati novi uređaj.';
_fc_props.timecard_does_not_contain_shift = 'Kartica radnog vremena zaposlenika ne sadrži navedenu smjenu (Informacije o vremenskoj kartici).';
_fc_props.timecard_not_found = 'Kartica radnog vremena zaposlenika nije pronađena';
_fc_props.vehicle_must_be_assigned_for_clt = 'Vozilo mora biti dodijeljeno lokacijama staza.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Vozilu nije dodijeljeno kontinuirano praćenje bit će onemogućeno.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Izgubljena veza s EDC-om. Provjerite jesu li WiFi i/ili Bluetooth omogućeni.';
_fc_props.acknowledge_all = 'Priznati sve';
_fc_props.expand = 'Proširiti';
_fc_props.collapse = 'Kolaps';
_fc_props.expand_all = 'Proširi sve';
_fc_props.collapse_all = 'Sažmi sve';
_fc_props.last_update = 'Zadnje ažuriranje';
_fc_props.acknowledge_alert = 'Upozorenje za potvrdu';
_fc_props.acknowledge_message = 'Potvrdite poruku';
_fc_props.acknowledge_all_alerts = 'Potvrdite sva upozorenja';
_fc_props.acknowledge_all_messages = 'Potvrdi sve poruke';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Tolerancija mora biti valjani cijeli broj između -99 i 99 (oba uključena)';
_fc_props.gps_permission_required = 'Potrebno je dopuštenje za GPS';
_fc_props.permissions_required = 'Potrebna su dopuštenja';
_fc_props.change_user = 'Promjena korisnika';
_fc_props.account_not_registered_with_carrier = 'Ovaj račun nije registriran kod mobilnog operatera';
_fc_props.slump_loss = 'Gubitak pada';
_fc_props.slump_loss_help = 'Pomoć pri gubitku pada';
_fc_props.did_not_receive_any_edc_devices = 'Nisam dobio nijedan EDC uređaj.';
_fc_props.edc_record_not_found = 'EDC zapis nije pronađen';
_fc_props.disable_hotspot_prompt = 'Dodirnite "U redu" kada se od vas zatraži da onemogućite svoju pristupnu točku.';
_fc_props.edc_device_is_in_use = 'EDC {0} je u upotrebi.';
_fc_props.an_unknown_exception_has_occurred = 'Dogodila se nepoznata iznimka';
_fc_props.login_has_timed_out = 'Prijava je istekla';
_fc_props.use_paper_logs = 'Koristite papirne zapise';
_fc_props.eld_login_failed = 'Prijava na radno vrijeme nije uspjela';
_fc_props.setup_time_off_type = 'Postavite vrstu slobodnog vremena.';
_fc_props.view_load_properties = 'Pregledajte svojstva učitavanja';
_fc_props.couldnt_download_edc_firmware = 'Nije moguće preuzeti EDC firmver.';
_fc_props.password_incorrect = 'Lozinka nije točna';
_fc_props.edc_configuration_password_prompt = 'Zbog stanja ovog uređaja morate dati lozinku instalatera za konfiguraciju.';
_fc_props.edc_configuration_check_failed = 'Provjera valjanosti zapisa EDC poslužitelja nije uspjela. Molimo provjerite poruke o pogrešci za detalje.';
_fc_props.configuration_issues_prompt = 'Tijekom konfiguriranja odabranog EDC-a za navedeni kamion otkriveni su sljedeći problemi';
_fc_props.checking_password = 'Provjera lozinke';
_fc_props.reset_password_instructions_sent = 'Upute za poništavanje zaporke poslane su na adresu e-pošte {0}.';
_fc_props.reset_password_failed = 'Poništavanje lozinke nije uspjelo, pokušajte ponovno.';
_fc_props.forgot_password = 'Zaboravili ste lozinku?';
_fc_props.enter_valid_email = 'Nevažeća adresa e-pošte, unesite valjanu adresu e-pošte i pokušajte ponovno.';
_fc_props.issues_while_updating_edc_record = 'EDC je uspješno konfiguriran. Međutim, postoje problemi sa zapisima za EDC na TrackIt poslužitelju koji mogu uzrokovati da on ne funkcionira. Molimo nazovite podršku da ispravite informacije.';
_fc_props.sending = 'Slanje...';
_fc_props.minimum_ratio = 'Minimalni omjer';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Broj okretaja bubnja nije u rasponu';
_fc_props.probe_failure = 'Kvar sonde';
_fc_props.unknown_probe_data_failure = 'Nepoznati kvar podataka sonde';
_fc_props.no_loaded_status_meaning = 'Nema učitanog statusa';
_fc_props.no_depart_status_meaning = 'Nema statusa polaska';
_fc_props.no_arrive_status_meaning = 'Nema statusa dolaska';
_fc_props.no_begin_work_status_meaning = 'Nema statusa početka rada';
_fc_props.no_fully_mixed_status_meaning = 'Nema potpuno miješanog statusa';
_fc_props.no_begin_work_or_at_job_status = 'Statusi Početak rada ili Na poslu nisu prisutni';
_fc_props.driver_didnt_follow_procedure = 'Vozač nije slijedio proceduru (Ne usporava bubanj)';
_fc_props.probe_malfunction = 'Kvar sonde (unutarnji kvar, itd.)';
_fc_props.no_data_from_probe = 'Nema podataka iz sonde (gubitak podataka)';
_fc_props.missing_probe_calibration = 'Nema podataka o kalibraciji sonde';
_fc_props.probe_pressure_not_in_range = 'Tlak sonde nije u rasponu';
_fc_props.arrival = 'Dolazak';
_fc_props.small_load = 'MALO OPTEREĆENJE';
_fc_props.dry_slump = 'SUHO KOL';
_fc_props.probe_remixing_turns = 'Remiksanje';
_fc_props.turns_needed_to_remix = 'Dodana voda - potrebno je dodatno miješanje';
_fc_props.probe_remixing_finished = 'Remiksanje je završeno';
_fc_props.probe_battery_low_alert = 'Upozorenje na praznu bateriju sonde';
_fc_props.probe_battery_low_message = 'Baterija sonde je prazna';
_fc_props.probe_battery_low = 'Baterija sonde je prazna';
_fc_props.alert_when_probe_battery_low = 'Upozorenje kada je baterija sonde prazna';
_fc_props.probe_sensor_fatal_failure = 'Fatalni kvar senzora';
_fc_props.probe_thermistor_failure = 'Kvar termistora';
_fc_props.probe_sensor_stuck_in_concrete = 'Senzor zaglavio u betonu';
_fc_props.probe_sensor_over_weight = 'Senzor prekomjerne težine';
_fc_props.probe_batteries_low = 'Niska razina baterije';
_fc_props.no_probe_data = 'Nema podataka sonde:';
_fc_props.check_probe = 'Provjerite sondu';
_fc_props.service_probe = 'Servisna sonda';
_fc_props.below_x = 'Ispod {0}';
_fc_props.above_x = 'Iznad {0}';
_fc_props.probe_serial_number_invalid_format = 'Serijski broj sonde nije u važećem formatu kao što je prikazano u rezerviranom mjestu za polje';
_fc_props.talk_dnd_permission_prompt = 'Za razgovor je potrebno dopuštenje NE Ometaj.';
_fc_props.max = 'Maks';
_fc_props.status_changed_quickly = 'Status se prebrzo promijenio';
_fc_props.is_empty = 'Prazno je';
_fc_props.slump_increased_plasticizer_question = 'Je li dodan plastifikator?';
_fc_props.slump_increased_water_added_question = 'Koliko je vode bez mjerenja dodano?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Praćenje sonde i ciklusa isporuke nisu podržani zajedno na istom uređaju.';
_fc_props.no_data_from_sensor = 'Nema podataka sa senzora (gubitak podataka)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Vrsta sustava';
_fc_props.magnet_count = 'Broj magneta';
_fc_props.sensors_setup = 'Postavljanje senzora';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM zahtijeva postavljanje MAC adrese I/O kutije, vrste bubnja, vrste DCM i broja magneta.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Volumen ulaznica';
_fc_props.drs_current_volume = 'Trenutni volumen';
_fc_props.drs_current_water_cement_ratio = 'Trenutni omjer voda/cement';
_fc_props.drs_target_water_cement_ratio = 'Ciljani omjer voda/cement';
_fc_props.drs_total_revolutions = 'Ukupno';
_fc_props.drs_current_volume_lp_small = 'Trenutno';
_fc_props.drum_revolutions = 'Revolucije bubnjeva';
_fc_props.drs_total_revolutions_lp_small = 'Ukupno';
_fc_props.drs_charging_revolutions_lp_small = 'Punjenje';
_fc_props.drs_discharging_revolutions_lp_small = 'Pražnjenje';
_fc_props.drs_target_wc_lp_small = 'Target W/C';
_fc_props.drs_current_wc_lp_small = 'Trenutni W/C';
_fc_props.drs_ticketed_volume_lp_small = 'Ulaznice';
_fc_props.android_location_disclosure = 'TrackIt koristi finu i grubu lokaciju za prikupljanje podataka o lokaciji u pozadini čak i kada se ne koristi, kako bi se omogućilo automatsko postavljanje i vidljivost karte u stvarnom vremenu za slanje.';
_fc_props.android_location_usage = 'Otkrivanje lokacije';
_fc_props.end_pour_trigger_threshold = 'Prag okidača za kraj izlijevanja';
_fc_props.drs_wiring_error = 'Greška u ožičenju';
_fc_props.drs_wiring_pulse_error = 'Greška impulsa ožičenja';
_fc_props.drs_wiring_magnet_error = 'Greška magneta ožičenja';
_fc_props.drs_magnet_missing_error = 'Magnet Missing Error';
_fc_props.android_location_disclosure_3p = 'TrackIt prikuplja podatke o lokaciji u pozadini kako bi omogućio automatsko postavljanje statusa i vidljivost karte u stvarnom vremenu za otpremu. Kada je otpremnik isključen, lokacija se ne dijeli.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Odaberite ili Full MDM ili Trackit Remote Support';
_fc_props.move_up = 'Pomakni gore';
_fc_props.move_down = 'Pomicati prema dolje';
_fc_props.driver_not_found = 'Vozač nije pronađen.';
_fc_props.multiple = 'Višestruko';
_fc_props.water_meter_installed = 'Instaliran vodomjer';
_fc_props.delete_waypoint = 'Izbriši međutočku';
_fc_props.wash_sensor_installed = 'Instaliran senzor pranja';
_fc_props.washing_start_time = 'Vrijeme početka pranja';
_fc_props.end_washing = 'Završi pranje';
_fc_props.variable_rpms = 'Promjenjivi broj okretaja';
_fc_props.no_slump = 'Nema pada';
_fc_props.end_washing_duration = 'Završi trajanje pranja';
_fc_props.privacy_policy = 'Politika privatnosti';
_fc_props.privacy_policy_text = 'Pročitao sam i prihvaćam';
_fc_props.login_fail_accept_privacy_policy = 'Politika privatnosti mora biti prihvaćena da biste nastavili.';
_fc_props.rotation_sensor = 'Senzor rotacije';
_fc_props.pto_sensor = 'PTO senzor';
_fc_props.pto_sensor_installed = 'PTO senzor instaliran';
_fc_props.polarity_reversed = 'Obrnuti polaritet';
_fc_props.pto_sensor_type = 'Tip PTO senzora';
_fc_props.sensor_type = 'Tip senzora';
_fc_props.prompt_select_pto_sensor_type = 'Morate odabrati "Vrstu PTO senzora" ako ste odabrali "PTO senzor" kao glavni tip senzora.';
_fc_props.priming_turns_at_full_load = 'Primjenjivanje se okreće pri punom opterećenju';
_fc_props.priming_turns_at_ten_percent_load = 'Pranje se okreće pri 10% opterećenja';
_fc_props.weight_units = 'Jedinice težine';
_fc_props.settings.send_status = 'Pošalji status/lokaciju na Event Bridge';
_fc_props.volume_difference = 'Razlika u volumenu';
_fc_props.mobile_ticket_iframe_url = 'URL IFramea mobilne ulaznice';
_fc_props.mobile_ticket_user = 'Korisnik mobilne karte';
_fc_props.mobile_ticket_password = 'Lozinka za mobilne ulaznice';
_fc_props.current_truck_status = 'Trenutni status kamiona';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Pokreće se maksimalnim brojem okretaja';
_fc_props.alerts_by_type = 'Upozorenja po vrsti';
_fc_props.water_flow_meter = 'Mjerač protoka vode';
_fc_props.flow_meter_no_pulses_warning = 'Nema impulsa - upozorenje';
_fc_props.flow_meter_no_pulses_critical = 'Nema impulsa - kritično';
_fc_props.flow_meter_excessive_pulses = 'Prekomjerni pulsevi';
_fc_props.flow_meter_constantly_flowing = 'Stalno teče';
_fc_props.flow_meter_good = 'Dobro';
_fc_props.whats_new = 'Što ima novog';
_fc_props.whats_new_widget_text = 'Jeste li znali da ažuriramo TrackIt barem jednom mjesečno? Dobijte najnovija ažuriranja i savjete.';
_fc_props.get_the_scoop = 'Uzmi Scoop';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Poslovna analitika koja poboljšava vaš krajnji rezultat, sada uključena u TrackIt';
_fc_props.find_out_more = 'Saznaj više';
_fc_props.daily_breakdown = 'Dnevni pregled';
_fc_props.question_summary = 'Sažetak pitanja';
_fc_props.trip_summary = 'Sažetak putovanja';
_fc_props.manage_employees = 'Upravljajte zaposlenicima';
_fc_props.manage_hotspots = 'Upravljajte vrućim točkama';
_fc_props.manage_devices = 'Upravljanje uređajima';
_fc_props.manage_equipment = 'Upravljajte opremom';
_fc_props.manage_users = 'Upravljajte korisnicima';
_fc_props.manage_statuses = 'Upravljajte statusima';
_fc_props.manage_alerts = 'Upravljajte upozorenjima';
_fc_props.popular_report_links = 'Popularni linkovi izvješća';
_fc_props.popular_settings_links = 'Popularne veze za postavke';
_fc_props.sidemenu_settings = 'Postavke';
_fc_props.loads_per_driver = 'Opterećenja po vozaču';
_fc_props.please_select_application = 'Molimo odaberite barem jednu aplikaciju';
_fc_props.external_app_processing = 'Obrada na vanjskoj aplikaciji';
_fc_props.external_app_sent = 'Poslano na vanjsku aplikaciju';
_fc_props.external_app_received = 'Primljeno putem vanjske aplikacije';
_fc_props.wait_time_at_job = 'Vrijeme čekanja na poslu';
_fc_props.wait_time_at_plant = 'Vrijeme čekanja u tvornici';
_fc_props.loading_time = 'Vrijeme učitavanja';
_fc_props.unloading_time = 'Vrijeme istovara';
_fc_props.pre_post_trip = 'Prije/poslije putovanja';
_fc_props.update_thresholds = 'Ažurirajte pragove';
_fc_props.green = 'Zelena';
_fc_props.yellow = 'Žuta boja';
_fc_props.red = 'Crvena';
_fc_props.between = 'Između';
_fc_props.num_of_loads = 'Broj opterećenja';
_fc_props.num_of_drivers = 'Broj vozača';
_fc_props.status_meaning_not_setup = 'Status Značenje nije postavljeno';
_fc_props.or_less = 'Ili manje';
_fc_props.or_greater = 'Ili veće';
_fc_props.probe_self_diagnostic_state = 'Samodijagnostičko stanje sonde';
_fc_props.update_current_truck_status_settings = 'Ažurirajte trenutne postavke statusa kamiona';
_fc_props.default_behavior = 'Zadano (top 10 statusa)';
_fc_props.combine_similar_statuses = 'Kombinirajte iste statuse iz svih vrsta (petlje)';
_fc_props.use_other_category = 'Koristite kategoriju \'Ostalo\'';
_fc_props.selected_statuses = 'Odabrani statusi';
_fc_props.ideas = 'Ideje';
_fc_props.air_content = 'Sadržaj zraka';
_fc_props.air_measured_time = 'Zračno izmjereno vrijeme';
_fc_props.ten_max = '10 Maks';
_fc_props.loads_per_plant = 'Opterećenja po biljci';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Nevažeći puls mjerača protoka po volumenu.';
_fc_props.coming_soon_title = 'Uskoro više widgeta';
_fc_props.submit_your_ideas = 'Pošaljite svoje ideje na naš portal ideja pomoću žarulje na lijevom navigacijskom izborniku.';
_fc_props.selected_plants = 'Odabrane biljke';
_fc_props.update_loads_per_plant_settings = 'Ažurirajte postavke opterećenja po biljci';
_fc_props.default_top_10_plants = 'Zadano (10 najboljih biljaka)';
_fc_props.phone_number_will_be_uploaded = 'ID uređaja bit će učitan za potrebe registracije.';
_fc_props.include_deleted = 'Uključi Izbrisano';
_fc_props.primary_status_type = 'Vrsta primarnog statusa';
_fc_props.additional_status_trigger = 'Dodatni okidači statusa';
_fc_props.update_tickets_destination = 'Ažurirajte odredište na svim povezanim kartama';
_fc_props.settings.send_destination_changes_to_connex = 'Pošaljite promjene odredišta na Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Je li ulaznica aktivna kada je TrackIt primi (zadano \'uvijek\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Automatski aktiviraj sljedeću ulaznicu nakon završetka prethodne ulaznice';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Treba li automatski aktivirati sljedeću kartu po dovršetku ulaznice (zadano \'true\')';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Status do stanja mirovanja kada nema aktivne karte';
_fc_props.break_down = 'Razbiti';
_fc_props.idle = 'Besposlen';
_fc_props.pto_work_maximum_speed_limit = 'Ograničenje maksimalne brzine rada';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Promjena statusa pridruživanja koja dovršava tiket s tom ulaznicom';
_fc_props.approaching = 'Približavanje';
_fc_props.performing_task = 'Izvođenje zadatka';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Jeste li sigurni da želite izbrisati Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Ažurirajte kartu {0}. Odredište promijenjeno.';
_fc_props.trackit_return_destination_changed_to_0 = 'Ažurirajte kartu {0}. Povratak promijenjen.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Dodaj Geogate';
_fc_props.driver_enabled = 'Upravljački program omogućen';
_fc_props.dispatcher_enabled = 'Dispečer omogućen';
_fc_props.runsheet_number = 'Broj radnog lista';
_fc_props.manual_load = 'Ručno učitavanje';
_fc_props.edit_geogate = 'Uredi Geogate';
_fc_props.handling = 'Rukovanje';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Geogate panel';
_fc_props.vehicle_is_not_logged_in = 'Poruka nije poslana (vozilo nije prijavljeno)';
_fc_props.air = 'Zrak';
_fc_props.create_a_geogate = 'Stvorite Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Pritisnite kartu za postavljanje geogate točaka.';
_fc_props.clock_out = 'Sat Out';
_fc_props.handling_operation = 'Operacija rukovanja';
_fc_props.view_geogates = 'Pogledajte Geogates';
_fc_props.select_a_geogate = 'Odaberite Geogate';
_fc_props.failed_to_find_geogate = 'Nije uspjelo pronaći Geogate';
_fc_props.adjust_geogate = 'Podesite Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Datum isteka Vrijeme Zadani dodatni dani';
_fc_props.menu_settings_title_retention = 'Zadržavanje podataka';
_fc_props.category = 'Kategorija';
_fc_props.telemetry = 'Telemetrija';
_fc_props.ticket_data = 'Podaci o ulaznici';
_fc_props._30_days = '30 dana';
_fc_props._6_months = '6 mjeseci';
_fc_props._1_year = '1 godina';
_fc_props._3_years = '3 godine';
_fc_props.new_group = 'Nova grupa';
_fc_props.selected_vehicles = 'Odabrana vozila';
_fc_props.group_name_is_required_field = 'Naziv grupe je obavezno polje';
_fc_props.at_least_vehicle_should_be_selected = 'Treba odabrati najmanje 1 vozilo';
_fc_props.groups = 'grupe';
_fc_props.trucks = 'Kamioni';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate je uklonjen. Molimo osvježite stranicu.';
_fc_props.air_timestamp = 'Vremenska oznaka zraka';
_fc_props.fail = 'IZNEVJERITI';
_fc_props.stuck = 'ZAPELO';
_fc_props.dirty = 'PRLJAVO';
_fc_props.seasonal = 'Sezonski';
_fc_props.category_details = 'Detalji kategorije';
_fc_props.alert_events = 'Događaji upozorenja';
_fc_props.driver_performance_infractions = 'Prekršaji u radu vozača';
_fc_props.telemetry_details = 'Podaci o lokaciji, vruće točke, vrijeme na vrućim točkama, vrijeme u regijama, DCM podaci, podaci sonde, podaci o motoru';
_fc_props.ticket_data_details = 'Podaci o ulaznici';
_fc_props.general_details = 'Promjene statusa, vremenske kartice, rasporedi';
_fc_props.wash = 'Pranje';
_fc_props.filter_options = 'Opcije filtra';
_fc_props.home_plants = 'Kućne biljke';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO zahtijeva postavljanje vrste senzora PTO.';
_fc_props.invalid_io_box_mac_address = 'Nevažeća Mac adresa I/O Box-a';
_fc_props.access_denied = 'Pristup odbijen';
_fc_props.wash_events = 'Wash Događaji';
_fc_props.min_speed = 'Min brzina';
_fc_props.temperature_change = 'Promjena temperature';
_fc_props.degrees_cap = 'Stupnjevi';
_fc_props.washout_tracking = 'Praćenje ispiranja';
_fc_props.approaching_wash = 'Približava se Washu';
_fc_props.arrival_wash = 'Dolazak Pranje';
_fc_props.performing_wash = 'Izvođenje pranja';
_fc_props.wash_complete = 'Pranje završeno';
_fc_props.must_be_positive_or_zero = 'Mora biti pozitivan ili nula!';
_fc_props.min_speed_greater_than_max = 'Min brzina mora biti manja od maksimalne brzine!';
_fc_props.clock_in = 'Sat In';
_fc_props.mix_water_at_x_to_y_for_t = 'Miješajte vodu pri {0} do {1} okretaja u minuti za {2} okretaja.';
_fc_props.discharge_water_for_x_revolutions = 'Ispustite vodu za {0} okretaja.';
_fc_props.eod_drum_wash_complete = 'Pranje bubnja završeno! <br> Bubanj za zaustavljanje sa sondom na vrhu.';
_fc_props.washing_complete = 'Vođeno ispiranje dovršeno';
_fc_props.eod_washout_no_data_available = 'Podaci senzora nisu dostupni, dovršite proces pranja.';
_fc_props.eod_washout_no_data_available_title = 'Vođeno ispiranje nije dostupno';
_fc_props.menu_reports_title_ticket_time_summary = 'Sažetak vremena ulaznice';
_fc_props.menu_reports_description_ticket_time_summary = 'Pogledajte informacije o vremenu karte za narudžbu ili vozilo';
_fc_props.menu_reports_title_summary = 'Sažetak';
_fc_props.menu_reports_title_ticket_summary = 'Sažetak ulaznice';
_fc_props.menu_reports_description_summary1 = 'Pogledajte sažetak vremena u žarišnim točkama';
_fc_props.menu_dispatch_title_main = 'Glavni';
_fc_props.menu_dispatch_title_login_registry = 'Registar za prijavu';
_fc_props.menu_reports_title_main = 'Glavni';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Jeste li sigurni da želite izbrisati ovu vrstu podataka za izvoz?';
_fc_props.dvir_status_paper_logs = 'Papirnati dnevnici';
_fc_props.menu_reports_title_billing_report = 'Izvješće o naplati';
_fc_props.menu_reports_description_billing_report = 'Izvješće o naplati';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status Do Ticketed Status On Activate Work';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status do Ticketed Status On Activate Work (zadano \'False\')';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'funti';
_fc_props.units_weight_kilogram_label = 'Kilogrami';
_fc_props.settings_weight = 'Težina';
_fc_props.units_pounds = 'funti';
_fc_props.units_kilograms = 'Kilogrami';
_fc_props.geo_gate_edit_modal_description = 'Opis';
_fc_props.geo_gate_edit_modal_start_point = 'Početna točka';
_fc_props.geo_gate_edit_modal_end_point = 'Krajnja točka';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Vrsta opreme';
_fc_props.geo_gate_edit_modal_heading_south = 'S juga na sjever';
_fc_props.geo_gate_edit_modal_heading_north = 'Od sjevera do juga';
_fc_props.geo_gate_edit_modal_driver_message = 'Poruka vozača';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Otpremna poruka';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Datum isteka Vrijeme';
_fc_props.geo_gate_edit_modal_permanent = 'Trajna';
_fc_props.geo_gate_edit_modal_critical = 'Kritično';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Pogreška pri spremanju Geogate: polje Opis mora biti popunjeno.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Pogreška prilikom spremanja Geogate: Polje početne točke mora biti popunjeno.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Pogreška spremanja Geogate: Polje početne točke sadrži nevažeći format podataka.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Pogreška pri spremanju Geogate: Polje krajnje točke sadrži nevažeći format podataka.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Pogreška pri spremanju Geogate-a: polje Datum isteka Vrijeme mora biti popunjeno.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Pogreška pri spremanju Geogate-a: polje Datum isteka Vrijeme sadrži nevažeći format podataka.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Pogreška pri spremanju Geogate: Sjever prema jugu - Polje za otpremnu poruku mora biti popunjeno';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Pogreška prilikom spremanja Geogate: nisu otkrivene promjene';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Pogreška pri spremanju Geogate-a: Polje datuma isteka vremena sadrži datum-vrijeme isteka';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Pogreška pri spremanju Geogate: polje Opis mora sadržavati samo slova i brojke';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Pogreška prilikom spremanja Geogate: polje vrste opreme mora biti popunjeno';
_fc_props.geo_gate_table_message_label_north_to_south = 'Od sjevera do juga';
_fc_props.geo_gate_table_message_label_south_to_north = 'S juga na sjever';
_fc_props.geo_gate_table_message_label_both = 'Oba';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Geografska širina, dužina';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Označavanjem ove opcije poništit će se postava za ograničenje poruke o kretanju uređaja';
_fc_props.geo_gate_edit_modal_seasonal = 'Sezonski';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Pogreška pri spremanju Geogate-a: moraju se popuniti sezonski datumi';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Pogreška pri spremanju Geogate-a: Polja sezonskog datuma sadrže nevažeći format podataka';
_fc_props.test_js_test_javascript = 'Testiraj Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometri';
_fc_props.units_miles = 'Miles';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Pogreška pri spremanju Geogate: jug prema sjeveru - Polje poruke vozača mora biti popunjeno';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Pogreška pri spremanju Geogate: jug prema sjeveru - Polje poruke o otpremi mora biti popunjeno';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Pogreška pri spremanju Geogate: Sjever prema jugu - Polje poruke vozača mora biti popunjeno';
_fc_props.geo_gate_heading = 'Naslov';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Pogreška prilikom spremanja Geogate: Polje krajnje točke mora biti popunjeno';
_fc_props.units_mile = 'Milja';
_fc_props.units_kilometer = 'Kilometar';
_fc_props.cloud_dvir__exit = 'Izlaz ';
_fc_props.cloud_dvir__signature = 'Potpis';
_fc_props.cloud_dvir__inspection = 'Inspekcija';
_fc_props.cloud_dvir__add_photos_notes = 'Dodaj fotografije/Bilješke';
_fc_props.cloud_dvir__odometer_reading = 'Očitanje odometra';
_fc_props.cloud_dvir__engine_hours = 'Radni sati motora';
_fc_props.cloud_dvir__truck_details = 'Detalji kamiona';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Odredite cjelokupno stanje vozila';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Puno ime i potpis vozača';
_fc_props.cloud_dvir__sign = 'Znak';
_fc_props.cloud_dvir__driver_note_from = 'Napomena vozača od';
_fc_props.cloud_dvir__add_notes = 'Dodajte bilješke';
_fc_props.cloud_dvir__driver_notes = 'Bilješke vozača';
_fc_props.cloud_dvir__done = 'Gotovo';
_fc_props.cloud_dvir__needs_repair = 'Potreban popravak';
_fc_props.cloud_dvir__not_applicable = 'Nije primjenjivo';
_fc_props.cloud_dvir__review_and_sign = 'Pregledajte i potpišite';
_fc_props.cloud_dvir__add_photos = 'Dodaj fotografiju';
_fc_props.cloud_dvir__upload_photo_limit = 'Možete učitati do 3 slike';
_fc_props.cloud_dvir__mismatch_title = 'Upozorenje o neslaganju';
_fc_props.cloud_dvir__ok = 'U redu';
_fc_props.cloud_dvir_template = 'Cloud DVIR predložak';
_fc_props.cloud_dvir__needs_review = 'DVIR treba reviziju';
_fc_props.cloud_dvir__submitted = 'Poslano';
_fc_props.cloud_dvir__completed_by = 'Dovršio';
_fc_props.cloud_dvir__review_btn = 'Pregled';
_fc_props.cloud_dvir__truck_number = 'Kamion {0}';
_fc_props.cloud_dvir__pending_review = 'Čeka pregled';
_fc_props.cloud_dvir__start_dvir = 'Pokrenite DVIR';
_fc_props.cloud_dvir__dvir_history = 'Povijest DVIR-a';
_fc_props.cloud_dvir__view_all = 'Pogledaj sve';
_fc_props.load_zone = 'Zona opterećenja';
_fc_props.view_load_zones = 'Pregledajte zone opterećenja';
_fc_props.edit_load_zone = 'Uredi zonu opterećenja';
_fc_props.create_a_load_zone = 'Stvorite zonu opterećenja';
_fc_props.load_zone_panel = 'Ploča zone opterećenja';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Pritisnite kartu za postavljanje središta zone opterećenja.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Ovaj pregled ima naznačene nedostatke i vi ste odabrali da je ovo vozilo sigurno za rad. Prije nastavka potvrdite';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Ovaj pregled nema uočenih nedostataka i vi ste odabrali da ovo vozilo nije sigurno za rad. Prije nastavka potvrdite.';
_fc_props._90_days = '90 dana';
_fc_props.cloud_dvir = 'Cloud DVIR';
_fc_props.cloud_dvir_details = 'Cloud DVIR podnesci';
_fc_props.show_load_zones = 'pokazati zone opterećenja';
_fc_props.hide_load_zones = 'sakriti zone opterećenja';
_fc_props.no = 'Ne';
_fc_props.cloud_dvir__reset = 'Resetiraj';
_fc_props.cloud_dvir__clear = 'Čisto';
_fc_props.mark_read = 'Mark Read';
_fc_props.mark_unread = 'Označi kao nepročitano';
_fc_props.read = 'Čitati';
_fc_props.unread = 'Nepročitano';
_fc_props.air_sensor_serial_number = 'Serijski broj senzora zraka';
_fc_props.air_sensor_mac_address = 'MAC adresa senzora zraka';
_fc_props.air_sensor_serial_number_invalid_format = 'Serijski broj senzora zraka nije u važećem formatu: (alfanumerički i najviše 32 znaka s crticama)';
_fc_props.air_sensor_mac_address_invalid_format = 'MAC adresa senzora zraka nije u važećem heksadecimalnom formatu kao što je prikazano u rezerviranom mjestu za polje';
_fc_props.air_sensor_license = 'Senzor zraka';
_fc_props.adjust_route_point = 'Podesite točku rute';
_fc_props.route_point_panel = 'Ploča s točkama rute';
_fc_props.route_point = 'Točka rute';
_fc_props.cloud_dvir__pre_trip = 'Prije putovanja';
_fc_props.cloud_dvir__post_trip = 'Nakon putovanja';
_fc_props.cloud_dvir__other_insction_type = 'ostalo';
_fc_props.cloud_dvir__dvir_completed = 'Završeno';
_fc_props.cloud_dvir__awaiting_mechanic = 'Čeka se mehaničar';
_fc_props.cloud_dvir__awaiting_driver = 'Čeka se odjava vozača';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Nije moguće provjeriti serijski broj senzora zraka, kontaktirajte podršku za dodatnu pomoć.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Ovaj serijski broj senzora zraka već postoji, molimo koristite drugi serijski broj';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Nije moguće provjeriti MAC adresu senzora zraka, kontaktirajte podršku za pomoć';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Pogreška pri slanju DVIR-a';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Molim te pokušaj ponovno';
_fc_props.ticket_integration_descr = 'Integracija ulaznica omogućuje postavljanje postavki integracije ulaznica, kao i Početni zaslon / Zaslon za učitavanje / Hard Mount Display';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR uspješno predan';
_fc_props.cloud_dvir__inspection_trip_type = 'Vrsta inspekcijskog putovanja';
_fc_props.menu_dvir_title_mechanic = 'Mehaničar';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Firmware senzora zraka';
_fc_props.when_no_active_ticket = 'Kada nema aktivne karte';
_fc_props.used_by = 'Korišten od';
_fc_props.fields = 'Polja';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Jeste li sigurni da želite izbrisati izgled ulaznice "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Izgled ulaznice';
_fc_props.add_ticket_layout = 'Dodajte izgled ulaznice';
_fc_props.edit_ticket_layout = 'Uredi izgled ulaznice';
_fc_props.ticket_layout_edit_modal_name = 'Ime';
_fc_props.ticket_layout_edit_modal_default = 'Zadano';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Pogreška pri spremanju izgleda ulaznice: Polje s imenom mora biti popunjeno.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Pogreška pri spremanju izgleda ulaznice: Polje s imenom mora sadržavati samo slova i brojke';
_fc_props.ticket_layout_edit_modal_fields = 'Polja';
_fc_props.ticket_layout_edit_modal_usage = 'Korištenje';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Izgled ulaznice je uklonjen. Molimo osvježite stranicu.';
_fc_props.more_lower_case = 'više';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Pogreška pri spremanju izgleda ulaznice: mora biti odabrano jedno ili više polja.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Pogreška pri spremanju izgleda ulaznice: mora biti odabran jedan ili više statusa.';
_fc_props.cloud_dvir__ad_hoc = 'Ad hoc';
_fc_props.radius_multiplier_should_be = 'Množitelj polumjera trebao bi biti pomični broj';
_fc_props.ticket_max_activate_age_err = 'Najveća aktivna dobna minuta karte treba biti cijeli broj.';
_fc_props.max_hotspot_unused_days_errs = 'Maksimalni broj neiskorištenih dana žarišne točke trebao bi biti cijeli broj.';
_fc_props.begin_pour_air_content = 'Počnite ulijevati sadržaj zraka';
_fc_props.arrival_air_content = 'Sadržaj zraka u dolasku';
_fc_props.fully_mixed_air_content = 'Potpuno izmiješan sadržaj zraka';
_fc_props.departure_air_content = 'Odlazni zračni sadržaj';
_fc_props.popup_enabled = 'Skočni prozor omogućen';
_fc_props.popup_disabled = 'Skočni prozor onemogućen';
_fc_props.cloud_dvir__leave_confirmation_title = 'Upozorenje';
_fc_props.cloud_dvir__leave_confirmation_description = 'Izlazak sa stranice sada će uzrokovati gubitak svih promjena.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Potvrdi';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Otkazati';
_fc_props.air_bt_ratio = 'Mjerenje senzora zraka';
_fc_props.cloud_dvir__view_photos_notes = 'Pogledajte fotografije/Bilješke';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Dodavanje statusa stvorit će praznu upotrebu u drugom izgledu ulaznice. Želite li nastaviti s dodavanjem?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Vozilo nema dozvolu senzora zraka.';
_fc_props.air_sensor_installed = 'Instaliran senzor zraka';
_fc_props.load_card = 'Učitaj karticu';
_fc_props.cloud_dvir__inspection_profile = 'Profil inspekcije';
_fc_props.cloud_dvir__view_details = 'Pregledavati pojedinosti';
_fc_props.cloud_dvir__certify_repairs = 'Certificirajte popravke';
_fc_props.cloud_dvir__repairs_made = 'Izvršeni popravci';
_fc_props.cloud_dvir__repairs_not_necessary = 'Popravci nisu potrebni';
_fc_props.cloud_dvir__mechanic_note_from = 'Napomena mehaničara od';
_fc_props.cloud_dvir__mechanic_assignment = 'Zadatak mehaničara';
_fc_props.cloud_dvir__mechanic_signature_description = 'Označite sve primjenjive stavke ispod i potpišite da je vozilo ispravno pregledano.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Stanje ovog vozila je zadovoljavajuće';
_fc_props.cloud_dvir__defects_corrected_label = 'Nedostaci su ispravljeni';
_fc_props.cloud_dvir__repair_not_required_label = 'Popravak nije potreban za siguran rad vozila';
_fc_props.safety = 'Sigurnost';
_fc_props.cloud_dvir__awaiting_sign = 'Čeka znak';
_fc_props.cloud_dvir__dvir_review = 'DVIR pregled';
_fc_props.settings.mobile_auto_start_navigation = 'Automatsko pokretanje navigacije na mobitelu';
_fc_props.cloud_dvir__edit = 'Uredi';
_fc_props.delete_custom_label = 'Izbriši prilagođenu oznaku';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Vaša verzija TrackIt-a je zastarjela i neke značajke DVIR-a možda neće ispravno raditi. Ažurirajte TrackIt prije nastavka.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Nešto nije u redu tijekom učitavanja potpisa. Molim te pokušaj ponovno';
_fc_props.max_hotspot_unused_days_error = 'Max Hotspot neiskorištenih dana ima najviše 1000 dana.';
_fc_props.menu_dvir_title_admin_templates = 'Predlošci';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Administrator';
_fc_props.cloud_dvir__admin_templates = 'Predlošci';
_fc_props.form_name = 'Naziv obrasca';
_fc_props.form_status = 'Status obrasca';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'preuzimanje datoteka';
_fc_props.cloud_dvir__option_view = 'Pogled';
_fc_props.cloud_dvir__inspectortype_driver = 'Vozač';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Pregledajte/uredite/stvorite prilagođene DVIR obrasce i podneske';
_fc_props.menu.dvir.description.mechanic = 'Pregledajte/uredite DVIR podneske koji se mogu poduzeti';
_fc_props.not_in_use = 'Ne koristi se';
_fc_props.logged_in = 'prijavljeni';
_fc_props.reviewing_driver = 'Pregled vozača';
_fc_props.cloud_dvir__report_title = 'Zapisnik o pregledu vozila vozača';
_fc_props.cloud_dvir__report_signed = 'potpisan';
_fc_props.cloud_dvir__report_page_counter = 'Stranica {0} od {1}';
_fc_props.cloud_dvir__report_defects = 'Nedostaci';
_fc_props.cloud_dvir__report_comments = 'Komentari';
_fc_props.cloud_dvir__report_inspection_table_title = 'Pregled vozila';
_fc_props.cloud_dvir__report_driver_table_title = 'Informacije o vozaču';
_fc_props.cloud_dvir__report_carrier = 'Prijevoznik';
_fc_props.cloud_dvir__report_truck = 'Kamion';
_fc_props.cloud_dvir__report_location = 'Mjesto';
_fc_props.cloud_dvir__report_odometer = 'Odometar';
_fc_props.cloud_dvir__report_hours = 'Sati';
_fc_props.cloud_dvir__report_no_comments = 'Bez komentara';
_fc_props.include_unlicensed_vehicles = 'Uključite vozila bez dozvole';
_fc_props.cloud_dvir__search_by_form_name = 'Pretraživanje prema nazivu obrasca';
_fc_props.cloud_dvir__create_dvir = 'Stvorite DVIR';
_fc_props.cloud_dvir__update_dvir = 'Ažurirajte DVIR';
_fc_props.cloud_dvir__form_name = 'Naziv obrasca';
_fc_props.cloud_dvir__form_description = 'Opis obrasca';
_fc_props.cloud_dvir__form_status = 'Status obrasca';
_fc_props.information = 'Informacija';
_fc_props.cloud_dvir__search_categories = 'Kategorije pretraživanja';
_fc_props.cloud_dvir__create_new_category = 'Stvorite novu kategoriju';
_fc_props.category_name = 'ime kategorije';
_fc_props.sub_category = 'Potkategorija';
_fc_props.sub_category_name = 'Naziv podkategorije';
_fc_props.delete_category = 'Izbriši kategoriju';
_fc_props.cloud_dvir__dvir_information = 'Informacije o DVIR-u';
_fc_props.cloud_dvir__dvir_choose_categories = 'Odaberite kategorije';
_fc_props.cloud_dvir__create_category = 'Stvori kategoriju';
_fc_props.cloud_dvir__update_category = 'Ažuriraj kategoriju';
_fc_props.delete_route_point = 'Izbriši točku rute';
_fc_props.view_route_points = 'Prikaz točaka rute';
_fc_props.ignore_cancel_preload = 'Ignorirajte otkazivanje, unaprijed ste učitani. Vaš home run će se uskoro prikazati';
_fc_props.cloud_dvir__assigned_mechanic = 'Dodijeljeni mehaničar';
_fc_props.cloud_dvir__add_mechanic = 'Dodajte mehaničara';
_fc_props.contact_type = 'Vrsta kontakta';
_fc_props.cloud_dvir__send_email_notifications = 'Pošaljite obavijesti e-poštom';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Pogreška pri dohvaćanju zapisa DVIR povijesti.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Pogreška pri dohvaćanju DVIR zapisa.';
_fc_props.cloud_dvir__error_saving_submission = 'Pogreška pri spremanju podneska. Pokušajte ponovno.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Pogreška pri dohvaćanju popisa mehaničara.';
_fc_props.cloud_dvir__template_saved = 'Predložak spremljen';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Pogreška pri dohvaćanju popisa predložaka.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Pogreška pri spremanju predloška. Pokušajte ponovno.';
_fc_props.dispatch_group = 'Grupa za otpremu';
_fc_props.save_adjust = 'Spremi i prilagodi točku rute';
_fc_props.reset_route_point = 'Poništi točku rute';
_fc_props.no_records_stub_adjust_filters = 'Pokušajte prilagoditi postavke pretraživanja ili filtra kako biste povećali broj rezultata.';
_fc_props.no_dvir_records = 'Sve je uhvaćeno na DVIR-ima!';
_fc_props.no_dvir_records_sub_title = 'Postigli ste puno';
_fc_props.settings.master_data_connex_x_api_key = 'Glavni podaci Connex x-api-ključ';
_fc_props.slump_not_obtained_for_x = 'Nema očitanja pada za: {0}';
_fc_props.please_select_a_sensor_status_type = 'Odaberite Vrste statusa senzora da biste aktivirali ovo upozorenje';
_fc_props.settings.master_data_connex_token = 'Glavni podaci Connex token';
_fc_props.error_validating_master_data_connex_credentials = 'Pogreška pri potvrđivanju glavnih podataka Connex vjerodajnica';
_fc_props.settings.master_data_connex_base_url = 'Glavni URL Connex baze podataka';
_fc_props.settings.master_data_connex_exp_time = 'Master Data Connex Vrijeme isteka';
_fc_props.download_template = 'Preuzmite predložak';
_fc_props.menu_settings_title_dispatch_group = 'Grupe za otpremu';
_fc_props.settings.dispatch_groups = 'Grupe za otpremu';
_fc_props.dispatch_group_name = 'Naziv grupe za otpremu';
_fc_props.dispatch_group_plants = 'Grupa za otpremu postrojenja';
_fc_props.dispatch_group_plants_mapping_message = 'Navedite koja će postrojenja biti dio ove grupe za otpremu.';
_fc_props.dispatch_group_users_mapping_message = 'Naznačite koji će korisnici biti dio ove grupe za otpremu.';
_fc_props.dispatch_group_plant_disassociation_message = 'Biljka bi trebala biti pod jednom po jednom otpremnom grupom';
_fc_props.dispatch_group_search_by_plant = 'Traži po nazivu biljke';
_fc_props.dispatch_group_search_by_username = 'Traži po korisničkom imenu';
_fc_props.search_by_dispatch_group_name = 'Pretraživanje prema nazivu grupe za otpremu';
_fc_props.create_dispatch_group = 'Stvorite grupu za otpremu';
_fc_props.update_dispatch_group = 'Ažuriraj grupu za otpremu';
_fc_props.dispatch_group_saved = 'Grupa otpreme spremljena';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Brisanje ove otpremne grupe stvorit će zadane otpremne grupe za povezana postrojenja.';
_fc_props.error_saving_dispatch_group = 'Pogreška pri spremanju grupe otpreme. Pokušajte ponovno.';
_fc_props.please_enter_a_valid_plant_num = 'Unesite važeći broj tvornice.';
_fc_props.cloud_dvir__report_no_defects = 'Nema nedostataka';
_fc_props.shoud_select_dispatch_group = 'Morate odabrati barem jednu grupu za otpremu';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Nije moguće izbrisati jer ova grupa za otpremu sadrži korisnike koji su povezani samo s ovom grupom za otpremu.';
_fc_props.cloud_dvir__username = 'Korisničko ime';
_fc_props.cloud_dvir__password = 'Lozinka';
_fc_props.cloud_dvir__login = 'Prijaviti se';
_fc_props.cloud_dvir__mechanic_sign_in = 'Prijava mehaničara';
_fc_props.delete_dispatch_group = 'Izbriši otpremnu grupu';
_fc_props.cloud_dvir__driver = 'Vozač';
_fc_props.cloud_dvir__equipment_pound = 'Oprema br.';
_fc_props.cloud_dvir__mechanic = 'Mehaničar';
_fc_props.dispatch_group_user_only_unassociate_message = 'Nije moguće izbrisati korisnika jer korisnici moraju biti povezani s najmanje jednom grupom za otpremu.';
_fc_props.cloud_dvir__time_period_filter_today = 'Danas';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Jučer';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Posljednjih 7 dana';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Posljednjih 30 dana';
_fc_props.cloud_dvir__date_range_filter = 'Filtriraj po datumu';
_fc_props.cloud_dvir__download = 'preuzimanje datoteka';
_fc_props.dispatch_group_is_empty = 'Grupa otpreme ne može biti prazna.';
_fc_props.dispatch_group_with_empty_plants = 'Najmanje jedno postrojenje mora biti povezano s otpremnom grupom.';
_fc_props.dispatch_group_with_empty_users = 'Najmanje jedan korisnik mora biti povezan s otpremnom grupom.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Nije moguće izbrisati grupu za otpremu jer sadrži korisnike koji su povezani samo s ovom grupom za otpremu.';
_fc_props.dispatch_group_not_exists = 'Otpremna grupa ne postoji.';
_fc_props.dispatch_group_contains_one_plant = 'Nije moguće izbrisati jer ova otpremna grupa sadrži samo jedno postrojenje.';
_fc_props.dispatch_group_users = 'Korisnici grupe za otpremu';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Pogreška pri dohvaćanju DVIR zapisa';
_fc_props.cloud_dvir__assign_mechanic = 'Dodijelite mehaničara';
_fc_props.cloud_dvir__submit_review = 'Pošalji recenziju';
_fc_props.error_deleting_dispatch_group = 'Pogreška pri brisanju grupe za otpremu.';
_fc_props.error_retrieving_dispatch_groups = 'Pogreška pri dohvaćanju popisa otpremnih grupa.';
_fc_props.cloud_dvir__undo_selection = 'Poništi odabir';
_fc_props.enable_master_data = 'Omogući matične podatke';
_fc_props.manually_sync = 'Ručna sinkronizacija';
_fc_props.master_data_connex_token_expiration = 'Glavni podaci Istek tokena Connex';
_fc_props.cloud_dvir__time_period_filter_custom = 'Prilagođen';
_fc_props.settings.zello_session_timeout_minutes = 'Istek Zello sesije (minute)';
_fc_props.menu_settings_title_master_data = 'Glavni podaci';
_fc_props.settings.master_data = 'Glavni podaci';
_fc_props.error_retrieving_master_data = 'Pogreška pri dohvaćanju matičnih podataka';
_fc_props.master_data_sync_completed = 'Sinkronizacija glavnih podataka je dovršena';
_fc_props.master_data_successfully_updated = 'Podaci su uspješno ažurirani';
_fc_props.master_data_failed_to_update = 'Spremanje nije uspjelo.';
_fc_props.master_data_sync_initiated = 'Sinkronizacija glavnih podataka je pokrenuta';
_fc_props.master_data_sync_failed = 'Sinkronizacija glavnih podataka nije uspjela';
_fc_props.enabled_excluding_origin_on_deliveries = 'Istina (isključujući porijeklo na kartama za dostavu)';
_fc_props.enabled_including_origin_on_deliveries = 'Istina (uključujući podrijetlo na kartama za dostavu)';
_fc_props.cloud_dvir__i_will_decide_later = 'Odlučit ću kasnije';
_fc_props.returning = 'Vraćajući se';
_fc_props.leftover_concrete = 'Ostaci betona';
_fc_props.leftover_concrete_on_vehicle_x = 'Ostaci betona na vozilu {0}';
_fc_props.leftover_concrete_alert = 'Upozorenje o ostacima betona';
_fc_props.wc_ratio_exceeded = 'Omjer W/C premašen';
_fc_props.search_by_site_name = 'Pretraživanje po nazivu stranice';
_fc_props.sites = 'Stranice';
_fc_props.token_expiration = 'Istek tokena';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API Key Management';
_fc_props.settings.connex_api_key_management = 'Connex API Key Management';
_fc_props.error_retrieving_connex_api_key_data = 'Pogreška pri dohvaćanju podataka o ključu connex api';
_fc_props.qr_code = 'QR kod';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informiranje vozača i sučelja kada se unese pogrešan pogon';
_fc_props.enable_driver_message = 'Omogući poruku vozača';
_fc_props.enable_dispatch_message = 'Omogući otpremnu poruku';
_fc_props.enable_sending_message_to_interface = 'Omogući slanje poruke sučelju';
_fc_props.ticket_qr_code = 'QR kod ulaznice';
_fc_props.leftover_concrete_alert_description = 'Upozorenje kada se nakon izlijevanja pronađe ostatak betona.';
_fc_props.minimum_leftover_concrete_amount = 'Minimalna količina ostatka betona';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Pogreška pri potvrđivanju glavnih podataka. Connex vjerodajnice nisu ovlaštene';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Pogreška pri potvrđivanju dozvola za vjerodajnice za glavne podatke Connex';
_fc_props.error_validating_master_data_connex_url_not_found = 'Pogreška provjere valjanosti glavnih podataka Connex URL nije pronađen';
_fc_props.error_validating_master_data_connex_unknown_error = 'Pogreška provjere glavnih podataka Connex Unknown Error';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Sinkronizacija glavnih podataka nije uspjela. Sinkronizirajte vrstu vozila.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Sinkronizacija glavnih podataka nije uspjela Sinkroniziraj vozilo.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Sinkronizacija glavnih podataka nije uspjela Sinkroniziraj postrojenje.';
_fc_props.master_data_sync_failed_data_base_connection = 'Povezivanje s bazom podataka nije uspjelo pri sinkronizaciji glavnih podataka.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Pogreška pri potvrđivanju glavnih podataka Connex Credentials Bearer Token Permission is Insufficient';
_fc_props.settings.back_date_arrive_job = 'Nazad datum dolaska posao';
_fc_props.device_notifications = 'Obavijesti';
_fc_props.loading_number = 'Broj učitavanja';
_fc_props.leftover_concrete_volume_temp_age = 'Preostali volumen={0}, Temp={1}, Starost={2}';
_fc_props.total_ordered = 'Ukupna naručena količina';
_fc_props.total_ticketed = 'Isporučeno Naručeno';
_fc_props.load_number = 'Broj opterećenja';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Minimalan ostatak betona mora biti ispunjen i veći od {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Pogreška pri potvrđivanju matičnih podataka. Vjerodajnice ne mogu biti iste.';
_fc_props.use_aws_for_talk = 'Koristite AWS za razgovor';
_fc_props.air_readings_not_supported = 'Očitavanja zraka nisu podržana';
_fc_props.air_not_supported = 'Air Nije podržan';
_fc_props.mix_not_supported = 'Miks nije podržan';
_fc_props.air_default_and_none_not_supported = 'Ne može se odabrati zadano i nije podržano u isto vrijeme!';
_fc_props.notifications_enabled = 'Obavijesti omogućene';
_fc_props.prevent_status_change = 'Spriječiti promjenu statusa';
_fc_props.message_to_interface = 'Poruka sučelju';
_fc_props.prevent_wrong_plant = 'Spriječite pogrešnu biljku';
_fc_props.previous_tickets = 'Prethodne ulaznice';
_fc_props.audit_ticket_number = 'Broj karte';
_fc_props.audit_date_created = 'Datum kreiranja';
_fc_props.audit_vehicle_number = 'Broj vozila';
_fc_props.cloud_dvir__table_ok = 'Siguran za rad';
_fc_props.cloud_dvir__table_needs_repair = 'Nije sigurno za rad';
_fc_props.missing_air_calibration = 'Nema podataka o kalibraciji zraka';
_fc_props.reported_defects = 'Prijavljeni nedostaci';
_fc_props.address_latitude = 'Adresa Latitude';
_fc_props.address_longitude = 'Dužina adrese';
_fc_props.routing_latitude = 'Routing Latitude';
_fc_props.routing_longitude = 'Dužina rute';
_fc_props.category_name_must_be_unique = 'Naziv kategorije mora biti jedinstven';
_fc_props.subcategory_name_must_be_unique = 'Naziv potkategorije mora biti jedinstven';
_fc_props.menu_settings_tracking_device = 'Uređaj za pračenje';
_fc_props.menu_settings_title_tracking_device = 'Uređaj za pračenje';
_fc_props.menu_settings_title_personal_device_registration = 'Registracija osobnog uređaja';
_fc_props.registration_code = 'Registracijski kod';
_fc_props.creation_date = 'Stvaranje';
_fc_props.expiration_date = 'Istek';
_fc_props.trackitadmin_personal_device_registration = 'Registracija osobnog uređaja';
_fc_props.create_registration_code = 'Dodajte registracijski kod';
_fc_props.remove_registration_code = 'Uklonite registracijski kod';
_fc_props.add_a_registration_code = 'Dodajte registracijski kod';
_fc_props.select_number_of_registration_codes_to_generate = 'Odaberite broj registracijskih kodova koje želite generirati.';
_fc_props.registration_code_count = 'Broj registracijskih kodova';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Jeste li sigurni da želite izbrisati odabrani registrirani kod?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Jeste li sigurni da želite izbrisati odabrane registrirane kodove?';
_fc_props.add_personal_device = 'Dodaj osobni uređaj';
_fc_props.menu_settings_title_personal_device = 'Osobni uređaj';
_fc_props.cloud_dvir__approval_critical_alert = 'Vozilo ima kritičan kvar. Ne može se označiti kao siguran za rad.';
_fc_props.export_registration_codes = 'Izvozne registracijske šifre';
_fc_props.download_format = 'Format preuzimanja';
_fc_props.specify_download_format_x = 'Navedite format preuzimanja za izvoz {0} registracijskih kodova';
_fc_props.personal_device_download = 'preuzimanje datoteka';
_fc_props.add_personal_device_input_message = 'U nastavku unesite ID osobnog uređaja. Istek će se automatski primijeniti nakon spremanja';
_fc_props.error_creating_personal_device = 'Pogreška pri stvaranju osobnog uređaja';
_fc_props.device_already_exists = 'Osobni uređaj već postoji';
_fc_props.export = 'Izvoz';
_fc_props.error_can_not_change_connex_eid = 'Nije moguće promijeniti connex eid jer se trenutno koristi za glavne podatke';
_fc_props.remove_personal_device = 'Ukloni osobni uređaj';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Jeste li sigurni da želite izbrisati odabrani osobni uređaj?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Jeste li sigurni da želite izbrisati odabrane osobne uređaje?';
_fc_props.error_deleting_personal_device = 'Pogreška pri brisanju osobnog uređaja';
_fc_props.customerid = 'ID kupca';
_fc_props.customername = 'Ime kupca';
_fc_props.ordernumber = 'Broj narudžbe';
_fc_props.orderdescription = 'Opis narudžbe';
_fc_props.locationid = 'ID lokacije';
_fc_props.locationdescription = 'Opis lokacije';
_fc_props.productid = 'Identifikacijski broj proizvoda';
_fc_props.productdescription = 'Opis proizvoda';
_fc_props.vehicleid = 'ID vozila';
_fc_props.vehicledescription = 'Opis vozila';
_fc_props.ticketnumber = 'ulaznica #';
_fc_props.ticketdate = 'Datum/vrijeme ulaznice';
_fc_props.drivername = 'Ime vozača';
_fc_props.arriveregion = 'Regija dolaska (TIME)';
_fc_props.leaveregion = 'Napusti regiju (TIME)';
_fc_props.regionduration = 'Trajanje regije';
_fc_props.menu_reports_title_prevailing_wage = 'Prevladavajuća plaća';
_fc_props.slump_calibration_x_not_found_for_y = 'Kalibracija pada "{0}" nije pronađena za kartu {1}';
_fc_props.slump_calibration_not_found = 'Kalibracija pada nije pronađena';
_fc_props.slump_calibration_not_found_description = 'Upozorenje kada kalibracija pada nije pronađena';
_fc_props.error_getting_personal_device_list = 'Pogreška pri dohvaćanju popisa osobnih uređaja';
_fc_props.batch_summary_date_range_note = '*NAPOMENA: ovo je izvješće ograničeno na 3 dana ako se ne primijene filtri. Raspon datuma može ići do 31 dana kada se filtrira prema opremi (maksimalno 10), pogonu (maksimum 1), proizvodu (maksimum 1) ili poslu (maksimum 1)';
_fc_props.select_registration_code_expiration_time_days = 'Odaberite broj dana do isteka registracijskih kodova i kliknite Spremi.';
_fc_props.add_personal_device_expiration = 'Istek (u danima)';
_fc_props.cloud_dvir__vehicle_no = 'Br. vozila';
_fc_props.cloud_dvir__trailer_no = 'Trailer br';
_fc_props.cloud_dvir__vehicle = 'Vozilo';
_fc_props.cloud_dvir__trailer = 'Prikolica';
_fc_props.cloud_dvir__truck_number_label = 'Broj kamiona';
_fc_props.cloud_dvir__trailer_number = 'Broj prikolice';
_fc_props.cloud_dvir__has_trailer = 'Ne vozi se s prikolicom';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Ovdje unesite broj svoje prikolice';
_fc_props.work_order = 'Radni nalog';
_fc_props.unable_to_determine_slump = 'Nije moguće odrediti Slump';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Odredite cjelokupno stanje prikolice';
_fc_props.defaultTrailer = 'Zadana najava';
_fc_props.trailer_type_column_billable = 'Naplativo';
_fc_props.trailer_type_column_notes = 'Bilješke';
_fc_props.trailer_type_column_date = 'Datum';
_fc_props.trailer_type_column_order = 'Redoslijed';
_fc_props.trailer_type_column_ticket = 'Ulaznica';
_fc_props.trailer_type_column_project = 'Projekt';
_fc_props.trailer_type_column_customer = 'Kupac';
_fc_props.trailer_type_column_location = 'Mjesto';
_fc_props.trailer_type_column_vehicle_type = 'Vrsta vozila';
_fc_props.trailer_type_column_product = 'Proizvod';
_fc_props.trailer_type_action_rebill = 'Ponovno naplatiti';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Sinkronizacija glavnih podataka nije uspjela, nije postavljena zadana vrsta opreme';
_fc_props.trailer_type = 'Vrsta prikolice';
_fc_props.trailer_type_is_required = 'Potrebna je vrsta prikolice';
_fc_props.trailer_number_already_exists = 'Broj prikolice već postoji';
_fc_props.plate_number_already_exists = 'Broj tablice već postoji';
_fc_props.trailer_vin_already_exists = 'Trailer Vin već postoji';
_fc_props.trailer_does_not_exist = 'Trailer ne postoji';
_fc_props.trailer_number_is_required = 'Potreban je broj prikolice';
_fc_props.plate_number_is_required = 'Broj tablice je obavezan';
_fc_props.plate_number = 'Broj tablice';
_fc_props.menu_settings_title_trailer = 'Prikolica';
_fc_props.add_trailer = 'Dodaj najavu';
_fc_props.edit_trailer = 'Uredi najavu';
_fc_props.menu_settings_title_equipment = 'Oprema';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorirajte nove tikete iz Command Clouda starije od X minuta';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignoriraj nove tikete iz Command Clouda s datumom slanja i vremenom starijim od X minuta (zadano je prazno i isključeno)';
_fc_props.edit_load_card = 'Uredi karticu opterećenja';
_fc_props.default_layout = 'Zadani izgled';
_fc_props.load_card_empty_fields = 'Pogreška pri spremanju Učitaj karticu: mora biti odabrano jedno ili više polja.';
_fc_props.trailers = 'Prikolice';
_fc_props.error_failed_to_save_data = 'Pogreška: Spremanje podataka nije uspjelo';
_fc_props.slump_increased_plasticizer_added_question = 'Koliko je plastifikatora dodano?';
_fc_props.add_trailer_type = 'Dodajte vrstu najave';
_fc_props.menu_settings_title_trailer_type = 'Vrsta prikolice';
_fc_props.edit_trailer_type = 'Uredi vrstu najave';
_fc_props.single_use_codes = 'Kodovi za jednokratnu upotrebu';
_fc_props.multiple_use_codes = 'Kodovi za višestruku upotrebu';
_fc_props.trailer_type_description_is_required = 'Potreban je opis vrste najave';
_fc_props.trailer_saved = 'Trailer je spremljen';
_fc_props.cloud_dvir__trailer_only_dvir = 'Samo najava DVIR';
_fc_props.choose_file = 'Odaberite Datoteka';
_fc_props.no_file_chosen = 'Nije odabrana datoteka';
_fc_props.please_choose_file = 'Odaberite datoteku';
_fc_props.no_filters = 'Nema odabranih filtara';
_fc_props.remaining_defects = 'Preostali nedostaci';
_fc_props.load_more = 'Učitaj više';
_fc_props.pagination_label = '{0}-{1} od {2}';
_fc_props.extend = 'Proširi';
_fc_props.cloud_dvir__notes_or_photo_required = 'Potrebne su bilješke ili fotografija.';

export default _fc_props;
