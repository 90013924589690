const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Yritä uudelleen';
_fc_props.default = 'Oletus';
_fc_props.origin = 'Lähtökohta';
_fc_props.loading = 'Lataa';
_fc_props.list = 'Lista';
_fc_props.send = 'Lähetä';
_fc_props.error = 'Virhe';
_fc_props.save = 'Tallenna';
_fc_props.delete = 'Poista';
_fc_props.message = 'Viesti';
_fc_props.logout = 'Kirjaudu ulos';
_fc_props.add = 'Lisää';
_fc_props.remove = 'Siirrä';
_fc_props.done = 'Tehty';
_fc_props.other = 'Muu';
_fc_props.interval = 'Aikaväli';
_fc_props.time = 'Aika';
_fc_props.speed = 'Nopeus';
_fc_props.longitude = 'Pituusaste';
_fc_props.latitude = 'Leveysaste';
_fc_props.x_minutes = '{0} Min';
_fc_props.general = 'Yleinen';
_fc_props.messages = 'Viestit';
_fc_props.gps_fixes = 'GPS Korjaukset';
_fc_props.device_id = 'Laitetunnus';
_fc_props.vehicle = 'Ajoneuvo';
_fc_props.employee = 'Työntekijä';
_fc_props.version = 'Versio';
_fc_props.statuses = 'Tilat';
_fc_props.edit = 'Muokkaa';
_fc_props.cancel = 'Peru';
_fc_props.yes = 'Kyllä';
_fc_props.none = 'Ei mitään';
_fc_props.never = 'Ei koskaan';
_fc_props.name = 'Nimi';
_fc_props.true = 'Tosi';
_fc_props.status = 'Tila';
_fc_props.distance = 'Kuljetusmatka';
_fc_props.ok = 'Ok';
_fc_props.login = 'Kirjaudu sisään';
_fc_props.next = 'Seuraava';
_fc_props.menu = 'Valikko';
_fc_props.not_available = 'Ei saatavilla';
_fc_props.back = 'Takaisin';
_fc_props.select = 'Valitse';
_fc_props.dispatch = 'Toimitus';
_fc_props.messaging = 'Viestitys';
_fc_props.plants = 'Tehtaat';
_fc_props.last_plants = 'Viimeisimmät Tehtaat';
_fc_props.vehicle_types = 'Ajoneuvolajit';
_fc_props.employee_types = 'Työntekijälajit';
_fc_props.employee_groups = 'Työntekijäryhmät';
_fc_props.equipment_groups = 'Laitteistoryhmät';
_fc_props.job = 'Työ';
_fc_props.order = 'Tilaus';
_fc_props.ticket = 'lippu #';
_fc_props.small = 'Pieni';
_fc_props.medium = 'Keski';
_fc_props.large = 'Suuri';
_fc_props.reports = 'Raportit';
_fc_props.payroll = 'Palkkalista';
_fc_props.support = 'Tuki';
_fc_props.username = 'Käyttäjänimi';
_fc_props.all = 'Kaikki';
_fc_props.question = 'Kysymys';
_fc_props.select_employee = 'Valitse Työntekijä';
_fc_props.phone = 'Puhelin';
_fc_props.january = 'Tammikuu';
_fc_props.february = 'Helmikuu';
_fc_props.march = 'Maaliskuu';
_fc_props.april = 'Huhtikuu';
_fc_props.may = 'Toukokuu';
_fc_props.june = 'Kesäkuu';
_fc_props.july = 'Heinäkuu';
_fc_props.august = 'Elokuu';
_fc_props.september = 'Syyskuu';
_fc_props.october = 'Lokakuu';
_fc_props.november = 'Marraskuu';
_fc_props.december = 'Joulukuu';
_fc_props.are_you_sure_you_want_to_delete = 'Haluatko varmasti poistaa';
_fc_props.unknown = 'Tuntematon';
_fc_props.select_all = 'Valitse Kaikki';
_fc_props.equipment = 'Välineistö';
_fc_props.region = 'Alue';
_fc_props.no_data = 'Ei Tietoa';
_fc_props.hotspot = 'Levähdyspaikka';
_fc_props.address = 'Osoite';
_fc_props.retrieving_address = 'Haetaan osoitetta...';
_fc_props.address_not_found = 'Osoitetta ei löytynyt';
_fc_props.active_time = 'Aktiivinen Aika';
_fc_props.destination_time = 'Määränpää Aika';
_fc_props.complete_time = 'Valmistusaika';
_fc_props.status_report = 'Tilaraportti';
_fc_props.export_report = 'Vie Raportti';
_fc_props.download_report = 'Lataa Raportti';
_fc_props.view_report = 'Katso Raportti';
_fc_props.duration = 'Kesto';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Aloituspäivämäärä/-aika';
_fc_props.select_start_date = 'Valitse Aloituspäivämäärä';
_fc_props.end_date_time = 'Päätöspäivämäärä/-aika';
_fc_props.select_end_date = 'Valitse Päätöspäivämäärä';
_fc_props.trip_time = 'Matka-aika';
_fc_props.travel_time = 'Matkustusaika';
_fc_props.description = 'Kuvaus';
_fc_props.map_controls = 'Karttaohjaukset';
_fc_props.road = 'Tie';
_fc_props.aerial = 'Antenni';
_fc_props.hybrid = 'Hybridi';
_fc_props.trip = 'Matka';
_fc_props.map = 'Kartta';
_fc_props.plant = 'Tehdas';
_fc_props.select_date = 'Valitse Päivämäärä';
_fc_props.apply = 'Käytä';
_fc_props.save_settings = 'Tallenna Asetukset';
_fc_props.false = 'Virheellinen';
_fc_props.delete_confirmation = 'Poiston Vahvistus';
_fc_props.last_name = 'Sukunimi';
_fc_props.none_selected = 'Ei Mitään Valittuna';
_fc_props.start_date = 'Aloituspäivämäärä';
_fc_props.group_by = 'Ryhmitä jnkn mukaan';
_fc_props.equipment_employee = 'Laitteisto / Työntekijä';
_fc_props.inactive = 'Toimeton';
_fc_props.active = 'Aktiivinen';
_fc_props.note_checking_may_affect_performance = 'Note: Tarkistus saattaa vaikuttaa suorituskykyyn';
_fc_props.show_distancefuel_used = 'Näytä Etäisyys/Käytetty Polttoaine';
_fc_props.mins = 'Mins';
_fc_props.threshold_time = 'Kynnysaika';
_fc_props.include_voided_tickets = 'Sisällytä Mitätöidyt Liput';
_fc_props.note_all_times_listed_are_in_minutes = 'Huom: Kaikki listatut ajat ovat minuuteissa';
_fc_props.please_select_a_job_order_or_equipment = 'Valitse työ, tilaus, tehdas, laitteisto tai työntekijä';
_fc_props.device = 'Laite';
_fc_props.select_equipment = 'Valitse Laitteisto';
_fc_props.all_equipment_types = 'Kaikki Laitteistolajit';
_fc_props.units = {};
_fc_props.units.liters = 'Litrat';
_fc_props.type = 'Laji';
_fc_props.no_data_available = 'Ei tietoa saatavilla';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Etäisyys ja Polttoaine Alueen mukaan';
_fc_props.menu_settings_title_settings = 'Asetukset';
_fc_props.menu_settings_title_alerts = 'Hälytykset';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Laitteet';
_fc_props.menu_settings_title_hotspots_regions = 'Levähdyspaikat & Alueet';
_fc_props.days = '{0} päivä(t)';
_fc_props.home = 'Koti';
_fc_props.find = 'Etsi';
_fc_props.close_this_panel = 'sulje tämä paneeli';
_fc_props.filters = 'Suodattimet';
_fc_props.start = 'Aloita';
_fc_props.end = 'Lopeta';
_fc_props.location_details = 'Sijaintitiedot';
_fc_props.first_name = 'Etunimi';
_fc_props.material_per_hour = 'Materiaali Per Tunti';
_fc_props.equipment_pound = 'Laitteisto #';
_fc_props.equipment_type = 'Laitteistolaji';
_fc_props.options = 'Valinnat';
_fc_props.zoom_on_cursor_position = 'Lähennä Osoittimen Sijainnilla';
_fc_props.display_equipment_identifier = 'Näytä Laitteisto Tunnistin';
_fc_props.view_equipment = 'Näytä Laitteisto';
_fc_props.view_hotspots = 'Näytä Levähdyspaikat';
_fc_props.view_regions = 'Näytä Alueet';
_fc_props.use_standard_filters = 'Käytä Yleisiä Suodattimia';
_fc_props.home_plant = 'Koti Tehdas';
_fc_props.last_plant = 'Viimeinen Tehdas';
_fc_props.equip_types = 'Laitteis. Lajit';
_fc_props.equip_groups = 'Laitteis. Ryhmät';
_fc_props.orders = 'Tilaukset';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Käytä Laitteisto Suodatinta';
_fc_props.all_selected = 'Kaikki Valittuna';
_fc_props.of = 'määrästä';
_fc_props.your_map_session_has_expired = 'Karttaistuntosi on vanhentunut, Ole hyvä ja Päivitä sivu.';
_fc_props.equipment_short = 'Lait.';
_fc_props.job_info_for = 'Työtiedot kohteelle';
_fc_props.order_info_for = 'Tilaustiedot kohteelle';
_fc_props.ticket_info_for_vehicle = 'Lipputiedot Ajoneuvolle';
_fc_props.inbox = 'Saapuneet';
_fc_props.outbox = 'Lähtevät';
_fc_props.alerts = 'Hälytykset';
_fc_props.critical = 'Kriittiset';
_fc_props.important = 'Tärkeät';
_fc_props.informational = 'Tiedottavat';
_fc_props.view_routes = 'Katso Reitit';
_fc_props.hours_size = 'Tunnit Koko';
_fc_props.used_count = 'Käytettyjen Lasku';
_fc_props.last_login = 'Viimeisin Sisäänkirjautuminen';
_fc_props.last_vehicle = 'Viimeisin Ajoneuvo';
_fc_props.regions = 'Alueet';
_fc_props.driver = 'Kuljettaja';
_fc_props.js_delete = 'Poista';
_fc_props.move = 'Siirrä';
_fc_props.edit_hotspot = 'Muokkaa Levähdyspaikkaa';
_fc_props.adjust_shape = 'Säädä Kuviota';
_fc_props.edit_region = 'Muokkaa Aluetta';
_fc_props.enter_a_start_address = 'Ole hyvä ja syötä aloitus Osoite';
_fc_props.select_a_start_hotspot = 'Ole hyvä ja syötä aloitus Levähdyspaikka';
_fc_props.failed_to_find_start_hotspot = 'Epäonnistui löytämään aloitus Levähdyspaikkaa.';
_fc_props.invalid_latitudelongitude = 'Virheellinen Leveys-/Pituusaste';
_fc_props.enter_an_end_address = 'Ole hyvä ja syötä lopetus Osoite';
_fc_props.select_an_end_hotspot = 'Ole hyvä ja syötä lopetus Levähdyspaikka';
_fc_props.failed_to_find_end_hotspot = 'Epäonnistui löytämään lopetus Levähdyspaikkaa.';
_fc_props.print = 'Tulosta';
_fc_props.these_directions_are_for_informational_purposes = 'Nämä ajoneuvot ovat tarkoitettu ainoastaan tiedoksi. Mitään taetta ei tehdä liittyen niiden täydellisyyteen tai tarkkuuteen. Rakennusprojektit, liikenne, tai muut tapahtumat voivat aiheuttaa, että todelliset olosuhteet vaihtelevat näihin tuloksiin nähden.';
_fc_props.enter_an_address = 'Ole hyvä ja syötä Osoite';
_fc_props.select_a_hotspot = 'Ole hyvä ja valitse Levähdyspaikka';
_fc_props.failed_to_find_hotspot = 'Epäonnistui löytämään Levähdyspaikkaa.';
_fc_props.select_a_vehicle = 'Ole hyvä ja valitse Ajoneuvo';
_fc_props.failed_to_find_vehicle = 'Epäonnistui löytämään Ajoneuvoa.';
_fc_props.failed_to_find_address = 'Epäonnistui löytämään Osoitetta';
_fc_props.failed_to_find_vehicles = 'Epäonnistui löytämään ajoneuvoja hakukriteereistä.';
_fc_props.get_address = 'Hae osoite';
_fc_props.results_for = 'Tulokset kohteelle';
_fc_props.error_retrieving_results = 'Virhe Hakiessa Tuloksia';
_fc_props.no_results_found = 'Tuloksia Ei Löytynyt';
_fc_props.address_is_required = 'Osoite Vaaditaan';
_fc_props.city_is_required = 'Kaupunki Vaaditaan';
_fc_props.set_location = 'Aseta Sijainti';
_fc_props.set_area_of_interest = 'Aseta Kiinnostuksen Alue';
_fc_props.center_map_here = 'Keskitä Kartta Tässä';
_fc_props.zoom_to_street_level = 'Lähennä Katutasolle';
_fc_props.set_start = 'Aseta Aloitus';
_fc_props.set_end = 'Aseta Lopetus';
_fc_props.delete_node = 'Poista Yhtymäkohta';
_fc_props.create_a_hotspot = 'Luo Levähdyspaikka';
_fc_props.create_a_region = 'Luo Alue';
_fc_props.toggle_shapes = 'Vaihda Kuvioita';
_fc_props.refresh_map = 'Päivitä Kartta';
_fc_props.more_options = 'Lisävalinnat';
_fc_props.recenter_map = 'Keskitä Kartta Uudelleen';
_fc_props.zoom_to_vehicle = 'Lähennä Ajoneuvoon';
_fc_props.apply_filters = 'Aseta Suodattimet';
_fc_props.grid_view = 'Ruudukkonäkymä';
_fc_props.list_view = 'Listanäkymä';
_fc_props.click_to_sort = 'Klikkaa järjestääksesi';
_fc_props.message_history = 'Viestihistoria';
_fc_props.equipment_types = 'Laitteistolajit';
_fc_props.send_message = 'Lähetä Viesti';
_fc_props.last_load = 'Viimeisin Lastaus';
_fc_props.last_load_on = 'Viimeisin Lastaus Päällä';
_fc_props.manual_logout = 'Manuaalinen Uloskirjautuminen';
_fc_props.manual_login = 'Manuaalinen Sisäänkirjautuminen';
_fc_props.no_employees_logged_in = 'Ei Työntekijöitä Sisäänkirjautuneena';
_fc_props.select_phone = 'Valitse Puhelin';
_fc_props.you_must_select_a_phone = 'Sinun täytyy valita Puhelin, Laitteisto ja Työntekijä';
_fc_props.are_you_sure_you_want_to_logout = 'Oletko varma, että haluat uloskirjautua?';
_fc_props.fix_time = 'Korjaa Aika';
_fc_props.to_next_fix = 'Seuraavaan Korjaukseen';
_fc_props.minutes_to_next_fix = 'Minuuttia Seuraavaan Korjaukseen';
_fc_props.liters = 'Litrat';
_fc_props.destination = 'Määränpää';
_fc_props.return = 'Paluu';
_fc_props.product = 'Tuote';
_fc_props.no_records_found = 'Tietoja ei löytynyt.';
_fc_props.vehicle_type = 'Ajoneuvolaji';
_fc_props.view_in_map = 'Katso Kaikki Kartalla';
_fc_props.uploads = 'Lataukset';
_fc_props.upload_csv_file = 'Lataa CSV -tiedosto';
_fc_props.permanent = 'Pysyvä';
_fc_props.expire_time = 'Eräännytä Aika';
_fc_props.please_select_x_for_tickets_marked_active = 'Ole hyvä ja Valitse {0} Lipuille merkittyinä AKTIIVINEN';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Lukuisat Liput merkittynä AKTIIVINEN samalle {0}';
_fc_props.failure_connecting_to_servlet = 'Virhe yhdistäessä palvelimeen.';
_fc_props.status_time_summary = 'Tila-aika Yhteenveto';
_fc_props.view_in_map_one = 'Katso Kartalla';
_fc_props.question_report = 'Kysymys Raportti';
_fc_props.end_date = 'Lopetuspäivämäärä';
_fc_props.trailer_number = 'Perävaunu Numero';
_fc_props.region_panel = 'Alueruutu';
_fc_props.hotspot_panel = 'Levähdyspaikkaruutu';
_fc_props.shape = 'Muoto';
_fc_props.circle = 'Ympyrä';
_fc_props.polygon = 'Monikulmio';
_fc_props.click_the_map_to_set_the_location = 'Klikkaa karttaa asettaaksesi haluttu sijainti.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Klikkaa karttaa asettaaksesi levähdyspaikan keskusta.';
_fc_props.click_the_map_to_set_the_region_center = 'Klikkaa karttaa asettaaksesi alueen keskusta.';
_fc_props.click_and_drag_points = 'Klikkaa ja vedä pisteitä muokataksesi kuviota. Klikkaa TALLENNA tallentaaksesi muutokset.';
_fc_props.size = 'Koko';
_fc_props.are_you_sure_you_want_to_move_this = 'Oletko varma, että haluat siirtää tämän';
_fc_props.are_you_sure_you_want_to_delete_this = 'Oletko varma, että haluat poistaa tämän';
_fc_props.please_enter_a_valid_size = 'Anna kelvollinen koko';
_fc_props.failed_to_save = 'Epäonnistui tallentamaan.';
_fc_props.failed_to_move = 'Epäonnistui siirtämään.';
_fc_props.failed_to_delete = 'Epäonnistui poistamaan.';
_fc_props.x_hours = '{0} tuntia';
_fc_props.hide_hotspots = 'Piilota Levähdyspaikat';
_fc_props.show_regions = 'Näytä Alueet';
_fc_props.show_hotspots = 'Näytä Levähdyspaikat';
_fc_props.hide_regions = 'Piilota Alueet';
_fc_props.numeric = 'Numeerinen';
_fc_props.message_type = 'Viestilaji';
_fc_props.text = 'Teksti';
_fc_props.yes_no = 'Kyllä/Ei';
_fc_props.responses = 'Vastaukset';
_fc_props.disabled = 'Pois Käytöstä';
_fc_props.group_name = 'Ryhmä Nimi';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Oletko varma, että haluat poistaa \'{0}\' muodon?';
_fc_props.phone_number = 'Puhelinnumero';
_fc_props.interface_pound = 'Rajapinta #';
_fc_props.field = 'Kenttä';
_fc_props.default_label = 'Oletus Leima';
_fc_props.custom_label = 'Oma Leima';
_fc_props.you_must_enter_a_label = 'Sinun täytyy syöttää Leima.';
_fc_props.enabled = 'Käytössä';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Lippu Integraatio';
_fc_props.settings.geofence_off_open_hotspots = 'Geoaita pois päältä avoimet Levähdyspaikat';
_fc_props.settings.associate_open_hotspots_wticket = 'Liitä Avoimet Levähdyspaikat Lippuun';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Sallli avoimien levähdyspaikkoijen tai ainoastaan levähdyspaikat, jotka on liitetty Kuljettajan Lippuun, geoaitaus pois päältä';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Tulisiko avoimet Levähdyspaikat liittää Lippuihin kun ajoneuvo pääsee niihin';
_fc_props.settings.void_ticket_without_destination = 'Mitätöi lippu ilman määränpäätä';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'jos lippu vastaanotetaan kun toinen on aktiivinen, jos aktiivisella lipulla ei ole määränpäätä, lippu mitätöidään.';
_fc_props.settings.autocreate_hotspots = 'Automaattisesti luo Levähdyspaikkoja';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Tulisiko levähdyspaikkoja automaattisesti luoda määränpää osoitteesta, kun lippu vastaanotetaan';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Automaattisesti aktivoi Lippu Vastaanotettua';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Onko lippu aktiivinen kun TrackIt vastaanottaa sen (Oletus \'tosi\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Automaattisesti Sulje Aktiivinen Lippu Vastaanotettua Minuutteja';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Kuinka kauan nykyisen aktiivisen lipun täytyy olla aktiivinen ennen kuin se suljetaan automaattisesti(Oletus \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Lippu Mak Aktivointi Ikä Minuutit';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Minuuteissa kuinka vanha lipun täytyy olla aktivoidessa sen TrackItissa (Oletus \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Max Levähdyspaikka Käyttämättömät Päivät';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Asettaa max päivät joita Levähdyspaikka voi olla käyttämätön ja silti ottaa takaisin käyttöön';
_fc_props.settings.radius_multiplier = 'Säde Kerroin';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Millä säde kerrotaan kun lipun vastaanottaa lähetysjärjestelmästä';
_fc_props.settings.ticket_message_date_format = 'Lippu Viesti Päivämäärä Muoto';
_fc_props.am = 'ap';
_fc_props.pm = 'ip';
_fc_props.settings.ticket_message = 'Lippu Viesti';
_fc_props.settings.add_ticket_element = 'Lisää Lippu Elementti';
_fc_props.driver_performance = 'Kuljettaja Suorituskyky';
_fc_props.update_failed_can_not_connect = 'Päivitys Epäonnistui: Ei pysty yhdistämään palvelimeen.';
_fc_props.unknown_error = 'Tuntematon Virhe';
_fc_props.selected = 'Valittu';
_fc_props.web_service = 'Verkkopalvelu';
_fc_props.error_processing_request = 'Virhe käsitellessä pyyntöä.';
_fc_props.email_address = 'Sähköposti';
_fc_props.id = 'ID';
_fc_props.last_used = 'Viimeksi Käytetty';
_fc_props.error_invalid_vehiclenum = 'Virhe Virheellinen ajoneuvonumero';
_fc_props.error_vehicle_is_not_logged_in = 'Virhe: Ajoneuvo ei ole sisäänkirjautunut.';
_fc_props.users = 'Käyttäjät';
_fc_props.you_dont_have_access_to_this_page = 'Sinulla ei ole oikeutta tarkastella tätä sivua. Ota yhteyttä järjestelmänvalvojaan saadaksesi käyttöoikeuden.';
_fc_props.previous_week = 'Edellinen Viikko';
_fc_props.status_breakdown = 'Tila Erittely';
_fc_props.select_plant_or_employee_group = 'Sinun täytyy valita joko tehdas- tai työntekijäryhmä.';
_fc_props.cannot_select_plant_and_employee_group = 'Sinä et voi lisätä sekä tehdas- että työntekijäryhmää.';
_fc_props.odometer_must_be_a_number = 'Aloitusmatkamittarin täytyy olla numero';
_fc_props.ending_odometer_must_be_a_number = 'Lopetusmatkamittarin täytyy olla numero';
_fc_props.beginning_hour_meter_must_be_a_number = 'Aloitus Tuntimittarin täytyy olla numero';
_fc_props.ending_hour_meter_must_be_a_number = 'Lopetus Tuntimittarin täytyy olla numero';
_fc_props.error_loading_status_change_page = 'Virhe ladatessa Tilamuutos sivua.';
_fc_props.x_is_an_invalid_time_format = '{0} on virheellinen aikamuoto sen täytyy olla muodossa VVVV-KK-PP TT:MM:SS';
_fc_props.x_for_schedule_login = '{0} ajoitetulle sisäänkirjautumiselle';
_fc_props.x_for_schedule_logout = '{0} ajoitetulle uloskirjautumiselle';
_fc_props.x_for_actual_login = '{0} todelliselle sisäänkirjautumiselle';
_fc_props.x_for_actual_logout = '{0} todelliselle uloskirjautumiselle';
_fc_props.by_refreshing_the_time_card_detail = 'Päivittämällä Aikakortti Tieto menetät kaikki muutokset, jatketaan?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Oletko varma, että haluat poistaa kohteen {0}?';
_fc_props.settings.command_mobile_ticket_url = 'Käsky Mobiililippu URL';
_fc_props.year = 'Vuosi';
_fc_props.make = 'Valmistaja';
_fc_props.model = 'Malli';
_fc_props.settings.command_mobile_ticket_password = 'Command Mobile Ticket Salasana';
_fc_props.settings.command_mobile_ticket_auth_token = 'Command Mobile Ticket Auth Token';
_fc_props.target = 'Tavoite';
_fc_props.number_of_digits = 'Numeroiden';
_fc_props.number_of_integer_digits = 'Lukumäärä Integer numerot';
_fc_props.number_of_fractional_digits = 'Määrä desimaaleja';
_fc_props.search = 'Haku';
_fc_props.settings.command_mobile_ticket_date_order = 'Command Mobile Ticket Järjestys';
_fc_props.settings.command_mobile_ticket_date_length = 'Command Mobile Ticket Päivämäärä Pituus';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command Mobile Ticket Päivämäärän erotin';
_fc_props.settings.command_mobile_ticket_slash = '/ (Kauttaviiva)';
_fc_props.settings.command_mobile_ticket_dash = '- (Viiva)';
_fc_props.settings.command_mobile_ticket_period = '. (Kauden)';
_fc_props.settings.command_mobile_ticket_space = '(Välilyönti)';
_fc_props.settings.command_mobile_ticket_time_length = 'Command Mobile Ticket Time Pituus';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command Mobile Ticket Aikaerotin';
_fc_props.settings.command_mobile_ticket_colon = ': (Kaksoispiste)';
_fc_props.settings.command_mobile_ticket_time_format = 'Command Mobile Ticket aikamuoto';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Käyttöliittymä määrä';
_fc_props.mechanic = 'Mekaanikko';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Poll Interval (sekuntia)';
_fc_props.driving_time = 'Ajoaika';
_fc_props.find_near_plants = 'Etsi läheltä Kasvit';
_fc_props.please_create_hot_spot_first = 'Luo hotspot ensin';
_fc_props.please_enter_hot_spot_description = 'Anna hot spot kuvaus';
_fc_props.please_select_a_hot_spot_type = 'Valitse hot spot tyyppi';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Send Kuljettajan Sähköposti';
_fc_props.directions_not_found = 'Virheellinen {0} Osoite. Ajo ei löytynyt.';
_fc_props.ws_user = 'käyttäjä';
_fc_props.ws_service = 'palvelu';
_fc_props.ws_method = 'Menetelmä';
_fc_props.ws_params = 'parametrien';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Alkuperä ja Destination';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Auttaa';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Käytä Alt Työntekijän Number';
_fc_props.settings.command_mobile_ticket_android_url = 'Android Mobile Ticket URL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Ticket Secret';
_fc_props.employee_x_has_no_logout_on_y = 'Työntekijän {0} ei ole logout Vaihdoksen alkaen {1}.';
_fc_props.phone_number_has_duplicates = 'Puhelinnumerot ovat päällekkäisiä merkintöjä';
_fc_props.ounce = 'Unssi';
_fc_props.water_extra = 'vesi Extra';
_fc_props.always = 'Aina';
_fc_props.work_types_must_be_unique = 'Työ Tyypit on oltava yksilöllinen';
_fc_props.manufacturer = 'Valmistaja';
_fc_props.need_to_enter_seconds = 'Täytyy syöttää sekuntia.';
_fc_props.copy = 'Kopio';
_fc_props.create_in_existing_required = 'Luo nykyisissä on valittava luoda roaming hotspot.';
_fc_props.break = 'Tauko';
_fc_props.add_export_data_type = 'Lisää vientitietotyyppi';
_fc_props.edit_export_data_type = 'Muokkaa vientitietotyyppiä';
_fc_props.delete_export_data_type = 'Poista vientitietotyyppi';
_fc_props.export_data_type = 'Vie tietotyyppi';
_fc_props.error_updating_export_data_type = 'Virhe tietojen vientitietojen päivittämisessä';
_fc_props.settings.address_key = 'Osoitekirja';
_fc_props.settings.address_key_hover = 'Osoitteen avainasetukset';
_fc_props.settings.address_key_options = 'Osoitteen avainasetukset';
_fc_props.more_filter_options = 'Lisää suodattimia';
_fc_props.unauthorized_mapit_app = 'MapIt-version tämä versio ei ole sallittua, päivitä MapIt mahdollisimman pian.';
_fc_props.zello_timeout = 'Aikakatkaisu tuli Zellon yhteyteen.';
_fc_props.user_agreement_text = 'Kirjautumalla sisään suostut';
_fc_props.user_agreement = 'Käyttäjäsopimus';
_fc_props.upload = 'upload';
_fc_props.x_mins_left = '{0} min (s) jäljellä';
_fc_props.continuous_location_tracking_colon = 'Jatkuva sijainninseuranta:';
_fc_props.hired_hauler_cannot_register_device = 'Vuokraaja ei voi rekisteröidä uutta laitetta.';
_fc_props.timecard_does_not_contain_shift = 'Työntekijän aikakortti ei sisällä siirtoa (Time Card Info)';
_fc_props.timecard_not_found = 'Työntekijäaikakorttia ei löydy';
_fc_props.vehicle_must_be_assigned_for_clt = 'Ajoneuvo on osoitettava seurata sijainteja.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Ajoneuvoa ei ole määritetty, jatkuva seuranta poistetaan käytöstä.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Kadonnut yhteys EDC: hen. Tarkista, että WiFi ja / tai Bluetooth ovat käytössä.';
_fc_props.acknowledge_all = 'Vahvista kaikki';
_fc_props.expand = 'Laajentaa';
_fc_props.collapse = 'Romahdus';
_fc_props.expand_all = 'Laajenna kaikki';
_fc_props.collapse_all = 'Kutista kaikki';
_fc_props.last_update = 'Viimeisin päivitys';
_fc_props.acknowledge_alert = 'Vahvista Alert';
_fc_props.acknowledge_message = 'Vahvista viesti';
_fc_props.acknowledge_all_alerts = 'Kuittaa kaikki ilmoitukset';
_fc_props.acknowledge_all_messages = 'Kuittaa kaikki viestit';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Toleranssin on oltava kelvollinen kokonaisluku välillä -99 ja 99 (molemmat mukana)';
_fc_props.gps_permission_required = 'GPS-käyttöoikeus vaaditaan';
_fc_props.permissions_required = 'Vaaditut luvat';
_fc_props.change_user = 'Vaihda käyttäjä';
_fc_props.account_not_registered_with_carrier = 'Tätä tiliä ei ole rekisteröity operaattorissa';
_fc_props.slump_loss = 'Slump Loss';
_fc_props.slump_loss_help = 'Slump Loss -ohje';
_fc_props.did_not_receive_any_edc_devices = 'Ei saanut EDC-laitteita.';
_fc_props.edc_record_not_found = 'EDC-tietuetta ei löytynyt';
_fc_props.disable_hotspot_prompt = 'Napauta "OK", kun sinua kehotetaan poistamaan hotspot.';
_fc_props.edc_device_is_in_use = 'EDC {0} on käytössä.';
_fc_props.an_unknown_exception_has_occurred = 'Tuntematon poikkeus on tapahtunut';
_fc_props.login_has_timed_out = 'Kirjaudu sisään on päättynyt';
_fc_props.use_paper_logs = 'Käytä paperilokeja';
_fc_props.eld_login_failed = 'Palvelun tunnit eivät onnistu';
_fc_props.setup_time_off_type = 'Aseta aikakatkaisutyyppi.';
_fc_props.view_load_properties = 'Näytä Lataa ominaisuudet';
_fc_props.couldnt_download_edc_firmware = 'EDC-laiteohjelmistoa ei voitu ladata.';
_fc_props.password_incorrect = 'Salasana väärin';
_fc_props.edc_configuration_password_prompt = 'Laitteen tilasta johtuen sinun on annettava asennusohjelman salasana määrittämistä varten.';
_fc_props.edc_configuration_check_failed = 'EDC-palvelintietueiden validointi epäonnistui. Tarkista virheilmoitukset yksityiskohtaisesti.';
_fc_props.configuration_issues_prompt = 'Seuraavat ongelmat havaittiin määritettyä valittua EDC: tä määritettyä trukkia varten';
_fc_props.checking_password = 'Tarkistetaan salasana';
_fc_props.reset_password_instructions_sent = 'Ohjeet sähköpostiosoitteeseen {0} lähetetyn salasanasi palauttamiseksi.';
_fc_props.reset_password_failed = 'Salasanan palauttaminen epäonnistui, yritä uudelleen.';
_fc_props.forgot_password = 'Unohtuiko salasana?';
_fc_props.enter_valid_email = 'Virheellinen sähköpostiosoite, kirjoita kelvollinen sähköpostiosoite ja yritä uudelleen.';
_fc_props.issues_while_updating_edc_record = 'EDC asetettiin onnistuneesti. Kuitenkin TrackIt-palvelimen EDC-tietueissa on ongelmia, jotka voivat aiheuttaa sen, että se ei toimi. Soita tukeen korjataksesi tiedot.';
_fc_props.sending = 'Lähetetään ...';
_fc_props.minimum_ratio = 'Vähimmäissuhde';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Rummun kierrosluku ei ole alueella';
_fc_props.probe_failure = 'Koettimen vika';
_fc_props.unknown_probe_data_failure = 'Tuntematon koetindatavirhe';
_fc_props.no_loaded_status_meaning = 'Ei ladattua tilaa';
_fc_props.no_depart_status_meaning = 'Ei lähtötilaa';
_fc_props.no_arrive_status_meaning = 'Ei saapumistilaa';
_fc_props.no_begin_work_status_meaning = 'Ei aloita työtilaa';
_fc_props.no_fully_mixed_status_meaning = 'Ei täysin sekoitettua tilaa';
_fc_props.no_begin_work_or_at_job_status = 'Aloita työ- tai työpaikalla -tilat eivät ole';
_fc_props.driver_didnt_follow_procedure = 'Kuljettaja ei noudattanut menettelyä (ei hidastaa rumpua)';
_fc_props.probe_malfunction = 'Anturin toimintahäiriö (sisäinen vika jne.)';
_fc_props.no_data_from_probe = 'Ei tietoja anturista (tietojen häviäminen)';
_fc_props.missing_probe_calibration = 'Ei anturin kalibrointitietoja';
_fc_props.probe_pressure_not_in_range = 'Koettimen paine ei ole alueella';
_fc_props.arrival = 'saapuminen';
_fc_props.small_load = 'Pieni kuorma';
_fc_props.dry_slump = 'KUIVA SLUMP';
_fc_props.probe_remixing_turns = 'remiksaus-';
_fc_props.turns_needed_to_remix = 'Vettä lisätty - Lisäsekoitusta tarvitaan';
_fc_props.probe_remixing_finished = 'Remixing valmis';
_fc_props.probe_battery_low_alert = 'Anturin akun heikko hälytys';
_fc_props.probe_battery_low_message = 'Anturin akku on loppunut';
_fc_props.probe_battery_low = 'Anturin akku on loppunut';
_fc_props.alert_when_probe_battery_low = 'Hälytys, kun anturin akku on tyhjä';
_fc_props.probe_sensor_fatal_failure = 'Anturin kohtalokas vika';
_fc_props.probe_thermistor_failure = 'Termistorivika';
_fc_props.probe_sensor_stuck_in_concrete = 'Anturi juuttunut betoniin';
_fc_props.probe_sensor_over_weight = 'Anturi ylipainoinen';
_fc_props.probe_batteries_low = 'Akku vähissä';
_fc_props.no_probe_data = 'Ei anturin tietoja:';
_fc_props.check_probe = 'Tarkista anturi';
_fc_props.service_probe = 'Huoltoanturi';
_fc_props.below_x = '{0} alapuolella';
_fc_props.above_x = 'Yli {0}';
_fc_props.probe_serial_number_invalid_format = 'Koettimen sarjanumero ei ole kelvollisessa muodossa, kuten näytetään kentän paikkamerkinnässä';
_fc_props.talk_dnd_permission_prompt = 'Keskustelu vaatii ÄLÄ DISTURB-lupaa.';
_fc_props.max = 'max';
_fc_props.status_changed_quickly = 'Tila muuttui liian nopeasti';
_fc_props.is_empty = 'On tyhjä';
_fc_props.slump_increased_plasticizer_question = 'Lisättiinkö pehmitintä?';
_fc_props.slump_increased_water_added_question = 'Kuinka paljon mittaamatonta vettä lisättiin?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Koettimen ja jakelun seurantaa ei tueta yhdessä samassa laitteessa.';
_fc_props.no_data_from_sensor = 'Ei tietoja anturista (tietojen häviäminen)';
_fc_props.dvir = 'Dvir';
_fc_props.system_type = 'Järjestelmän tyyppi';
_fc_props.magnet_count = 'Magneettimäärä';
_fc_props.sensors_setup = 'Anturien asetukset';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM vaatii I / O Box MAC -osoitteen, rumputyypin, DCM-tyypin ja magneettimäärän asettamisen.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Lippujen määrä';
_fc_props.drs_current_volume = 'Nykyinen äänenvoimakkuus';
_fc_props.drs_current_water_cement_ratio = 'Nykyinen vesi / sementtisuhde';
_fc_props.drs_target_water_cement_ratio = 'Kohdevesi / sementtisuhde';
_fc_props.drs_total_revolutions = 'Kaikki yhteensä';
_fc_props.drs_current_volume_lp_small = 'Nykyinen';
_fc_props.drum_revolutions = 'Rumpukierrokset';
_fc_props.drs_total_revolutions_lp_small = 'Kaikki yhteensä';
_fc_props.drs_charging_revolutions_lp_small = 'Lataus';
_fc_props.drs_discharging_revolutions_lp_small = 'Poistaminen';
_fc_props.drs_target_wc_lp_small = 'Tavoite W / C';
_fc_props.drs_current_wc_lp_small = 'Nykyinen W / C';
_fc_props.drs_ticketed_volume_lp_small = 'Lippu';
_fc_props.android_location_disclosure = 'TrackIt käyttää hienoa ja karkeaa sijaintia sijaintitietojen keräämiseen taustalla myös silloin, kun sitä ei käytetä, mahdollistaakseen automaattisen tilan ja reaaliaikaisen kartan näkyvyyden lähettämistä varten.';
_fc_props.android_location_usage = 'Sijainnin ilmoittaminen';
_fc_props.end_pour_trigger_threshold = 'Lopeta kaatumisen käynnistyskynnys';
_fc_props.drs_wiring_error = 'Johdotusvirhe';
_fc_props.drs_wiring_pulse_error = 'Johdotuksen pulssivirhe';
_fc_props.drs_wiring_magnet_error = 'Johdotuksen magneettivirhe';
_fc_props.drs_magnet_missing_error = 'Magneetti puuttuu virheestä';
_fc_props.android_location_disclosure_3p = 'TrackIt kerää sijaintitiedot taustalla auttaakseen tilaa ja reaaliaikaista kartan näkyvyyttä lähetystä varten. Lähettäjältä ajoitettua sijaintia ei jaeta.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Valitse joko Full MDM tai Trackit-etätuki';
_fc_props.move_up = 'Liiku ylös';
_fc_props.move_down = 'Siirry alas';
_fc_props.driver_not_found = 'Ohjainta ei löydy.';
_fc_props.multiple = 'Useita';
_fc_props.water_meter_installed = 'Vesimittari asennettu';
_fc_props.delete_waypoint = 'Poista reittipiste';
_fc_props.wash_sensor_installed = 'Pesuanturi asennettuna';
_fc_props.washing_start_time = 'Pesun aloitusaika';
_fc_props.end_washing = 'Lopeta pesu';
_fc_props.variable_rpms = 'Var kierrosluvut';
_fc_props.no_slump = 'Ei lamaantumista';
_fc_props.end_washing_duration = 'Lopeta pesun kesto';
_fc_props.privacy_policy = 'Tietosuojakäytäntö';
_fc_props.privacy_policy_text = 'Olen lukenut ja hyväksynyt';
_fc_props.login_fail_accept_privacy_policy = 'Tietosuojakäytäntö on hyväksyttävä jatkaaksesi.';
_fc_props.rotation_sensor = 'Pyörimisanturi';
_fc_props.pto_sensor = 'Voimanottoakselin anturi';
_fc_props.pto_sensor_installed = 'Nivelanturi asennettuna';
_fc_props.polarity_reversed = 'Käänteinen napaisuus';
_fc_props.pto_sensor_type = 'Voimanottoanturin tyyppi';
_fc_props.sensor_type = 'Anturin tyyppi';
_fc_props.prompt_select_pto_sensor_type = 'Sinun on valittava "voimanottoanturin tyyppi", jos olet valinnut pääanturin tyypiksi "voimanottoanturin".';
_fc_props.priming_turns_at_full_load = 'Pohjustus kääntyy täydellä kuormalla';
_fc_props.priming_turns_at_ten_percent_load = 'Pohjustus kääntyy 10%: n kuormituksella';
_fc_props.weight_units = 'Painoyksiköt';
_fc_props.settings.send_status = 'Lähetä tila / sijainti tapahtumasillalle';
_fc_props.volume_difference = 'Äänenvoimakkuuden ero';
_fc_props.mobile_ticket_iframe_url = 'Mobiililipun IFrame-URL';
_fc_props.mobile_ticket_user = 'Mobiililipun käyttäjä';
_fc_props.mobile_ticket_password = 'Mobiililipun salasana';
_fc_props.current_truck_status = 'Kuorma -auton nykyinen tila';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Maksimikierrokset laukaisevat';
_fc_props.alerts_by_type = 'Varoitukset tyypin mukaan';
_fc_props.water_flow_meter = 'Veden virtausmittari';
_fc_props.flow_meter_no_pulses_warning = 'Ei palkokasveja - varoitus';
_fc_props.flow_meter_no_pulses_critical = 'Ei palkokasveja - kriittinen';
_fc_props.flow_meter_excessive_pulses = 'Liialliset palkokasvit';
_fc_props.flow_meter_constantly_flowing = 'Jatkuvasti virtaava';
_fc_props.flow_meter_good = 'Hyvä';
_fc_props.whats_new = 'Mikä on uutta';
_fc_props.whats_new_widget_text = 'Tiesitkö, että päivitämme TrackIt -ohjelman vähintään kerran kuukaudessa? Hanki viimeisimmät päivitykset ja vinkit.';
_fc_props.get_the_scoop = 'Hanki kauha';
_fc_props.trackit_insights = 'Seuraa oivalluksia';
_fc_props.business_analytics_blurb = 'Liiketoiminta -analytiikka, joka parantaa tulosta, sisältyy nyt TrackItiin';
_fc_props.find_out_more = 'Lue lisää';
_fc_props.daily_breakdown = 'Päivittäinen erittely';
_fc_props.question_summary = 'Kysymysten yhteenveto';
_fc_props.trip_summary = 'Matkan yhteenveto';
_fc_props.manage_employees = 'Hallitse työntekijöitä';
_fc_props.manage_hotspots = 'Hallitse hotspotteja';
_fc_props.manage_devices = 'Hallitse laitteita';
_fc_props.manage_equipment = 'Hallitse laitteita';
_fc_props.manage_users = 'Hallitse käyttäjiä';
_fc_props.manage_statuses = 'Hallitse tiloja';
_fc_props.manage_alerts = 'Hallitse hälytyksiä';
_fc_props.popular_report_links = 'Suositut raporttilinkit';
_fc_props.popular_settings_links = 'Suosittujen asetusten linkit';
_fc_props.sidemenu_settings = 'asetukset';
_fc_props.loads_per_driver = 'Kuormat kuljettajaa kohden';
_fc_props.please_select_application = 'Valitse vähintään yksi sovellus';
_fc_props.external_app_processing = 'Käsittely ulkoisella sovelluksella';
_fc_props.external_app_sent = 'Lähetetty ulkoiseen sovellukseen';
_fc_props.external_app_received = 'Vastaanotettu ulkoisella sovelluksella';
_fc_props.wait_time_at_job = 'Odota aikaa työssä';
_fc_props.wait_time_at_plant = 'Odota aikaa tehtaalla';
_fc_props.loading_time = 'Latausaika';
_fc_props.unloading_time = 'Purkausaika';
_fc_props.pre_post_trip = 'Ennen/Jälkeen Matka';
_fc_props.update_thresholds = 'Päivitä kynnykset';
_fc_props.green = 'Vihreä';
_fc_props.yellow = 'Keltainen';
_fc_props.red = 'Punainen';
_fc_props.between = 'Välillä';
_fc_props.num_of_loads = 'Kuormien määrä';
_fc_props.num_of_drivers = 'Kuljettajien määrä';
_fc_props.status_meaning_not_setup = 'Tila Merkitys ei asetusta';
_fc_props.or_less = 'Tai vähemmän';
_fc_props.or_greater = 'Tai Suurempi';
_fc_props.probe_self_diagnostic_state = 'Anturin itsediagnostiikkatila';
_fc_props.update_current_truck_status_settings = 'Päivitä kuorma-auton nykyiset tilaasetukset';
_fc_props.default_behavior = 'Oletus (10 suosituinta tilaa)';
_fc_props.combine_similar_statuses = 'Yhdistä samat tilat kaikista tyypeistä (silmukat)';
_fc_props.use_other_category = 'Käytä "Muu" -luokkaa';
_fc_props.selected_statuses = 'Valitut tilat';
_fc_props.ideas = 'Ideoita';
_fc_props.air_content = 'Ilman sisältö';
_fc_props.air_measured_time = 'Ilmalla mitattu aika';
_fc_props.ten_max = '10 Max';
_fc_props.loads_per_plant = 'Kuormat laitosta kohti';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Virtausmittarin pulssi tilavuutta kohti virheellinen.';
_fc_props.coming_soon_title = 'Lisää widgetejä tulossa pian';
_fc_props.submit_your_ideas = 'Lähetä ideasi Ideasportaaliimme käyttämällä vasemman navigointivalikon lamppua.';
_fc_props.selected_plants = 'Valitut kasvit';
_fc_props.update_loads_per_plant_settings = 'Päivitä kuormitus laitosta kohti';
_fc_props.default_top_10_plants = 'Oletus (10 parasta kasvia)';
_fc_props.phone_number_will_be_uploaded = 'Laitteen tunnus ladataan rekisteröintiä varten.';
_fc_props.include_deleted = 'Sisällytä Poistettu';
_fc_props.primary_status_type = 'Ensisijainen tilatyyppi';
_fc_props.additional_status_trigger = 'Muut tilalaukaisimet';
_fc_props.update_tickets_destination = 'Päivitä määränpää kaikissa liittyvissä lipuissa';
_fc_props.settings.send_destination_changes_to_connex = 'Lähetä kohteen muutokset Connexille';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Onko lippu aktiivinen, kun TrackIt vastaanottaa sen (oletus "aina")';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Aktivoi automaattisesti seuraava lippu edellisen lipun valmistuttua';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Pitäisikö aktivoida automaattisesti seuraava lippu lipun valmistuttua (oletus "true")';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Tila valmiustilaan, kun aktiivista lippua ei ole';
_fc_props.break_down = 'Hajota';
_fc_props.idle = 'Tyhjäkäynti';
_fc_props.pto_work_maximum_speed_limit = 'Työn enimmäisnopeusrajoitus';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Liitä tilanmuutos, joka täydentää lipun kyseiseen lippuun';
_fc_props.approaching = 'Lähestymässä';
_fc_props.performing_task = 'Tehtävän suorittaminen';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Haluatko varmasti poistaa Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Päivitä lippu {0}. Kohde muuttui.';
_fc_props.trackit_return_destination_changed_to_0 = 'Päivitä lippu {0}. Palautus muutettu.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Lisää Geogate';
_fc_props.driver_enabled = 'Ohjain käytössä';
_fc_props.dispatcher_enabled = 'Dispatcher käytössä';
_fc_props.runsheet_number = 'Ajotaulukon numero';
_fc_props.manual_load = 'Manuaalinen lataus';
_fc_props.edit_geogate = 'Muokkaa Geogatea';
_fc_props.handling = 'Käsittely';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Geogate-paneeli';
_fc_props.vehicle_is_not_logged_in = 'Viestiä ei lähetetty (ajoneuvo ei ole kirjautunut sisään)';
_fc_props.air = 'Ilmaa';
_fc_props.create_a_geogate = 'Luo Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Napsauta karttaa asettaaksesi geogate-pisteet.';
_fc_props.clock_out = 'Leimata itsensä ulos';
_fc_props.handling_operation = 'Käsittelytoiminto';
_fc_props.view_geogates = 'Näytä Geogates';
_fc_props.select_a_geogate = 'Valitse Geogate';
_fc_props.failed_to_find_geogate = 'Geogaten löytäminen epäonnistui';
_fc_props.adjust_geogate = 'Säädä Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate vanhenemispäivämäärä Aika Oletus lisäpäivät';
_fc_props.menu_settings_title_retention = 'Tietojen säilyttäminen';
_fc_props.category = 'Kategoria';
_fc_props.telemetry = 'Telemetria';
_fc_props.ticket_data = 'Lipputiedot';
_fc_props._30_days = '30 päivää';
_fc_props._6_months = '6 kuukautta';
_fc_props._1_year = '1 vuosi';
_fc_props._3_years = '3 vuotta';
_fc_props.new_group = 'Uusi ryhmä';
_fc_props.selected_vehicles = 'Valitut ajoneuvot';
_fc_props.group_name_is_required_field = 'Ryhmän nimi on pakollinen kenttä';
_fc_props.at_least_vehicle_should_be_selected = 'Vähintään 1 ajoneuvo tulee valita';
_fc_props.groups = 'Ryhmät';
_fc_props.trucks = 'Kuorma-autot';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate on poistettu. Päivitä sivu.';
_fc_props.air_timestamp = 'Ilman aikaleima';
_fc_props.fail = 'FAIL';
_fc_props.stuck = 'JUUTTUNUT';
_fc_props.dirty = 'LIKAINEN';
_fc_props.seasonal = 'Kausiluonteinen';
_fc_props.category_details = 'Luokan tiedot';
_fc_props.alert_events = 'Hälytystapahtumat';
_fc_props.driver_performance_infractions = 'Kuljettajan suorituskyvyn rikkomukset';
_fc_props.telemetry_details = 'Sijaintitiedot, yhteyspisteet, aika hotspoteissa, aika alueilla, DCM-tiedot, koetintiedot, moottoritiedot';
_fc_props.ticket_data_details = 'Lipputiedot';
_fc_props.general_details = 'Tilamuutokset, aikakortit, aikataulut';
_fc_props.wash = 'Pestä';
_fc_props.filter_options = 'Suodatinvalinnat';
_fc_props.home_plants = 'Kotikasvit';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO vaatii PTO-anturin tyypin asettamisen.';
_fc_props.invalid_io_box_mac_address = 'Virheellinen I/O-laatikon Mac-osoite';
_fc_props.access_denied = 'Pääsy evätty';
_fc_props.wash_events = 'Pesutapahtumat';
_fc_props.min_speed = 'Min nopeus';
_fc_props.temperature_change = 'Lämpötilan muutos';
_fc_props.degrees_cap = 'astetta';
_fc_props.washout_tracking = 'Washout-seuranta';
_fc_props.approaching_wash = 'Pesua lähestymässä';
_fc_props.arrival_wash = 'Saapumispesu';
_fc_props.performing_wash = 'Pesun suorittaminen';
_fc_props.wash_complete = 'Pesu valmis';
_fc_props.must_be_positive_or_zero = 'Täytyy olla positiivinen tai nolla!';
_fc_props.min_speed_greater_than_max = 'Miniminopeuden on oltava pienempi kuin maksiminopeus!';
_fc_props.clock_in = 'Leimata sisään';
_fc_props.mix_water_at_x_to_y_for_t = 'Sekoita vettä {0} - {1} kierrosta minuutissa {2} kierrosta.';
_fc_props.discharge_water_for_x_revolutions = 'Pura vettä {0} kierrosta.';
_fc_props.eod_drum_wash_complete = 'Rummun pesu valmis! <br> Pysäytysrumpu anturilla ylhäällä.';
_fc_props.washing_complete = 'Ohjattu pesu loppuun';
_fc_props.eod_washout_no_data_available = 'Anturin tietoja ei ole saatavilla, suorita pesu loppuun.';
_fc_props.eod_washout_no_data_available_title = 'Ohjattu pesu ei saatavilla';
_fc_props.menu_reports_title_ticket_time_summary = 'Lippujen ajan yhteenveto';
_fc_props.menu_reports_description_ticket_time_summary = 'Katso tilauksen tai ajoneuvon lippuaikatiedot';
_fc_props.menu_reports_title_summary = 'Yhteenveto';
_fc_props.menu_reports_title_ticket_summary = 'Lippujen yhteenveto';
_fc_props.menu_reports_description_summary1 = 'Katso yhteenveto hotspottien ajasta';
_fc_props.menu_dispatch_title_main = 'Main';
_fc_props.menu_dispatch_title_login_registry = 'Kirjaudu rekisteriin';
_fc_props.menu_reports_title_main = 'Main';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Haluatko varmasti poistaa tämän vientitietotyypin?';
_fc_props.dvir_status_paper_logs = 'Paperi lokit';
_fc_props.menu_reports_title_billing_report = 'Laskutusraportti';
_fc_props.menu_reports_description_billing_report = 'Laskutusraportti';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Tila Ticketed-tilaan Aktivoi työ';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Tila liputettuun tilaan Aktivoi työ (oletus \'Epätosi\')';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Puntaa';
_fc_props.units_weight_kilogram_label = 'Kilogrammaa';
_fc_props.settings_weight = 'Paino';
_fc_props.units_pounds = 'Puntaa';
_fc_props.units_kilograms = 'Kilogrammaa';
_fc_props.geo_gate_edit_modal_description = 'Kuvaus';
_fc_props.geo_gate_edit_modal_start_point = 'Lähtöpiste';
_fc_props.geo_gate_edit_modal_end_point = 'Päätepiste';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Laitteen tyyppi';
_fc_props.geo_gate_edit_modal_heading_south = 'Etelästä pohjoiseen';
_fc_props.geo_gate_edit_modal_heading_north = 'Pohjoisesta etelään';
_fc_props.geo_gate_edit_modal_driver_message = 'Kuljettajan viesti';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Lähetysviesti';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Viimeinen voimassaolopäivä Aika';
_fc_props.geo_gate_edit_modal_permanent = 'Pysyvä';
_fc_props.geo_gate_edit_modal_critical = 'Kriittinen';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Virhe tallennettaessa Geogate: Kuvauskenttä on täytettävä.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Virhe tallennettaessa Geogate: Aloituspiste -kenttä on täytettävä.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Virhe tallennettaessa Geogate: Aloituspiste -kenttä sisältää virheellisen tietomuodon.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Virhe tallennettaessa Geogate: Päätepiste -kenttä sisältää virheellisen tietomuodon.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Virhe tallennettaessa Geogate: Expiration Date Time -kenttä on täytettävä.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Virhe tallennettaessa Geogate: Expiration Date Time -kenttä sisältää virheellisen tietomuodon.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Virhe tallennettaessa Geogate: pohjoisesta etelään - Lähetysviesti -kenttä on täytettävä';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Virhe tallennettaessa Geogate: Muutoksia ei havaittu';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Virhe tallennettaessa Geogate: Expiration Date Time -kenttä sisältää vanhentuneen päivämäärän ja ajan';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Virhe tallennettaessa Geogate: Kuvauskenttä saa sisältää vain kirjaimia ja numeroita';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Virhe tallennettaessa Geogate: Equipment Type -kenttä on täytettävä';
_fc_props.geo_gate_table_message_label_north_to_south = 'Pohjoisesta etelään';
_fc_props.geo_gate_table_message_label_south_to_north = 'Etelästä pohjoiseen';
_fc_props.geo_gate_table_message_label_both = 'Molemmat';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Leveysaste Pituusaste';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Tämän tarkistaminen ohittaa laitteen liikeviestirajoituksen asetukset';
_fc_props.geo_gate_edit_modal_seasonal = 'Kausiluonteinen';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Virhe tallennettaessa Geogate: Kausipäivät on täytettävä';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Virhe tallennettaessa Geogate: Seasonal Date -kentät sisältävät virheellisen tietomuodon';
_fc_props.test_js_test_javascript = 'Testaa Javascriptiä';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometrejä';
_fc_props.units_miles = 'Mailia';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Virhe tallennettaessa Geogate: etelästä pohjoiseen - Kuljettajan viesti -kenttä on täytettävä';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Virhe tallennettaessa Geogate: etelästä pohjoiseen - Lähetysviesti -kenttä on täytettävä';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Virhe tallennettaessa Geogate: pohjoisesta etelään - Kuljettajan viesti -kenttä on täytettävä';
_fc_props.geo_gate_heading = 'Otsikko';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Virhe tallennettaessa Geogate: Päätepiste -kenttä on täytettävä';
_fc_props.units_mile = 'Mailia';
_fc_props.units_kilometer = 'Kilometri';
_fc_props.cloud_dvir__exit = 'Poistu';
_fc_props.cloud_dvir__signature = 'Allekirjoitus';
_fc_props.cloud_dvir__inspection = 'Tarkastus';
_fc_props.cloud_dvir__add_photos_notes = 'Lisää kuvia/muistiinpanoja';
_fc_props.cloud_dvir__odometer_reading = 'Matkamittarin lukema';
_fc_props.cloud_dvir__engine_hours = 'Moottorin tunnit';
_fc_props.cloud_dvir__truck_details = 'Kuorma-auton tiedot';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Selvitä ajoneuvon yleinen kunto';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Kuljettajan koko nimi ja allekirjoitus';
_fc_props.cloud_dvir__sign = 'Merkki';
_fc_props.cloud_dvir__driver_note_from = 'Kuljettajan huomautus';
_fc_props.cloud_dvir__add_notes = 'Lisää muistiinpanoja';
_fc_props.cloud_dvir__driver_notes = 'Kuljettajan huomautukset';
_fc_props.cloud_dvir__done = 'Tehty';
_fc_props.cloud_dvir__needs_repair = 'Tarvitsee korjausta';
_fc_props.cloud_dvir__not_applicable = 'Ei sovellettavissa';
_fc_props.cloud_dvir__review_and_sign = 'Tarkista & allekirjoita';
_fc_props.cloud_dvir__add_photos = 'Lisää kuva';
_fc_props.cloud_dvir__upload_photo_limit = 'Voit ladata enintään 3 kuvaa';
_fc_props.cloud_dvir__mismatch_title = 'Epäsopivuushälytys';
_fc_props.cloud_dvir__ok = 'Ok';
_fc_props.cloud_dvir_template = 'Pilvi DVIR -malli';
_fc_props.cloud_dvir__needs_review = 'DVIR tarvitsee tarkistuksen';
_fc_props.cloud_dvir__submitted = 'Lähetetty';
_fc_props.cloud_dvir__completed_by = 'Täydentänyt';
_fc_props.cloud_dvir__review_btn = 'Arvostelu';
_fc_props.cloud_dvir__truck_number = 'Kuorma-auto {0}';
_fc_props.cloud_dvir__pending_review = 'Odottaa tarkistusta';
_fc_props.cloud_dvir__start_dvir = 'Käynnistä DVIR';
_fc_props.cloud_dvir__dvir_history = 'DVIR historia';
_fc_props.cloud_dvir__view_all = 'Näytä kaikki';
_fc_props.load_zone = 'Load Zone';
_fc_props.view_load_zones = 'Näytä kuormitusalueet';
_fc_props.edit_load_zone = 'Muokkaa kuormitusaluetta';
_fc_props.create_a_load_zone = 'Luo kuormitusalue';
_fc_props.load_zone_panel = 'Lataa Zone-paneeli';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Napsauta karttaa asettaaksesi kuormitusalueen keskipisteen.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Tässä tarkastuksessa on havaittu vikoja ja olet valinnut, että tämä ajoneuvo on turvallinen käyttää. Vahvista ennen kuin jatkat';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Tässä tarkastuksessa ei ole havaittu vikoja ja olet valinnut, että tämä ajoneuvo ei ole turvallinen käyttää. Vahvista ennen kuin jatkat.';
_fc_props._90_days = '90 päivää';
_fc_props.cloud_dvir = 'Pilvi DVIR';
_fc_props.cloud_dvir_details = 'Cloud DVIR -lähetykset';
_fc_props.show_load_zones = 'näytä kuormitusalueet';
_fc_props.hide_load_zones = 'piilottaa kuormitusalueet';
_fc_props.no = 'Ei';
_fc_props.cloud_dvir__reset = 'Nollaa';
_fc_props.cloud_dvir__clear = 'Asia selvä';
_fc_props.mark_read = 'Merkitse luetuksi';
_fc_props.mark_unread = 'Merkitse lukemattomaksi';
_fc_props.read = 'Lukea';
_fc_props.unread = 'Lukematon';
_fc_props.air_sensor_serial_number = 'Ilma-anturin sarjanumero';
_fc_props.air_sensor_mac_address = 'Ilmasensorin MAC-osoite';
_fc_props.air_sensor_serial_number_invalid_format = 'Ilmaanturin sarjanumero ei ole kelvollisessa muodossa: (aakkosnumeerinen ja enintään 32 merkkiä pituudeltaan väliviivoja)';
_fc_props.air_sensor_mac_address_invalid_format = 'Ilmasensorin MAC-osoite ei ole kelvollisessa heksadesimaalimuodossa, joka näkyy kentän paikkamerkissä';
_fc_props.air_sensor_license = 'Ilman anturi';
_fc_props.adjust_route_point = 'Säädä reittipistettä';
_fc_props.route_point_panel = 'Reittipistepaneeli';
_fc_props.route_point = 'Reittipiste';
_fc_props.cloud_dvir__pre_trip = 'Ennen matkaa';
_fc_props.cloud_dvir__post_trip = 'Matkan jälkeinen';
_fc_props.cloud_dvir__other_insction_type = 'Muut';
_fc_props.cloud_dvir__dvir_completed = 'Valmis';
_fc_props.cloud_dvir__awaiting_mechanic = 'Mekaanikkoa odotellessa';
_fc_props.cloud_dvir__awaiting_driver = 'Odottaa kuljettajan kirjautumista';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Ilma-anturin sarjanumeroa ei voi tarkistaa, ota yhteyttä tukeen saadaksesi lisäapua.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Tämä ilmaanturin sarjanumero on jo olemassa, käytä toista sarjanumeroa';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Ilma-anturin MAC-osoitetta ei voi tarkistaa, ota yhteyttä tukeen saadaksesi apua';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Virhe lähetettäessä DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Yritä uudelleen';
_fc_props.ticket_integration_descr = 'Ticket Integration avulla voit määrittää lippujen integrointiasetukset sekä aloitusnäytön / latausnäytön / kovakiinnitysnäytön';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR lähetetty onnistuneesti';
_fc_props.cloud_dvir__inspection_trip_type = 'Tarkastusmatkan tyyppi';
_fc_props.menu_dvir_title_mechanic = 'Mekaaninen';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Ilma-anturin laiteohjelmisto';
_fc_props.when_no_active_ticket = 'Kun ei ole aktiivista lippua';
_fc_props.used_by = 'Käyttää';
_fc_props.fields = 'Kentät';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Haluatko varmasti poistaa lippuasettelun "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Lippujen asettelu';
_fc_props.add_ticket_layout = 'Lisää lipun asettelu';
_fc_props.edit_ticket_layout = 'Muokkaa lipun asettelua';
_fc_props.ticket_layout_edit_modal_name = 'Nimi';
_fc_props.ticket_layout_edit_modal_default = 'Oletus';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Virhe tallennettaessa lippuasettelua: Nimikenttä on täytettävä.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Virhe tallennettaessa lipun asettelua: Nimikenttä saa sisältää vain kirjaimia ja numeroita';
_fc_props.ticket_layout_edit_modal_fields = 'Kentät';
_fc_props.ticket_layout_edit_modal_usage = 'Käyttö';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Ticket Layout on poistettu. Ole hyvä ja päivitä sivu.';
_fc_props.more_lower_case = 'lisää';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Virhe tallennettaessa lippuasettelua: Yksi tai useampi kenttä on valittava.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Virhe tallennettaessa lippuasettelua: Yksi tai useampi tila on valittava.';
_fc_props.cloud_dvir__ad_hoc = 'Ad Hoc';
_fc_props.radius_multiplier_should_be = 'Sädekertoimen tulee olla kelluva luku';
_fc_props.ticket_max_activate_age_err = 'Ticket Max Activate Age Minutes -arvon tulee olla kokonaisluku.';
_fc_props.max_hotspot_unused_days_errs = 'Käyttämättömien Hotspot-päivien enimmäismäärän tulee olla kokonaisluku.';
_fc_props.begin_pour_air_content = 'Aloita Kaada ilmasisältö';
_fc_props.arrival_air_content = 'Saapuvan lentoliikenteen sisältö';
_fc_props.fully_mixed_air_content = 'Täysin sekoitettu ilmasisältö';
_fc_props.departure_air_content = 'Lähtöilman sisältö';
_fc_props.popup_enabled = 'Ponnahdusikkuna käytössä';
_fc_props.popup_disabled = 'Ponnahdusikkuna pois käytöstä';
_fc_props.cloud_dvir__leave_confirmation_title = 'Varoitus';
_fc_props.cloud_dvir__leave_confirmation_description = 'Jos poistut sivulta nyt, kaikki muutokset menetetään.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Vahvistaa';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Peruuttaa';
_fc_props.air_bt_ratio = 'Ilma-anturin mittaus';
_fc_props.cloud_dvir__view_photos_notes = 'Näytä valokuvat/muistiinpanot';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Tilan lisääminen luo tyhjän käytön toiseen lippuasetteluun. Haluatko jatkaa lisäämistä?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Ajoneuvolla ei ole ilmaanturilupaa.';
_fc_props.air_sensor_installed = 'Ilma-anturi asennettu';
_fc_props.load_card = 'Lataa kortti';
_fc_props.cloud_dvir__inspection_profile = 'Tarkastusprofiili';
_fc_props.cloud_dvir__view_details = 'Tarkemmat tiedot';
_fc_props.cloud_dvir__certify_repairs = 'Sertifioi korjaukset';
_fc_props.cloud_dvir__repairs_made = 'Korjaukset tehty';
_fc_props.cloud_dvir__repairs_not_necessary = 'Korjauksia ei tarvita';
_fc_props.cloud_dvir__mechanic_note_from = 'Mekaanikon huomautus';
_fc_props.cloud_dvir__mechanic_assignment = 'Mekaanikkotehtävä';
_fc_props.cloud_dvir__mechanic_signature_description = 'Merkitse alla kaikki asiaankuuluvat kohdat ja allekirjoita, että ajoneuvo on tarkastettu asianmukaisesti.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Tämän ajoneuvon kunto on tyydyttävä';
_fc_props.cloud_dvir__defects_corrected_label = 'Viat on korjattu';
_fc_props.cloud_dvir__repair_not_required_label = 'Korjausta ei tarvita ajoneuvon turvallisen käytön vuoksi';
_fc_props.safety = 'Turvallisuus';
_fc_props.cloud_dvir__awaiting_sign = 'Odottaa merkkiä';
_fc_props.cloud_dvir__dvir_review = 'DVIR arvostelu';
_fc_props.settings.mobile_auto_start_navigation = 'Käynnistä navigointi automaattisesti mobiililaitteella';
_fc_props.cloud_dvir__edit = 'Muokata';
_fc_props.delete_custom_label = 'Poista mukautettu tarra';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'TrackIt-versiosi on vanhentunut ja jotkin DVIR-ominaisuudet eivät ehkä toimi kunnolla. Päivitä TrackIt ennen kuin jatkat.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Jotain meni pieleen allekirjoituksen latauksen aikana. Yritä uudelleen';
_fc_props.max_hotspot_unused_days_error = 'Max Hotspot Unused Days on enintään 1000 päivää.';
_fc_props.menu_dvir_title_admin_templates = 'Mallit';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Admin';
_fc_props.cloud_dvir__admin_templates = 'Mallit';
_fc_props.form_name = 'Lomakkeen nimi';
_fc_props.form_status = 'Lomakkeen tila';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'ladata';
_fc_props.cloud_dvir__option_view = 'Näytä';
_fc_props.cloud_dvir__inspectortype_driver = 'Kuljettaja';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Tarkastele/muokkaa/luo mukautettuja DVIR-lomakkeita ja lähetyksiä';
_fc_props.menu.dvir.description.mechanic = 'Tarkastele/muokkaa toiminnallisia DVIR-lähetyksiä';
_fc_props.not_in_use = 'Ei käytössä';
_fc_props.logged_in = 'kirjautunut sisään';
_fc_props.reviewing_driver = 'Tarkistetaan kuljettajaa';
_fc_props.cloud_dvir__report_title = 'Kuljettajan ajoneuvon katsastusraportti';
_fc_props.cloud_dvir__report_signed = 'allekirjoitettu';
_fc_props.cloud_dvir__report_page_counter = 'Sivu {0}/{1}';
_fc_props.cloud_dvir__report_defects = 'Vikoja';
_fc_props.cloud_dvir__report_comments = 'Kommentit';
_fc_props.cloud_dvir__report_inspection_table_title = 'Ajoneuvon tarkastus';
_fc_props.cloud_dvir__report_driver_table_title = 'Kuljettajan tiedot';
_fc_props.cloud_dvir__report_carrier = 'Kuljettaja';
_fc_props.cloud_dvir__report_truck = 'Kuorma-auto';
_fc_props.cloud_dvir__report_location = 'Sijainti';
_fc_props.cloud_dvir__report_odometer = 'Matkamittari';
_fc_props.cloud_dvir__report_hours = 'Tuntia';
_fc_props.cloud_dvir__report_no_comments = 'Ei kommentteja';
_fc_props.include_unlicensed_vehicles = 'Sisällytä luvattomia ajoneuvoja';
_fc_props.cloud_dvir__search_by_form_name = 'Hae lomakkeen nimellä';
_fc_props.cloud_dvir__create_dvir = 'Luo DVIR';
_fc_props.cloud_dvir__update_dvir = 'Päivitä DVIR';
_fc_props.cloud_dvir__form_name = 'Lomakkeen nimi';
_fc_props.cloud_dvir__form_description = 'Lomakkeen kuvaus';
_fc_props.cloud_dvir__form_status = 'Lomakkeen tila';
_fc_props.information = 'Tiedot';
_fc_props.cloud_dvir__search_categories = 'Haku luokat';
_fc_props.cloud_dvir__create_new_category = 'Luo uusi luokka';
_fc_props.category_name = 'Kategorian nimi';
_fc_props.sub_category = 'Alaluokka';
_fc_props.sub_category_name = 'Alaluokan nimi';
_fc_props.delete_category = 'Poista luokka';
_fc_props.cloud_dvir__dvir_information = 'DVIR tiedot';
_fc_props.cloud_dvir__dvir_choose_categories = 'Valitse Luokat';
_fc_props.cloud_dvir__create_category = 'Luo luokka';
_fc_props.cloud_dvir__update_category = 'Päivitä luokka';
_fc_props.delete_route_point = 'Poista reittipiste';
_fc_props.view_route_points = 'Näytä reittipisteet';
_fc_props.ignore_cancel_preload = 'Ohita peruutus, olet esiladattu. Kotilenkkisi näytetään pian';
_fc_props.cloud_dvir__assigned_mechanic = 'Määrätty mekaanikko';
_fc_props.cloud_dvir__add_mechanic = 'Lisää mekaanikko';
_fc_props.contact_type = 'Yhteyden tyyppi';
_fc_props.cloud_dvir__send_email_notifications = 'Lähetä sähköposti-ilmoituksia';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Virhe haettaessa DVIR-historiatietueita.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Virhe noudettaessa DVIR-tietuetta.';
_fc_props.cloud_dvir__error_saving_submission = 'Virhe lähetyksen tallentamisessa. Yritä uudelleen.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Virhe noudettaessa mekaniikkaluetteloa.';
_fc_props.cloud_dvir__template_saved = 'Malli tallennettu';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Virhe malliluettelon haussa.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Virhe mallin tallentamisessa. Yritä uudelleen.';
_fc_props.dispatch_group = 'Lähetysryhmä';
_fc_props.save_adjust = 'Tallenna ja säädä reittipistettä';
_fc_props.reset_route_point = 'Nollaa reittipiste';
_fc_props.no_records_stub_adjust_filters = 'Kokeile haku- tai suodatinasetuksiasi lisätäksesi tulosten määrää.';
_fc_props.no_dvir_records = 'Kaikki kiinni DVIR:istä!';
_fc_props.no_dvir_records_sub_title = 'Olet saavuttanut paljon';
_fc_props.settings.master_data_connex_x_api_key = 'Master Data Connex x-api-avain';
_fc_props.slump_not_obtained_for_x = 'Laskua ei saatu: {0}';
_fc_props.please_select_a_sensor_status_type = 'Valitse anturin tilatyypit tämän hälytyksen laukaisemiseksi';
_fc_props.settings.master_data_connex_token = 'Master Data Connex Token';
_fc_props.error_validating_master_data_connex_credentials = 'Virhe vahvistettaessa Master Data Connex -tunnistetietoja';
_fc_props.settings.master_data_connex_base_url = 'Master Data Connex -perus-URL-osoite';
_fc_props.settings.master_data_connex_exp_time = 'Master Data Connexin vanhenemisaika';
_fc_props.download_template = 'Lataa malli';
_fc_props.menu_settings_title_dispatch_group = 'Lähetysryhmät';
_fc_props.settings.dispatch_groups = 'Lähetysryhmät';
_fc_props.dispatch_group_name = 'Lähetysryhmän nimi';
_fc_props.dispatch_group_plants = 'Lähetysryhmän kasvit';
_fc_props.dispatch_group_plants_mapping_message = 'Ilmoita, mitkä laitokset kuuluvat tähän lähetysryhmään.';
_fc_props.dispatch_group_users_mapping_message = 'Ilmoita, ketkä käyttäjät kuuluvat tähän lähetysryhmään.';
_fc_props.dispatch_group_plant_disassociation_message = 'Laitoksen tulee olla yhden lähetysryhmän alla kerrallaan';
_fc_props.dispatch_group_search_by_plant = 'Hae kasvin nimellä';
_fc_props.dispatch_group_search_by_username = 'Hae käyttäjätunnuksella';
_fc_props.search_by_dispatch_group_name = 'Hae lähetysryhmän nimen mukaan';
_fc_props.create_dispatch_group = 'Luo lähetysryhmä';
_fc_props.update_dispatch_group = 'Päivitä lähetysryhmä';
_fc_props.dispatch_group_saved = 'Lähetysryhmä tallennettu';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Tämän lähetysryhmän poistaminen luo oletusarvoiset lähetysryhmät liittyville tehtaille.';
_fc_props.error_saving_dispatch_group = 'Virhe lähetysryhmän tallentamisessa. Yritä uudelleen.';
_fc_props.please_enter_a_valid_plant_num = 'Anna kelvollinen laitoksen numero.';
_fc_props.cloud_dvir__report_no_defects = 'Ei vikoja';
_fc_props.shoud_select_dispatch_group = 'Sinun on valittava vähintään yksi lähetysryhmä';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Ei voida poistaa, koska tämä lähetysryhmä sisältää käyttäjiä, jotka liittyvät vain tähän lähetysryhmään.';
_fc_props.cloud_dvir__username = 'Käyttäjätunnus';
_fc_props.cloud_dvir__password = 'Salasana';
_fc_props.cloud_dvir__login = 'Kirjaudu sisään';
_fc_props.cloud_dvir__mechanic_sign_in = 'Mekaanikkokirjautuminen';
_fc_props.delete_dispatch_group = 'Poista lähetysryhmä';
_fc_props.cloud_dvir__driver = 'Kuljettaja';
_fc_props.cloud_dvir__equipment_pound = 'Varustus #';
_fc_props.cloud_dvir__mechanic = 'Mekaaninen';
_fc_props.dispatch_group_user_only_unassociate_message = 'Käyttäjää ei voi poistaa, koska käyttäjien on oltava liitettynä vähintään yhteen lähetysryhmään.';
_fc_props.cloud_dvir__time_period_filter_today = 'Tänään';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Eilen';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Viimeiset 7 päivää';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Viimeiset 30 päivää';
_fc_props.cloud_dvir__date_range_filter = 'Suodata päivämäärän mukaan';
_fc_props.cloud_dvir__download = 'ladata';
_fc_props.dispatch_group_is_empty = 'Lähetysryhmä ei voi olla tyhjä.';
_fc_props.dispatch_group_with_empty_plants = 'Vähintään yksi tehdas on liitettävä lähetysryhmään.';
_fc_props.dispatch_group_with_empty_users = 'Vähintään yksi käyttäjä on liitettävä lähetysryhmään.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Lähetysryhmää ei voida poistaa, koska se sisältää käyttäjiä, jotka liittyvät vain tähän lähetysryhmään.';
_fc_props.dispatch_group_not_exists = 'Lähetysryhmää ei ole olemassa.';
_fc_props.dispatch_group_contains_one_plant = 'Ei voida poistaa, koska tämä lähetysryhmä sisältää vain yhden tehtaan.';
_fc_props.dispatch_group_users = 'Lähetä ryhmäkäyttäjät';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Virhe noudettaessa DVIR-tietuetta';
_fc_props.cloud_dvir__assign_mechanic = 'Määritä mekaanikko';
_fc_props.cloud_dvir__submit_review = 'Lähetä arvostelu';
_fc_props.error_deleting_dispatch_group = 'Virhe poistettaessa lähetysryhmää.';
_fc_props.error_retrieving_dispatch_groups = 'Virhe haettaessa luetteloa lähetysryhmistä.';
_fc_props.cloud_dvir__undo_selection = 'Kumoa valinta';
_fc_props.enable_master_data = 'Ota perustiedot käyttöön';
_fc_props.manually_sync = 'Manuaalinen synkronointi';
_fc_props.master_data_connex_token_expiration = 'Master Data Connex Token vanhentunut';
_fc_props.cloud_dvir__time_period_filter_custom = 'Mukautettu';
_fc_props.settings.zello_session_timeout_minutes = 'Zello-istunnon aikakatkaisu (minuutteja)';
_fc_props.menu_settings_title_master_data = 'Perustiedot';
_fc_props.settings.master_data = 'Perustiedot';
_fc_props.error_retrieving_master_data = 'Virhe perustietojen noutamisessa';
_fc_props.master_data_sync_completed = 'Perustietojen synkronointi valmis';
_fc_props.master_data_successfully_updated = 'Tietojen päivitys onnistui';
_fc_props.master_data_failed_to_update = 'Tallennus epäonnistui.';
_fc_props.master_data_sync_initiated = 'Perustietojen synkronointi aloitettu';
_fc_props.master_data_sync_failed = 'Perustietojen synkronointi epäonnistui';
_fc_props.enabled_excluding_origin_on_deliveries = 'Totta (ei sisällä toimituslippujen alkuperää)';
_fc_props.enabled_including_origin_on_deliveries = 'Totta (mukaan lukien toimituslippujen alkuperä)';
_fc_props.cloud_dvir__i_will_decide_later = 'Päätän myöhemmin';
_fc_props.returning = 'Paluu';
_fc_props.leftover_concrete = 'Betonijäännös';
_fc_props.leftover_concrete_on_vehicle_x = 'Ajoneuvoon {0} jäänyt betoni';
_fc_props.leftover_concrete_alert = 'Ylijäämäbetonihälytys';
_fc_props.wc_ratio_exceeded = 'W/C-suhde ylitetty';
_fc_props.search_by_site_name = 'Hae sivuston nimellä';
_fc_props.sites = 'Sivustot';
_fc_props.token_expiration = 'Tokenin vanheneminen';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API Key Management';
_fc_props.settings.connex_api_key_management = 'Connex API Key Management';
_fc_props.error_retrieving_connex_api_key_data = 'Virhe haettaessa connex api -avaintietoja';
_fc_props.qr_code = 'QR koodi';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Kuljettajan ja käyttöliittymän ilmoittaminen väärään tehtaaseen saapuessaan';
_fc_props.enable_driver_message = 'Ota ohjainviesti käyttöön';
_fc_props.enable_dispatch_message = 'Ota lähetysviesti käyttöön';
_fc_props.enable_sending_message_to_interface = 'Ota käyttöön viestin lähettäminen käyttöliittymään';
_fc_props.ticket_qr_code = 'Lipun QR-koodi';
_fc_props.leftover_concrete_alert_description = 'Hälytä, kun betonijäämiä löytyy kaatamisen jälkeen.';
_fc_props.minimum_leftover_concrete_amount = 'Vähimmäismäärä ylimääräistä betonia';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Virhe vahvistettaessa Master Data Connex -käyttöoikeustietoja ei ole valtuutettu';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Virhe vahvistettaessa Master Data Connex -käyttöoikeustietoja';
_fc_props.error_validating_master_data_connex_url_not_found = 'Virhe vahvistettaessa perustietojen liitännän URL-osoitetta ei löydy';
_fc_props.error_validating_master_data_connex_unknown_error = 'Virhe vahvistettaessa perustietoyhteyttä Tuntematon virhe';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Perustietojen synkronointi epäonnistui Synkronoi ajoneuvotyyppi.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Perustietojen synkronointi epäonnistui Synkronoi ajoneuvo.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Perustietojen synkronointi epäonnistui Synkronoi laitos.';
_fc_props.master_data_sync_failed_data_base_connection = 'Perustietojen synkronointi epäonnistui yhteys tietokantaan.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Virhe vahvistettaessa Master Data Connexin valtuustietojen siirtotiedon käyttöoikeus on riittämätön';
_fc_props.settings.back_date_arrive_job = 'Takaisin saapumispäivä töihin';
_fc_props.device_notifications = 'Ilmoitukset';
_fc_props.loading_number = 'Latausnumero';
_fc_props.leftover_concrete_volume_temp_age = 'Jäljellä oleva tilavuus={0}, lämpötila={1}, ikä={2}';
_fc_props.total_ordered = 'Tilattu kokonaismäärä';
_fc_props.total_ticketed = 'Toimitettu tilattu';
_fc_props.load_number = 'Latausnumero';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Vähimmäisjäännösbetoni on täytettävä ja suurempi kuin {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Virhe perustietojen vahvistamisessa. Tunnukset eivät voi olla samat.';
_fc_props.use_aws_for_talk = 'Käytä AWS:ää keskusteluun';
_fc_props.air_readings_not_supported = 'Ilmalukemia ei tueta';
_fc_props.air_not_supported = 'Ilmaa ei tueta';
_fc_props.mix_not_supported = 'Sekoitusta ei tueta';
_fc_props.air_default_and_none_not_supported = 'Ei voi valita oletusarvoa ja sitä ei tueta samanaikaisesti!';
_fc_props.notifications_enabled = 'Ilmoitukset käytössä';
_fc_props.prevent_status_change = 'Estä tilanmuutos';
_fc_props.message_to_interface = 'Viesti käyttöliittymään';
_fc_props.prevent_wrong_plant = 'Estä väärä kasvi';
_fc_props.previous_tickets = 'Aiemmat liput';
_fc_props.audit_ticket_number = 'Lipun numero';
_fc_props.audit_date_created = 'Päivämäärä luotu';
_fc_props.audit_vehicle_number = 'Ajoneuvon numero';
_fc_props.cloud_dvir__table_ok = 'Turvallinen käyttää';
_fc_props.cloud_dvir__table_needs_repair = 'Ei turvallista käyttää';
_fc_props.missing_air_calibration = 'Ei ilmakalibrointitietoja';
_fc_props.reported_defects = 'Ilmoitetut viat';
_fc_props.address_latitude = 'Osoite Latitude';
_fc_props.address_longitude = 'Osoite Pituusaste';
_fc_props.routing_latitude = 'Reititys Latitude';
_fc_props.routing_longitude = 'Reitin pituusaste';
_fc_props.category_name_must_be_unique = 'Luokan nimen on oltava yksilöllinen';
_fc_props.subcategory_name_must_be_unique = 'Alaluokan nimen on oltava yksilöllinen';
_fc_props.menu_settings_tracking_device = 'Jäljityslaite';
_fc_props.menu_settings_title_tracking_device = 'Jäljityslaite';
_fc_props.menu_settings_title_personal_device_registration = 'Henkilökohtaisen laitteen rekisteröinti';
_fc_props.registration_code = 'Rekisteröintikoodi';
_fc_props.creation_date = 'Luominen';
_fc_props.expiration_date = 'Vanheneminen';
_fc_props.trackitadmin_personal_device_registration = 'Henkilökohtaisen laitteen rekisteröinti';
_fc_props.create_registration_code = 'Lisää rekisteröintikoodi';
_fc_props.remove_registration_code = 'Poista rekisteröintikoodi';
_fc_props.add_a_registration_code = 'Lisää rekisteröintikoodi';
_fc_props.select_number_of_registration_codes_to_generate = 'Valitse rekisteröintikoodien määrä, jonka haluat luoda.';
_fc_props.registration_code_count = 'Rekisteröintikoodien määrä';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Haluatko varmasti poistaa valitun rekisteröidyn koodin?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Haluatko varmasti poistaa valitut rekisteröidyt koodit?';
_fc_props.add_personal_device = 'Lisää henkilökohtainen laite';
_fc_props.menu_settings_title_personal_device = 'Henkilökohtainen laite';
_fc_props.cloud_dvir__approval_critical_alert = 'Autossa on kriittinen vika. Ei voida merkitä turvalliseksi käyttää.';
_fc_props.export_registration_codes = 'Vie rekisteröintikoodit';
_fc_props.download_format = 'Lataa muoto';
_fc_props.specify_download_format_x = 'Määritä latausmuoto viedäksesi {0} rekisteröintikoodia';
_fc_props.personal_device_download = 'ladata';
_fc_props.add_personal_device_input_message = 'Syötä henkilökohtainen laitetunnus alle. Vanheneminen otetaan käyttöön automaattisesti tallennuksen jälkeen';
_fc_props.error_creating_personal_device = 'Virhe luotaessa henkilökohtaista laitetta';
_fc_props.device_already_exists = 'Henkilökohtainen laite on jo olemassa';
_fc_props.export = 'Viedä';
_fc_props.error_can_not_change_connex_eid = 'Connex eid:tä ei voi muuttaa, koska sitä käytetään tällä hetkellä perustiedoille';
_fc_props.remove_personal_device = 'Poista henkilökohtainen laite';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Haluatko varmasti poistaa valitun henkilökohtaisen laitteen?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Haluatko varmasti poistaa valitut henkilökohtaiset laitteet?';
_fc_props.error_deleting_personal_device = 'Virhe poistettaessa henkilökohtaista laitetta';
_fc_props.customerid = 'Asiakas ID';
_fc_props.customername = 'Asiakkaan nimi';
_fc_props.ordernumber = 'Tilausnumero';
_fc_props.orderdescription = 'Tilauksen kuvaus';
_fc_props.locationid = 'Sijainnin tunnus';
_fc_props.locationdescription = 'Sijainti Kuvaus';
_fc_props.productid = 'Tuotetunnus';
_fc_props.productdescription = 'Tuotteen Kuvaus';
_fc_props.vehicleid = 'Ajoneuvon tunnus';
_fc_props.vehicledescription = 'Ajoneuvon kuvaus';
_fc_props.ticketnumber = 'Lippu nro';
_fc_props.ticketdate = 'Lipun päivämäärä/aika';
_fc_props.drivername = 'Kuljettajan nimi';
_fc_props.arriveregion = 'Saapumisalue (TIME)';
_fc_props.leaveregion = 'Poistu alueelta (TIME)';
_fc_props.regionduration = 'Alueen kesto';
_fc_props.menu_reports_title_prevailing_wage = 'Vallitseva palkka';
_fc_props.slump_calibration_x_not_found_for_y = 'Laman kalibrointia "{0}" ei löytynyt lipulle {1}';
_fc_props.slump_calibration_not_found = 'Laman kalibrointia ei löydy';
_fc_props.slump_calibration_not_found_description = 'Varoitus, kun laman kalibrointia ei löydy';
_fc_props.error_getting_personal_device_list = 'Virhe haettaessa henkilökohtaista laiteluetteloa';
_fc_props.batch_summary_date_range_note = '*HUOMAA: Tämä raportti on rajoitettu 3 päivään, jos suodattimia ei käytetä. Ajanjakso voi olla jopa 31 päivää, kun se suodatetaan laitteiden (enintään 10), tehtaan (enintään 1), tuotteen (enintään 1) tai työn (enintään 1) mukaan.';
_fc_props.select_registration_code_expiration_time_days = 'Valitse päivien lukumäärä, jonka jälkeen rekisteröintikoodit vanhenevat, ja napsauta sitten Tallenna.';
_fc_props.add_personal_device_expiration = 'Vanheneminen (päivissä)';
_fc_props.cloud_dvir__vehicle_no = 'Ajoneuvon nro';
_fc_props.cloud_dvir__trailer_no = 'Traileri nro';
_fc_props.cloud_dvir__vehicle = 'Ajoneuvo';
_fc_props.cloud_dvir__trailer = 'Traileri';
_fc_props.cloud_dvir__truck_number_label = 'Kuorma-auton numero';
_fc_props.cloud_dvir__trailer_number = 'Trailerin numero';
_fc_props.cloud_dvir__has_trailer = 'Ei ajeta perävaunun kanssa';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Kirjoita trailerin numero tähän';
_fc_props.work_order = 'Työjärjestys';
_fc_props.unable_to_determine_slump = 'Slumpia ei voida määrittää';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Selvitä perävaunun yleinen kunto';
_fc_props.defaultTrailer = 'Oletustraileri';
_fc_props.trailer_type_column_billable = 'Laskutettava';
_fc_props.trailer_type_column_notes = 'Huomautuksia';
_fc_props.trailer_type_column_date = 'Päivämäärä';
_fc_props.trailer_type_column_order = 'Tilata';
_fc_props.trailer_type_column_ticket = 'Lippu';
_fc_props.trailer_type_column_project = 'Projekti';
_fc_props.trailer_type_column_customer = 'Asiakas';
_fc_props.trailer_type_column_location = 'Sijainti';
_fc_props.trailer_type_column_vehicle_type = 'Ajoneuvon tyyppi';
_fc_props.trailer_type_column_product = 'Tuote';
_fc_props.trailer_type_action_rebill = 'Rebill';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Perustietojen synkronointi epäonnistui oletuslaitteistotyyppiä ei ole asetettu';
_fc_props.trailer_type = 'Perävaunun tyyppi';
_fc_props.trailer_type_is_required = 'Trailerityyppi vaaditaan';
_fc_props.trailer_number_already_exists = 'Trailerin numero on jo olemassa';
_fc_props.plate_number_already_exists = 'Kilpinumero on jo olemassa';
_fc_props.trailer_vin_already_exists = 'Traileri Vin on jo olemassa';
_fc_props.trailer_does_not_exist = 'Traileria ei ole olemassa';
_fc_props.trailer_number_is_required = 'Trailerin numero vaaditaan';
_fc_props.plate_number_is_required = 'Kilven numero vaaditaan';
_fc_props.plate_number = 'Kilven numero';
_fc_props.menu_settings_title_trailer = 'Traileri';
_fc_props.add_trailer = 'Lisää traileri';
_fc_props.edit_trailer = 'Muokkaa traileria';
_fc_props.menu_settings_title_equipment = 'Laitteet';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ohita uudet Command Cloudin X minuuttia vanhemmat liput';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ohita Command Cloudin uudet liput, joiden lähetyspäivämäärä on vanhempi kuin X minuuttia (oletus on tyhjä ja pois päältä)';
_fc_props.edit_load_card = 'Muokkaa latauskorttia';
_fc_props.default_layout = 'Oletusasettelu';
_fc_props.load_card_empty_fields = 'Virhe tallennettaessa Load Card: Yksi tai useampi kenttä on valittava.';
_fc_props.trailers = 'Perävaunut';
_fc_props.error_failed_to_save_data = 'Virhe: Tietojen tallennus epäonnistui';
_fc_props.slump_increased_plasticizer_added_question = 'Kuinka paljon pehmitettä lisättiin?';
_fc_props.add_trailer_type = 'Lisää trailerityyppi';
_fc_props.menu_settings_title_trailer_type = 'Perävaunun tyyppi';
_fc_props.edit_trailer_type = 'Muokkaa trailerin tyyppiä';
_fc_props.single_use_codes = 'Kertakäyttöiset koodit';
_fc_props.multiple_use_codes = 'Useita koodeja';
_fc_props.trailer_type_description_is_required = 'Perävaunutyypin kuvaus vaaditaan';
_fc_props.trailer_saved = 'Traileri tallennettu';
_fc_props.cloud_dvir__trailer_only_dvir = 'Vain traileri DVIR';
_fc_props.choose_file = 'Valitse Tiedosto';
_fc_props.no_file_chosen = 'Tiedostoa ei ole valittu';
_fc_props.please_choose_file = 'Ole hyvä ja valitse tiedosto';
_fc_props.no_filters = 'Ei valittuja suodattimia';
_fc_props.remaining_defects = 'Jäljellä olevat viat';
_fc_props.load_more = 'Lataa lisää';
_fc_props.pagination_label = '{0}-{1}/{2}';
_fc_props.extend = 'Laajenna';
_fc_props.cloud_dvir__notes_or_photo_required = 'Joko muistiinpanot tai valokuva vaaditaan.';

export default _fc_props;
