import React, { FC, useEffect, useRef } from 'react';
import { Box, Select, MenuItem } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useTranslation } from 'react-i18next';
import Category from './Category';
import { Text, Input } from '../../components';
import { InspectionValue } from '../../types/submission';
import { InspectionForm } from '../forms/useInspectionForm';
import { AttachmentsForm, AddAttachment } from '../forms/useAttachments';
import { InspectionTripTypeLabels, VehicleForm, VehicleFormFields, VehicleFormLabels } from '../forms/useVehicleForm';
import { CategoryType } from '../../types/template';
import { DvirSubmitMode } from '../../constants';

interface InspectionProps {
  attachmentsForm: AttachmentsForm;
  onAddAttachment: (attachmentOptions: AddAttachment) => void;
  onEditNote: (categoryName: string, itemName: string, noteText: string) => void;
  onDeleteNote: (categoryName: string, itemName: string) => void;
  onDeletePhoto: (categoryName: string, itemName: string, photoId: string) => void;
  readOnly: boolean;
  isVehicleDvirSubmit: boolean;
  isTrailerDvirSubmit: boolean;
  vehicleForm: VehicleForm;
  inspectionForm: InspectionForm;
  onInspectionFormChange: (categoryName: string, itemName: string, value: InspectionValue) => void;
  onVehicleFormChange: (fieldName: string, value: string) => void;
  incompleteInspectionCategoryName: string;
  isDriverSignOff: boolean;
  currentDvirSubmitMode: string;
}

const Inspection: FC<InspectionProps> = ({
  incompleteInspectionCategoryName,
  attachmentsForm,
  onAddAttachment,
  onDeletePhoto,
  onEditNote,
  onDeleteNote,
  readOnly,
  isVehicleDvirSubmit,
  isTrailerDvirSubmit,
  vehicleForm,
  onVehicleFormChange,
  inspectionForm,
  onInspectionFormChange,
  isDriverSignOff,
  currentDvirSubmitMode,
}) => {
  const { t } = useTranslation();
  const categoryItems = useRef<HTMLDivElement[]>([]);

  let renderCounter = 0;

  const handleValueChange = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
    onVehicleFormChange(name, value);
  };

  useEffect(() => {
    if (incompleteInspectionCategoryName) {
      categoryItems.current?.[incompleteInspectionCategoryName]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [incompleteInspectionCategoryName]);

  useEffect(() => {
    if (currentDvirSubmitMode === DvirSubmitMode.Trailer) {
      VehicleFormFields.engineHours.optional = true;
      VehicleFormFields.odometer.optional = true;
    }
  }, []);

  const isNewRendered = (categoryName: string) =>
    (categoryName.startsWith(`${CategoryType.Vehicle}-`) && isVehicleDvirSubmit) ||
    (categoryName.startsWith(`${CategoryType.Trailer}-`) && isTrailerDvirSubmit) ||
    ((categoryName.startsWith(`${CategoryType.Vehicle}-`) || categoryName.startsWith(`${CategoryType.Trailer}-`)) &&
      isVehicleDvirSubmit &&
      isTrailerDvirSubmit);

  const isReviewRendered = (categoryName: string) =>
    !categoryName.startsWith(`${CategoryType.Vehicle}-`) &&
    !categoryName.startsWith(`${CategoryType.Trailer}-`) &&
    (isVehicleDvirSubmit || isTrailerDvirSubmit);

  const isRendered = (categoryName: string) => {
    const result = isNewRendered(categoryName) || isReviewRendered(categoryName);
    if (result) {
      renderCounter += 1;
    }
    return result;
  };

  return (
    <Box
      sx={{
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <Box
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            marginBottom: 4,
            color: (theme) => theme.palette.neutral.variation40,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              gap: 3,
              alignItems: 'center',
            }}
          >
            <LocalShippingOutlinedIcon sx={{ fontSize: 30 }} />
            <Text fontLevel="title" fontSize="large" fontWeight="bold">
              {t('cloud_dvir__truck_details')}
            </Text>
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Select
              name={VehicleFormFields.inspectionTripType.name}
              value={vehicleForm.inspectionTripType.value}
              error={readOnly ? false : vehicleForm.inspectionTripType.isError}
              fullWidth
              readOnly={readOnly}
              onChange={handleValueChange}
            >
              {Object.entries(InspectionTripTypeLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {t(value)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        {currentDvirSubmitMode !== DvirSubmitMode.Trailer && (
          <Box display="flex" gap={3} marginBottom={7}>
            {[VehicleFormFields.odometer.name, VehicleFormFields.engineHours.name].map((fieldName) => (
              <Input
                key={fieldName}
                type="number"
                fullWidth
                InputProps={{ readOnly }}
                label={t(VehicleFormLabels[fieldName])}
                value={vehicleForm[fieldName].value}
                error={readOnly ? false : vehicleForm[fieldName].isError}
                name={fieldName}
                onChange={handleValueChange}
              />
            ))}
          </Box>
        )}
      </Box>

      {Object.entries(inspectionForm).map(
        ([categoryName, category]) =>
          isRendered(categoryName) && (
            <Box
              key={categoryName}
              ref={(ref) => {
                categoryItems.current[categoryName] = ref;
              }}
            >
              <Category
                category={category}
                categoryName={categoryName}
                attachments={attachmentsForm?.[categoryName]?.items}
                isOpen={renderCounter === 1}
                readOnly={readOnly}
                onInspectionFormChange={onInspectionFormChange}
                onAddAttachment={onAddAttachment}
                onEditNote={onEditNote}
                onDeleteNote={onDeleteNote}
                onDeletePhoto={onDeletePhoto}
                isDriverSignOff={isDriverSignOff}
              />
            </Box>
          ),
      )}
    </Box>
  );
};

export default Inspection;
