const _fc_props = {};
_fc_props.ante_meridiem = 'AM (ранок)';
_fc_props.post_meridiem = 'PM (вечір)';
_fc_props.retry = 'Спробуйте ще раз';
_fc_props.default = 'За замовчуванням';
_fc_props.origin = 'Пункт відправлення';
_fc_props.loading = 'Завантаження';
_fc_props.list = 'Список';
_fc_props.send = 'Відправити';
_fc_props.error = 'Помилка';
_fc_props.save = 'Зберегти';
_fc_props.delete = 'Видалити';
_fc_props.message = 'Повідомлення';
_fc_props.logout = 'Вихід';
_fc_props.add = 'Додати';
_fc_props.remove = 'Видалити';
_fc_props.done = 'Готово';
_fc_props.other = 'Інше';
_fc_props.interval = 'Інтервал';
_fc_props.time = 'Час';
_fc_props.speed = 'Швидкість';
_fc_props.longitude = 'Довгота';
_fc_props.latitude = 'Широта';
_fc_props.x_minutes = '{0} хвил.';
_fc_props.general = 'Загальне';
_fc_props.messages = 'Повідомлення';
_fc_props.gps_fixes = 'Прийом сигналів GPS';
_fc_props.device_id = 'Id пристрою';
_fc_props.vehicle = 'Транспортний засіб';
_fc_props.employee = 'Працівник';
_fc_props.version = 'Версія';
_fc_props.statuses = 'Статуси';
_fc_props.edit = 'Редагувати';
_fc_props.cancel = 'Відмінити';
_fc_props.yes = 'Так';
_fc_props.none = 'Немає';
_fc_props.never = 'Ніколи';
_fc_props.name = 'Назва';
_fc_props.true = 'Правильно';
_fc_props.status = 'Статус';
_fc_props.distance = 'Відстань';
_fc_props.ok = 'Oк';
_fc_props.login = 'Вхід';
_fc_props.next = 'Наступне';
_fc_props.menu = 'Меню';
_fc_props.not_available = 'Не зазначено';
_fc_props.back = 'Назад';
_fc_props.select = 'Обрати';
_fc_props.dispatch = 'Відправка';
_fc_props.messaging = 'Надсилання повідомлень';
_fc_props.plants = 'Пункти';
_fc_props.last_plants = 'Останній пункт';
_fc_props.vehicle_types = 'Типи транспортних засобів';
_fc_props.employee_types = 'Типи працівників';
_fc_props.employee_groups = 'Групи працівників';
_fc_props.equipment_groups = 'Групи обладнання';
_fc_props.job = 'Робота';
_fc_props.order = 'Замовлення';
_fc_props.ticket = 'Ticket #';
_fc_props.small = 'Маленький';
_fc_props.medium = 'Середній';
_fc_props.large = 'Великий';
_fc_props.reports = 'Звіти';
_fc_props.payroll = 'Платіжна відомість';
_fc_props.support = 'Підтримка';
_fc_props.username = 'Ім\'я користувача';
_fc_props.all = 'Все';
_fc_props.question = 'Питання';
_fc_props.select_employee = 'Оберіть працівника';
_fc_props.phone = 'Телефон';
_fc_props.january = 'Січень';
_fc_props.february = 'Лютий';
_fc_props.march = 'Березень';
_fc_props.april = 'Квітень';
_fc_props.may = 'Травень';
_fc_props.june = 'Червень';
_fc_props.july = 'Липень';
_fc_props.august = 'Серпень';
_fc_props.september = 'Вересень';
_fc_props.october = 'Жовтень';
_fc_props.november = 'Листопад';
_fc_props.december = 'Грудень';
_fc_props.are_you_sure_you_want_to_delete = 'Ви впевнені, що хочете видалити?';
_fc_props.unknown = 'Невідомо';
_fc_props.select_all = 'Обрати все';
_fc_props.equipment = 'Обладнання';
_fc_props.region = 'Регіон';
_fc_props.no_data = 'Немає даних';
_fc_props.hotspot = 'геозона';
_fc_props.address = 'Адреса';
_fc_props.retrieving_address = 'Пошук адреси...';
_fc_props.address_not_found = 'Адресу не знайдено';
_fc_props.active_time = 'Активний час';
_fc_props.destination_time = 'Час прибуття';
_fc_props.complete_time = 'Зупинити час';
_fc_props.status_report = 'Звіт за статусом';
_fc_props.export_report = 'Звіт про експорт';
_fc_props.download_report = 'Завантажити звіт';
_fc_props.view_report = 'Переглянути звіт';
_fc_props.duration = 'Тривалість';
_fc_props.min = 'Хвил';
_fc_props.start_date_time = 'Дата/час початку';
_fc_props.select_start_date = 'Оберіть дату початку';
_fc_props.end_date_time = 'Дата/час закінчення';
_fc_props.select_end_date = 'Оберіть дату закінчення';
_fc_props.trip_time = 'Тривалість рейсу';
_fc_props.travel_time = 'Час у дорозі';
_fc_props.description = 'Опис';
_fc_props.map_controls = 'Робота з картою';
_fc_props.road = 'Дорога';
_fc_props.aerial = 'Антена';
_fc_props.hybrid = 'Гібрид';
_fc_props.trip = 'Рейс';
_fc_props.map = 'Карта';
_fc_props.plant = 'Пункт';
_fc_props.select_date = 'Оберіть дату';
_fc_props.apply = 'Застосувати';
_fc_props.save_settings = 'Зберегти налаштування';
_fc_props.false = 'Невірно';
_fc_props.delete_confirmation = 'Підтвердити видалення';
_fc_props.last_name = 'Прізвище';
_fc_props.none_selected = 'Не обрано';
_fc_props.start_date = 'Дата початку';
_fc_props.group_by = 'Групування за';
_fc_props.equipment_employee = 'Обладнання / Працівник';
_fc_props.inactive = 'Неактивний';
_fc_props.active = 'Активний';
_fc_props.note_checking_may_affect_performance = 'Увага: Перевірка може вплинути на виконання';
_fc_props.show_distancefuel_used = 'Показати відстань/кількість використаного палива';
_fc_props.mins = 'Хв';
_fc_props.threshold_time = 'Ліміт часу';
_fc_props.include_voided_tickets = 'Включити анульовані тікети';
_fc_props.note_all_times_listed_are_in_minutes = 'Увага: весь час вказаний у хвилинах';
_fc_props.please_select_a_job_order_or_equipment = 'Виберіть роботу, замовлення, установку, обладнання або працівника';
_fc_props.device = 'Пристрій';
_fc_props.select_equipment = 'Обрати обладнання';
_fc_props.all_equipment_types = 'Усі типи обладнання';
_fc_props.units = {};
_fc_props.units.liters = 'літри';
_fc_props.type = 'Тип';
_fc_props.no_data_available = 'Дані недоступні';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Відстань та використане паливо по регіонах';
_fc_props.menu_settings_title_settings = 'Налаштування';
_fc_props.menu_settings_title_alerts = 'Сповіщення';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Обладнання';
_fc_props.menu_settings_title_hotspots_regions = 'Геозони та Регіони';
_fc_props.days = '{0} день(-ів)';
_fc_props.home = 'Головна';
_fc_props.find = 'Знайти';
_fc_props.close_this_panel = 'Закрити дану панель';
_fc_props.filters = 'Фільтри';
_fc_props.start = 'Початок';
_fc_props.end = 'Кінець';
_fc_props.location_details = 'Дані про місцезнаходження';
_fc_props.first_name = 'Ім\'я';
_fc_props.material_per_hour = 'Кількість матеріалу, що перевозиться за годину';
_fc_props.equipment_pound = 'Обладнання #';
_fc_props.equipment_type = 'Тип обладнання';
_fc_props.options = 'Варіанти';
_fc_props.zoom_on_cursor_position = 'Збільшити курсор';
_fc_props.display_equipment_identifier = 'Відображення ідентифікатора обладнання';
_fc_props.view_equipment = 'Переглянути обладнання';
_fc_props.view_hotspots = 'Переглянути геозони';
_fc_props.view_regions = 'Переглянути регіони';
_fc_props.use_standard_filters = 'Використовувати стандартні фільтри';
_fc_props.home_plant = 'Головний пункт';
_fc_props.last_plant = 'Останній пункт';
_fc_props.equip_types = 'Типи обладнання';
_fc_props.equip_groups = 'Групи обладнання';
_fc_props.orders = 'Замовлення';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Використовувати фільтр обладнання';
_fc_props.all_selected = 'Обрано все';
_fc_props.of = 'з';
_fc_props.your_map_session_has_expired = 'Ваша сесія закінчилася. Будь ласка, обновіть сторінку.';
_fc_props.equipment_short = 'Обладн.';
_fc_props.job_info_for = 'Інформація про роботу по';
_fc_props.order_info_for = 'Інформація про замовлення по';
_fc_props.ticket_info_for_vehicle = 'Дані тікета по транспортному засобу';
_fc_props.inbox = 'Вхідні';
_fc_props.outbox = 'Вихідні';
_fc_props.alerts = 'Сповіщення';
_fc_props.critical = 'Критичні';
_fc_props.important = 'Важливі';
_fc_props.informational = 'Інформаційні';
_fc_props.view_routes = 'Переглянути маршрути';
_fc_props.hours_size = 'Кількість годин';
_fc_props.used_count = 'Кількість використаного';
_fc_props.last_login = 'Останній вхід';
_fc_props.last_vehicle = 'Останній транспортний засіб';
_fc_props.regions = 'Регіони';
_fc_props.driver = 'Водій';
_fc_props.js_delete = 'Видалити';
_fc_props.move = 'Перемістити';
_fc_props.edit_hotspot = 'Редагувати геозону';
_fc_props.adjust_shape = 'Встановити бажану форму';
_fc_props.edit_region = 'Редагувати регіон';
_fc_props.enter_a_start_address = 'Будь ласка, введіть адресу відправлення';
_fc_props.select_a_start_hotspot = 'Будь ласка, оберіть геозону відправлення';
_fc_props.failed_to_find_start_hotspot = 'Не вдалося знайти геозону відправки.';
_fc_props.invalid_latitudelongitude = 'Неправильна довгота/широта';
_fc_props.enter_an_end_address = 'Будь ласка, введіть адресу кінцевого пункту';
_fc_props.select_an_end_hotspot = 'Будь ласка, оберіть кінцеву геозону';
_fc_props.failed_to_find_end_hotspot = 'Не вдалося знайти кінцеву геозону.';
_fc_props.print = 'Роздрукувати';
_fc_props.these_directions_are_for_informational_purposes = 'Ці інструкції надані виключно в інформаційних цілях. Ми не можемо надавати гарантій про повноту й точність інфомації. Різні будівельні проекти, рух на дорогах та інші фактори можуть спричиняти зміни в інформації.';
_fc_props.enter_an_address = 'Будь ласка, введіть адресу';
_fc_props.select_a_hotspot = 'Будь ласка, оберіть геозону';
_fc_props.failed_to_find_hotspot = 'Не вдалося знайти геозону.';
_fc_props.select_a_vehicle = 'Будь ласка, оберіть транспортний засіб';
_fc_props.failed_to_find_vehicle = 'Не вдалося знайти транспортний засіб.';
_fc_props.failed_to_find_address = 'Не вдалося знайти адресу';
_fc_props.failed_to_find_vehicles = 'За заданими критеріями транспортний засіб знайти не вдалося.';
_fc_props.get_address = 'Отримати адресу';
_fc_props.results_for = 'Результати з';
_fc_props.error_retrieving_results = 'Не вдалося оновити результати';
_fc_props.no_results_found = 'Результати не знайдені';
_fc_props.address_is_required = 'Необхідно вказати адресу';
_fc_props.city_is_required = 'Необхідно вказати місто';
_fc_props.set_location = 'Позначити місце розташування';
_fc_props.set_area_of_interest = 'Позначити область, що цікавить';
_fc_props.center_map_here = 'Позначити центр карти тут';
_fc_props.zoom_to_street_level = 'Збільшити масштаб до перегляду вулиць';
_fc_props.set_start = 'Задати початок';
_fc_props.set_end = 'Задати кінець';
_fc_props.delete_node = 'Видалити точку перетину';
_fc_props.create_a_hotspot = 'Створити геозону';
_fc_props.create_a_region = 'Створити регіон';
_fc_props.toggle_shapes = 'Переключити форми';
_fc_props.refresh_map = 'Оновити карту';
_fc_props.more_options = 'Більше опцій';
_fc_props.recenter_map = 'Повернути карту в початковий стан';
_fc_props.zoom_to_vehicle = 'Збільшити до огляду транспортного засобу';
_fc_props.apply_filters = 'Застосувати фільтри';
_fc_props.grid_view = 'Стислий огляд';
_fc_props.list_view = 'Перегляд списку';
_fc_props.click_to_sort = 'Натисніть для сортування';
_fc_props.message_history = 'Історія повідомлень';
_fc_props.equipment_types = 'Типи обладнання';
_fc_props.send_message = 'Надіслати повідомлення';
_fc_props.last_load = 'Останнє завантаження';
_fc_props.last_load_on = 'Останнє завантаження';
_fc_props.manual_logout = 'Вийти вручну';
_fc_props.manual_login = 'Увійти вручну';
_fc_props.no_employees_logged_in = 'У системі немає працівників';
_fc_props.select_phone = 'Оберіть телефон';
_fc_props.you_must_select_a_phone = 'Оберіть телефон, обладнання або працівника';
_fc_props.are_you_sure_you_want_to_logout = 'Ви впевнені, що хочете вийти?';
_fc_props.fix_time = 'Час прийому';
_fc_props.to_next_fix = 'До наступного прийому';
_fc_props.minutes_to_next_fix = 'Хвилин до наступного';
_fc_props.liters = 'Літри';
_fc_props.destination = 'Місце призначення';
_fc_props.return = 'Зворотня відправка';
_fc_props.product = 'Продукт';
_fc_props.no_records_found = 'Даних не знайдено.';
_fc_props.vehicle_type = 'Тип транспорту';
_fc_props.view_in_map = 'Переглянути все на карті';
_fc_props.uploads = 'Завантажування';
_fc_props.upload_csv_file = 'Завантажити CSV файл';
_fc_props.permanent = 'Постійна';
_fc_props.expire_time = 'Час, що минув';
_fc_props.please_select_x_for_tickets_marked_active = 'Для АКТИВНИХ тікетів, будь ласка, оберіть {0}';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Велика кількість тікетів позначена АКТИВНИМИ для однакового {0}';
_fc_props.failure_connecting_to_servlet = 'Не вдалося з\'єднатися із servlet.';
_fc_props.status_time_summary = 'Зведення за часом у статусі';
_fc_props.view_in_map_one = 'Подивитися по карті';
_fc_props.question_report = 'Звіт із запитань';
_fc_props.end_date = 'Дата закінчення';
_fc_props.trailer_number = 'Номер автопричіпа';
_fc_props.region_panel = 'Панель регіонів';
_fc_props.hotspot_panel = 'Панель геозон';
_fc_props.shape = 'Форма';
_fc_props.circle = 'Коло';
_fc_props.polygon = 'Багатокутник';
_fc_props.click_the_map_to_set_the_location = 'Натисніть на карту, щоб задати бажане місце розташування.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Натисніть на карту, щоб задати центральний хотспот.';
_fc_props.click_the_map_to_set_the_region_center = 'Натисніть на карту, щоб задати центр регіону.';
_fc_props.click_and_drag_points = 'Натисніть і перетягніть точки для редагування форми. Щоб зберегти зміни, натисніть ЗБЕРЕГТИ.';
_fc_props.size = 'Розмір';
_fc_props.are_you_sure_you_want_to_move_this = 'Ви впевнені, що хочете здійснити перетягування?';
_fc_props.are_you_sure_you_want_to_delete_this = 'Ви наполягаєте на видаленні?';
_fc_props.please_enter_a_valid_size = 'Будь ласка, введіть розмір';
_fc_props.failed_to_save = 'Не вдалося зберегти.';
_fc_props.failed_to_move = 'Не вдалося пересунути.';
_fc_props.failed_to_delete = 'Неможливо видалити.';
_fc_props.x_hours = '{0} годин';
_fc_props.hide_hotspots = 'Сховати геозони';
_fc_props.show_regions = 'Показати регіони';
_fc_props.show_hotspots = 'Показати геозони';
_fc_props.hide_regions = 'Сховати регіони';
_fc_props.numeric = 'Число';
_fc_props.message_type = 'Тип повідомлення';
_fc_props.text = 'Текст';
_fc_props.yes_no = 'Так/Ні';
_fc_props.responses = 'Відповіді';
_fc_props.disabled = 'Недоступно';
_fc_props.group_name = 'Назва групи';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Ви впевнені, що хочете видалити формат \'{0}\'?';
_fc_props.phone_number = 'Номер телефону';
_fc_props.interface_pound = 'Інтерфейс #';
_fc_props.field = 'Поле';
_fc_props.default_label = 'Ярлик за замовчуванням';
_fc_props.custom_label = 'Користувальницький ярлик';
_fc_props.you_must_enter_a_label = 'Введіть ярлик.';
_fc_props.enabled = 'Активовано';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Інтегрування тікета';
_fc_props.settings.geofence_off_open_hotspots = 'Дозволити згортання відкритих геозон';
_fc_props.settings.associate_open_hotspots_wticket = 'Прив\'язати відкриті геозони до тікетів';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Дозволити згортання відкритих геозон або тільки геозон з прив\'язкою до тікетів водія';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Прив’язувати чи ні відкриті геозони до тікетів при досягненні їх транспортним засобом';
_fc_props.settings.void_ticket_without_destination = 'Недійсний тікет без вказівки пункту призначення.';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'У разі, якщо працівник отримує новий тікет поки попередній ще відкритий, коли в попередньому тікеті пункт призначення не вказаний, він буде анульований.';
_fc_props.settings.autocreate_hotspots = 'Авто-створення геозон';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Автоматично створювати чи ні геозони в пункті призначення при отриманні тікета';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Авто-активація тікета при його отриманні';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Активний чи ні отриманий від TrackIt тікет (За замовчуванням ’Так’)';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Авто-закриття активного тікета після отримання хвилин';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Час активності поточного тікета перш ніж він буде автоматично закритий (За замовчуванням\'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Максимальний час активності тікета у хвилинах';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Час у хвилинах, протягом якого тікет буде активний після його активації в системі TrackIt (За замовчуванням \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Максимальна кількість днів, протягом яких можна не заходити до геозон';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Визначає максимальну кількість днів, протягом яких до геозони можна буде увійти в будь-який час.';
_fc_props.settings.radius_multiplier = 'Множник радіуса';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'На що слід множити радіус при отриманні тікета від диспетчерської системи';
_fc_props.settings.ticket_message_date_format = 'Формат повідомлення та дати тікета';
_fc_props.am = 'до обіду';
_fc_props.pm = 'після обіду';
_fc_props.settings.ticket_message = 'Повідомлення по тікету';
_fc_props.settings.add_ticket_element = 'Додати елемент тікета';
_fc_props.driver_performance = 'Результати роботи водія';
_fc_props.update_failed_can_not_connect = 'Оновлення не виконане: Неможливо з\'єднатися з servlet.';
_fc_props.unknown_error = 'Невідома помилка';
_fc_props.selected = 'Обрані';
_fc_props.web_service = 'Веб-сервіс';
_fc_props.error_processing_request = 'Помилка обробки запиту.';
_fc_props.email_address = 'Email адреса';
_fc_props.id = 'ID';
_fc_props.last_used = 'Востаннє використано';
_fc_props.error_invalid_vehiclenum = 'Помилка Недійсний номер транспортного засобу';
_fc_props.error_vehicle_is_not_logged_in = 'Помилка: транспортний засіб не знаходиться у системі.';
_fc_props.users = 'Користувачі';
_fc_props.you_dont_have_access_to_this_page = 'У вас немає доступу для перегляду цієї сторінки. Для доступу зверніться до свого системного адміністратора.';
_fc_props.previous_week = 'Попередній тиждень';
_fc_props.status_breakdown = 'Порушення статусу';
_fc_props.select_plant_or_employee_group = 'Оберіть пункт або групу працівників.';
_fc_props.cannot_select_plant_and_employee_group = 'Ви не можете одночасно обрати і пункт і групу працівників.';
_fc_props.odometer_must_be_a_number = 'Початкове значення на одометрі має бути числом';
_fc_props.ending_odometer_must_be_a_number = 'Кінцеве значення на одометрі має бути числом';
_fc_props.beginning_hour_meter_must_be_a_number = 'Початкові показання лічильника повинні бути числом';
_fc_props.ending_hour_meter_must_be_a_number = 'Кінцеві показання лічильника повинні бути числом';
_fc_props.error_loading_status_change_page = 'Помилка завантаження сторінки зміни статусу.';
_fc_props.x_is_an_invalid_time_format = '{0} в невірному форматі. Дійсний формат РРРР-MM-ДД ГГ:ХХ:СС';
_fc_props.x_for_schedule_login = '{0} для входа за графіком';
_fc_props.x_for_schedule_logout = '{0} для вихода за графіком';
_fc_props.x_for_actual_login = '{0} для фактичного входу';
_fc_props.x_for_actual_logout = '{0} для фактичного виходу';
_fc_props.by_refreshing_the_time_card_detail = 'By refreshing the Time Card Detail you will lose all changes, continue?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Ви впевнені, що бажаєте видалити {0}?';
_fc_props.settings.command_mobile_ticket_url = 'URL до мобільного тікету Command';
_fc_props.year = 'Рік';
_fc_props.make = 'Зробити';
_fc_props.model = 'Модель';
_fc_props.settings.command_mobile_ticket_password = 'Команда Mobile квитків Пароль';
_fc_props.settings.command_mobile_ticket_auth_token = 'Команда Mobile квитків Auth маркера';
_fc_props.target = 'Мета';
_fc_props.number_of_digits = 'Кількість цифр';
_fc_props.number_of_integer_digits = 'Кількість цілих чисел';
_fc_props.number_of_fractional_digits = 'Кількість знаків після коми';
_fc_props.search = 'Пошук';
_fc_props.settings.command_mobile_ticket_date_order = 'Команда Mobile квитків Дата замовлення';
_fc_props.settings.command_mobile_ticket_date_length = 'Команда Mobile квитків Дата Довжина';
_fc_props.settings.command_mobile_ticket_date_separator = 'Команда Mobile квитків роздільник дати';
_fc_props.settings.command_mobile_ticket_slash = '/ (Коса риса)';
_fc_props.settings.command_mobile_ticket_dash = '- (Тирі)';
_fc_props.settings.command_mobile_ticket_period = ', (Період)';
_fc_props.settings.command_mobile_ticket_space = '(Пропуск)';
_fc_props.settings.command_mobile_ticket_time_length = 'Команда Мобільна час з продажу квитків Довжина';
_fc_props.settings.command_mobile_ticket_time_separator = 'Команда Mobile квитків роздільник часу';
_fc_props.settings.command_mobile_ticket_colon = ': (Двокрапка)';
_fc_props.settings.command_mobile_ticket_time_format = 'Команда Мобільна час з продажу квитків Формат';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Використання інтерфейсу номер';
_fc_props.mechanic = 'Механік';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Poll Interval (секунди)';
_fc_props.driving_time = 'Час водіння';
_fc_props.find_near_plants = 'Знайти Біля Рослини';
_fc_props.please_create_hot_spot_first = 'Будь ласка, створіть точку доступу спочатку';
_fc_props.please_enter_hot_spot_description = 'Будь ласка, введіть гарячої опис пляма';
_fc_props.please_select_a_hot_spot_type = 'Будь ласка, виберіть тип плями гарячої';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Надіслати на e-mail водія';
_fc_props.directions_not_found = 'Невірний {0} Адреса. Спрямування не знайдений.';
_fc_props.ws_user = 'користувач';
_fc_props.ws_service = 'служба';
_fc_props.ws_method = 'метод';
_fc_props.ws_params = 'Params';
_fc_props.units_kilometers_abbr = 'КМ';
_fc_props.units_miles_abbr = 'Мічиган';
_fc_props.origin_and_destination = 'Походження та призначення';
_fc_props.liter_abbr = 'L';
_fc_props.help = 'допомога';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Використовуйте Alt Кількість працівників';
_fc_props.settings.command_mobile_ticket_android_url = 'URL Android Mobile Ticket';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Secret Ticket';
_fc_props.employee_x_has_no_logout_on_y = 'Співробітник {0} не має виходу з системи для зсуву, починаючи з {1}.';
_fc_props.phone_number_has_duplicates = 'Номери телефонів мають повторювані записи';
_fc_props.ounce = 'унція';
_fc_props.water_extra = 'вода Екстра';
_fc_props.always = 'завжди';
_fc_props.work_types_must_be_unique = 'Види роботи повинні бути унікальними';
_fc_props.manufacturer = 'виробник';
_fc_props.need_to_enter_seconds = 'Необхідно ввести секунди.';
_fc_props.copy = 'копія';
_fc_props.create_in_existing_required = 'Створення в існуючому повинні бути вибрано для створення точки доступу в роумінгу.';
_fc_props.break = 'Перерва';
_fc_props.add_export_data_type = 'Додати тип даних експорту';
_fc_props.edit_export_data_type = 'Редагувати тип даних експорту';
_fc_props.delete_export_data_type = 'Видалити тип даних експорту';
_fc_props.export_data_type = 'Експортувати дані типу';
_fc_props.error_updating_export_data_type = 'Помилка оновлення типу даних експорту';
_fc_props.settings.address_key = 'Адресний ключ';
_fc_props.settings.address_key_hover = 'Параметри ключових адрес';
_fc_props.settings.address_key_options = 'Параметри ключових адрес';
_fc_props.more_filter_options = 'Додаткові параметри фільтра';
_fc_props.unauthorized_mapit_app = 'Ця версія версії MapIt не авторизована, оновіть MapIt якомога швидше.';
_fc_props.zello_timeout = 'Тайм-аут відбувся, звернувшись до Зелло.';
_fc_props.user_agreement_text = 'Увійшовши, ви погоджуєтеся з';
_fc_props.user_agreement = 'користувацька угода';
_fc_props.upload = 'Завантажити';
_fc_props.x_mins_left = 'Залишилося {0} хв';
_fc_props.continuous_location_tracking_colon = 'Постійне відстеження місцезнаходження:';
_fc_props.hired_hauler_cannot_register_device = 'Наемний перевізник не може зареєструвати новий пристрій.';
_fc_props.timecard_does_not_contain_shift = 'Карта часу співробітника не містить зміну (Time Card Info)';
_fc_props.timecard_not_found = 'Картка часу працівника не знайдена';
_fc_props.vehicle_must_be_assigned_for_clt = 'Автомобіль повинен бути призначений для відстеження місцезнаходження.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Автомобіль був непризначений, безперервне відстеження буде вимкнено.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'М';
_fc_props.error_lost_edc_connection = 'Загублений зв\'язок з EDC. Будь-ласка, перевірте, чи включено Wi-Fi та / або Bluetooth.';
_fc_props.acknowledge_all = 'Підтвердити все';
_fc_props.expand = 'Розгорнути';
_fc_props.collapse = 'Колапс';
_fc_props.expand_all = 'Розгорнути все';
_fc_props.collapse_all = 'Зруйнувати все';
_fc_props.last_update = 'Останнє оновлення';
_fc_props.acknowledge_alert = 'Підтвердити сповіщення';
_fc_props.acknowledge_message = 'Підтвердити повідомлення';
_fc_props.acknowledge_all_alerts = 'Підтвердити всі сповіщення';
_fc_props.acknowledge_all_messages = 'Підтвердити всі повідомлення';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Толерантність має бути дійсним цілим числом між -99 та 99 (обидва включені)';
_fc_props.gps_permission_required = 'Необхідно дозволити GPS';
_fc_props.permissions_required = 'Потрібно дозволи';
_fc_props.change_user = 'Змінити користувача';
_fc_props.account_not_registered_with_carrier = 'Цей обліковий запис не зареєстровано оператором';
_fc_props.slump_loss = 'Втрата падіння';
_fc_props.slump_loss_help = 'Довідка про втрату спаду';
_fc_props.did_not_receive_any_edc_devices = 'Не отримали жодних пристроїв EDC.';
_fc_props.edc_record_not_found = 'Запис EDC не знайдено';
_fc_props.disable_hotspot_prompt = 'Натисніть "ОК", коли буде запропоновано вимкнути точку доступу.';
_fc_props.edc_device_is_in_use = 'Використовується EDC {0}.';
_fc_props.an_unknown_exception_has_occurred = 'Сталося невідоме виключення';
_fc_props.login_has_timed_out = 'Час очікування входу минув';
_fc_props.use_paper_logs = 'Використовуйте журнали паперу';
_fc_props.eld_login_failed = 'Не вдалося ввести час входу до служби';
_fc_props.setup_time_off_type = 'Встановіть тип відключення.';
_fc_props.view_load_properties = 'Перегляд властивостей завантаження';
_fc_props.couldnt_download_edc_firmware = 'Не вдалося завантажити прошивку EDC.';
_fc_props.password_incorrect = 'Неправильний пароль';
_fc_props.edc_configuration_password_prompt = 'Через стан цього пристрою ви повинні вказати пароль інсталятора для налаштування.';
_fc_props.edc_configuration_check_failed = 'Помилка перевірки записів серверів EDC. Перевірте повідомлення про помилки для отримання детальної інформації.';
_fc_props.configuration_issues_prompt = 'Наступні проблеми були виявлені під час налаштування вибраного EDC для зазначеної вантажівки';
_fc_props.checking_password = 'Перевірка пароля';
_fc_props.reset_password_instructions_sent = 'Інструкції щодо скидання пароля, надісланого на електронну адресу {0}.';
_fc_props.reset_password_failed = 'Не вдалося скинути пароль. Повторіть спробу.';
_fc_props.forgot_password = 'Забули пароль?';
_fc_props.enter_valid_email = 'Недійсна електронна адреса. Введіть дійсну електронну адресу та спробуйте ще раз.';
_fc_props.issues_while_updating_edc_record = 'EDC було налаштовано успішно. Однак існують проблеми із записами для EDC на сервері TrackIt, які можуть спричинити його функціонування. Будь ласка, зателефонуйте до служби підтримки, щоб виправити інформацію.';
_fc_props.sending = 'Надсилання ...';
_fc_props.minimum_ratio = 'Мінімальний коефіцієнт';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'RPM барабану не в діапазоні';
_fc_props.probe_failure = 'Збій зонду';
_fc_props.unknown_probe_data_failure = 'Невідома помилка даних зонда';
_fc_props.no_loaded_status_meaning = 'Немає завантаженого стану';
_fc_props.no_depart_status_meaning = 'Немає статусу вильоту';
_fc_props.no_arrive_status_meaning = 'Немає статусу прибуття';
_fc_props.no_begin_work_status_meaning = 'Не починається робочий статус';
_fc_props.no_fully_mixed_status_meaning = 'Немає повністю змішаного стану';
_fc_props.no_begin_work_or_at_job_status = 'Почніть роботу або статусів на роботі немає';
_fc_props.driver_didnt_follow_procedure = 'Водій не дотримувався процедури (Не гальмує барабан)';
_fc_props.probe_malfunction = 'Несправність зонда (внутрішня помилка тощо)';
_fc_props.no_data_from_probe = 'Немає даних зонда (втрата даних)';
_fc_props.missing_probe_calibration = 'Немає даних калібрування зонда';
_fc_props.probe_pressure_not_in_range = 'Тиск зонда не в діапазоні';
_fc_props.arrival = 'Прибуття';
_fc_props.small_load = 'МАЛИЙ НАВАНТАЖЕННЯ';
_fc_props.dry_slump = 'СУХА СЛУМПА';
_fc_props.probe_remixing_turns = 'Реміксинг';
_fc_props.turns_needed_to_remix = 'Додана вода - потрібно додаткове перемішування';
_fc_props.probe_remixing_finished = 'Реміксінг закінчено';
_fc_props.probe_battery_low_alert = 'Низький рівень сповіщення акумулятора зонда';
_fc_props.probe_battery_low_message = 'Акумулятор зонду низький';
_fc_props.probe_battery_low = 'Акумулятор зонду низький';
_fc_props.alert_when_probe_battery_low = 'Сповіщення, коли акумулятор зонда розряджений';
_fc_props.probe_sensor_fatal_failure = 'Смертельна несправність датчика';
_fc_props.probe_thermistor_failure = 'Поломка термістора';
_fc_props.probe_sensor_stuck_in_concrete = 'Датчик застряг у бетоні';
_fc_props.probe_sensor_over_weight = 'Датчик над вагою';
_fc_props.probe_batteries_low = 'Низький заряд батареї';
_fc_props.no_probe_data = 'Немає даних зонда:';
_fc_props.check_probe = 'Перевірте зонд';
_fc_props.service_probe = 'Службовий зонд';
_fc_props.below_x = 'Нижче {0}';
_fc_props.above_x = 'Вище {0}';
_fc_props.probe_serial_number_invalid_format = 'Серійний номер зонда не у правильному форматі, як відображено в заповнювачі для поля';
_fc_props.talk_dnd_permission_prompt = 'Для розмови потрібен НЕ РОЗБУДОВА дозволу.';
_fc_props.max = 'Макс';
_fc_props.status_changed_quickly = 'Статус змінився занадто швидко';
_fc_props.is_empty = 'Пусто';
_fc_props.slump_increased_plasticizer_question = 'Чи додали пластифікатор?';
_fc_props.slump_increased_water_added_question = 'Скільки води було додано без вимірювання?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Моніторинг зонда та циклу доставки не підтримуються разом на одному пристрої.';
_fc_props.no_data_from_sensor = 'Немає даних сенсора (втрата даних)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Тип системи';
_fc_props.magnet_count = 'Графік магнітів';
_fc_props.sensors_setup = 'Налаштування датчиків';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM вимагає встановлення MAC-адреси коробки вводу-виводу, типу барабана, типу DCM та кількості магнітів.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Об’ємний квиток';
_fc_props.drs_current_volume = 'Поточний обсяг';
_fc_props.drs_current_water_cement_ratio = 'Поточне співвідношення води / цементу';
_fc_props.drs_target_water_cement_ratio = 'Цільове співвідношення води / цементу';
_fc_props.drs_total_revolutions = 'Всього';
_fc_props.drs_current_volume_lp_small = 'Поточний';
_fc_props.drum_revolutions = 'Барабанні революції';
_fc_props.drs_total_revolutions_lp_small = 'Всього';
_fc_props.drs_charging_revolutions_lp_small = 'Зарядка';
_fc_props.drs_discharging_revolutions_lp_small = 'Вивантаження';
_fc_props.drs_target_wc_lp_small = 'Ціль W / C';
_fc_props.drs_current_wc_lp_small = 'Поточний W / C';
_fc_props.drs_ticketed_volume_lp_small = 'Квитки';
_fc_props.android_location_disclosure = 'TrackIt використовує точне та грубе розташування для збору даних про місцезнаходження у фоновому режимі, навіть коли вони не використовуються, щоб увімкнути автоматичне встановлення статусу та видимість карти в реальному часі для відправлення.';
_fc_props.android_location_usage = 'Розкриття інформації про місцезнаходження';
_fc_props.end_pour_trigger_threshold = 'Кінцевий поріг тригера порогу';
_fc_props.drs_wiring_error = 'Помилка проводки';
_fc_props.drs_wiring_pulse_error = 'Помилка імпульсу проводки';
_fc_props.drs_wiring_magnet_error = 'Помилка проводки магніту';
_fc_props.drs_magnet_missing_error = 'Помилка відсутнього магніту';
_fc_props.android_location_disclosure_3p = 'TrackIt збирає дані про місцезнаходження у фоновому режимі, щоб забезпечити автоматичне встановлення статусу та видимість карти в режимі реального часу для відправки. Коли вигнали з вантажовідправника, місцезнаходження не ділиться.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Будь ласка, виберіть Full MDM або Trackit Remote Support';
_fc_props.move_up = 'Рухатися вгору';
_fc_props.move_down = 'Рухатися вниз';
_fc_props.driver_not_found = 'Драйвер не знайдено.';
_fc_props.multiple = 'Множинні';
_fc_props.water_meter_installed = 'Встановлено лічильник води';
_fc_props.delete_waypoint = 'Видалити пункт маршруту';
_fc_props.wash_sensor_installed = 'Встановлено датчик прання';
_fc_props.washing_start_time = 'Час початку прання';
_fc_props.end_washing = 'Завершення прання';
_fc_props.variable_rpms = 'Var RPM';
_fc_props.no_slump = 'Ніякого спаду';
_fc_props.end_washing_duration = 'Кінець тривалості прання';
_fc_props.privacy_policy = 'Політика конфіденційності';
_fc_props.privacy_policy_text = 'Я прочитав і приймаю';
_fc_props.login_fail_accept_privacy_policy = 'Політика конфіденційності повинна бути прийнята, щоб продовжити.';
_fc_props.rotation_sensor = 'Датчик обертання';
_fc_props.pto_sensor = 'Датчик відбору потужності';
_fc_props.pto_sensor_installed = 'Встановлений датчик відбору потужності';
_fc_props.polarity_reversed = 'Зворотна полярність';
_fc_props.pto_sensor_type = 'Тип датчика відбору потужності';
_fc_props.sensor_type = 'Тип датчика';
_fc_props.prompt_select_pto_sensor_type = 'Ви повинні вибрати "Тип датчика відбору потужності", якщо ви вибрали "датчик відбору потужності" як основний тип датчика.';
_fc_props.priming_turns_at_full_load = 'Грунтування поворотів при повному навантаженні';
_fc_props.priming_turns_at_ten_percent_load = 'Грунтування обертів при 10% навантаженні';
_fc_props.weight_units = 'Одиниці ваги';
_fc_props.settings.send_status = 'Надіслати статус / місце розташування на міст подій';
_fc_props.volume_difference = 'Різниця в гучності';
_fc_props.mobile_ticket_iframe_url = 'URL-адреса IFrame для мобільного квитка';
_fc_props.mobile_ticket_user = 'Користувач мобільного квитка';
_fc_props.mobile_ticket_password = 'Пароль мобільного квитка';
_fc_props.current_truck_status = 'Поточний стан вантажівки';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Спрацьовує від максимальних обертів';
_fc_props.alerts_by_type = 'Сповіщення за типом';
_fc_props.water_flow_meter = 'Лічильник витрати води';
_fc_props.flow_meter_no_pulses_warning = 'Без імпульсів - попередження';
_fc_props.flow_meter_no_pulses_critical = 'Без імпульсів - критично';
_fc_props.flow_meter_excessive_pulses = 'Надмірні імпульси';
_fc_props.flow_meter_constantly_flowing = 'Постійно тече';
_fc_props.flow_meter_good = 'Добре';
_fc_props.whats_new = 'Що нового';
_fc_props.whats_new_widget_text = 'Чи знаєте ви, що ми оновлюємо TrackIt принаймні раз на місяць? Отримуйте останні оновлення та поради.';
_fc_props.get_the_scoop = 'Візьміть Совок';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Бізнес -аналітика, яка покращує ваші результати, тепер включена до TrackIt';
_fc_props.find_out_more = 'Дізнатися більше';
_fc_props.daily_breakdown = 'Щоденна розбивка';
_fc_props.question_summary = 'Підсумок запитання';
_fc_props.trip_summary = 'Підсумок подорожі';
_fc_props.manage_employees = 'Управління працівниками';
_fc_props.manage_hotspots = 'Керування точками доступу';
_fc_props.manage_devices = 'Керування пристроями';
_fc_props.manage_equipment = 'Управління обладнанням';
_fc_props.manage_users = 'Керування користувачами';
_fc_props.manage_statuses = 'Керування статусами';
_fc_props.manage_alerts = 'Керуйте сповіщеннями';
_fc_props.popular_report_links = 'Популярні посилання на звіти';
_fc_props.popular_settings_links = 'Популярні посилання на налаштування';
_fc_props.sidemenu_settings = 'Налаштування';
_fc_props.loads_per_driver = 'Навантаження на водія';
_fc_props.please_select_application = 'Виберіть принаймні одну програму';
_fc_props.external_app_processing = 'Обробка у зовнішньому додатку';
_fc_props.external_app_sent = 'Надіслано у зовнішній додаток';
_fc_props.external_app_received = 'Отримано зовнішнім додатком';
_fc_props.wait_time_at_job = 'Час очікування на роботі';
_fc_props.wait_time_at_plant = 'Час очікування на заводі';
_fc_props.loading_time = 'Час завантаження';
_fc_props.unloading_time = 'Час розвантаження';
_fc_props.pre_post_trip = 'Подорож до/після';
_fc_props.update_thresholds = 'Оновлення порогів';
_fc_props.green = 'Зелений';
_fc_props.yellow = 'Жовтий';
_fc_props.red = 'Червоний';
_fc_props.between = 'Між';
_fc_props.num_of_loads = 'Кількість навантажень';
_fc_props.num_of_drivers = 'Кількість драйверів';
_fc_props.status_meaning_not_setup = 'Статус Значення не налаштовано';
_fc_props.or_less = 'Менш';
_fc_props.or_greater = 'Або Великий';
_fc_props.probe_self_diagnostic_state = 'Стан самодіагностики зонда';
_fc_props.update_current_truck_status_settings = 'Оновіть поточні налаштування статусу вантажівки';
_fc_props.default_behavior = 'За умовчанням (10 найкращих статусів)';
_fc_props.combine_similar_statuses = 'Комбінувати однакові статуси з усіх типів (цикли)';
_fc_props.use_other_category = 'Використовуйте категорію "Інше".';
_fc_props.selected_statuses = 'Вибрані статуси';
_fc_props.ideas = 'Ідеї';
_fc_props.air_content = 'Вміст повітря';
_fc_props.air_measured_time = 'Виміряний час повітря';
_fc_props.ten_max = '10 Макс';
_fc_props.loads_per_plant = 'Навантаження на рослину';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Недійсний імпульс витратоміра на обсяг.';
_fc_props.coming_soon_title = 'Більше віджетів незабаром';
_fc_props.submit_your_ideas = 'Надсилайте свої ідеї на наш портал ідей за допомогою лампочки в меню навігації зліва.';
_fc_props.selected_plants = 'Вибрані рослини';
_fc_props.update_loads_per_plant_settings = 'Оновлення налаштувань навантаження на завод';
_fc_props.default_top_10_plants = 'За замовчуванням (10 найкращих рослин)';
_fc_props.phone_number_will_be_uploaded = 'Ідентифікатор пристрою буде завантажено з метою реєстрації.';
_fc_props.include_deleted = 'Включити видалено';
_fc_props.primary_status_type = 'Тип первинного статусу';
_fc_props.additional_status_trigger = 'Додаткові тригери стану';
_fc_props.update_tickets_destination = 'Оновіть пункт призначення для всіх пов’язаних квитків';
_fc_props.settings.send_destination_changes_to_connex = 'Надіслати зміни призначення до Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Чи активний квиток, коли його отримує TrackIt (за замовчуванням "завжди")';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Автоматична активація наступного квитка після завершення попереднього квитка';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Слід автоматично активувати наступний квиток після завершення квитка (за умовчанням "true")';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Статус до стану очікування, якщо немає активного квитка';
_fc_props.break_down = 'Зламатися';
_fc_props.idle = 'Простою';
_fc_props.pto_work_maximum_speed_limit = 'Обмеження максимальної швидкості роботи';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Пов’язана зміна статусу, яка завершує заявку з цим квитком';
_fc_props.approaching = 'Наближається';
_fc_props.performing_task = 'Виконання завдання';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Ви впевнені, що бажаєте видалити Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Оновити квиток {0}. Пункт призначення змінено.';
_fc_props.trackit_return_destination_changed_to_0 = 'Оновити квиток {0}. Повернення змінено.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Додати Geogate';
_fc_props.driver_enabled = 'Драйвер включений';
_fc_props.dispatcher_enabled = 'Диспетчер включений';
_fc_props.runsheet_number = 'Номер аркуша';
_fc_props.manual_load = 'Ручне завантаження';
_fc_props.edit_geogate = 'Редагувати Geogate';
_fc_props.handling = 'поводження';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Панель Geogate';
_fc_props.vehicle_is_not_logged_in = 'Повідомлення не надіслано (транспортний засіб не авторизовано)';
_fc_props.air = 'повітря';
_fc_props.create_a_geogate = 'Створіть Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Клацніть на карті, щоб встановити точки геошлюзу.';
_fc_props.clock_out = 'Годинник';
_fc_props.handling_operation = 'Операція обробки';
_fc_props.view_geogates = 'Переглянути Geogates';
_fc_props.select_a_geogate = 'Будь ласка, виберіть Geogate';
_fc_props.failed_to_find_geogate = 'Не вдалося знайти Geogate';
_fc_props.adjust_geogate = 'Налаштуйте Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Дата закінчення терміну дії Geogate Час за замовчуванням Додаткові дні';
_fc_props.menu_settings_title_retention = 'Зберігання даних';
_fc_props.category = 'Категорія';
_fc_props.telemetry = 'Телеметрія';
_fc_props.ticket_data = 'Дані квитка';
_fc_props._30_days = '30 днів';
_fc_props._6_months = '6 місяців';
_fc_props._1_year = '1 рік';
_fc_props._3_years = '3 роки';
_fc_props.new_group = 'Нова група';
_fc_props.selected_vehicles = 'Вибрані транспортні засоби';
_fc_props.group_name_is_required_field = 'Поле для назви групи є обов’язковим';
_fc_props.at_least_vehicle_should_be_selected = 'Потрібно вибрати принаймні 1 транспортний засіб';
_fc_props.groups = 'Групи';
_fc_props.trucks = 'Вантажні автомобілі';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate видалено. Будь ласка, оновіть сторінку.';
_fc_props.air_timestamp = 'Повітряна позначка часу';
_fc_props.fail = 'НЕВДАЧА';
_fc_props.stuck = 'ЗАСТЯГ';
_fc_props.dirty = 'БРУДНИЙ';
_fc_props.seasonal = 'Сезонний';
_fc_props.category_details = 'Деталі категорії';
_fc_props.alert_events = 'Повідомлення про події';
_fc_props.driver_performance_infractions = 'Порушення продуктивності драйвера';
_fc_props.telemetry_details = 'Дані про місцезнаходження, гарячі точки, час у гарячих точках, час у регіонах, дані DCM, дані зонду, дані двигуна';
_fc_props.ticket_data_details = 'Дані квитка';
_fc_props.general_details = 'Зміни статусу, табелі, розклади';
_fc_props.wash = 'мити';
_fc_props.filter_options = 'Параметри фільтра';
_fc_props.home_plants = 'Домашні рослини';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO вимагає встановлення типу датчика PTO.';
_fc_props.invalid_io_box_mac_address = 'Недійсна Mac-адреса блоку введення/виведення';
_fc_props.access_denied = 'Доступ заборонено';
_fc_props.wash_events = 'Wash Events';
_fc_props.min_speed = 'Мінімальна швидкість';
_fc_props.temperature_change = 'Зміна температури';
_fc_props.degrees_cap = 'Ступені';
_fc_props.washout_tracking = 'Відстеження вимивання';
_fc_props.approaching_wash = 'Наближається до Уоша';
_fc_props.arrival_wash = 'Прибуття Wash';
_fc_props.performing_wash = 'Виконання Wash';
_fc_props.wash_complete = 'Прання завершено';
_fc_props.must_be_positive_or_zero = 'Має бути позитивним або нульовим!';
_fc_props.min_speed_greater_than_max = 'Мінімальна швидкість має бути меншою за максимальну!';
_fc_props.clock_in = 'Годинник';
_fc_props.mix_water_at_x_to_y_for_t = 'Змішуйте воду зі швидкістю від {0} до {1} об/хв протягом {2} обертів.';
_fc_props.discharge_water_for_x_revolutions = 'Злийте воду протягом {0} обертів.';
_fc_props.eod_drum_wash_complete = 'Барабанне прання завершено! <br> Зупинний барабан із зондом угорі.';
_fc_props.washing_complete = 'Кероване вимивання завершено';
_fc_props.eod_washout_no_data_available = 'Дані датчика недоступні, завершіть процес прання.';
_fc_props.eod_washout_no_data_available_title = 'Кероване змивання недоступне';
_fc_props.menu_reports_title_ticket_time_summary = 'Підсумок часу квитка';
_fc_props.menu_reports_description_ticket_time_summary = 'Перегляньте інформацію про час квитка для замовлення або автомобіля';
_fc_props.menu_reports_title_summary = 'Резюме';
_fc_props.menu_reports_title_ticket_summary = 'Резюме квитка';
_fc_props.menu_reports_description_summary1 = 'Перегляньте підсумок часу в гарячих точках';
_fc_props.menu_dispatch_title_main = 'Головна';
_fc_props.menu_dispatch_title_login_registry = 'Реєстр входів';
_fc_props.menu_reports_title_main = 'Головна';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Ви впевнені, що хочете видалити цей тип експортованих даних?';
_fc_props.dvir_status_paper_logs = 'Паперові журнали';
_fc_props.menu_reports_title_billing_report = 'Платіжний звіт';
_fc_props.menu_reports_description_billing_report = 'Платіжний звіт';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Статус до квитка Статус активації роботи';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Статус до тикетованого статусу для активації роботи (за замовчуванням "False")';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Фунтів';
_fc_props.units_weight_kilogram_label = 'кілограмів';
_fc_props.settings_weight = 'вага';
_fc_props.units_pounds = 'Фунтів';
_fc_props.units_kilograms = 'кілограмів';
_fc_props.geo_gate_edit_modal_description = 'опис';
_fc_props.geo_gate_edit_modal_start_point = 'Початкова точка';
_fc_props.geo_gate_edit_modal_end_point = 'Кінцева точка';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Тип обладнання';
_fc_props.geo_gate_edit_modal_heading_south = 'З півдня на північ';
_fc_props.geo_gate_edit_modal_heading_north = 'З півночі на південь';
_fc_props.geo_gate_edit_modal_driver_message = 'Повідомлення водія';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Відправка повідомлення';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Термін придатності Час';
_fc_props.geo_gate_edit_modal_permanent = 'Постійний';
_fc_props.geo_gate_edit_modal_critical = 'Критичний';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Помилка збереження Geogate: Поле опису має бути заповнене.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Помилка збереження Geogate: Поле початкової точки має бути заповнене.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Помилка збереження Geogate: Поле початкової точки містить недійсний формат даних.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Помилка збереження Geogate: поле кінцевої точки містить недійсний формат даних.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Помилка збереження Geogate: необхідно заповнити поле «Дата закінчення терміну дії».';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Помилка збереження Geogate: Поле «Дата закінчення терміну дії» містить недійсний формат даних.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Помилка збереження Geogate: з півночі на південь - Поле повідомлення про відправку має бути заповнене';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Помилка збереження Geogate: змін не виявлено';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Помилка збереження Geogate: Поле "Дата закінчення терміну дії" містить дату й час, що закінчилися';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Помилка збереження Geogate: поле опису має містити лише літери та цифри';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Помилка збереження Geogate: необхідно заповнити поле типу обладнання';
_fc_props.geo_gate_table_message_label_north_to_south = 'З півночі на південь';
_fc_props.geo_gate_table_message_label_south_to_north = 'З півдня на північ';
_fc_props.geo_gate_table_message_label_both = 'Обидва';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Широта довгота';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Якщо вибрати цей прапорець, параметри обмеження повідомлень про переміщення пристрою будуть перевизначені';
_fc_props.geo_gate_edit_modal_seasonal = 'Сезонний';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Помилка збереження Geogate: потрібно вказати сезонні дати';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Помилка збереження Geogate: поля сезонної дати містять недійсний формат даних';
_fc_props.test_js_test_javascript = 'Тест Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'кілометрів';
_fc_props.units_miles = 'Майлз';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'Л';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Помилка збереження Geogate: з півдня на північ – необхідно заповнити поле повідомлення водія';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Помилка збереження Geogate: з півдня на північ - Поле повідомлення про відправку має бути заповнене';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Помилка збереження Geogate: з півночі на південь - Поле повідомлення водія має бути заповнене';
_fc_props.geo_gate_heading = 'Заголовок';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Помилка збереження Geogate: необхідно заповнити поле кінцевої точки';
_fc_props.units_mile = 'Миля';
_fc_props.units_kilometer = 'кілометр';
_fc_props.cloud_dvir__exit = 'Вихід';
_fc_props.cloud_dvir__signature = 'Підпис';
_fc_props.cloud_dvir__inspection = 'Огляд';
_fc_props.cloud_dvir__add_photos_notes = 'Додайте фотографії/нотатки';
_fc_props.cloud_dvir__odometer_reading = 'Показання одометра';
_fc_props.cloud_dvir__engine_hours = 'Час роботи двигуна';
_fc_props.cloud_dvir__truck_details = 'Деталі вантажівки';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Визначити загальний стан автомобіля';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'ПІБ та підпис водія';
_fc_props.cloud_dvir__sign = 'Знак';
_fc_props.cloud_dvir__driver_note_from = 'Примітка водія від';
_fc_props.cloud_dvir__add_notes = 'Додайте нотатки';
_fc_props.cloud_dvir__driver_notes = 'Примітки водія';
_fc_props.cloud_dvir__done = 'Готово';
_fc_props.cloud_dvir__needs_repair = 'Потребує ремонту';
_fc_props.cloud_dvir__not_applicable = 'Не застосовується';
_fc_props.cloud_dvir__review_and_sign = 'Перегляньте та підпишіть';
_fc_props.cloud_dvir__add_photos = 'Додати фото';
_fc_props.cloud_dvir__upload_photo_limit = 'Ви можете завантажити до 3 фотографій';
_fc_props.cloud_dvir__mismatch_title = 'Сповіщення про невідповідність';
_fc_props.cloud_dvir__ok = 'В порядку';
_fc_props.cloud_dvir_template = 'Хмарний шаблон DVIR';
_fc_props.cloud_dvir__needs_review = 'DVIR потребує перевірки';
_fc_props.cloud_dvir__submitted = 'Надіслано';
_fc_props.cloud_dvir__completed_by = 'Виконав';
_fc_props.cloud_dvir__review_btn = 'Огляд';
_fc_props.cloud_dvir__truck_number = 'Вантажівка {0}';
_fc_props.cloud_dvir__pending_review = 'Очікує на розгляд';
_fc_props.cloud_dvir__start_dvir = 'Запустіть DVIR';
_fc_props.cloud_dvir__dvir_history = 'Історія DVIR';
_fc_props.cloud_dvir__view_all = 'Подивитись все';
_fc_props.load_zone = 'Зона навантаження';
_fc_props.view_load_zones = 'Переглянути зони навантаження';
_fc_props.edit_load_zone = 'Редагувати зону навантаження';
_fc_props.create_a_load_zone = 'Створіть зону навантаження';
_fc_props.load_zone_panel = 'Панель зони навантаження';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Клацніть на карті, щоб встановити центр зони навантаження.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Ця перевірка виявила дефекти, і ви вирішили, що цей автомобіль безпечний для експлуатації. Підтвердьте, перш ніж продовжити';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Ця перевірка не виявила жодних дефектів, і ви вибрали цей транспортний засіб небезпечним для експлуатації. Підтвердьте, перш ніж продовжити.';
_fc_props._90_days = '90 днів';
_fc_props.cloud_dvir = 'Хмарний ДВІР';
_fc_props.cloud_dvir_details = 'Надсилання даних Cloud DVIR';
_fc_props.show_load_zones = 'показати зони навантаження';
_fc_props.hide_load_zones = 'приховати зони навантаження';
_fc_props.no = 'Ні';
_fc_props.cloud_dvir__reset = 'Скинути';
_fc_props.cloud_dvir__clear = 'ясно';
_fc_props.mark_read = 'Позначте прочитане';
_fc_props.mark_unread = 'Позначити як непрочитане';
_fc_props.read = 'Прочитайте';
_fc_props.unread = 'Непрочитаний';
_fc_props.air_sensor_serial_number = 'Серійний номер датчика повітря';
_fc_props.air_sensor_mac_address = 'MAC-адреса датчика повітря';
_fc_props.air_sensor_serial_number_invalid_format = 'Серійний номер повітряного датчика недійсний: (буквено-цифровий і щонайбільше 32 символи з тире)';
_fc_props.air_sensor_mac_address_invalid_format = 'MAC-адреса датчика повітря має недійсний шістнадцятковий формат, як показано в заповнювачі для поля';
_fc_props.air_sensor_license = 'Датчик повітря';
_fc_props.adjust_route_point = 'Налаштуйте точку маршруту';
_fc_props.route_point_panel = 'Панель точки маршруту';
_fc_props.route_point = 'Точка маршруту';
_fc_props.cloud_dvir__pre_trip = 'Перед поїздкою';
_fc_props.cloud_dvir__post_trip = 'Після поїздки';
_fc_props.cloud_dvir__other_insction_type = 'Інший';
_fc_props.cloud_dvir__dvir_completed = 'Виконано';
_fc_props.cloud_dvir__awaiting_mechanic = 'Очікування механіка';
_fc_props.cloud_dvir__awaiting_driver = 'Очікується підтвердження водія';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Не вдається перевірити серійний номер датчика повітря, зверніться до служби підтримки за додатковою допомогою.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Цей серійний номер датчика повітря вже існує, використовуйте інший серійний номер';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Не вдалося перевірити MAC-адресу датчика повітря, зверніться по допомогу до служби підтримки';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Помилка надсилання DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Будь ласка спробуйте ще раз';
_fc_props.ticket_integration_descr = 'Інтеграція квитків дозволяє встановити параметри інтеграції квитків, а також головний екран / екран завантаження / жорсткий дисплей';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR успішно надіслано';
_fc_props.cloud_dvir__inspection_trip_type = 'Тип інспекційної поїздки';
_fc_props.menu_dvir_title_mechanic = 'Механік';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Прошивка датчика повітря';
_fc_props.when_no_active_ticket = 'Коли немає активного квитка';
_fc_props.used_by = 'Використаний';
_fc_props.fields = 'поля';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Ви впевнені, що бажаєте видалити макет квитка "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Макет квитка';
_fc_props.add_ticket_layout = 'Додати макет квитка';
_fc_props.edit_ticket_layout = 'Редагувати макет квитка';
_fc_props.ticket_layout_edit_modal_name = 'Ім\'я';
_fc_props.ticket_layout_edit_modal_default = 'За замовчуванням';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Помилка збереження макету квитка: поле імені має бути заповнене.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Помилка збереження макету квитка: поле імені має містити лише літери та цифри';
_fc_props.ticket_layout_edit_modal_fields = 'поля';
_fc_props.ticket_layout_edit_modal_usage = 'Використання';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Макет квитка видалено. Будь ласка, оновіть сторінку.';
_fc_props.more_lower_case = 'більше';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Помилка збереження макету квитка: необхідно вибрати одне або кілька полів.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Помилка збереження макету квитка: необхідно вибрати один або кілька статусів.';
_fc_props.cloud_dvir__ad_hoc = 'Ad Hoc';
_fc_props.radius_multiplier_should_be = 'Множник радіуса має бути плаваючим числом';
_fc_props.ticket_max_activate_age_err = 'Максимальна кількість активованих вікових хвилин квитка має бути цілим числом.';
_fc_props.max_hotspot_unused_days_errs = 'Максимальна кількість невикористаних днів точки доступу має бути цілим числом.';
_fc_props.begin_pour_air_content = 'Почніть заливати вміст повітря';
_fc_props.arrival_air_content = 'Прибуття Air Content';
_fc_props.fully_mixed_air_content = 'Повністю змішаний вміст повітря';
_fc_props.departure_air_content = 'Зміст повітря при відправленні';
_fc_props.popup_enabled = 'Спливаюче вікно ввімкнено';
_fc_props.popup_disabled = 'Спливаюче вікно вимкнено';
_fc_props.cloud_dvir__leave_confirmation_title = 'УВАГА';
_fc_props.cloud_dvir__leave_confirmation_description = 'Вихід зі сторінки зараз призведе до втрати всіх змін.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Підтвердити';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Скасувати';
_fc_props.air_bt_ratio = 'Вимірювання датчика повітря';
_fc_props.cloud_dvir__view_photos_notes = 'Перегляд фотографій/нотаток';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Додавання статусу створить порожнє використання в іншому макеті квитка. Бажаєте продовжити додавання?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Автомобіль не має ліцензії на датчик повітря.';
_fc_props.air_sensor_installed = 'Встановлено датчик повітря';
_fc_props.load_card = 'Завантажити картку';
_fc_props.cloud_dvir__inspection_profile = 'Профіль перевірки';
_fc_props.cloud_dvir__view_details = 'Докладніше';
_fc_props.cloud_dvir__certify_repairs = 'Сертифікація ремонту';
_fc_props.cloud_dvir__repairs_made = 'Зроблений ремонт';
_fc_props.cloud_dvir__repairs_not_necessary = 'Ремонт не потрібен';
_fc_props.cloud_dvir__mechanic_note_from = 'Механік Примітка Від';
_fc_props.cloud_dvir__mechanic_assignment = 'Призначення механіка';
_fc_props.cloud_dvir__mechanic_signature_description = 'Позначте всі відповідні елементи нижче та підпишіть, що транспортний засіб пройшов належний огляд.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Стан автомобіля задовільний';
_fc_props.cloud_dvir__defects_corrected_label = 'Недоліки виправлено';
_fc_props.cloud_dvir__repair_not_required_label = 'Для безпечної експлуатації автомобіля ремонт не потрібен';
_fc_props.safety = 'Безпека';
_fc_props.cloud_dvir__awaiting_sign = 'Очікує знак';
_fc_props.cloud_dvir__dvir_review = 'Огляд ДВІР';
_fc_props.settings.mobile_auto_start_navigation = 'Автоматичний запуск навігації на мобільному пристрої';
_fc_props.cloud_dvir__edit = 'Редагувати';
_fc_props.delete_custom_label = 'Видалити спеціальну мітку';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Ваша версія TrackIt застаріла, і деякі функції DVIR можуть не працювати належним чином. Перш ніж продовжити, оновіть TrackIt.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Під час завантаження підпису сталася помилка. Будь ласка спробуйте ще раз';
_fc_props.max_hotspot_unused_days_error = 'Максимальна кількість невикористаних днів точки доступу становить 1000 днів.';
_fc_props.menu_dvir_title_admin_templates = 'Шаблони';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'адмін';
_fc_props.cloud_dvir__admin_templates = 'Шаблони';
_fc_props.form_name = 'Назва форми';
_fc_props.form_status = 'Статус форми';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Завантажити';
_fc_props.cloud_dvir__option_view = 'Переглянути';
_fc_props.cloud_dvir__inspectortype_driver = 'Водій';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Перегляд/Редагування/Створення власних форм DVIR і подання';
_fc_props.menu.dvir.description.mechanic = 'Переглядайте/редагуйте активні подання DVIR';
_fc_props.not_in_use = 'Не використовується';
_fc_props.logged_in = 'увійшли в';
_fc_props.reviewing_driver = 'Огляд драйвера';
_fc_props.cloud_dvir__report_title = 'Акт огляду транспортного засобу водія';
_fc_props.cloud_dvir__report_signed = 'підписаний';
_fc_props.cloud_dvir__report_page_counter = 'Сторінка {0} з {1}';
_fc_props.cloud_dvir__report_defects = 'дефекти';
_fc_props.cloud_dvir__report_comments = 'Коментарі';
_fc_props.cloud_dvir__report_inspection_table_title = 'Огляд автомобіля';
_fc_props.cloud_dvir__report_driver_table_title = 'Інформація про водія';
_fc_props.cloud_dvir__report_carrier = 'Перевізник';
_fc_props.cloud_dvir__report_truck = 'Вантажівка';
_fc_props.cloud_dvir__report_location = 'Місцезнаходження';
_fc_props.cloud_dvir__report_odometer = 'Одометр';
_fc_props.cloud_dvir__report_hours = 'години';
_fc_props.cloud_dvir__report_no_comments = 'Без коментарів';
_fc_props.include_unlicensed_vehicles = 'Включити транспортні засоби без ліцензії';
_fc_props.cloud_dvir__search_by_form_name = 'Пошук за назвою форми';
_fc_props.cloud_dvir__create_dvir = 'Створіть DVIR';
_fc_props.cloud_dvir__update_dvir = 'Оновіть DVIR';
_fc_props.cloud_dvir__form_name = 'Назва форми';
_fc_props.cloud_dvir__form_description = 'Опис форми';
_fc_props.cloud_dvir__form_status = 'Статус форми';
_fc_props.information = 'Інформація';
_fc_props.cloud_dvir__search_categories = 'Категорії пошуку';
_fc_props.cloud_dvir__create_new_category = 'Створити нову категорію';
_fc_props.category_name = 'Назва категорії';
_fc_props.sub_category = 'Підкатегорія';
_fc_props.sub_category_name = 'Назва підкатегорії';
_fc_props.delete_category = 'Видалити категорію';
_fc_props.cloud_dvir__dvir_information = 'Інформація про DVIR';
_fc_props.cloud_dvir__dvir_choose_categories = 'Виберіть Категорії';
_fc_props.cloud_dvir__create_category = 'Створити категорію';
_fc_props.cloud_dvir__update_category = 'Оновити категорію';
_fc_props.delete_route_point = 'Видалити точку маршруту';
_fc_props.view_route_points = 'Перегляд точок маршруту';
_fc_props.ignore_cancel_preload = 'Ігноруйте скасування, ви попередньо завантажені. Незабаром буде показано ваш хоумран';
_fc_props.cloud_dvir__assigned_mechanic = 'Закріплений механік';
_fc_props.cloud_dvir__add_mechanic = 'Додайте механіка';
_fc_props.contact_type = 'Контактний тип';
_fc_props.cloud_dvir__send_email_notifications = 'Надсилати повідомлення електронною поштою';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Помилка отримання записів історії DVIR.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Помилка отримання запису DVIR.';
_fc_props.cloud_dvir__error_saving_submission = 'Помилка збереження подання. Спробуйте знову.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Помилка отримання списку механіків.';
_fc_props.cloud_dvir__template_saved = 'Шаблон збережено';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Помилка отримання списку шаблонів.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Помилка збереження шаблону. Спробуйте знову.';
_fc_props.dispatch_group = 'Диспетчерська група';
_fc_props.save_adjust = 'Зберегти та налаштувати точку маршруту';
_fc_props.reset_route_point = 'Скинути точку маршруту';
_fc_props.no_records_stub_adjust_filters = 'Спробуйте налаштувати параметри пошуку або фільтра, щоб збільшити кількість результатів.';
_fc_props.no_dvir_records = 'Усі наздогнали на DVIRs!';
_fc_props.no_dvir_records_sub_title = 'Ви багато чого досягли';
_fc_props.settings.master_data_connex_x_api_key = 'Основні дані Connex x-api-ключ';
_fc_props.slump_not_obtained_for_x = 'Немає показників падіння для: {0}';
_fc_props.please_select_a_sensor_status_type = 'Будь ласка, виберіть типи статусу датчика, щоб активувати це сповіщення';
_fc_props.settings.master_data_connex_token = 'Основні дані Connex Token';
_fc_props.error_validating_master_data_connex_credentials = 'Помилка перевірки основних даних облікових даних Connex';
_fc_props.settings.master_data_connex_base_url = 'Основні дані Connex Base URL';
_fc_props.settings.master_data_connex_exp_time = 'Термін дії основних даних Connex';
_fc_props.download_template = 'Завантажити шаблон';
_fc_props.menu_settings_title_dispatch_group = 'Групи відправлення';
_fc_props.settings.dispatch_groups = 'Групи відправлення';
_fc_props.dispatch_group_name = 'Назва групи відправлення';
_fc_props.dispatch_group_plants = 'Диспетчерська група рослин';
_fc_props.dispatch_group_plants_mapping_message = 'Вкажіть, які заводи входитимуть до цієї диспетчерської групи.';
_fc_props.dispatch_group_users_mapping_message = 'Вкажіть, які користувачі входитимуть до цієї групи диспетчеризації.';
_fc_props.dispatch_group_plant_disassociation_message = 'Завод має бути в одній диспетчерській групі одночасно';
_fc_props.dispatch_group_search_by_plant = 'Пошук за назвою рослини';
_fc_props.dispatch_group_search_by_username = 'Пошук за іменем користувача';
_fc_props.search_by_dispatch_group_name = 'Пошук за назвою групи відправлення';
_fc_props.create_dispatch_group = 'Створіть групу диспетчеризації';
_fc_props.update_dispatch_group = 'Оновити диспетчерську групу';
_fc_props.dispatch_group_saved = 'Групу відправлення збережено';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Видалення цієї групи відправлення створить групи відправлення за замовчуванням для пов’язаних установок.';
_fc_props.error_saving_dispatch_group = 'Помилка збереження групи відправлення. Спробуйте знову.';
_fc_props.please_enter_a_valid_plant_num = 'Введіть дійсний номер підприємства.';
_fc_props.cloud_dvir__report_no_defects = 'Без дефектів';
_fc_props.shoud_select_dispatch_group = 'Ви повинні вибрати принаймні одну групу відправлення';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Не вдалося видалити, оскільки ця група диспетчеризації містить користувачів, пов’язаних лише з цією групою диспетчеризації.';
_fc_props.cloud_dvir__username = 'Ім\'я користувача';
_fc_props.cloud_dvir__password = 'Пароль';
_fc_props.cloud_dvir__login = 'Логін';
_fc_props.cloud_dvir__mechanic_sign_in = 'Вхід механіка';
_fc_props.delete_dispatch_group = 'Видалити групу відправлення';
_fc_props.cloud_dvir__driver = 'Водій';
_fc_props.cloud_dvir__equipment_pound = 'номер обладнання';
_fc_props.cloud_dvir__mechanic = 'Механік';
_fc_props.dispatch_group_user_only_unassociate_message = 'Неможливо видалити користувача, оскільки користувачі мають бути пов’язані принаймні з однією групою диспетчеризації.';
_fc_props.cloud_dvir__time_period_filter_today = 'Сьогодні';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'вчора';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Останні 7 днів';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Останні 30 днів';
_fc_props.cloud_dvir__date_range_filter = 'Фільтрувати за датою';
_fc_props.cloud_dvir__download = 'Завантажити';
_fc_props.dispatch_group_is_empty = 'Група відправлення не може бути порожньою.';
_fc_props.dispatch_group_with_empty_plants = 'Принаймні один завод має бути пов’язаний із групою відправлення.';
_fc_props.dispatch_group_with_empty_users = 'Принаймні один користувач має бути пов’язаний із групою диспетчеризації.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Не вдалося видалити групу диспетчеризації, оскільки вона містить користувачів, пов’язаних лише з цією групою диспетчеризації.';
_fc_props.dispatch_group_not_exists = 'Група відправлення не існує.';
_fc_props.dispatch_group_contains_one_plant = 'Неможливо видалити, оскільки ця група відправлення містить лише один завод.';
_fc_props.dispatch_group_users = 'Користувачі групи диспетчеризації';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Помилка отримання запису DVIR';
_fc_props.cloud_dvir__assign_mechanic = 'Призначити механіка';
_fc_props.cloud_dvir__submit_review = 'Надіслати відгук';
_fc_props.error_deleting_dispatch_group = 'Помилка видалення групи відправлення.';
_fc_props.error_retrieving_dispatch_groups = 'Помилка отримання списку груп відправлення.';
_fc_props.cloud_dvir__undo_selection = 'Скасувати вибір';
_fc_props.enable_master_data = 'Увімкнути основні дані';
_fc_props.manually_sync = 'Синхронізувати вручну';
_fc_props.master_data_connex_token_expiration = 'Термін дії основних даних Connex Token';
_fc_props.cloud_dvir__time_period_filter_custom = 'Custom';
_fc_props.settings.zello_session_timeout_minutes = 'Час очікування сеансу Zello (хвилини)';
_fc_props.menu_settings_title_master_data = 'Основні дані';
_fc_props.settings.master_data = 'Основні дані';
_fc_props.error_retrieving_master_data = 'Помилка отримання основних даних';
_fc_props.master_data_sync_completed = 'Синхронізацію основних даних завершено';
_fc_props.master_data_successfully_updated = 'Дані успішно оновлено';
_fc_props.master_data_failed_to_update = 'Не вдалося зберегти.';
_fc_props.master_data_sync_initiated = 'Розпочато синхронізацію основних даних';
_fc_props.master_data_sync_failed = 'Помилка синхронізації основних даних';
_fc_props.enabled_excluding_origin_on_deliveries = 'Правда (за винятком місця походження в квитках на доставку)';
_fc_props.enabled_including_origin_on_deliveries = 'Правда (включаючи походження на квитках доставки)';
_fc_props.cloud_dvir__i_will_decide_later = 'Я вирішу пізніше';
_fc_props.returning = 'Повернення';
_fc_props.leftover_concrete = 'Залишки бетону';
_fc_props.leftover_concrete_on_vehicle_x = 'Залишки бетону на автомобілі {0}';
_fc_props.leftover_concrete_alert = 'Сповіщення про залишки бетону';
_fc_props.wc_ratio_exceeded = 'W/C Ratio Exceeded';
_fc_props.search_by_site_name = 'Пошук за назвою сайту';
_fc_props.sites = 'Сайти';
_fc_props.token_expiration = 'Термін дії токена';
_fc_props.menu_settings_title_connex_api_key_management = 'Керування ключами API Connex';
_fc_props.settings.connex_api_key_management = 'Керування ключами API Connex';
_fc_props.error_retrieving_connex_api_key_data = 'Помилка отримання даних ключа connex API';
_fc_props.qr_code = 'QR-код';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Інформування водія та інтерфейсу при введенні неправильного заводу';
_fc_props.enable_driver_message = 'Увімкнути повідомлення драйвера';
_fc_props.enable_dispatch_message = 'Увімкнути диспетчерське повідомлення';
_fc_props.enable_sending_message_to_interface = 'Увімкнути надсилання повідомлень до інтерфейсу';
_fc_props.ticket_qr_code = 'QR-код квитка';
_fc_props.leftover_concrete_alert_description = 'Сповіщення про виявлення залишків бетону після заливки.';
_fc_props.minimum_leftover_concrete_amount = 'Мінімальна кількість залишків бетону';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Помилка перевірки основних даних. Облікові дані Connex не авторизовані';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Помилка перевірки дозволів облікових даних Connex на основні дані';
_fc_props.error_validating_master_data_connex_url_not_found = 'Помилка перевірки основних даних Connex URL-адресу не знайдено';
_fc_props.error_validating_master_data_connex_unknown_error = 'Помилка перевірки основних даних Connex Невідома помилка';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Помилка синхронізації основних даних. Синхронізувати тип автомобіля.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Помилка синхронізації основних даних. Синхронізувати автомобіль.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Помилка синхронізації основних даних. Синхронізувати завод.';
_fc_props.master_data_sync_failed_data_base_connection = 'Помилка синхронізації основних даних підключення до бази даних.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Помилка перевірки основних даних облікових даних Connex дозвіл маркера носія недостатній';
_fc_props.settings.back_date_arrive_job = 'Задня дата прибуття на роботу';
_fc_props.device_notifications = 'Сповіщення';
_fc_props.loading_number = 'Номер завантаження';
_fc_props.leftover_concrete_volume_temp_age = 'Залишковий обсяг={0}, темп={1}, вік={2}';
_fc_props.total_ordered = 'Загальна замовлена кількість';
_fc_props.total_ticketed = 'Доставлено Замовлено';
_fc_props.load_number = 'Номер навантаження';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Мінімальний залишковий бетон має бути заповнений і повинен перевищувати {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Помилка перевірки основних даних. Облікові дані не можуть бути однаковими.';
_fc_props.use_aws_for_talk = 'Використовуйте AWS для розмови';
_fc_props.air_readings_not_supported = 'Показання повітря не підтримуються';
_fc_props.air_not_supported = 'Air не підтримується';
_fc_props.mix_not_supported = 'Суміш не підтримується';
_fc_props.air_default_and_none_not_supported = 'Неможливо вибрати за замовчуванням і не підтримувати одночасно!';
_fc_props.notifications_enabled = 'Сповіщення ввімкнено';
_fc_props.prevent_status_change = 'Запобігання зміні статусу';
_fc_props.message_to_interface = 'Повідомлення до інтерфейсу';
_fc_props.prevent_wrong_plant = 'Запобігти неправильній рослині';
_fc_props.previous_tickets = 'Попередні квитки';
_fc_props.audit_ticket_number = 'Номер квитка';
_fc_props.audit_date_created = 'Дата створення';
_fc_props.audit_vehicle_number = 'Номер транспортного засобу';
_fc_props.cloud_dvir__table_ok = 'Безпечний в експлуатації';
_fc_props.cloud_dvir__table_needs_repair = 'Небезпечно в експлуатації';
_fc_props.missing_air_calibration = 'Немає даних калібрування повітря';
_fc_props.reported_defects = 'Повідомлені дефекти';
_fc_props.address_latitude = 'Широта адреси';
_fc_props.address_longitude = 'Довгота адреси';
_fc_props.routing_latitude = 'Маршрутизація Latitude';
_fc_props.routing_longitude = 'Довгота маршруту';
_fc_props.category_name_must_be_unique = 'Назва категорії має бути унікальною';
_fc_props.subcategory_name_must_be_unique = 'Назва підкатегорії має бути унікальною';
_fc_props.menu_settings_tracking_device = 'Пристрій стеження';
_fc_props.menu_settings_title_tracking_device = 'Пристрій стеження';
_fc_props.menu_settings_title_personal_device_registration = 'Реєстрація персонального пристрою';
_fc_props.registration_code = 'Код реєстрації';
_fc_props.creation_date = 'Створення';
_fc_props.expiration_date = 'Термін дії';
_fc_props.trackitadmin_personal_device_registration = 'Реєстрація персонального пристрою';
_fc_props.create_registration_code = 'Додайте реєстраційний код';
_fc_props.remove_registration_code = 'Видалити реєстраційний код';
_fc_props.add_a_registration_code = 'Додайте реєстраційний код';
_fc_props.select_number_of_registration_codes_to_generate = 'Виберіть кількість реєстраційних кодів, які ви хочете створити.';
_fc_props.registration_code_count = 'Кількість реєстраційних кодів';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Ви впевнені, що бажаєте видалити вибраний зареєстрований код?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Ви дійсно хочете видалити вибрані зареєстровані коди?';
_fc_props.add_personal_device = 'Додати персональний пристрій';
_fc_props.menu_settings_title_personal_device = 'Персональний пристрій';
_fc_props.cloud_dvir__approval_critical_alert = 'Автомобіль має критичну несправність. Не можна позначити як безпечний для експлуатації.';
_fc_props.export_registration_codes = 'Реєстраційні коди експорту';
_fc_props.download_format = 'Формат завантаження';
_fc_props.specify_download_format_x = 'Укажіть формат завантаження для експорту реєстраційних кодів {0}';
_fc_props.personal_device_download = 'Завантажити';
_fc_props.add_personal_device_input_message = 'Введіть ідентифікатор персонального пристрою нижче. Термін дії буде автоматично застосовано після збереження';
_fc_props.error_creating_personal_device = 'Помилка створення персонального пристрою';
_fc_props.device_already_exists = 'Персональний пристрій вже існує';
_fc_props.export = 'Експорт';
_fc_props.error_can_not_change_connex_eid = 'Неможливо змінити connex eid, оскільки він зараз використовується для основних даних';
_fc_props.remove_personal_device = 'Видаліть персональний пристрій';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Ви впевнені, що бажаєте видалити вибраний особистий пристрій?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Ви дійсно бажаєте видалити вибрані особисті пристрої?';
_fc_props.error_deleting_personal_device = 'Помилка видалення особистого пристрою';
_fc_props.customerid = 'ID клієнта';
_fc_props.customername = 'Ім\'я клієнта';
_fc_props.ordernumber = 'Номер замовлення';
_fc_props.orderdescription = 'Опис замовлення';
_fc_props.locationid = 'ID місця розташування';
_fc_props.locationdescription = 'Опис розташування';
_fc_props.productid = 'ID продукту';
_fc_props.productdescription = 'Опис продукту';
_fc_props.vehicleid = 'ID транспортного засобу';
_fc_props.vehicledescription = 'Опис транспортного засобу';
_fc_props.ticketnumber = '№ квитка';
_fc_props.ticketdate = 'Дата/час квитка';
_fc_props.drivername = 'Ім\'я водія';
_fc_props.arriveregion = 'Регіон прибуття (TIME)';
_fc_props.leaveregion = 'Покинути регіон (TIME)';
_fc_props.regionduration = 'Регіон Тривалість';
_fc_props.menu_reports_title_prevailing_wage = 'Переважна заробітна плата';
_fc_props.slump_calibration_x_not_found_for_y = 'Калібрування спаду "{0}" не знайдено для квитка {1}';
_fc_props.slump_calibration_not_found = 'Калібрування спаду не знайдено';
_fc_props.slump_calibration_not_found_description = 'Сповіщення, коли калібрування спаду не знайдено';
_fc_props.error_getting_personal_device_list = 'Помилка отримання особистого списку пристроїв';
_fc_props.batch_summary_date_range_note = '*ПРИМІТКА. Тривалість цього звіту обмежена 3 днями, якщо не застосовано фільтри. Діапазон дат може досягати 31 дня, якщо відфільтровано за обладнанням (максимум 10), заводом (максимум 1), продуктом (максимум 1) або роботою (максимум 1)';
_fc_props.select_registration_code_expiration_time_days = 'Виберіть кількість днів до завершення терміну дії реєстраційних кодів і натисніть «Зберегти».';
_fc_props.add_personal_device_expiration = 'Термін дії (у днях)';
_fc_props.cloud_dvir__vehicle_no = '№ транспортного засобу';
_fc_props.cloud_dvir__trailer_no = 'Трейлер №';
_fc_props.cloud_dvir__vehicle = 'транспортний засіб';
_fc_props.cloud_dvir__trailer = 'Трейлер';
_fc_props.cloud_dvir__truck_number_label = 'Номер вантажівки';
_fc_props.cloud_dvir__trailer_number = 'Номер трейлера';
_fc_props.cloud_dvir__has_trailer = 'Не їздить з причепом';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Введіть тут свій номер трейлера';
_fc_props.work_order = 'Порядок роботи';
_fc_props.unable_to_determine_slump = 'Неможливо визначити спад';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Визначте загальний стан причепа';
_fc_props.defaultTrailer = 'Трейлер за замовчуванням';
_fc_props.trailer_type_column_billable = 'Оплачується';
_fc_props.trailer_type_column_notes = 'Примітки';
_fc_props.trailer_type_column_date = 'Дата';
_fc_props.trailer_type_column_order = 'порядок';
_fc_props.trailer_type_column_ticket = 'Квиток';
_fc_props.trailer_type_column_project = 'Демонструвати';
_fc_props.trailer_type_column_customer = 'Замовник';
_fc_props.trailer_type_column_location = 'Розташування';
_fc_props.trailer_type_column_vehicle_type = 'Тип транспортного засобу';
_fc_props.trailer_type_column_product = 'Продукт';
_fc_props.trailer_type_action_rebill = 'Перерахувати';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Помилка синхронізації основних даних, тип обладнання за замовчуванням не встановлено';
_fc_props.trailer_type = 'Тип причепа';
_fc_props.trailer_type_is_required = 'Потрібно вказати тип причепа';
_fc_props.trailer_number_already_exists = 'Номер трейлера вже є';
_fc_props.plate_number_already_exists = 'Номерний знак вже є';
_fc_props.trailer_vin_already_exists = 'Трейлер Vin вже є';
_fc_props.trailer_does_not_exist = 'Трейлера не існує';
_fc_props.trailer_number_is_required = 'Необхідно вказати номер трейлера';
_fc_props.plate_number_is_required = 'Потрібно вказати номерний знак';
_fc_props.plate_number = 'Табличний номер';
_fc_props.menu_settings_title_trailer = 'Трейлер';
_fc_props.add_trailer = 'Додати трейлер';
_fc_props.edit_trailer = 'Редагувати трейлер';
_fc_props.menu_settings_title_equipment = 'Обладнання';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ігноруйте нові квитки від Command Cloud старше X хвилин';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ігнорувати нові квитки з Command Cloud із датою відправлення та часом старше X хвилин (за умовчанням порожнє та вимкнене)';
_fc_props.edit_load_card = 'Редагувати картку завантаження';
_fc_props.default_layout = 'Типовий макет';
_fc_props.load_card_empty_fields = 'Помилка збереження картки завантаження: необхідно вибрати одне або кілька полів.';
_fc_props.trailers = 'Трейлери';
_fc_props.error_failed_to_save_data = 'Помилка: не вдалося зберегти дані';
_fc_props.slump_increased_plasticizer_added_question = 'Скільки пластифікатора додали?';
_fc_props.add_trailer_type = 'Додайте тип трейлера';
_fc_props.menu_settings_title_trailer_type = 'Тип причепа';
_fc_props.edit_trailer_type = 'Редагувати тип трейлера';
_fc_props.single_use_codes = 'Одноразові коди';
_fc_props.multiple_use_codes = 'Коди багаторазового використання';
_fc_props.trailer_type_description_is_required = 'Потрібний опис типу трейлера';
_fc_props.trailer_saved = 'Трейлер збережено';
_fc_props.cloud_dvir__trailer_only_dvir = 'Тільки трейлер ДВІР';
_fc_props.choose_file = 'Виберіть Файл';
_fc_props.no_file_chosen = 'Файл не вибрано';
_fc_props.please_choose_file = 'Виберіть файл';
_fc_props.no_filters = 'Фільтри не вибрано';
_fc_props.remaining_defects = 'Дефекти, що залишилися';
_fc_props.load_more = 'Завантажте більше';
_fc_props.pagination_label = '{0}-{1} з {2}';
_fc_props.extend = 'Розширити';
_fc_props.cloud_dvir__notes_or_photo_required = 'Потрібні примітки або фото.';

export default _fc_props;
