const _fc_props = {};
_fc_props.ante_meridiem = 'утра';
_fc_props.post_meridiem = 'вечера';
_fc_props.retry = 'Попробуйте еще раз';
_fc_props.default = 'По умолчанию';
_fc_props.origin = 'Источник';
_fc_props.loading = 'Загрузка';
_fc_props.list = 'Список';
_fc_props.send = 'Отправить';
_fc_props.error = 'Ошибка';
_fc_props.save = 'Сохранить';
_fc_props.delete = 'Удалить';
_fc_props.message = 'Сообщение';
_fc_props.logout = 'Выход';
_fc_props.add = 'Добавить';
_fc_props.remove = 'Удалить';
_fc_props.done = 'Готово';
_fc_props.other = 'Другое';
_fc_props.interval = 'Интервал';
_fc_props.time = 'Время';
_fc_props.speed = 'Скорость';
_fc_props.longitude = 'Долгота';
_fc_props.latitude = 'Широта';
_fc_props.x_minutes = '{0} Мин';
_fc_props.general = 'Общее';
_fc_props.messages = 'Сообщения';
_fc_props.gps_fixes = 'Изменения в GPS';
_fc_props.device_id = 'Id устройства';
_fc_props.vehicle = 'Транспортное средство';
_fc_props.employee = 'Сотрудник';
_fc_props.version = 'Версия';
_fc_props.statuses = 'Статусы';
_fc_props.edit = 'Внести изменения';
_fc_props.cancel = 'Отменить';
_fc_props.yes = 'Да';
_fc_props.none = 'Не обозначено';
_fc_props.never = 'Никогда';
_fc_props.name = 'Название';
_fc_props.true = 'Верно';
_fc_props.status = 'Статус';
_fc_props.distance = 'Расстояние';
_fc_props.ok = 'Ok';
_fc_props.login = 'Логин';
_fc_props.next = 'Следующий';
_fc_props.menu = 'Меню';
_fc_props.not_available = 'Нет данных';
_fc_props.back = 'Назад';
_fc_props.select = 'Выбрать';
_fc_props.dispatch = 'Отправка';
_fc_props.messaging = 'Обмен сообщениями';
_fc_props.plants = 'Заводы';
_fc_props.last_plants = 'Последние заводы';
_fc_props.vehicle_types = 'Типы транспортных средств';
_fc_props.employee_types = 'Типы сотрудников';
_fc_props.employee_groups = 'Группы сотрудников';
_fc_props.equipment_groups = 'Группы оборудования';
_fc_props.job = 'Работа';
_fc_props.order = 'Заказ';
_fc_props.ticket = 'Билет #';
_fc_props.small = 'Маленький';
_fc_props.medium = 'Средний';
_fc_props.large = 'Большой';
_fc_props.reports = 'Отчеты';
_fc_props.payroll = 'Платежная ведомость';
_fc_props.support = 'Поддержка';
_fc_props.username = 'Имя пользователя';
_fc_props.all = 'Все';
_fc_props.question = 'Вопрос';
_fc_props.select_employee = 'Выберите сотрудника';
_fc_props.phone = 'Телефон';
_fc_props.january = 'Январь';
_fc_props.february = 'Февраль';
_fc_props.march = 'Март';
_fc_props.april = 'Апрель';
_fc_props.may = 'Май';
_fc_props.june = 'Июнь';
_fc_props.july = 'Июль';
_fc_props.august = 'Август';
_fc_props.september = 'Сентябрь';
_fc_props.october = 'Октябрь';
_fc_props.november = 'Ноябрь';
_fc_props.december = 'Декабрь';
_fc_props.are_you_sure_you_want_to_delete = 'Вы уверенны, что хотите удалить';
_fc_props.unknown = 'Неизвестно';
_fc_props.select_all = 'Выбрать все';
_fc_props.equipment = 'Оборудование';
_fc_props.region = 'Регион';
_fc_props.no_data = 'Нет данных';
_fc_props.hotspot = 'хот-спот';
_fc_props.address = 'Адрес';
_fc_props.retrieving_address = 'Поиск адреса...';
_fc_props.address_not_found = 'Адрес не найден';
_fc_props.active_time = 'Активное время';
_fc_props.destination_time = 'Время прибытия';
_fc_props.complete_time = 'Остановить время';
_fc_props.status_report = 'Отчет о статусе';
_fc_props.export_report = 'Экспорт Отчета';
_fc_props.download_report = 'Скачать Отчет';
_fc_props.view_report = 'Просмотреть отчет';
_fc_props.duration = 'Длительность';
_fc_props.min = 'Мин';
_fc_props.start_date_time = 'Дата/Время старта';
_fc_props.select_start_date = 'Выберите дату старта';
_fc_props.end_date_time = 'Дата/Время окончания';
_fc_props.select_end_date = 'Выберите дату окончания';
_fc_props.trip_time = 'Длительность рейса';
_fc_props.travel_time = 'Время в пути';
_fc_props.description = 'Описание';
_fc_props.map_controls = 'Управление картой';
_fc_props.road = 'Дорога';
_fc_props.aerial = 'Антенна';
_fc_props.hybrid = 'Гибрид';
_fc_props.trip = 'Рейс';
_fc_props.map = 'Карта';
_fc_props.plant = 'Пункт';
_fc_props.select_date = 'Выберите дату';
_fc_props.apply = 'Применить';
_fc_props.save_settings = 'Сохранить настройки';
_fc_props.false = 'Неверно';
_fc_props.delete_confirmation = 'Подтвердить удаление';
_fc_props.last_name = 'Фамилия';
_fc_props.none_selected = 'Не выбрано';
_fc_props.start_date = 'Дата начала';
_fc_props.group_by = 'Группировать по';
_fc_props.equipment_employee = 'Оборудование / Сотрудник';
_fc_props.inactive = 'Неактивный';
_fc_props.active = 'Активный';
_fc_props.note_checking_may_affect_performance = 'Внимание: Проверка может повлиять на деятельность';
_fc_props.show_distancefuel_used = 'Показать расстояние/Количество использованного топлива';
_fc_props.mins = 'Мин';
_fc_props.threshold_time = 'Пороговое время';
_fc_props.include_voided_tickets = 'Включить аннулированные тикеты';
_fc_props.note_all_times_listed_are_in_minutes = 'Внимание: время указано в минутах';
_fc_props.please_select_a_job_order_or_equipment = 'Пожалуйста, выберите работу, заказ, завод, оборудование или сотрудника';
_fc_props.device = 'Устройство';
_fc_props.select_equipment = 'Выбрать оборудование';
_fc_props.all_equipment_types = 'Все типы оборудования';
_fc_props.units = {};
_fc_props.units.liters = 'Литры';
_fc_props.type = 'Тип';
_fc_props.no_data_available = 'Данные недоступны';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Расстояние и расходы топлива по областям';
_fc_props.menu_settings_title_settings = 'Настройки';
_fc_props.menu_settings_title_alerts = 'Оповещения';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Оборудование';
_fc_props.menu_settings_title_hotspots_regions = 'Хот-споты и регионы';
_fc_props.days = '{0} день(-ей)';
_fc_props.home = 'Главная';
_fc_props.find = 'Найти';
_fc_props.close_this_panel = 'Закрыть данную панель';
_fc_props.filters = 'Фильтры';
_fc_props.start = 'Начало';
_fc_props.end = 'Закончить';
_fc_props.location_details = 'Данные о местоположении';
_fc_props.first_name = 'Имя';
_fc_props.material_per_hour = 'Количество перевозимого материала в час';
_fc_props.equipment_pound = '# оборудования';
_fc_props.equipment_type = 'Тип оборудования';
_fc_props.options = 'Варианты';
_fc_props.zoom_on_cursor_position = 'Увеличить курсор';
_fc_props.display_equipment_identifier = 'Отображение идентификатора оборудования';
_fc_props.view_equipment = 'Просмотреть оборудование';
_fc_props.view_hotspots = 'Просмотреть геозоны';
_fc_props.view_regions = 'Просмотреть регионы';
_fc_props.use_standard_filters = 'Использовать стандартные фильтры';
_fc_props.home_plant = 'Главный пункт';
_fc_props.last_plant = 'Последний пункт';
_fc_props.equip_types = 'Типы оборуд.';
_fc_props.equip_groups = 'Группы оборуд.';
_fc_props.orders = 'Заказы';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Использовать фильтр оборудования';
_fc_props.all_selected = 'Выбрано все';
_fc_props.of = 'из';
_fc_props.your_map_session_has_expired = 'Ваша сессия истекла. Пожалуйста, обновите страницу.';
_fc_props.equipment_short = 'Оборуд.';
_fc_props.job_info_for = 'Информация о работе по';
_fc_props.order_info_for = 'Информация о заказе по';
_fc_props.ticket_info_for_vehicle = 'Данные тикета по транспортному средству';
_fc_props.inbox = 'Входящие';
_fc_props.outbox = 'Исходящие';
_fc_props.alerts = 'Оповещения';
_fc_props.critical = 'Критические';
_fc_props.important = 'Важные';
_fc_props.informational = 'Информационные';
_fc_props.view_routes = 'Посмотреть маршруты';
_fc_props.hours_size = 'Количество часов';
_fc_props.used_count = 'Счет использованного';
_fc_props.last_login = 'Последний вход';
_fc_props.last_vehicle = 'Последнее транспортное средство';
_fc_props.regions = 'Регионы';
_fc_props.driver = 'Водитель';
_fc_props.js_delete = 'Удалить';
_fc_props.move = 'Переместить';
_fc_props.edit_hotspot = 'Редактировать геозону';
_fc_props.adjust_shape = 'Установить желаемую форму';
_fc_props.edit_region = 'Редактировать регион';
_fc_props.enter_a_start_address = 'Пожалуйста, введите начальный адрес';
_fc_props.select_a_start_hotspot = 'Пожалуйста, выберите отправную геозону';
_fc_props.failed_to_find_start_hotspot = 'Не удалось найти отправную геозону.';
_fc_props.invalid_latitudelongitude = 'Недействительная долгота/широта';
_fc_props.enter_an_end_address = 'Пожалуйста, введите адрес конечного пункта';
_fc_props.select_an_end_hotspot = 'Пожалуйста, выберите конечную геозону';
_fc_props.failed_to_find_end_hotspot = 'Не удалось найти конечную геозону.';
_fc_props.print = 'Распечатать';
_fc_props.these_directions_are_for_informational_purposes = 'Данные инструкции предоставлены исключительно в информационных целях. Мы не можем давать гарантий о полноте и точности инфомации. Строительные проекты, движение на дорогах и другие факторы могут повлечь за собой изменения.';
_fc_props.enter_an_address = 'Пожалуйста, введите адрес';
_fc_props.select_a_hotspot = 'Пожалуйста, выберите геозону';
_fc_props.failed_to_find_hotspot = 'Не удалось найти геозону.';
_fc_props.select_a_vehicle = 'Пожалуйста, выберите транспортное средство';
_fc_props.failed_to_find_vehicle = 'Не удалось найти транспортное средство.';
_fc_props.failed_to_find_address = 'Не удалось найти адрес';
_fc_props.failed_to_find_vehicles = 'По заданным критериям не удалось найти транспортное средство.';
_fc_props.get_address = 'Получить адрес';
_fc_props.results_for = 'Результаты по';
_fc_props.error_retrieving_results = 'Не удалось обновить результаты';
_fc_props.no_results_found = 'Результаты не найдены';
_fc_props.address_is_required = 'Запрашивается адрес';
_fc_props.city_is_required = 'Необходимо ввести город';
_fc_props.set_location = 'Необходимо обозначить местоположение';
_fc_props.set_area_of_interest = 'Обозначьте интересующую область';
_fc_props.center_map_here = 'Обозначить центр карты здесь';
_fc_props.zoom_to_street_level = 'Увечилить масштаб';
_fc_props.set_start = 'Задать начало';
_fc_props.set_end = 'Задать конец';
_fc_props.delete_node = 'Удалить точку пересечения';
_fc_props.create_a_hotspot = 'Создать геозону';
_fc_props.create_a_region = 'Создать регион';
_fc_props.toggle_shapes = 'Переключить формы';
_fc_props.refresh_map = 'Обновить карту';
_fc_props.more_options = 'Больше опций';
_fc_props.recenter_map = 'Вернуть карту в исходное состояние';
_fc_props.zoom_to_vehicle = 'Увеличить масштаб';
_fc_props.apply_filters = 'Применить фильтры';
_fc_props.grid_view = 'Сжатое изображение';
_fc_props.list_view = 'Просмотр списка';
_fc_props.click_to_sort = 'Нажать для сортировки';
_fc_props.message_history = 'История сообщений';
_fc_props.equipment_types = 'Типы оборудования';
_fc_props.send_message = 'Отправить сообщение';
_fc_props.last_load = 'Последняя загрузка';
_fc_props.last_load_on = 'Последняя загрузка';
_fc_props.manual_logout = 'Выйти вручную';
_fc_props.manual_login = 'Войти вручную';
_fc_props.no_employees_logged_in = 'В системе нет сотрудников';
_fc_props.select_phone = 'Выбрать телефон';
_fc_props.you_must_select_a_phone = 'Вам необходимо выбрать телефон, оборудование или сотрудника';
_fc_props.are_you_sure_you_want_to_logout = 'Вы уверены, что хотите выйти?';
_fc_props.fix_time = 'Изменение';
_fc_props.to_next_fix = 'к следующему изменению';
_fc_props.minutes_to_next_fix = 'Минут до следующего изменения';
_fc_props.liters = 'Литры';
_fc_props.destination = 'Пункт назначения';
_fc_props.return = 'Обратная отправка';
_fc_props.product = 'Товар';
_fc_props.no_records_found = 'Данные не найдены.';
_fc_props.vehicle_type = 'Тип транспорта';
_fc_props.view_in_map = 'Посмотреть все на карте';
_fc_props.uploads = 'Загрузки';
_fc_props.upload_csv_file = 'Загрузить CSV файл';
_fc_props.permanent = 'Постоянная';
_fc_props.expire_time = 'Истекшее время';
_fc_props.please_select_x_for_tickets_marked_active = 'Для АКТИВНЫХ тикетов, пожалуйста, выберите {0}';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Многие тикеты обозначены АКТИВНЫМИ для одинакового {0}';
_fc_props.failure_connecting_to_servlet = 'Не удалось соединиться с servlet.';
_fc_props.status_time_summary = 'Сводка по времени в статусе';
_fc_props.view_in_map_one = 'Посмотреть по карте';
_fc_props.question_report = 'Отчет по вопросам';
_fc_props.end_date = 'Дата окончания';
_fc_props.trailer_number = 'Номер автоприцепа';
_fc_props.region_panel = 'Панель регионов';
_fc_props.hotspot_panel = 'Панель геозон';
_fc_props.shape = 'Форма';
_fc_props.circle = 'Круг';
_fc_props.polygon = 'Многоугольник';
_fc_props.click_the_map_to_set_the_location = 'Нажмите на карту, чтобы задать желаемое местоположение.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Нажмите на карту, чтобы задать центральный хотспот.';
_fc_props.click_the_map_to_set_the_region_center = 'Нажмите на карту, чтобы задать центр региона.';
_fc_props.click_and_drag_points = 'Нажмите и перетащите точки для редактирования формы. Чтобы сохранить изменения, нажмите СОХРАНИТЬ.';
_fc_props.size = 'Размер';
_fc_props.are_you_sure_you_want_to_move_this = 'Вы уверены, что хотите переместить объект?';
_fc_props.are_you_sure_you_want_to_delete_this = 'Вы настаиваете на удалении?';
_fc_props.please_enter_a_valid_size = 'Пожалуйста, введите размер';
_fc_props.failed_to_save = 'Ошибка сохранения.';
_fc_props.failed_to_move = 'Ошибка перемещения.';
_fc_props.failed_to_delete = 'Невозможно удалить';
_fc_props.x_hours = '{0} час(-а)';
_fc_props.hide_hotspots = 'Спрятать геозоны';
_fc_props.show_regions = 'Показать регионы';
_fc_props.show_hotspots = 'Показать геозоны';
_fc_props.hide_regions = 'Спрятать регионы';
_fc_props.numeric = 'Число';
_fc_props.message_type = 'Тип сообщения';
_fc_props.text = 'Текст';
_fc_props.yes_no = 'Да/Нет';
_fc_props.responses = 'Ответы';
_fc_props.disabled = 'Недоступны';
_fc_props.group_name = 'Название группы';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Вы уверены, что хотите удалить формат \'{0}\'?';
_fc_props.phone_number = 'Номер телефона';
_fc_props.interface_pound = 'Интерфейс #';
_fc_props.field = 'Поле';
_fc_props.default_label = 'Ярлык по умолчанию';
_fc_props.custom_label = 'Ярлык пользователя';
_fc_props.you_must_enter_a_label = 'Введите ярлык.';
_fc_props.enabled = 'Разрешено';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Интегрирование тикета';
_fc_props.settings.geofence_off_open_hotspots = 'Разрешить сворачивание открытых геозон';
_fc_props.settings.associate_open_hotspots_wticket = 'Привязать открытые геозоны к тикетам';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Разрешить geofencing off открытых геозон или только геозон, связанных с тикетом водителя';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Привязывать или нет открытые геозоны к тикетам, когда транспортное средство в них входит';
_fc_props.settings.void_ticket_without_destination = 'Недействительный тикет без указания пункта назначения.';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'в случае получения нового тикета, когда еще открыт предыдущий, если в предыдущем тикете пункт назначения не указан, он будет аннулирован.';
_fc_props.settings.autocreate_hotspots = 'Авто-создание геозон';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Автоматически создавать или нет геозоны в пункте назначения при получении тикета';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Авто-активация тикета по его получению';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Активен ли тикет, получененный TrackIt? (По умолчанию \'Да\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Авто-закрытие активного тикета по получению минут';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Сколько времени текущий тикет активен, прежде чем он будет автоматически закрыт? (По умолчанию \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Максимальное время активности тикета в минутах';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Сколько минут тикет может быть активным после его активации в системе TrackIt? (По умолчанию \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Максимально возможное количество дней не использования геозоны';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Определяет максимальное количество дней, в течение которых в геозону можно будет войти в любой момент.';
_fc_props.settings.radius_multiplier = 'Множитель радиуса';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'На что следует умножать радиус при получении тикета от диспетчерской системы';
_fc_props.settings.ticket_message_date_format = 'Формат сообщения и даты тикета';
_fc_props.am = 'до обеда';
_fc_props.pm = 'после обеда';
_fc_props.settings.ticket_message = 'Сообщение тикета';
_fc_props.settings.add_ticket_element = 'Добавить тикет';
_fc_props.driver_performance = 'Результаты водителя';
_fc_props.update_failed_can_not_connect = 'Не удалось совершить обновление: Невозможно соединиться с servlet.';
_fc_props.unknown_error = 'Неизвестная ошибка';
_fc_props.selected = 'Выбрано';
_fc_props.web_service = 'Веб-сервис';
_fc_props.error_processing_request = 'Ошибка обработки запроса.';
_fc_props.email_address = 'Email адрес';
_fc_props.id = 'Я БЫ';
_fc_props.last_used = 'Использовано последним';
_fc_props.error_invalid_vehiclenum = 'Ошибка неверного номера автомобиля';
_fc_props.error_vehicle_is_not_logged_in = 'Ошибка: транспортное средство не залогинено.';
_fc_props.users = 'Пользователи';
_fc_props.you_dont_have_access_to_this_page = 'У вас нет доступа для просмотра этой страницы. Пожалуйста, свяжитесь с вашим системным администратором для получения доступа.';
_fc_props.previous_week = 'Предыдущая неделя';
_fc_props.status_breakdown = 'Нарушение статуса';
_fc_props.select_plant_or_employee_group = 'Выберите пункт или группу сотрудников.';
_fc_props.cannot_select_plant_and_employee_group = 'Вы не можете одновременно выбрать и пункт и группу сотрудников.';
_fc_props.odometer_must_be_a_number = 'Изначальное значение на одометре должно быть числом';
_fc_props.ending_odometer_must_be_a_number = 'Конечное значение на одометре должно быть числом';
_fc_props.beginning_hour_meter_must_be_a_number = 'Начальные показания счетчика должны быть числом';
_fc_props.ending_hour_meter_must_be_a_number = 'Конечные показания счетчика должны быть числом';
_fc_props.error_loading_status_change_page = 'Ошибка при загрузке страницы изменения статуса.';
_fc_props.x_is_an_invalid_time_format = '{0} в неверном часовом формате. Верный часовой формат: ГГГГ-MM-ДД ЧЧ:MM:СС';
_fc_props.x_for_schedule_login = '{0} для входа по графику';
_fc_props.x_for_schedule_logout = '{0} для выхода по графику';
_fc_props.x_for_actual_login = '{0} для фактического логина';
_fc_props.x_for_actual_logout = '{0} для фактического логаута';
_fc_props.by_refreshing_the_time_card_detail = 'Обновив данные хронокарты, вы потеряете все изменения. Вы всё равно желаете продолжить?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Вы точно хотите удалить {0}?';
_fc_props.settings.command_mobile_ticket_url = 'URL к мобильному тикету Command';
_fc_props.year = 'Год';
_fc_props.make = 'Производитель';
_fc_props.model = 'Модель';
_fc_props.settings.command_mobile_ticket_password = 'Команда Mobile билетов Пароль';
_fc_props.settings.command_mobile_ticket_auth_token = 'Команда Mobile билетов Auth маркера';
_fc_props.target = 'Цель';
_fc_props.number_of_digits = 'Количество цифр';
_fc_props.number_of_integer_digits = 'Количество целых чисел';
_fc_props.number_of_fractional_digits = 'Количество знаков после запятой';
_fc_props.search = 'Поиск';
_fc_props.settings.command_mobile_ticket_date_order = 'Команда Mobile билетов Дата заказа';
_fc_props.settings.command_mobile_ticket_date_length = 'Команда Mobile билетов Дата Длина';
_fc_props.settings.command_mobile_ticket_date_separator = 'Команда Mobile билетов разделитель даты';
_fc_props.settings.command_mobile_ticket_slash = '/ (Косая черта)';
_fc_props.settings.command_mobile_ticket_dash = '- (Тире)';
_fc_props.settings.command_mobile_ticket_period = ', (Период)';
_fc_props.settings.command_mobile_ticket_space = '(Пробел)';
_fc_props.settings.command_mobile_ticket_time_length = 'Команда Мобильная время по продаже билетов Длина';
_fc_props.settings.command_mobile_ticket_time_separator = 'Команда Mobile билетов разделитель времени';
_fc_props.settings.command_mobile_ticket_colon = ': (Двоеточие)';
_fc_props.settings.command_mobile_ticket_time_format = 'Команда Мобильная время по продаже билетов Формат';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Использование интерфейса номер';
_fc_props.mechanic = 'Механик';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Poll Interval (секунды)';
_fc_props.driving_time = 'Время вождения';
_fc_props.find_near_plants = 'Найти Возле Растения';
_fc_props.please_create_hot_spot_first = 'Пожалуйста, создайте точку доступа сначала';
_fc_props.please_enter_hot_spot_description = 'Пожалуйста, введите горячей описание пятно';
_fc_props.please_select_a_hot_spot_type = 'Пожалуйста, выберите тип пятна горячей';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Отправить на e-mail водителя';
_fc_props.directions_not_found = 'Неверный {0} Адрес. Направления не найден.';
_fc_props.ws_user = 'пользователь';
_fc_props.ws_service = 'обслуживание';
_fc_props.ws_method = 'метод';
_fc_props.ws_params = 'Params';
_fc_props.units_kilometers_abbr = 'КМ';
_fc_props.units_miles_abbr = 'Мичиган';
_fc_props.origin_and_destination = 'Происхождение и назначения';
_fc_props.liter_abbr = 'L';
_fc_props.help = 'Помогите';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Используйте Alt Количество сотрудников';
_fc_props.settings.command_mobile_ticket_android_url = 'URL Android Mobile Ticket';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Secret Ticket';
_fc_props.employee_x_has_no_logout_on_y = 'Сотрудник {0} не имеет выхода из системы для сдвига, начиная с {1}.';
_fc_props.phone_number_has_duplicates = 'Номера телефонов имеют повторяющиеся записи';
_fc_props.ounce = 'унция';
_fc_props.water_extra = 'Вода Экстра';
_fc_props.always = 'Всегда';
_fc_props.work_types_must_be_unique = 'Виды работы должны быть уникальными';
_fc_props.manufacturer = 'производитель';
_fc_props.need_to_enter_seconds = 'Необходимо указать секунды.';
_fc_props.copy = 'копия';
_fc_props.create_in_existing_required = 'Для создания точки доступа в роуминге необходимо выбрать «Создать в существующем».';
_fc_props.break = 'Ломать';
_fc_props.add_export_data_type = 'Добавить тип данных экспорта';
_fc_props.edit_export_data_type = 'Изменить тип данных экспорта';
_fc_props.delete_export_data_type = 'Удалить тип данных экспорта';
_fc_props.export_data_type = 'Тип данных экспорта';
_fc_props.error_updating_export_data_type = 'Ошибка при обновлении типа данных экспорта';
_fc_props.settings.address_key = 'Ключ адреса';
_fc_props.settings.address_key_hover = 'Параметры ключа адреса';
_fc_props.settings.address_key_options = 'Параметры ключа адреса';
_fc_props.more_filter_options = 'Дополнительные параметры фильтра';
_fc_props.unauthorized_mapit_app = 'Эта версия MapIt не авторизована, пожалуйста, обновите MapIt как можно скорее.';
_fc_props.zello_timeout = 'Тайм-аут произошел, связавшись с Зелло.';
_fc_props.user_agreement_text = 'Войдя в систему, вы соглашаетесь с';
_fc_props.user_agreement = 'Пользовательское Соглашение';
_fc_props.upload = 'Загрузить';
_fc_props.x_mins_left = '{0} мин (ы) осталось';
_fc_props.continuous_location_tracking_colon = 'Непрерывное отслеживание местоположения:';
_fc_props.hired_hauler_cannot_register_device = 'Нанятый перевозчик не может зарегистрировать новое устройство.';
_fc_props.timecard_does_not_contain_shift = 'Временная карта сотрудника не содержит смены (информация о временной карте)';
_fc_props.timecard_not_found = 'Тайм-карта сотрудника не найдена';
_fc_props.vehicle_must_be_assigned_for_clt = 'Автомобиль должен быть назначен для отслеживания местоположений.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Неавтоматизированное транспортное средство будет отключено.';
_fc_props.units_yards_abbr = 'Ю.Д.';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Потерянное соединение с EDC. Проверьте Wi-Fi и / или Bluetooth.';
_fc_props.acknowledge_all = 'Подтвердить все';
_fc_props.expand = 'расширять';
_fc_props.collapse = 'коллапс';
_fc_props.expand_all = 'Расширить все';
_fc_props.collapse_all = 'Свернуть все';
_fc_props.last_update = 'Последнее обновление';
_fc_props.acknowledge_alert = 'Признать оповещение';
_fc_props.acknowledge_message = 'Подтвердить сообщение';
_fc_props.acknowledge_all_alerts = 'Подтвердить все оповещения';
_fc_props.acknowledge_all_messages = 'Подтвердить все сообщения';
_fc_props.eta = 'расчетное время прибытия';
_fc_props.tolerance_must_be_a_valid_integer = 'Допуск должен быть действительным целым числом от -99 до 99 (оба включены)';
_fc_props.gps_permission_required = 'Требуется разрешение GPS';
_fc_props.permissions_required = 'Требуются разрешения';
_fc_props.change_user = 'Сменить пользователя';
_fc_props.account_not_registered_with_carrier = 'Этот аккаунт не зарегистрирован у оператора';
_fc_props.slump_loss = 'Спад Потеря';
_fc_props.slump_loss_help = 'Помощь Потери Спада';
_fc_props.did_not_receive_any_edc_devices = 'Не получил никаких устройств EDC.';
_fc_props.edc_record_not_found = 'Запись EDC не найдена';
_fc_props.disable_hotspot_prompt = 'Пожалуйста, нажмите «ОК», когда будет предложено отключить вашу точку доступа.';
_fc_props.edc_device_is_in_use = 'EDC {0} используется.';
_fc_props.an_unknown_exception_has_occurred = 'Произошло неизвестное исключение';
_fc_props.login_has_timed_out = 'Время входа в систему истекло';
_fc_props.use_paper_logs = 'Используйте бумажные журналы';
_fc_props.eld_login_failed = 'Часы входа в систему не удалось';
_fc_props.setup_time_off_type = 'Пожалуйста, установите выходной тип.';
_fc_props.view_load_properties = 'Просмотр свойств загрузки';
_fc_props.couldnt_download_edc_firmware = 'Не удалось загрузить прошивку EDC.';
_fc_props.password_incorrect = 'Неверный пароль';
_fc_props.edc_configuration_password_prompt = 'Из-за состояния этого устройства вы должны предоставить пароль для установки.';
_fc_props.edc_configuration_check_failed = 'Ошибка проверки записей сервера EDC. Пожалуйста, проверьте сообщения об ошибках для деталей.';
_fc_props.configuration_issues_prompt = 'Следующие проблемы были обнаружены при настройке выбранного EDC для указанного грузовика';
_fc_props.checking_password = 'Проверка пароля';
_fc_props.reset_password_instructions_sent = 'Инструкция по сбросу пароля отправлена на адрес электронной почты {0}.';
_fc_props.reset_password_failed = 'Сброс пароля не выполнен, попробуйте еще раз.';
_fc_props.forgot_password = 'Забыли пароль?';
_fc_props.enter_valid_email = 'Неверный адрес электронной почты, введите действительный адрес электронной почты и повторите попытку.';
_fc_props.issues_while_updating_edc_record = 'EDC был успешно настроен. Однако существуют проблемы с записями для EDC на сервере TrackIt, которые могут привести к его неработоспособности. Пожалуйста, позвоните в службу поддержки, чтобы исправить информацию.';
_fc_props.sending = 'Посылка ...';
_fc_props.minimum_ratio = 'Минимальное соотношение';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Обороты барабана не в диапазоне';
_fc_props.probe_failure = 'Ошибка зонда';
_fc_props.unknown_probe_data_failure = 'Неизвестный сбой данных зонда';
_fc_props.no_loaded_status_meaning = 'Нет загруженного статуса';
_fc_props.no_depart_status_meaning = 'Нет статуса вылета';
_fc_props.no_arrive_status_meaning = 'Нет статуса прибытия';
_fc_props.no_begin_work_status_meaning = 'Нет Начать работу Статус';
_fc_props.no_fully_mixed_status_meaning = 'Нет полностью смешанного статуса';
_fc_props.no_begin_work_or_at_job_status = 'Статус «Начать работу» или «На работе» отсутствует';
_fc_props.driver_didnt_follow_procedure = 'Водитель не выполнил процедуру (не тормозит барабан)';
_fc_props.probe_malfunction = 'Неисправность зонда (внутренняя неисправность и т.п.)';
_fc_props.no_data_from_probe = 'Нет данных с зонда (потеря данных)';
_fc_props.missing_probe_calibration = 'Нет данных калибровки датчика';
_fc_props.probe_pressure_not_in_range = 'Давление датчика не находится в диапазоне';
_fc_props.arrival = 'прибытие';
_fc_props.small_load = 'МАЛЕНЬКАЯ НАГРУЗКА';
_fc_props.dry_slump = 'СУХОЙ ШЛАМ';
_fc_props.probe_remixing_turns = 'ремиксы';
_fc_props.turns_needed_to_remix = 'Добавлена вода - требуется дополнительное смешивание';
_fc_props.probe_remixing_finished = 'Ремикс закончен';
_fc_props.probe_battery_low_alert = 'Предупреждение о низком заряде батареи датчика';
_fc_props.probe_battery_low_message = 'Низкий уровень заряда батареи';
_fc_props.probe_battery_low = 'Низкий уровень заряда батареи';
_fc_props.alert_when_probe_battery_low = 'Оповещение, когда батарея зонда разряжена';
_fc_props.probe_sensor_fatal_failure = 'Сбой датчика';
_fc_props.probe_thermistor_failure = 'Отказ термистора';
_fc_props.probe_sensor_stuck_in_concrete = 'Датчик застрял в бетоне';
_fc_props.probe_sensor_over_weight = 'Датчик над весом';
_fc_props.probe_batteries_low = 'Низкий заряд батареи';
_fc_props.no_probe_data = 'Нет данных зонда:';
_fc_props.check_probe = 'Проверьте зонд';
_fc_props.service_probe = 'Сервисный зонд';
_fc_props.below_x = 'Ниже {0}';
_fc_props.above_x = 'Выше {0}';
_fc_props.probe_serial_number_invalid_format = 'Серийный номер датчика не в правильном формате, как показано в заполнителе для поля';
_fc_props.talk_dnd_permission_prompt = 'Разговор требует разрешения НЕ БЕСПОКОИТЬ.';
_fc_props.max = 'Максимум';
_fc_props.status_changed_quickly = 'Статус изменился слишком быстро';
_fc_props.is_empty = 'Пустой';
_fc_props.slump_increased_plasticizer_question = 'Был ли добавлен пластификатор?';
_fc_props.slump_increased_water_added_question = 'Сколько воды было добавлено без учета расхода?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Зонд и мониторинг цикла доставки не поддерживаются одновременно на одном устройстве.';
_fc_props.no_data_from_sensor = 'Нет данных с датчика (потеря данных)';
_fc_props.dvir = 'ДВОР';
_fc_props.system_type = 'Тип системы';
_fc_props.magnet_count = 'Счетчик магнитов';
_fc_props.sensors_setup = 'Настройка датчиков';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM требует установки MAC-адреса блока ввода-вывода, типа барабана, типа DCM и счетчика магнитов.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Билетный объем';
_fc_props.drs_current_volume = 'Текущий объем';
_fc_props.drs_current_water_cement_ratio = 'Текущее соотношение вода / цемент';
_fc_props.drs_target_water_cement_ratio = 'Целевое соотношение вода / цемент';
_fc_props.drs_total_revolutions = 'Общее количество';
_fc_props.drs_current_volume_lp_small = 'Текущий';
_fc_props.drum_revolutions = 'Барабанные революции';
_fc_props.drs_total_revolutions_lp_small = 'Общее количество';
_fc_props.drs_charging_revolutions_lp_small = 'Зарядка';
_fc_props.drs_discharging_revolutions_lp_small = 'Разрядка';
_fc_props.drs_target_wc_lp_small = 'Target W / C';
_fc_props.drs_current_wc_lp_small = 'Текущий W / C';
_fc_props.drs_ticketed_volume_lp_small = 'Билет';
_fc_props.android_location_disclosure = 'TrackIt использует точное и грубое местоположение для сбора данных о местоположении в фоновом режиме, даже когда оно не используется, чтобы включить автоматическое определение статуса и видимость карты в реальном времени для отправки.';
_fc_props.android_location_usage = 'Раскрытие местоположения';
_fc_props.end_pour_trigger_threshold = 'Пороговое значение триггера конца заливки';
_fc_props.drs_wiring_error = 'Ошибка подключения';
_fc_props.drs_wiring_pulse_error = 'Ошибка импульса проводки';
_fc_props.drs_wiring_magnet_error = 'Ошибка магнита проводки';
_fc_props.drs_magnet_missing_error = 'Ошибка отсутствия магнита';
_fc_props.android_location_disclosure_3p = 'TrackIt собирает данные о местоположении в фоновом режиме, чтобы включить автоматическое определение статуса и видимость карты в реальном времени для отправки. При отключении от отправителя местоположение не сообщается.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Пожалуйста, выберите Full MDM или Trackit Remote Support.';
_fc_props.move_up = 'Двигаться вверх';
_fc_props.move_down = 'Вниз';
_fc_props.driver_not_found = 'Драйвер не найден.';
_fc_props.multiple = 'Множественный';
_fc_props.water_meter_installed = 'Установлен счетчик воды';
_fc_props.delete_waypoint = 'Удалить маршрутную точку';
_fc_props.wash_sensor_installed = 'Датчик промывки установлен';
_fc_props.washing_start_time = 'Время начала стирки';
_fc_props.end_washing = 'Окончание стирки';
_fc_props.variable_rpms = 'Var RPMs';
_fc_props.no_slump = 'Без спада';
_fc_props.end_washing_duration = 'Окончание продолжительности стирки';
_fc_props.privacy_policy = 'Политика конфиденциальности';
_fc_props.privacy_policy_text = 'Я прочитал и принимаю';
_fc_props.login_fail_accept_privacy_policy = 'Чтобы продолжить, необходимо принять Политику конфиденциальности.';
_fc_props.rotation_sensor = 'Датчик вращения';
_fc_props.pto_sensor = 'Датчик ВОМ';
_fc_props.pto_sensor_installed = 'Датчик ВОМ установлен';
_fc_props.polarity_reversed = 'Обратная полярность';
_fc_props.pto_sensor_type = 'Тип датчика ВОМ';
_fc_props.sensor_type = 'Тип датчика';
_fc_props.prompt_select_pto_sensor_type = 'Вы должны выбрать «Тип датчика ВОМ», если вы выбрали «Датчик ВОМ» в качестве основного типа датчика.';
_fc_props.priming_turns_at_full_load = 'Заправка оборотов при полной нагрузке';
_fc_props.priming_turns_at_ten_percent_load = 'Заливка оборотов при нагрузке 10%';
_fc_props.weight_units = 'Единицы веса';
_fc_props.settings.send_status = 'Отправить статус / местоположение на мост событий';
_fc_props.volume_difference = 'Разница в объеме';
_fc_props.mobile_ticket_iframe_url = 'URL-адрес IFrame мобильного билета';
_fc_props.mobile_ticket_user = 'Пользователь мобильного билета';
_fc_props.mobile_ticket_password = 'Пароль мобильного билета';
_fc_props.current_truck_status = 'Текущий статус грузовика';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Срабатывает при максимальных оборотах';
_fc_props.alerts_by_type = 'Оповещения по типу';
_fc_props.water_flow_meter = 'Расходомер воды';
_fc_props.flow_meter_no_pulses_warning = 'Нет импульсов - предупреждение';
_fc_props.flow_meter_no_pulses_critical = 'Нет импульсов - критично';
_fc_props.flow_meter_excessive_pulses = 'Чрезмерный пульс';
_fc_props.flow_meter_constantly_flowing = 'Постоянно течет';
_fc_props.flow_meter_good = 'Хороший';
_fc_props.whats_new = 'Что нового';
_fc_props.whats_new_widget_text = 'Знаете ли вы, что мы обновляем TrackIt не реже одного раза в месяц? Получайте последние обновления и советы.';
_fc_props.get_the_scoop = 'Получить совок';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Бизнес-аналитика, улучшающая вашу прибыль, теперь включена в TrackIt';
_fc_props.find_out_more = 'Узнать больше';
_fc_props.daily_breakdown = 'Ежедневная разбивка';
_fc_props.question_summary = 'Резюме вопроса';
_fc_props.trip_summary = 'Сводка поездки';
_fc_props.manage_employees = 'Управляйте сотрудниками';
_fc_props.manage_hotspots = 'Управление горячими точками';
_fc_props.manage_devices = 'Управление устройствами';
_fc_props.manage_equipment = 'Управление оборудованием';
_fc_props.manage_users = 'Управление пользователями';
_fc_props.manage_statuses = 'Управление статусами';
_fc_props.manage_alerts = 'Управлять оповещениями';
_fc_props.popular_report_links = 'Ссылки на популярные отчеты';
_fc_props.popular_settings_links = 'Ссылки на популярные настройки';
_fc_props.sidemenu_settings = 'Настройки';
_fc_props.loads_per_driver = 'Нагрузки на водителя';
_fc_props.please_select_application = 'Выберите хотя бы одно приложение';
_fc_props.external_app_processing = 'Обработка во внешнем приложении';
_fc_props.external_app_sent = 'Отправлено во внешнее приложение';
_fc_props.external_app_received = 'Получено внешним приложением';
_fc_props.wait_time_at_job = 'Время ожидания на работе';
_fc_props.wait_time_at_plant = 'Время ожидания на заводе';
_fc_props.loading_time = 'Время загрузки';
_fc_props.unloading_time = 'Время разгрузки';
_fc_props.pre_post_trip = 'До / после поездки';
_fc_props.update_thresholds = 'Обновить пороги';
_fc_props.green = 'Зеленый';
_fc_props.yellow = 'Желтый';
_fc_props.red = 'красный';
_fc_props.between = 'Между';
_fc_props.num_of_loads = 'Кол-во загрузок';
_fc_props.num_of_drivers = 'Кол-во драйверов';
_fc_props.status_meaning_not_setup = 'Состояние Значение не настроено';
_fc_props.or_less = 'Или менее';
_fc_props.or_greater = 'Или больше';
_fc_props.probe_self_diagnostic_state = 'Состояние самодиагностики датчика';
_fc_props.update_current_truck_status_settings = 'Обновить текущие настройки статуса грузовика';
_fc_props.default_behavior = 'По умолчанию (10 основных статусов)';
_fc_props.combine_similar_statuses = 'Объедините одинаковые статусы всех типов (циклы)';
_fc_props.use_other_category = 'Используйте категорию "Другое"';
_fc_props.selected_statuses = 'Выбранные статусы';
_fc_props.ideas = 'Идеи';
_fc_props.air_content = 'Содержание воздуха';
_fc_props.air_measured_time = 'Измеренное время воздуха';
_fc_props.ten_max = '10 Макс.';
_fc_props.loads_per_plant = 'Нагрузки на завод';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Неверный импульс расходомера на объем.';
_fc_props.coming_soon_title = 'Скоро появятся новые виджеты';
_fc_props.submit_your_ideas = 'Отправьте свои идеи на наш Портал идей с помощью лампочки в левом меню навигации.';
_fc_props.selected_plants = 'Избранные растения';
_fc_props.update_loads_per_plant_settings = 'Обновить настройки нагрузки на завод';
_fc_props.default_top_10_plants = 'По умолчанию (10 лучших растений)';
_fc_props.phone_number_will_be_uploaded = 'Идентификатор устройства будет загружен для целей регистрации.';
_fc_props.include_deleted = 'Включить Удалено';
_fc_props.primary_status_type = 'Тип основного статуса';
_fc_props.additional_status_trigger = 'Дополнительные триггеры статуса';
_fc_props.update_tickets_destination = 'Обновить пункт назначения для всех связанных заявок';
_fc_props.settings.send_destination_changes_to_connex = 'Отправить изменения назначения в Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Активен ли билет, когда он получен TrackIt (по умолчанию «всегда»)';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Автоматически активировать следующий тикет при завершении предыдущего тикета';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Должен автоматически активировать следующий тикет по завершении тикета (по умолчанию «true»)';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Статус к статусу ожидания, когда нет активного билета';
_fc_props.break_down = 'Авария';
_fc_props.idle = 'Праздный';
_fc_props.pto_work_maximum_speed_limit = 'Ограничение максимальной скорости работы';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Свяжите изменение статуса, которое завершает заявку, с этой заявкой';
_fc_props.approaching = 'приближается';
_fc_props.performing_task = 'Выполнение задачи';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Вы уверены, что хотите удалить Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Обновить заявку {0}. Пункт назначения изменен.';
_fc_props.trackit_return_destination_changed_to_0 = 'Обновить заявку {0}. Возврат изменен.';
_fc_props.menu_settings_title_geogate = 'Геогейт';
_fc_props.add_geogate = 'Добавить геогейт';
_fc_props.driver_enabled = 'Драйвер включен';
_fc_props.dispatcher_enabled = 'Диспетчер включен';
_fc_props.runsheet_number = 'Номер рабочего листа';
_fc_props.manual_load = 'Ручная загрузка';
_fc_props.edit_geogate = 'Изменить геогейт';
_fc_props.handling = 'Умение обращаться';
_fc_props.geogate_name = 'Геогейт';
_fc_props.geogate_panel = 'Панель геогейта';
_fc_props.vehicle_is_not_logged_in = 'Сообщение не отправлено (автомобиль не зарегистрирован)';
_fc_props.air = 'Воздуха';
_fc_props.create_a_geogate = 'Создать геогейт';
_fc_props.click_the_map_to_set_the_geogate_points = 'Щелкните карту, чтобы установить точки геогейта.';
_fc_props.clock_out = 'Часы из';
_fc_props.handling_operation = 'Обработка Операция';
_fc_props.view_geogates = 'Посмотреть геогейты';
_fc_props.select_a_geogate = 'Пожалуйста, выберите геогейт';
_fc_props.failed_to_find_geogate = 'Не удалось найти Геогейт';
_fc_props.adjust_geogate = 'Настроить геогейт';
_fc_props.geogate_expiration_date_time_extra_days = 'Дата истечения срока действия Geogate Время По умолчанию Дополнительные дни';
_fc_props.menu_settings_title_retention = 'Хранение данных';
_fc_props.category = 'Категория';
_fc_props.telemetry = 'Телеметрия';
_fc_props.ticket_data = 'Данные билета';
_fc_props._30_days = '30 дней';
_fc_props._6_months = '6 месяцев';
_fc_props._1_year = '1 год';
_fc_props._3_years = '3 года';
_fc_props.new_group = 'Новая группа';
_fc_props.selected_vehicles = 'Выбранные автомобили';
_fc_props.group_name_is_required_field = 'Название группы обязательное поле';
_fc_props.at_least_vehicle_should_be_selected = 'Необходимо выбрать хотя бы 1 автомобиль.';
_fc_props.groups = 'Группы';
_fc_props.trucks = 'Грузовые автомобили';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Геогейт удален. Пожалуйста, обновите страницу.';
_fc_props.air_timestamp = 'Отметка времени эфира';
_fc_props.fail = 'ПОТЕРПЕТЬ НЕУДАЧУ';
_fc_props.stuck = 'ЗАСТРЯВШИЙ';
_fc_props.dirty = 'ГРЯЗНЫЙ';
_fc_props.seasonal = 'Сезонный';
_fc_props.category_details = 'Детали категории';
_fc_props.alert_events = 'Оповещения о событиях';
_fc_props.driver_performance_infractions = 'Нарушения правил поведения водителя';
_fc_props.telemetry_details = 'Данные о местоположении, горячие точки, время в горячих точках, время в регионах, данные DCM, данные зонда, данные двигателя';
_fc_props.ticket_data_details = 'Данные билета';
_fc_props.general_details = 'Изменения статуса, табели учета рабочего времени, расписания';
_fc_props.wash = 'Мыть';
_fc_props.filter_options = 'Параметры фильтра';
_fc_props.home_plants = 'Домашние растения';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO требует установки типа датчика PTO.';
_fc_props.invalid_io_box_mac_address = 'Неверный Mac-адрес блока ввода/вывода';
_fc_props.access_denied = 'Доступ запрещен';
_fc_props.wash_events = 'События стирки';
_fc_props.min_speed = 'Мин. скорость';
_fc_props.temperature_change = 'Изменение температуры';
_fc_props.degrees_cap = 'Степени';
_fc_props.washout_tracking = 'Отслеживание вымывания';
_fc_props.approaching_wash = 'Приближается к стирке';
_fc_props.arrival_wash = 'Прибытие Мойка';
_fc_props.performing_wash = 'Выполнение стирки';
_fc_props.wash_complete = 'Стирка завершена';
_fc_props.must_be_positive_or_zero = 'Должен быть положительным или равным нулю!';
_fc_props.min_speed_greater_than_max = 'Минимальная скорость должна быть меньше максимальной скорости!';
_fc_props.clock_in = 'Часы в';
_fc_props.mix_water_at_x_to_y_for_t = 'Смешайте воду со скоростью от {0} до {1} об/мин на {2} оборотов.';
_fc_props.discharge_water_for_x_revolutions = 'Сбросьте воду на {0} оборотов.';
_fc_props.eod_drum_wash_complete = 'Промывка барабана завершена! <br> Остановка барабана щупом вверху.';
_fc_props.washing_complete = 'Управляемая промывка завершена';
_fc_props.eod_washout_no_data_available = 'Данные датчика недоступны, пожалуйста, завершите процесс стирки.';
_fc_props.eod_washout_no_data_available_title = 'Управляемая промывка недоступна';
_fc_props.menu_reports_title_ticket_time_summary = 'Сводка времени билета';
_fc_props.menu_reports_description_ticket_time_summary = 'Просмотр информации о времени оформления заказа или транспортного средства';
_fc_props.menu_reports_title_summary = 'Краткое содержание';
_fc_props.menu_reports_title_ticket_summary = 'Сводка билетов';
_fc_props.menu_reports_description_summary1 = 'Просмотр сводки времени в горячих точках';
_fc_props.menu_dispatch_title_main = 'Основной';
_fc_props.menu_dispatch_title_login_registry = 'Логин Реестр';
_fc_props.menu_reports_title_main = 'Основной';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Вы уверены, что хотите удалить этот тип данных экспорта?';
_fc_props.dvir_status_paper_logs = 'Бумажные журналы';
_fc_props.menu_reports_title_billing_report = 'Отчет о выставлении счетов';
_fc_props.menu_reports_description_billing_report = 'Отчет о выставлении счетов';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Статус для получения статуса при активации работы';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Статус к статусу билета при активации работы (по умолчанию «Ложь»)';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Фунтов стерлингов';
_fc_props.units_weight_kilogram_label = 'Килограммы';
_fc_props.settings_weight = 'Масса';
_fc_props.units_pounds = 'Фунтов стерлингов';
_fc_props.units_kilograms = 'Килограммы';
_fc_props.geo_gate_edit_modal_description = 'Описание';
_fc_props.geo_gate_edit_modal_start_point = 'Начальная точка';
_fc_props.geo_gate_edit_modal_end_point = 'Конечная точка';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Тип оборудования';
_fc_props.geo_gate_edit_modal_heading_south = 'С юга на север';
_fc_props.geo_gate_edit_modal_heading_north = 'С севера на юг';
_fc_props.geo_gate_edit_modal_driver_message = 'Сообщение водителя';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Отправка сообщения';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Дата истечения срока действия';
_fc_props.geo_gate_edit_modal_permanent = 'Постоянный';
_fc_props.geo_gate_edit_modal_critical = 'Критический';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Ошибка сохранения Geogate: поле описания должно быть заполнено.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Ошибка сохранения Geogate: поле «Начальная точка» должно быть заполнено.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Ошибка сохранения Geogate: поле «Начальная точка» содержит недопустимый формат данных.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Ошибка сохранения Geogate: поле End Point содержит неверный формат данных.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Ошибка сохранения Geogate: поле «Дата истечения срока действия» должно быть заполнено.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Ошибка сохранения Geogate: поле «Дата истечения срока действия» содержит неверный формат данных.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Ошибка сохранения Geogate: с севера на юг — поле «Сообщение об отправке» должно быть заполнено';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Ошибка сохранения Geogate: изменений не обнаружено';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Ошибка сохранения Geogate: поле «Дата истечения срока действия» содержит дату и время с истекшим сроком действия';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Ошибка сохранения Geogate: поле описания должно содержать только буквы и цифры';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Ошибка сохранения Geogate: поле «Тип оборудования» должно быть заполнено';
_fc_props.geo_gate_table_message_label_north_to_south = 'С севера на юг';
_fc_props.geo_gate_table_message_label_south_to_north = 'С юга на север';
_fc_props.geo_gate_table_message_label_both = 'Оба';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Широта Долгота';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Установка этого флажка отменит настройку ограничения сообщений о перемещении устройства.';
_fc_props.geo_gate_edit_modal_seasonal = 'Сезонный';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Ошибка при сохранении Geogate: должны быть указаны сезонные даты';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Ошибка сохранения Geogate: поля сезонной даты содержат неверный формат данных';
_fc_props.test_js_test_javascript = 'Тест Javascript';
_fc_props.units_miles_per_hour_abbr = 'миль в час';
_fc_props.units_kilometers_per_hour_abbr = 'км/ч';
_fc_props.units_kilometers = 'км';
_fc_props.units_miles = 'Мили';
_fc_props.units_gallons_abbr = 'ГАЛ';
_fc_props.units_liters_abbr = 'л';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Ошибка сохранения Geogate: с юга на север — поле сообщения водителя должно быть заполнено';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Ошибка сохранения Geogate: с юга на север — поле «Отправочное сообщение» должно быть заполнено';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Ошибка сохранения Geogate: с севера на юг — поле сообщения водителя должно быть заполнено';
_fc_props.geo_gate_heading = 'Заголовок';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Ошибка сохранения Geogate: поле «Конечная точка» должно быть заполнено';
_fc_props.units_mile = 'Миля';
_fc_props.units_kilometer = 'километр';
_fc_props.cloud_dvir__exit = 'Выход';
_fc_props.cloud_dvir__signature = 'Подпись';
_fc_props.cloud_dvir__inspection = 'осмотр';
_fc_props.cloud_dvir__add_photos_notes = 'Добавить фото/заметки';
_fc_props.cloud_dvir__odometer_reading = 'Показания одометра';
_fc_props.cloud_dvir__engine_hours = 'Моточасы';
_fc_props.cloud_dvir__truck_details = 'Детали грузовика';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Определить общее состояние автомобиля.';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'ФИО и подпись водителя';
_fc_props.cloud_dvir__sign = 'Знак';
_fc_props.cloud_dvir__driver_note_from = 'Примечание водителя от';
_fc_props.cloud_dvir__add_notes = 'Добавить заметки';
_fc_props.cloud_dvir__driver_notes = 'Примечания для водителя';
_fc_props.cloud_dvir__done = 'Сделанный';
_fc_props.cloud_dvir__needs_repair = 'Требуется ремонт';
_fc_props.cloud_dvir__not_applicable = 'Непригодный';
_fc_props.cloud_dvir__review_and_sign = 'Проверить и подписать';
_fc_props.cloud_dvir__add_photos = 'Добавить фото';
_fc_props.cloud_dvir__upload_photo_limit = 'Вы можете загрузить до 3 фотографий';
_fc_props.cloud_dvir__mismatch_title = 'Предупреждение о несоответствии';
_fc_props.cloud_dvir__ok = 'Хорошо';
_fc_props.cloud_dvir_template = 'Облачный шаблон DVIR';
_fc_props.cloud_dvir__needs_review = 'ДВИР нуждается в проверке';
_fc_props.cloud_dvir__submitted = 'Поданный';
_fc_props.cloud_dvir__completed_by = 'Завершено';
_fc_props.cloud_dvir__review_btn = 'Обзор';
_fc_props.cloud_dvir__truck_number = 'Грузовик {0}';
_fc_props.cloud_dvir__pending_review = 'Ожидает оценки';
_fc_props.cloud_dvir__start_dvir = 'Запустить ДВИР';
_fc_props.cloud_dvir__dvir_history = 'История ДВИР';
_fc_props.cloud_dvir__view_all = 'Посмотреть все';
_fc_props.load_zone = 'Зона нагрузки';
_fc_props.view_load_zones = 'Просмотр зон нагрузки';
_fc_props.edit_load_zone = 'Изменить зону нагрузки';
_fc_props.create_a_load_zone = 'Создайте зону нагрузки';
_fc_props.load_zone_panel = 'Панель зоны нагрузки';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Щелкните карту, чтобы установить центр зоны нагрузки.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'В ходе этой проверки были выявлены дефекты, и вы решили, что этот автомобиль безопасен в эксплуатации. Пожалуйста, подтвердите, прежде чем продолжить';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Эта проверка не выявила выявленных дефектов, и вы выбрали, что этот автомобиль небезопасен в эксплуатации. Пожалуйста, подтвердите, прежде чем продолжить.';
_fc_props._90_days = '90 дней';
_fc_props.cloud_dvir = 'Облачный ДВИР';
_fc_props.cloud_dvir_details = 'Облачные отправки DVIR';
_fc_props.show_load_zones = 'показать зоны нагрузки';
_fc_props.hide_load_zones = 'скрыть зоны нагрузки';
_fc_props.no = 'Нет';
_fc_props.cloud_dvir__reset = 'Перезагрузить';
_fc_props.cloud_dvir__clear = 'Прозрачный';
_fc_props.mark_read = 'Отметить как прочитанное';
_fc_props.mark_unread = 'Пометить как непрочитанное';
_fc_props.read = 'Читать';
_fc_props.unread = 'Не прочитано';
_fc_props.air_sensor_serial_number = 'Серийный номер датчика воздуха';
_fc_props.air_sensor_mac_address = 'MAC-адрес датчика воздуха';
_fc_props.air_sensor_serial_number_invalid_format = 'Серийный номер датчика воздуха имеет недопустимый формат: (буквенно-цифровой, длиной не более 32 символов с дефисами).';
_fc_props.air_sensor_mac_address_invalid_format = 'MAC-адрес датчика воздуха не имеет допустимого шестнадцатеричного формата, как показано в заполнителе поля.';
_fc_props.air_sensor_license = 'Датчик воздуха';
_fc_props.adjust_route_point = 'Отрегулировать точку маршрута';
_fc_props.route_point_panel = 'Панель точек маршрута';
_fc_props.route_point = 'Точка маршрута';
_fc_props.cloud_dvir__pre_trip = 'Перед поездкой';
_fc_props.cloud_dvir__post_trip = 'Сообщение о поездке';
_fc_props.cloud_dvir__other_insction_type = 'Другой';
_fc_props.cloud_dvir__dvir_completed = 'Завершенный';
_fc_props.cloud_dvir__awaiting_mechanic = 'Ожидание механика';
_fc_props.cloud_dvir__awaiting_driver = 'Ожидание подтверждения драйвера';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Невозможно проверить серийный номер датчика воздуха. Обратитесь в службу поддержки за дополнительной помощью.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Этот серийный номер датчика воздуха уже существует, используйте другой серийный номер.';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Невозможно проверить MAC-адрес датчика воздуха. Обратитесь в службу поддержки за помощью.';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Ошибка отправки DVIR.';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Пожалуйста, попробуйте еще раз';
_fc_props.ticket_integration_descr = 'Интеграция билетов позволяет настроить параметры интеграции билетов, а также главный экран/экран загрузки/дисплей с жестким креплением.';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR успешно отправлен';
_fc_props.cloud_dvir__inspection_trip_type = 'Тип инспекционной поездки';
_fc_props.menu_dvir_title_mechanic = 'Механик';
_fc_props.sidemenu_dvir = 'ДВИР';
_fc_props.air_sensor_firmware = 'Прошивка датчика воздуха';
_fc_props.when_no_active_ticket = 'Когда нет активного билета';
_fc_props.used_by = 'Использован';
_fc_props.fields = 'Поля';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Вы уверены, что хотите удалить макет билета "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Схема билета';
_fc_props.add_ticket_layout = 'Добавить макет билета';
_fc_props.edit_ticket_layout = 'Изменить макет билета';
_fc_props.ticket_layout_edit_modal_name = 'Имя';
_fc_props.ticket_layout_edit_modal_default = 'По умолчанию';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Ошибка при сохранении макета билета: поле имени должно быть заполнено.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Ошибка при сохранении макета билета: поле имени должно содержать только буквы и цифры.';
_fc_props.ticket_layout_edit_modal_fields = 'Поля';
_fc_props.ticket_layout_edit_modal_usage = 'Применение';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Макет билета был удален. Пожалуйста, обновите страницу.';
_fc_props.more_lower_case = 'более';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Ошибка при сохранении макета заявки: необходимо выбрать одно или несколько полей.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Ошибка при сохранении макета заявки: необходимо выбрать один или несколько статусов.';
_fc_props.cloud_dvir__ad_hoc = 'Для этого случая';
_fc_props.radius_multiplier_should_be = 'Множитель радиуса должен быть плавающим числом.';
_fc_props.ticket_max_activate_age_err = 'Максимальное количество минут активации билета должно быть целым числом.';
_fc_props.max_hotspot_unused_days_errs = 'Максимальное количество неиспользованных дней горячей точки должно быть целым числом.';
_fc_props.begin_pour_air_content = 'Начать заливку содержимого воздуха';
_fc_props.arrival_air_content = 'Содержание воздуха прибытия';
_fc_props.fully_mixed_air_content = 'Полностью смешанный воздух';
_fc_props.departure_air_content = 'Содержание воздуха при вылете';
_fc_props.popup_enabled = 'Всплывающее окно включено';
_fc_props.popup_disabled = 'Всплывающее окно отключено';
_fc_props.cloud_dvir__leave_confirmation_title = 'Предупреждение';
_fc_props.cloud_dvir__leave_confirmation_description = 'Если выйти со страницы сейчас, все изменения будут потеряны.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Подтверждать';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Отмена';
_fc_props.air_bt_ratio = 'Измерение датчика воздуха';
_fc_props.cloud_dvir__view_photos_notes = 'Просмотр фотографий/заметок';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Добавление статуса создаст пустое использование в другом макете заявки. Хотите продолжить добавление?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'У автомобиля нет лицензии на датчик воздуха.';
_fc_props.air_sensor_installed = 'Установлен датчик воздуха';
_fc_props.load_card = 'Загрузить карту';
_fc_props.cloud_dvir__inspection_profile = 'Профиль проверки';
_fc_props.cloud_dvir__view_details = 'Посмотреть детали';
_fc_props.cloud_dvir__certify_repairs = 'Сертифицировать ремонт';
_fc_props.cloud_dvir__repairs_made = 'Ремонт сделан';
_fc_props.cloud_dvir__repairs_not_necessary = 'Ремонт не требуется';
_fc_props.cloud_dvir__mechanic_note_from = 'Записка механика от';
_fc_props.cloud_dvir__mechanic_assignment = 'Назначение механика';
_fc_props.cloud_dvir__mechanic_signature_description = 'Отметьте ниже все применимые позиции и подпишите, что автомобиль прошел надлежащий осмотр.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Состояние данного автомобиля удовлетворительное';
_fc_props.cloud_dvir__defects_corrected_label = 'Дефекты исправлены';
_fc_props.cloud_dvir__repair_not_required_label = 'Ремонт не требуется для безопасной эксплуатации автомобиля';
_fc_props.safety = 'Безопасность';
_fc_props.cloud_dvir__awaiting_sign = 'Ожидание знака';
_fc_props.cloud_dvir__dvir_review = 'Обзор ДВИР';
_fc_props.settings.mobile_auto_start_navigation = 'Автоматический запуск навигации на мобильном устройстве';
_fc_props.cloud_dvir__edit = 'Редактировать';
_fc_props.delete_custom_label = 'Удалить пользовательскую этикетку';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Ваша версия TrackIt устарела, и некоторые функции DVIR могут работать некорректно. Пожалуйста, обновите TrackIt, прежде чем продолжить.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Что-то пошло не так во время загрузки подписи. Пожалуйста, попробуйте еще раз';
_fc_props.max_hotspot_unused_days_error = 'Максимальное количество неиспользованных дней Hotspot составляет 1000 дней.';
_fc_props.menu_dvir_title_admin_templates = 'Шаблоны';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Админ';
_fc_props.cloud_dvir__admin_templates = 'Шаблоны';
_fc_props.form_name = 'Имя формы';
_fc_props.form_status = 'Статус формы';
_fc_props.menu.dvir.title = 'ДВИР';
_fc_props.cloud_dvir__option_download = 'Скачать';
_fc_props.cloud_dvir__option_view = 'Вид';
_fc_props.cloud_dvir__inspectortype_driver = 'Водитель';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Просмотр/редактирование/создание пользовательских форм и материалов DVIR';
_fc_props.menu.dvir.description.mechanic = 'Просмотр/редактирование действенных материалов DVIR';
_fc_props.not_in_use = 'Не используется';
_fc_props.logged_in = 'вошел в систему';
_fc_props.reviewing_driver = 'Обзор драйвера';
_fc_props.cloud_dvir__report_title = 'Акт осмотра транспортного средства водителем';
_fc_props.cloud_dvir__report_signed = 'подписано';
_fc_props.cloud_dvir__report_page_counter = 'Страница {0} из {1}';
_fc_props.cloud_dvir__report_defects = 'Дефекты';
_fc_props.cloud_dvir__report_comments = 'Комментарии';
_fc_props.cloud_dvir__report_inspection_table_title = 'Осмотр транспортного средства';
_fc_props.cloud_dvir__report_driver_table_title = 'Информация о драйвере';
_fc_props.cloud_dvir__report_carrier = 'Перевозчик';
_fc_props.cloud_dvir__report_truck = 'Грузовик';
_fc_props.cloud_dvir__report_location = 'Расположение';
_fc_props.cloud_dvir__report_odometer = 'Одометр';
_fc_props.cloud_dvir__report_hours = 'Часы';
_fc_props.cloud_dvir__report_no_comments = 'Без комментариев';
_fc_props.include_unlicensed_vehicles = 'Включить нелицензированные транспортные средства';
_fc_props.cloud_dvir__search_by_form_name = 'Поиск по названию формы';
_fc_props.cloud_dvir__create_dvir = 'Создать DVIR';
_fc_props.cloud_dvir__update_dvir = 'Обновить ДВИР';
_fc_props.cloud_dvir__form_name = 'Имя формы';
_fc_props.cloud_dvir__form_description = 'Описание формы';
_fc_props.cloud_dvir__form_status = 'Статус формы';
_fc_props.information = 'Информация';
_fc_props.cloud_dvir__search_categories = 'Категории поиска';
_fc_props.cloud_dvir__create_new_category = 'Создать новую категорию';
_fc_props.category_name = 'Название категории';
_fc_props.sub_category = 'Подкатегория';
_fc_props.sub_category_name = 'Название подкатегории';
_fc_props.delete_category = 'Удалить категорию';
_fc_props.cloud_dvir__dvir_information = 'Информация ДВИР';
_fc_props.cloud_dvir__dvir_choose_categories = 'Выберите категории';
_fc_props.cloud_dvir__create_category = 'Создать категорию';
_fc_props.cloud_dvir__update_category = 'Обновить категорию';
_fc_props.delete_route_point = 'Удалить точку маршрута';
_fc_props.view_route_points = 'Просмотр точек маршрута';
_fc_props.ignore_cancel_preload = 'Не обращайте внимания на отмену, вы уже загружены. Ваш хоумран скоро будет показан';
_fc_props.cloud_dvir__assigned_mechanic = 'Назначенный механик';
_fc_props.cloud_dvir__add_mechanic = 'Добавить механика';
_fc_props.contact_type = 'Тип контакта';
_fc_props.cloud_dvir__send_email_notifications = 'Отправлять уведомления по электронной почте';
_fc_props.menu_dvir_title_admin_dvir = 'ДВИР';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Ошибка получения записей истории DVIR.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Ошибка получения записи DVIR.';
_fc_props.cloud_dvir__error_saving_submission = 'Ошибка сохранения отправки. Попробуйте еще раз.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Ошибка при получении списка механиков.';
_fc_props.cloud_dvir__template_saved = 'Шаблон сохранен.';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Ошибка получения списка шаблонов.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Ошибка сохранения шаблона. Попробуйте еще раз.';
_fc_props.dispatch_group = 'Диспетчерская группа';
_fc_props.save_adjust = 'Сохранить и настроить точку маршрута';
_fc_props.reset_route_point = 'Сбросить точку маршрута';
_fc_props.no_records_stub_adjust_filters = 'Попробуйте изменить настройки поиска или фильтра, чтобы увеличить количество результатов.';
_fc_props.no_dvir_records = 'Все догнали ДВИРы!';
_fc_props.no_dvir_records_sub_title = 'Вы многого достигли';
_fc_props.settings.master_data_connex_x_api_key = 'Основные данные Connex x-api-ключ';
_fc_props.slump_not_obtained_for_x = 'Показания о спаде не получены для: {0}';
_fc_props.please_select_a_sensor_status_type = 'Пожалуйста, выберите типы состояний датчиков, чтобы вызвать это оповещение';
_fc_props.settings.master_data_connex_token = 'Токен Connex основных данных';
_fc_props.error_validating_master_data_connex_credentials = 'Ошибка проверки учетных данных Master Data Connex';
_fc_props.settings.master_data_connex_base_url = 'URL-адрес базы основных данных Connex';
_fc_props.settings.master_data_connex_exp_time = 'Срок действия Master Data Connex';
_fc_props.download_template = 'Скачать шаблон';
_fc_props.menu_settings_title_dispatch_group = 'Группы диспетчеризации';
_fc_props.settings.dispatch_groups = 'Группы диспетчеризации';
_fc_props.dispatch_group_name = 'Имя группы диспетчеризации';
_fc_props.dispatch_group_plants = 'Заводы группы диспетчеризации';
_fc_props.dispatch_group_plants_mapping_message = 'Укажите, какие заводы будут входить в эту диспетчерскую группу.';
_fc_props.dispatch_group_users_mapping_message = 'Укажите, какие пользователи будут частью этой диспетчерской группы.';
_fc_props.dispatch_group_plant_disassociation_message = 'Завод должен одновременно находиться в одной группе диспетчеризации.';
_fc_props.dispatch_group_search_by_plant = 'Поиск по названию растения';
_fc_props.dispatch_group_search_by_username = 'Поиск по имени пользователя';
_fc_props.search_by_dispatch_group_name = 'Поиск по названию группы диспетчеризации';
_fc_props.create_dispatch_group = 'Создать группу отправки';
_fc_props.update_dispatch_group = 'Обновить группу отправки';
_fc_props.dispatch_group_saved = 'Группа отправки сохранена.';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Удаление этой группы диспетчеризации приведет к созданию групп диспетчеризации по умолчанию для связанных заводов.';
_fc_props.error_saving_dispatch_group = 'Ошибка при сохранении группы рассылки. Попробуйте еще раз.';
_fc_props.please_enter_a_valid_plant_num = 'Пожалуйста, введите действительный номер завода.';
_fc_props.cloud_dvir__report_no_defects = 'Без дефектов';
_fc_props.shoud_select_dispatch_group = 'Вам необходимо выбрать хотя бы одну группу отправки.';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Невозможно удалить, поскольку эта группа рассылки содержит пользователей, которые связаны только с этой группой рассылки.';
_fc_props.cloud_dvir__username = 'Имя пользователя';
_fc_props.cloud_dvir__password = 'Пароль';
_fc_props.cloud_dvir__login = 'Авторизоваться';
_fc_props.cloud_dvir__mechanic_sign_in = 'Механик Войти';
_fc_props.delete_dispatch_group = 'Удалить группу отправки';
_fc_props.cloud_dvir__driver = 'Водитель';
_fc_props.cloud_dvir__equipment_pound = 'Оборудование #';
_fc_props.cloud_dvir__mechanic = 'Механик';
_fc_props.dispatch_group_user_only_unassociate_message = 'Невозможно удалить пользователя, поскольку пользователи должны быть связаны хотя бы с одной группой рассылки.';
_fc_props.cloud_dvir__time_period_filter_today = 'Сегодня';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'Вчера';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Последние 7 дней';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Последние 30 дней';
_fc_props.cloud_dvir__date_range_filter = 'Фильтровать по дате';
_fc_props.cloud_dvir__download = 'Скачать';
_fc_props.dispatch_group_is_empty = 'Группа отправки не может быть пустой.';
_fc_props.dispatch_group_with_empty_plants = 'Хотя бы один завод должен быть связан с группой диспетчеризации.';
_fc_props.dispatch_group_with_empty_users = 'По крайней мере один пользователь должен быть связан с группой диспетчеризации.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Невозможно удалить группу рассылки, поскольку она содержит пользователей, связанных только с этой группой рассылки.';
_fc_props.dispatch_group_not_exists = 'Группа отправки не существует.';
_fc_props.dispatch_group_contains_one_plant = 'Невозможно удалить, поскольку эта группа диспетчеризации содержит только один завод.';
_fc_props.dispatch_group_users = 'Пользователи группы диспетчеризации';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Ошибка получения записи DVIR.';
_fc_props.cloud_dvir__assign_mechanic = 'Назначить механика';
_fc_props.cloud_dvir__submit_review = 'добавить отзыв';
_fc_props.error_deleting_dispatch_group = 'Ошибка удаления группы рассылки.';
_fc_props.error_retrieving_dispatch_groups = 'Ошибка при получении списка групп отправки.';
_fc_props.cloud_dvir__undo_selection = 'Отменить выбор';
_fc_props.enable_master_data = 'Включить основные данные';
_fc_props.manually_sync = 'Синхронизировать вручную';
_fc_props.master_data_connex_token_expiration = 'Срок действия токена Connex основных данных';
_fc_props.cloud_dvir__time_period_filter_custom = 'Обычай';
_fc_props.settings.zello_session_timeout_minutes = 'Тайм-аут сеанса Zello (минуты)';
_fc_props.menu_settings_title_master_data = 'Основные данные';
_fc_props.settings.master_data = 'Основные данные';
_fc_props.error_retrieving_master_data = 'Ошибка получения основных данных';
_fc_props.master_data_sync_completed = 'Синхронизация основных данных завершена';
_fc_props.master_data_successfully_updated = 'Данные успешно обновлены';
_fc_props.master_data_failed_to_update = 'Не удалось сохранить.';
_fc_props.master_data_sync_initiated = 'Синхронизация основных данных начата';
_fc_props.master_data_sync_failed = 'Синхронизация основных данных не удалась';
_fc_props.enabled_excluding_origin_on_deliveries = 'Правда (исключая пункт отправления в билетах на доставку)';
_fc_props.enabled_including_origin_on_deliveries = 'Правда (включая место происхождения в билетах на доставку)';
_fc_props.cloud_dvir__i_will_decide_later = 'я решу позже';
_fc_props.returning = 'Возвращение';
_fc_props.leftover_concrete = 'Остатки бетона';
_fc_props.leftover_concrete_on_vehicle_x = 'Остатки бетона на автомобиле {0}';
_fc_props.leftover_concrete_alert = 'Оповещение об остатках бетона';
_fc_props.wc_ratio_exceeded = 'Превышено соотношение воды и тепла';
_fc_props.search_by_site_name = 'Поиск по названию сайта';
_fc_props.sites = 'Места';
_fc_props.token_expiration = 'Срок действия токена';
_fc_props.menu_settings_title_connex_api_key_management = 'Управление ключами API Connex';
_fc_props.settings.connex_api_key_management = 'Управление ключами Connex API';
_fc_props.error_retrieving_connex_api_key_data = 'Ошибка при получении данных ключа connex API.';
_fc_props.qr_code = 'QR код';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Информирование водителя и интерфейса при вводе неправильного завода';
_fc_props.enable_driver_message = 'Включить сообщение драйвера';
_fc_props.enable_dispatch_message = 'Включить отправку сообщения';
_fc_props.enable_sending_message_to_interface = 'Включить отправку сообщения в интерфейс';
_fc_props.ticket_qr_code = 'QR-код билета';
_fc_props.leftover_concrete_alert_description = 'Оповещение об обнаружении остатков бетона после заливки.';
_fc_props.minimum_leftover_concrete_amount = 'Минимальное количество остатка бетона';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Ошибка проверки учетных данных Master Data Connex. Не авторизовано.';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Ошибка проверки разрешений учетных данных Master Data Connex';
_fc_props.error_validating_master_data_connex_url_not_found = 'Ошибка проверки URL-адреса Connex основных данных не найдена';
_fc_props.error_validating_master_data_connex_unknown_error = 'Ошибка проверки Master Data Connex Неизвестная ошибка';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Не удалось синхронизировать основные данные. Синхронизируйте тип транспортного средства.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Синхронизация основных данных не удалась. Синхронизируйте автомобиль.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Синхронизация основных данных не удалась. Синхронизируйте завод.';
_fc_props.master_data_sync_failed_data_base_connection = 'Синхронизация основных данных не удалась при подключении к базе данных.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Ошибка проверки учетных данных Master Data Connex. Разрешение токена носителя недостаточно.';
_fc_props.settings.back_date_arrive_job = 'Обратная дата прибытия на работу';
_fc_props.device_notifications = 'Уведомления';
_fc_props.loading_number = 'Номер загрузки';
_fc_props.leftover_concrete_volume_temp_age = 'Оставшийся объем={0}, температура={1}, возраст={2}';
_fc_props.total_ordered = 'Общее заказанное количество';
_fc_props.total_ticketed = 'Доставлено Заказано';
_fc_props.load_number = 'Номер загрузки';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Минимальный остаток бетона должен быть залит и не должен превышать {0}.';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Ошибка проверки основных данных. Учетные данные не могут быть одинаковыми.';
_fc_props.use_aws_for_talk = 'Используйте AWS для общения';
_fc_props.air_readings_not_supported = 'Показания воздуха не поддерживаются';
_fc_props.air_not_supported = 'Воздух не поддерживается';
_fc_props.mix_not_supported = 'Микс не поддерживается';
_fc_props.air_default_and_none_not_supported = 'Невозможно одновременно выбрать значение по умолчанию и не поддерживается!';
_fc_props.notifications_enabled = 'Уведомления включены';
_fc_props.prevent_status_change = 'Запретить изменение статуса';
_fc_props.message_to_interface = 'Сообщение для интерфейса';
_fc_props.prevent_wrong_plant = 'Предотвратите неправильное растение';
_fc_props.previous_tickets = 'Предыдущие билеты';
_fc_props.audit_ticket_number = 'Номер билета';
_fc_props.audit_date_created = 'Дата создания';
_fc_props.audit_vehicle_number = 'Номер автомобиля';
_fc_props.cloud_dvir__table_ok = 'Безопасно в эксплуатации';
_fc_props.cloud_dvir__table_needs_repair = 'Небезопасно в эксплуатации';
_fc_props.missing_air_calibration = 'Нет данных калибровки по воздуху';
_fc_props.reported_defects = 'Сообщенные дефекты';
_fc_props.address_latitude = 'Адрес Широта';
_fc_props.address_longitude = 'Адрес Долгота';
_fc_props.routing_latitude = 'Широта маршрутизации';
_fc_props.routing_longitude = 'Долгота маршрута';
_fc_props.category_name_must_be_unique = 'Название категории должно быть уникальным.';
_fc_props.subcategory_name_must_be_unique = 'Название подкатегории должно быть уникальным.';
_fc_props.menu_settings_tracking_device = 'Отслеживающие устройство';
_fc_props.menu_settings_title_tracking_device = 'Отслеживающие устройство';
_fc_props.menu_settings_title_personal_device_registration = 'Регистрация личного устройства';
_fc_props.registration_code = 'Регистрационный код';
_fc_props.creation_date = 'Создание';
_fc_props.expiration_date = 'Срок действия';
_fc_props.trackitadmin_personal_device_registration = 'Регистрация личного устройства';
_fc_props.create_registration_code = 'Добавить регистрационный код';
_fc_props.remove_registration_code = 'Удалить регистрационный код';
_fc_props.add_a_registration_code = 'Добавить регистрационный код';
_fc_props.select_number_of_registration_codes_to_generate = 'Выберите количество регистрационных кодов, которые вы хотите сгенерировать.';
_fc_props.registration_code_count = 'Количество регистрационных кодов';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Вы уверены, что хотите удалить выбранный зарегистрированный код?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Вы уверены, что хотите удалить выбранные зарегистрированные коды?';
_fc_props.add_personal_device = 'Добавить личное устройство';
_fc_props.menu_settings_title_personal_device = 'Персональное устройство';
_fc_props.cloud_dvir__approval_critical_alert = 'Автомобиль имеет критический дефект. Не может быть помечен как безопасный для эксплуатации.';
_fc_props.export_registration_codes = 'Экспортные регистрационные коды';
_fc_props.download_format = 'Скачать формат';
_fc_props.specify_download_format_x = 'Укажите формат загрузки для экспорта регистрационных кодов {0}.';
_fc_props.personal_device_download = 'Скачать';
_fc_props.add_personal_device_input_message = 'Введите идентификатор личного устройства ниже. Срок действия будет автоматически применен после сохранения.';
_fc_props.error_creating_personal_device = 'Ошибка при создании личного устройства.';
_fc_props.device_already_exists = 'Личное устройство уже существует';
_fc_props.export = 'Экспорт';
_fc_props.error_can_not_change_connex_eid = 'Невозможно изменить идентификатор connex, поскольку в настоящее время он используется для основных данных.';
_fc_props.remove_personal_device = 'Удалить личное устройство';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Вы уверены, что хотите удалить выбранное личное устройство?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Вы уверены, что хотите удалить выбранные личные устройства?';
_fc_props.error_deleting_personal_device = 'Ошибка удаления личного устройства.';
_fc_props.customerid = 'Пользовательский ИД';
_fc_props.customername = 'Имя Клиента';
_fc_props.ordernumber = 'Номер заказа';
_fc_props.orderdescription = 'Описание заказа';
_fc_props.locationid = 'Идентификатор местоположения';
_fc_props.locationdescription = 'Описание местоположения';
_fc_props.productid = 'Код товара';
_fc_props.productdescription = 'Описание продукта';
_fc_props.vehicleid = 'Идентификатор автомобиля';
_fc_props.vehicledescription = 'Описание автомобиля';
_fc_props.ticketnumber = 'Билет #';
_fc_props.ticketdate = 'Дата/время билета';
_fc_props.drivername = 'Имя драйвера';
_fc_props.arriveregion = 'Регион прибытия (TIME)';
_fc_props.leaveregion = 'Покинуть регион (TIME)';
_fc_props.regionduration = 'Регион Продолжительность';
_fc_props.menu_reports_title_prevailing_wage = 'Преобладающая заработная плата';
_fc_props.slump_calibration_x_not_found_for_y = 'Калибровка спада "{0}" не найдена для билета {1}';
_fc_props.slump_calibration_not_found = 'Калибровка спада не найдена';
_fc_props.slump_calibration_not_found_description = 'Оповещение, когда калибровка резкости не найдена';
_fc_props.error_getting_personal_device_list = 'Ошибка получения списка личных устройств.';
_fc_props.batch_summary_date_range_note = '*ПРИМЕЧАНИЕ. Этот отчет ограничен 3 днями, если не применены фильтры. Диапазон дат может достигать 31 дня при фильтрации по оборудованию (максимум 10), заводу (максимум 1), продукту (максимум 1) или заданию (максимум 1).';
_fc_props.select_registration_code_expiration_time_days = 'Выберите количество дней до истечения срока действия регистрационных кодов, а затем нажмите «Сохранить».';
_fc_props.add_personal_device_expiration = 'Срок действия (в днях)';
_fc_props.cloud_dvir__vehicle_no = 'Номер транспортного средства';
_fc_props.cloud_dvir__trailer_no = 'Трейлер №';
_fc_props.cloud_dvir__vehicle = 'Транспортное средство';
_fc_props.cloud_dvir__trailer = 'Трейлер';
_fc_props.cloud_dvir__truck_number_label = 'Номер грузовика';
_fc_props.cloud_dvir__trailer_number = 'Номер прицепа';
_fc_props.cloud_dvir__has_trailer = 'Не ездите с прицепом';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Введите здесь номер вашего прицепа';
_fc_props.work_order = 'Заказ на работу';
_fc_props.unable_to_determine_slump = 'Невозможно определить спад';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Определить общее состояние прицепа';
_fc_props.defaultTrailer = 'Трейлер по умолчанию';
_fc_props.trailer_type_column_billable = 'Оплачиваемый';
_fc_props.trailer_type_column_notes = 'Примечания';
_fc_props.trailer_type_column_date = 'Дата';
_fc_props.trailer_type_column_order = 'Заказ';
_fc_props.trailer_type_column_ticket = 'Билет';
_fc_props.trailer_type_column_project = 'Проект';
_fc_props.trailer_type_column_customer = 'Клиент';
_fc_props.trailer_type_column_location = 'Расположение';
_fc_props.trailer_type_column_vehicle_type = 'Тип транспортного средства';
_fc_props.trailer_type_column_product = 'Продукт';
_fc_props.trailer_type_action_rebill = 'Ребилл';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Синхронизация основных данных не удалась, тип оборудования по умолчанию не установлен';
_fc_props.trailer_type = 'Тип прицепа';
_fc_props.trailer_type_is_required = 'Требуется тип прицепа';
_fc_props.trailer_number_already_exists = 'Номер прицепа уже существует';
_fc_props.plate_number_already_exists = 'Номерной знак уже существует';
_fc_props.trailer_vin_already_exists = 'Трейлер Vin уже существует';
_fc_props.trailer_does_not_exist = 'Трейлер не существует';
_fc_props.trailer_number_is_required = 'Требуется номер прицепа';
_fc_props.plate_number_is_required = 'Требуется номерной знак';
_fc_props.plate_number = 'Номерной знак';
_fc_props.menu_settings_title_trailer = 'Трейлер';
_fc_props.add_trailer = 'Добавить трейлер';
_fc_props.edit_trailer = 'Редактировать трейлер';
_fc_props.menu_settings_title_equipment = 'Оборудование';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Игнорировать новые тикеты из Command Cloud старше X минут';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Игнорировать новые тикеты из Command Cloud с датой отправки старше X минут (по умолчанию пусто и отключено)';
_fc_props.edit_load_card = 'Изменить Загрузить Карту';
_fc_props.default_layout = 'Макет по умолчанию';
_fc_props.load_card_empty_fields = 'Ошибка сохранения карты загрузки: необходимо выбрать одно или несколько полей.';
_fc_props.trailers = 'Прицепы';
_fc_props.error_failed_to_save_data = 'Ошибка: не удалось сохранить данные';
_fc_props.slump_increased_plasticizer_added_question = 'Сколько пластификатора было добавлено?';
_fc_props.add_trailer_type = 'Добавить тип прицепа';
_fc_props.menu_settings_title_trailer_type = 'Тип прицепа';
_fc_props.edit_trailer_type = 'Изменить тип трейлера';
_fc_props.single_use_codes = 'Одноразовые коды';
_fc_props.multiple_use_codes = 'Многократные коды использования';
_fc_props.trailer_type_description_is_required = 'Требуется описание типа прицепа';
_fc_props.trailer_saved = 'Трейлер сохранен';
_fc_props.cloud_dvir__trailer_only_dvir = 'Только трейлер DVIR';
_fc_props.choose_file = 'Выбрать файл';
_fc_props.no_file_chosen = 'Файл не выбран';
_fc_props.please_choose_file = 'Пожалуйста, выберите файл';
_fc_props.no_filters = 'Фильтры не выбраны';
_fc_props.remaining_defects = 'Оставшиеся дефекты';
_fc_props.load_more = 'Загрузить больше';
_fc_props.pagination_label = '{0}-{1} из {2}';
_fc_props.extend = 'Продлевать';
_fc_props.cloud_dvir__notes_or_photo_required = 'Требуются либо заметки, либо фотография.';

export default _fc_props;
