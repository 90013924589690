const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Försök igen';
_fc_props.default = 'Standard';
_fc_props.origin = 'Ursprung';
_fc_props.loading = 'Läser in';
_fc_props.list = 'Lista';
_fc_props.send = 'Skicka';
_fc_props.error = 'Fel';
_fc_props.save = 'Spara';
_fc_props.delete = 'Radera';
_fc_props.message = 'Meddelande';
_fc_props.logout = 'Logga ut';
_fc_props.add = 'Lägg till';
_fc_props.remove = 'Ta bort';
_fc_props.done = 'Gjort';
_fc_props.other = 'Övrig';
_fc_props.interval = 'Intervall';
_fc_props.time = 'Tid';
_fc_props.speed = 'Hastighet';
_fc_props.longitude = 'Longitud';
_fc_props.latitude = 'Latitud';
_fc_props.x_minutes = '{0} Min';
_fc_props.general = 'Allmän';
_fc_props.messages = 'Meddelanden';
_fc_props.gps_fixes = 'GPS-fixar';
_fc_props.device_id = 'Enhets-ID';
_fc_props.vehicle = 'Fordon';
_fc_props.employee = 'Anställd';
_fc_props.version = 'Version';
_fc_props.statuses = 'Statuser';
_fc_props.edit = 'Redigera';
_fc_props.cancel = 'Avbryt';
_fc_props.yes = 'Ja';
_fc_props.none = 'Ingen';
_fc_props.never = 'Aldrig';
_fc_props.name = 'namn';
_fc_props.true = 'Sann';
_fc_props.status = 'Status';
_fc_props.distance = 'Distans';
_fc_props.ok = 'Ok';
_fc_props.login = 'Logga in';
_fc_props.next = 'Nästa';
_fc_props.menu = 'Meny';
_fc_props.not_available = 'N/A';
_fc_props.back = 'Tillbaka';
_fc_props.select = 'Välj';
_fc_props.dispatch = 'Avsändande';
_fc_props.messaging = 'Meddelanden';
_fc_props.plants = 'Växter';
_fc_props.last_plants = 'Sista växterna';
_fc_props.vehicle_types = 'Fordonstyper';
_fc_props.employee_types = 'Typer av anställda';
_fc_props.employee_groups = 'Anställda grupper';
_fc_props.equipment_groups = 'Utrustningsgrupper';
_fc_props.job = 'Jobb';
_fc_props.order = 'Beställa';
_fc_props.ticket = 'Biljett #';
_fc_props.small = 'Små';
_fc_props.medium = 'Medium';
_fc_props.large = 'Stor';
_fc_props.reports = 'Rapporter';
_fc_props.payroll = 'Löner';
_fc_props.support = 'Stöd';
_fc_props.username = 'Användarnamn';
_fc_props.all = 'Allt';
_fc_props.question = 'Fråga';
_fc_props.select_employee = 'Välj Anställd';
_fc_props.phone = 'Telefon';
_fc_props.january = 'januari';
_fc_props.february = 'februari';
_fc_props.march = 'Mars';
_fc_props.april = 'april';
_fc_props.may = 'Maj';
_fc_props.june = 'juni';
_fc_props.july = 'juli';
_fc_props.august = 'augusti';
_fc_props.september = 'september';
_fc_props.october = 'oktober';
_fc_props.november = 'november';
_fc_props.december = 'december';
_fc_props.are_you_sure_you_want_to_delete = 'Är du säker att du vill radera';
_fc_props.unknown = 'Okänd';
_fc_props.select_all = 'Välj alla';
_fc_props.equipment = 'Utrustning';
_fc_props.region = 'Område';
_fc_props.no_data = 'Inga data';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Adress';
_fc_props.retrieving_address = 'Hämtar adress...';
_fc_props.address_not_found = 'Adressen hittades inte';
_fc_props.active_time = 'Aktiv tid';
_fc_props.destination_time = 'Destinationstid';
_fc_props.complete_time = 'Fullständig tid';
_fc_props.status_report = 'Status rapport';
_fc_props.export_report = 'Exportera rapport';
_fc_props.download_report = 'Ladda ner rapport';
_fc_props.view_report = 'Visa rapport';
_fc_props.duration = 'Varaktighet';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Startdatum/tid';
_fc_props.select_start_date = 'Välj Startdatum';
_fc_props.end_date_time = 'Slutdatum/tid';
_fc_props.select_end_date = 'Välj Slutdatum';
_fc_props.trip_time = 'Restid';
_fc_props.travel_time = 'Restid';
_fc_props.description = 'Beskrivning';
_fc_props.map_controls = 'Kartkontroller';
_fc_props.road = 'Väg';
_fc_props.aerial = 'Antenn';
_fc_props.hybrid = 'Hybrid';
_fc_props.trip = 'Resa';
_fc_props.map = 'Karta';
_fc_props.plant = 'Växt';
_fc_props.select_date = 'Välj Datum';
_fc_props.apply = 'Tillämpa';
_fc_props.save_settings = 'Spara inställningar';
_fc_props.false = 'Falsk';
_fc_props.delete_confirmation = 'Ta bort bekräftelse';
_fc_props.last_name = 'Efternamn';
_fc_props.none_selected = 'Ingen vald';
_fc_props.start_date = 'Start datum';
_fc_props.group_by = 'Grupp av';
_fc_props.equipment_employee = 'Utrustning / Anställd';
_fc_props.inactive = 'Inaktiv';
_fc_props.active = 'Aktiva';
_fc_props.note_checking_may_affect_performance = 'Obs: Kontroll kan påverka prestandan';
_fc_props.show_distancefuel_used = 'Visa avstånd/använt bränsle';
_fc_props.mins = 'Min';
_fc_props.threshold_time = 'Tröskeltid';
_fc_props.include_voided_tickets = 'Inkludera ogiltigförklarade biljetter';
_fc_props.note_all_times_listed_are_in_minutes = 'Obs: Alla tider som anges är i minuter';
_fc_props.please_select_a_job_order_or_equipment = 'Välj ett jobb, order, anläggning, utrustning eller anställd';
_fc_props.device = 'Enhet';
_fc_props.select_equipment = 'Välj Utrustning';
_fc_props.all_equipment_types = 'Alla utrustningstyper';
_fc_props.units = {};
_fc_props.units.liters = 'Liter';
_fc_props.type = 'Typ';
_fc_props.no_data_available = 'Ingen data tillgänglig';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Avstånd och bränsle efter region';
_fc_props.menu_settings_title_settings = 'inställningar';
_fc_props.menu_settings_title_alerts = 'Varningar';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Utrustning';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspots och regioner';
_fc_props.days = '{0} dag(ar)';
_fc_props.home = 'Hem';
_fc_props.find = 'Hitta';
_fc_props.close_this_panel = 'stäng den här panelen';
_fc_props.filters = 'Filter';
_fc_props.start = 'Start';
_fc_props.end = 'Slutet';
_fc_props.location_details = 'Platsinformation';
_fc_props.first_name = 'Förnamn';
_fc_props.material_per_hour = 'Material per timme';
_fc_props.equipment_pound = 'Utrustning #';
_fc_props.equipment_type = 'utrustnings typ';
_fc_props.options = 'alternativ';
_fc_props.zoom_on_cursor_position = 'Zooma på markörposition';
_fc_props.display_equipment_identifier = 'Visa utrustningsidentifierare';
_fc_props.view_equipment = 'Visa utrustning';
_fc_props.view_hotspots = 'Visa hotspots';
_fc_props.view_regions = 'Visa regioner';
_fc_props.use_standard_filters = 'Använd standardfilter';
_fc_props.home_plant = 'Hemmaväxt';
_fc_props.last_plant = 'Sista växten';
_fc_props.equip_types = 'Utrusta. Typer';
_fc_props.equip_groups = 'Utrusta. Grupper';
_fc_props.orders = 'Order';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Använd utrustningsfilter';
_fc_props.all_selected = 'Alla valda';
_fc_props.of = 'Av';
_fc_props.your_map_session_has_expired = 'Din kartsession har löpt ut. Uppdatera sidan.';
_fc_props.equipment_short = 'Utrusta.';
_fc_props.job_info_for = 'Jobbinfo för';
_fc_props.order_info_for = 'Beställningsinfo för';
_fc_props.ticket_info_for_vehicle = 'Biljettinformation för fordon';
_fc_props.inbox = 'Inkorg';
_fc_props.outbox = 'Utkorg';
_fc_props.alerts = 'Varningar';
_fc_props.critical = 'Kritisk';
_fc_props.important = 'Viktig';
_fc_props.informational = 'Informationsinformation';
_fc_props.view_routes = 'Visa rutter';
_fc_props.hours_size = 'Timmar Storlek';
_fc_props.used_count = 'Begagnad Count';
_fc_props.last_login = 'Senaste inloggning';
_fc_props.last_vehicle = 'Sista fordonet';
_fc_props.regions = 'Regioner';
_fc_props.driver = 'Förare';
_fc_props.js_delete = 'Radera';
_fc_props.move = 'Flytta';
_fc_props.edit_hotspot = 'Redigera Hotspot';
_fc_props.adjust_shape = 'Justera formen';
_fc_props.edit_region = 'Redigera region';
_fc_props.enter_a_start_address = 'Vänligen ange en startadress';
_fc_props.select_a_start_hotspot = 'Välj en starthotspot';
_fc_props.failed_to_find_start_hotspot = 'Det gick inte att hitta starthotspot.';
_fc_props.invalid_latitudelongitude = 'Ogiltig latitud/longitud';
_fc_props.enter_an_end_address = 'Vänligen ange en slutadress';
_fc_props.select_an_end_hotspot = 'Vänligen välj en slut-hotspot';
_fc_props.failed_to_find_end_hotspot = 'Det gick inte att hitta slutet av hotspot.';
_fc_props.print = 'Skriva ut';
_fc_props.these_directions_are_for_informational_purposes = 'Dessa anvisningar är endast i informationssyfte. Ingen garanti lämnas angående deras fullständighet eller riktighet. Byggprojekt, trafik eller andra händelser kan göra att de faktiska förhållandena skiljer sig från dessa resultat.';
_fc_props.enter_an_address = 'Vänligen ange en adress';
_fc_props.select_a_hotspot = 'Välj en hotspot';
_fc_props.failed_to_find_hotspot = 'Det gick inte att hitta Hotspot.';
_fc_props.select_a_vehicle = 'Välj ett fordon';
_fc_props.failed_to_find_vehicle = 'Det gick inte att hitta fordonet.';
_fc_props.failed_to_find_address = 'Det gick inte att hitta adressen';
_fc_props.failed_to_find_vehicles = 'Det gick inte att hitta fordon i sökkriterierna.';
_fc_props.get_address = 'Få adress';
_fc_props.results_for = 'resultat för';
_fc_props.error_retrieving_results = 'Fel vid hämtning av resultat';
_fc_props.no_results_found = 'Inga resultat funna';
_fc_props.address_is_required = 'Adress krävs';
_fc_props.city_is_required = 'Stad krävs';
_fc_props.set_location = 'Ställ in plats';
_fc_props.set_area_of_interest = 'Ange intresseområde';
_fc_props.center_map_here = 'Centrera kartan här';
_fc_props.zoom_to_street_level = 'Zooma till gatunivå';
_fc_props.set_start = 'Ställ in Start';
_fc_props.set_end = 'Ställ in Slut';
_fc_props.delete_node = 'Radera knutpunkt';
_fc_props.create_a_hotspot = 'Skapa en hotspot';
_fc_props.create_a_region = 'Skapa en region';
_fc_props.toggle_shapes = 'Växla former';
_fc_props.refresh_map = 'Uppdatera karta';
_fc_props.more_options = 'Fler alternativ';
_fc_props.recenter_map = 'Omcentrera karta';
_fc_props.zoom_to_vehicle = 'Zooma till fordon';
_fc_props.apply_filters = 'Använd filter';
_fc_props.grid_view = 'Rutnätsvy';
_fc_props.list_view = 'Listvy';
_fc_props.click_to_sort = 'Klicka för att sortera';
_fc_props.message_history = 'Meddelandehistorik';
_fc_props.equipment_types = 'Utrustningstyper';
_fc_props.send_message = 'Skicka meddelande';
_fc_props.last_load = 'Sista lasten';
_fc_props.last_load_on = 'Senaste laddning på';
_fc_props.manual_logout = 'Manuell utloggning';
_fc_props.manual_login = 'Manuell inloggning';
_fc_props.no_employees_logged_in = 'Inga anställda inloggade';
_fc_props.select_phone = 'Välj Telefon';
_fc_props.you_must_select_a_phone = 'Du måste välja en telefon, utrustning och anställd';
_fc_props.are_you_sure_you_want_to_logout = 'Är du säker på att du vill logga ut';
_fc_props.fix_time = 'Fixa tid';
_fc_props.to_next_fix = 'till nästa korrigering';
_fc_props.minutes_to_next_fix = 'Minuter till nästa fix';
_fc_props.liters = 'Liter';
_fc_props.destination = 'Destination';
_fc_props.return = 'Lämna tillbaka';
_fc_props.product = 'Produkt';
_fc_props.no_records_found = 'Inga uppgifter funna.';
_fc_props.vehicle_type = 'fordonstyp';
_fc_props.view_in_map = 'Visa allt på kartan';
_fc_props.uploads = 'Uppladdningar';
_fc_props.upload_csv_file = 'Ladda upp CSV-fil';
_fc_props.permanent = 'Permanent';
_fc_props.expire_time = 'sista förbrukningsdag';
_fc_props.please_select_x_for_tickets_marked_active = 'Välj {0} för biljetter märkta AKTIVA';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Flera biljetter märkta AKTIVA för samma {0}';
_fc_props.failure_connecting_to_servlet = 'Det gick inte att ansluta till servlet.';
_fc_props.status_time_summary = 'Status Tidssammanfattning';
_fc_props.view_in_map_one = 'Visa i karta';
_fc_props.question_report = 'Frågerapport';
_fc_props.end_date = 'Slutdatum';
_fc_props.trailer_number = 'Trailernummer';
_fc_props.region_panel = 'Regionpanel';
_fc_props.hotspot_panel = 'Hotspot-panel';
_fc_props.shape = 'Form';
_fc_props.circle = 'Cirkel';
_fc_props.polygon = 'Polygon';
_fc_props.click_the_map_to_set_the_location = 'Klicka på kartan för att ange önskad plats.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Klicka på kartan för att ställa in centrum för hotspot.';
_fc_props.click_the_map_to_set_the_region_center = 'Klicka på kartan för att ställa in regioncentrum.';
_fc_props.click_and_drag_points = 'Klicka och dra punkter för att redigera formen. Klicka på SPARA för att spara ändringarna.';
_fc_props.size = 'Storlek';
_fc_props.are_you_sure_you_want_to_move_this = 'Är du säker på att du vill flytta detta';
_fc_props.are_you_sure_you_want_to_delete_this = 'Är du säker på att du vill ta bort detta';
_fc_props.please_enter_a_valid_size = 'Ange en giltig storlek';
_fc_props.failed_to_save = 'Det gick inte att spara.';
_fc_props.failed_to_move = 'Det gick inte att flytta.';
_fc_props.failed_to_delete = 'Det gick inte att radera.';
_fc_props.x_hours = '{0} timmar';
_fc_props.hide_hotspots = 'Göm hotspots';
_fc_props.show_regions = 'Visa regioner';
_fc_props.show_hotspots = 'Visa hotspots';
_fc_props.hide_regions = 'Göm regioner';
_fc_props.numeric = 'Numerisk';
_fc_props.message_type = 'Meddelandetyp';
_fc_props.text = 'Text';
_fc_props.yes_no = 'Ja Nej';
_fc_props.responses = 'Svar';
_fc_props.disabled = 'Inaktiverad';
_fc_props.group_name = 'Grupp namn';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Är du säker på att du vill ta bort "{0}"-formatet?';
_fc_props.phone_number = 'Telefonnummer';
_fc_props.interface_pound = 'Gränssnitt #';
_fc_props.field = 'Fält';
_fc_props.default_label = 'Standardetikett';
_fc_props.custom_label = 'Anpassad etikett';
_fc_props.you_must_enter_a_label = 'Du måste ange en etikett.';
_fc_props.enabled = 'Aktiverad';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Biljettintegrering';
_fc_props.settings.geofence_off_open_hotspots = 'Geofence off öppna Hotspots';
_fc_props.settings.associate_open_hotspots_wticket = 'Associera öppna hotspots med biljett';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Tillåt geofencing av öppna hotspots eller endast hotspots associerade med Driver\'s Ticket';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Huruvida öppna hotspots ska associeras med biljetter när ett fordon kommer in i dem';
_fc_props.settings.void_ticket_without_destination = 'Ogiltig biljett utan destination';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'om en biljett tas emot medan en annan är aktiv, om den aktiva biljetten inte har en destination kommer biljetten att annulleras.';
_fc_props.settings.autocreate_hotspots = 'Skapa hotspots automatiskt';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Huruvida automatiskt skapa hotspots från destinationsadressen när en biljett tas emot';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Autoaktivera biljett vid mottagning';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Är biljetten aktiv när den tas emot av TrackIt (Standard "true")';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Stäng automatiskt aktiv biljett vid mottagningsprotokoll';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Hur länge måste den aktuella aktiva biljetten ha varit aktiv innan den automatiskt stängs (standard \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Ticket Max Aktivera Ålder Minuter';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Hur gammal i minuter kan en biljett vara när du aktiverar den i TrackIt (Standard \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Max Hotspot oanvända dagar';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Ställer in de maxdagar som en hotspot kan vara oanvänd och fortfarande återupplivas';
_fc_props.settings.radius_multiplier = 'Radiemultiplikator';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Vad ska man multiplicera radien med när en biljett tas emot från leveranssystemet';
_fc_props.settings.ticket_message_date_format = 'Datumformat för biljettmeddelande';
_fc_props.am = 'am';
_fc_props.pm = 'kl';
_fc_props.settings.ticket_message = 'Biljettmeddelande';
_fc_props.settings.add_ticket_element = 'Lägg till biljettelement';
_fc_props.driver_performance = 'Drivrutinsprestanda';
_fc_props.update_failed_can_not_connect = 'Uppdatering misslyckades: Kan inte ansluta till servlet.';
_fc_props.unknown_error = 'Okänt fel';
_fc_props.selected = 'Vald';
_fc_props.web_service = 'Webb-service';
_fc_props.error_processing_request = 'Fel vid behandling av begäran.';
_fc_props.email_address = 'E-postadress';
_fc_props.id = 'ID';
_fc_props.last_used = 'Senast använd';
_fc_props.error_invalid_vehiclenum = 'Fel Ogiltigt fordonsnummer';
_fc_props.error_vehicle_is_not_logged_in = 'Fel: Fordonet är inte inloggat.';
_fc_props.users = 'Användare';
_fc_props.you_dont_have_access_to_this_page = 'Du har inte tillgång till denna sida. Kontakta din systemadministratör för åtkomst.';
_fc_props.previous_week = 'Förra veckan';
_fc_props.status_breakdown = 'Statusuppdelning';
_fc_props.select_plant_or_employee_group = 'Du måste välja antingen en anläggning eller en personalgrupp.';
_fc_props.cannot_select_plant_and_employee_group = 'Du kan inte välja både en anläggning och en personalgrupp.';
_fc_props.odometer_must_be_a_number = 'Startvägmätaren måste vara en siffra';
_fc_props.ending_odometer_must_be_a_number = 'Avslutande vägmätare måste vara ett nummer';
_fc_props.beginning_hour_meter_must_be_a_number = 'Beginning Hour Meter måste vara en siffra';
_fc_props.ending_hour_meter_must_be_a_number = 'Sluttimräknaren måste vara ett nummer';
_fc_props.error_loading_status_change_page = 'Det gick inte att läsa in sidan för statusändring.';
_fc_props.x_is_an_invalid_time_format = '{0} är ett ogiltigt tidsformat, det måste vara ÅÅÅÅ-MM-DD TT:MM:SS';
_fc_props.x_for_schedule_login = '{0} för schemalagd inloggning';
_fc_props.x_for_schedule_logout = '{0} för schemalagd utloggning';
_fc_props.x_for_actual_login = '{0} för faktisk inloggning';
_fc_props.x_for_actual_logout = '{0} för faktisk utloggning';
_fc_props.by_refreshing_the_time_card_detail = 'Genom att uppdatera Time Card Detail kommer du att förlora alla ändringar, fortsätt?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Är du säker på att du vill ta bort {0}?';
_fc_props.settings.command_mobile_ticket_url = 'Command Mobile Ticket URL';
_fc_props.year = 'År';
_fc_props.make = 'Göra';
_fc_props.model = 'Modell';
_fc_props.settings.command_mobile_ticket_password = 'Command Mobile Ticket Lösenord';
_fc_props.settings.command_mobile_ticket_auth_token = 'Command Mobile Ticket Auth Token';
_fc_props.target = 'Mål';
_fc_props.number_of_digits = 'Antal siffror';
_fc_props.number_of_integer_digits = 'Antal heltalssiffror';
_fc_props.number_of_fractional_digits = 'Antal bråksiffror';
_fc_props.search = 'Sök';
_fc_props.settings.command_mobile_ticket_date_order = 'Command Mobile Ticket Date Order';
_fc_props.settings.command_mobile_ticket_date_length = 'Command Mobile Ticket Datum Längd';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command Mobile Ticket Date Separator';
_fc_props.settings.command_mobile_ticket_slash = '/ (snedstreck)';
_fc_props.settings.command_mobile_ticket_dash = '- (streck)';
_fc_props.settings.command_mobile_ticket_period = '. (period)';
_fc_props.settings.command_mobile_ticket_space = '(Plats)';
_fc_props.settings.command_mobile_ticket_time_length = 'Command Mobile Ticket Tidslängd';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command Mobile Ticket Time Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (kolon)';
_fc_props.settings.command_mobile_ticket_time_format = 'Command Mobile Ticket Time Format';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Använd gränssnittsnummer';
_fc_props.mechanic = 'Mekaniker';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Omröstningsintervall (sekunder)';
_fc_props.driving_time = 'Körtid';
_fc_props.find_near_plants = 'Hitta nära växter';
_fc_props.please_create_hot_spot_first = 'Vänligen skapa hotspot först';
_fc_props.please_enter_hot_spot_description = 'Vänligen ange hot spot-beskrivning';
_fc_props.please_select_a_hot_spot_type = 'Välj en typ av hotspot';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Skicka förarens e-post';
_fc_props.directions_not_found = 'Ogiltig {0}-adress. Vägbeskrivning hittades inte.';
_fc_props.ws_user = 'Användare';
_fc_props.ws_service = 'Service';
_fc_props.ws_method = 'Metod';
_fc_props.ws_params = 'Params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Ursprung och destination';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Hjälp';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Använd Alt-anställdsnummer';
_fc_props.settings.command_mobile_ticket_android_url = 'Android Mobile Ticket URL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Ticket Secret';
_fc_props.employee_x_has_no_logout_on_y = 'Anställd {0} har ingen logga ut för ett skift som börjar den {1}.';
_fc_props.phone_number_has_duplicates = 'Telefonnummer har dubbla poster';
_fc_props.ounce = 'Uns';
_fc_props.water_extra = 'Vatten Extra';
_fc_props.always = 'Alltid';
_fc_props.work_types_must_be_unique = 'Arbetstyper måste vara unika';
_fc_props.manufacturer = 'Tillverkare';
_fc_props.need_to_enter_seconds = 'Behöver ange sekunder.';
_fc_props.copy = 'Kopiera';
_fc_props.create_in_existing_required = 'Skapa i befintlig måste väljas för att skapa roaming-hotspot.';
_fc_props.break = 'Ha sönder';
_fc_props.add_export_data_type = 'Lägg till exportdatatyp';
_fc_props.edit_export_data_type = 'Redigera exportdatatyp';
_fc_props.delete_export_data_type = 'Ta bort exportdatatyp';
_fc_props.export_data_type = 'Exportera datatyp';
_fc_props.error_updating_export_data_type = 'Fel vid uppdatering av exportdatatyp';
_fc_props.settings.address_key = 'Adressnyckel';
_fc_props.settings.address_key_hover = 'Adressnyckelalternativ';
_fc_props.settings.address_key_options = 'Adressnyckelalternativ';
_fc_props.more_filter_options = 'Fler filteralternativ';
_fc_props.unauthorized_mapit_app = 'Denna version av MapIt är inte auktoriserad, uppdatera MapIt så snart som möjligt.';
_fc_props.zello_timeout = 'Timeout inträffade vid kontakt med Zello.';
_fc_props.user_agreement_text = 'Genom att logga in godkänner du';
_fc_props.user_agreement = 'Användaravtal';
_fc_props.upload = 'Ladda upp';
_fc_props.x_mins_left = '{0} min(er) kvar';
_fc_props.continuous_location_tracking_colon = 'Kontinuerlig platsspårning:';
_fc_props.hired_hauler_cannot_register_device = 'Hired Hauler kan inte registrera ny enhet.';
_fc_props.timecard_does_not_contain_shift = 'Anställdas tidskort innehåller inte det skift (tidkortsinformation) som tillhandahålls';
_fc_props.timecard_not_found = 'Anställdas tidskort hittades inte';
_fc_props.vehicle_must_be_assigned_for_clt = 'Fordonet måste tilldelas spårplatser.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Fordonet har inte tilldelats kontinuerlig spårning kommer att inaktiveras.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Förlorade kontakten med EDC. Kontrollera att ditt WiFi och/eller Bluetooth är aktiverat.';
_fc_props.acknowledge_all = 'Erkänn alla';
_fc_props.expand = 'Bygga ut';
_fc_props.collapse = 'Kollaps';
_fc_props.expand_all = 'Expandera alla';
_fc_props.collapse_all = 'Kollapsa alla';
_fc_props.last_update = 'Senaste uppdateringen';
_fc_props.acknowledge_alert = 'Bekräfta varning';
_fc_props.acknowledge_message = 'Bekräfta meddelande';
_fc_props.acknowledge_all_alerts = 'Bekräfta alla varningar';
_fc_props.acknowledge_all_messages = 'Bekräfta alla meddelanden';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Toleransen måste vara ett giltigt heltal mellan -99 och 99 (båda inkluderade)';
_fc_props.gps_permission_required = 'GPS-tillstånd krävs';
_fc_props.permissions_required = 'Behörigheter krävs';
_fc_props.change_user = 'Växla användare';
_fc_props.account_not_registered_with_carrier = 'Detta konto är inte registrerat hos en operatör';
_fc_props.slump_loss = 'Slumpförlust';
_fc_props.slump_loss_help = 'Slump Loss Hjälp';
_fc_props.did_not_receive_any_edc_devices = 'Fick inga EDC-enheter.';
_fc_props.edc_record_not_found = 'EDC-posten hittades inte';
_fc_props.disable_hotspot_prompt = 'Vänligen tryck på "OK" när du uppmanas att inaktivera din hotspot.';
_fc_props.edc_device_is_in_use = 'EDC {0} används.';
_fc_props.an_unknown_exception_has_occurred = 'Ett okänt undantag har inträffat';
_fc_props.login_has_timed_out = 'Inloggningen har gått ut';
_fc_props.use_paper_logs = 'Använd pappersloggar';
_fc_props.eld_login_failed = 'Inloggning på timmar av tjänst misslyckades';
_fc_props.setup_time_off_type = 'Vänligen ställ in en ledighetstyp.';
_fc_props.view_load_properties = 'Visa lastegenskaper';
_fc_props.couldnt_download_edc_firmware = 'Det gick inte att ladda ner EDC-firmware.';
_fc_props.password_incorrect = 'Inkorrekt lösenord';
_fc_props.edc_configuration_password_prompt = 'På grund av enhetens tillstånd måste du ange installationslösenordet för att konfigurera.';
_fc_props.edc_configuration_check_failed = 'Validering av EDC-serverposter misslyckades. Kontrollera felmeddelanden för detaljer.';
_fc_props.configuration_issues_prompt = 'Följande problem upptäcktes vid konfigurering av vald EDC för den angivna lastbilen';
_fc_props.checking_password = 'Kontrollerar lösenord';
_fc_props.reset_password_instructions_sent = 'Instruktioner för att återställa ditt lösenord har skickats till e-postadressen {0}.';
_fc_props.reset_password_failed = 'Det gick inte att återställa lösenordet, försök igen.';
_fc_props.forgot_password = 'Glömt ditt lösenord?';
_fc_props.enter_valid_email = 'Ogiltig e-postadress. Ange en giltig e-postadress och försök igen.';
_fc_props.issues_while_updating_edc_record = 'EDC:n konfigurerades framgångsrikt. Det finns dock problem med posterna för EDC på TrackIt-servern som kan göra att den inte fungerar. Ring supporten för att rätta informationen.';
_fc_props.sending = 'Sändning...';
_fc_props.minimum_ratio = 'Minsta förhållande';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Drum RPM inte inom räckvidd';
_fc_props.probe_failure = 'Sondfel';
_fc_props.unknown_probe_data_failure = 'Okänd sonddatafel';
_fc_props.no_loaded_status_meaning = 'Ingen laddad status';
_fc_props.no_depart_status_meaning = 'Ingen avgångsstatus';
_fc_props.no_arrive_status_meaning = 'Ingen ankomststatus';
_fc_props.no_begin_work_status_meaning = 'Ingen Start Work Status';
_fc_props.no_fully_mixed_status_meaning = 'Ingen helt blandad status';
_fc_props.no_begin_work_or_at_job_status = 'Statusen Börja arbete eller Vid jobb finns inte';
_fc_props.driver_didnt_follow_procedure = 'Föraren följde inte proceduren (sänker inte trumman)';
_fc_props.probe_malfunction = 'Sondfel (internt fel, etc.)';
_fc_props.no_data_from_probe = 'Inga data från sonden (dataförlust)';
_fc_props.missing_probe_calibration = 'Inga sondkalibreringsdata';
_fc_props.probe_pressure_not_in_range = 'Sondtrycket är inte inom området';
_fc_props.arrival = 'Ankomst';
_fc_props.small_load = 'LITEN LAST';
_fc_props.dry_slump = 'TORRA SLUMP';
_fc_props.probe_remixing_turns = 'Remixning';
_fc_props.turns_needed_to_remix = 'Vatten tillsatt - Ytterligare blandning behövs';
_fc_props.probe_remixing_finished = 'Remixen avslutad';
_fc_props.probe_battery_low_alert = 'Varning för lågt batteri på sond';
_fc_props.probe_battery_low_message = 'Sondbatteri lågt';
_fc_props.probe_battery_low = 'Sondbatteri lågt';
_fc_props.alert_when_probe_battery_low = 'Varning när sondbatteriet är lågt';
_fc_props.probe_sensor_fatal_failure = 'Dödligt fel på sensorn';
_fc_props.probe_thermistor_failure = 'Termistorfel';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensor fast i betong';
_fc_props.probe_sensor_over_weight = 'Sensor över vikt';
_fc_props.probe_batteries_low = 'Svagt batteri';
_fc_props.no_probe_data = 'Inga sonddata:';
_fc_props.check_probe = 'Kontrollera sonden';
_fc_props.service_probe = 'Service Sond';
_fc_props.below_x = 'Under {0}';
_fc_props.above_x = 'Över {0}';
_fc_props.probe_serial_number_invalid_format = 'Probens serienummer är inte i giltigt format som visas i platshållaren för fältet';
_fc_props.talk_dnd_permission_prompt = 'Talk kräver STÖR INTE-tillstånd.';
_fc_props.max = 'Max';
_fc_props.status_changed_quickly = 'Status ändrades för snabbt';
_fc_props.is_empty = 'Är tom';
_fc_props.slump_increased_plasticizer_question = 'Tillsattes mjukgörare?';
_fc_props.slump_increased_water_added_question = 'Hur mycket ouppmätt vatten tillsattes?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Sond- och leveranscykelövervakning stöds inte tillsammans på samma enhet.';
_fc_props.no_data_from_sensor = 'Ingen data från sensorn (dataförlust)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Systemtyp';
_fc_props.magnet_count = 'Magneträkning';
_fc_props.sensors_setup = 'Inställning av sensorer';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM kräver att I/O-boxens MAC-adress, trumtyp, DCM-typ och magnetantal ställs in.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Biljettvolym';
_fc_props.drs_current_volume = 'Aktuell volym';
_fc_props.drs_current_water_cement_ratio = 'Nuvarande vatten/cementförhållande';
_fc_props.drs_target_water_cement_ratio = 'Målförhållande vatten/cement';
_fc_props.drs_total_revolutions = 'Total';
_fc_props.drs_current_volume_lp_small = 'Nuvarande';
_fc_props.drum_revolutions = 'Drum Revolutions';
_fc_props.drs_total_revolutions_lp_small = 'Total';
_fc_props.drs_charging_revolutions_lp_small = 'Laddar';
_fc_props.drs_discharging_revolutions_lp_small = 'Urladdning';
_fc_props.drs_target_wc_lp_small = 'Mål W/C';
_fc_props.drs_current_wc_lp_small = 'Nuvarande W/C';
_fc_props.drs_ticketed_volume_lp_small = 'Biljettsedd';
_fc_props.android_location_disclosure = 'TrackIt använder fin och grov plats för att samla in platsdata i bakgrunden även när den inte används, för att möjliggöra automatisk status och kartsynlighet i realtid för sändning.';
_fc_props.android_location_usage = 'Platsupplysning';
_fc_props.end_pour_trigger_threshold = 'Avsluta hälltriggertröskel';
_fc_props.drs_wiring_error = 'Ledningsfel';
_fc_props.drs_wiring_pulse_error = 'Ledningspulsfel';
_fc_props.drs_wiring_magnet_error = 'Kabelmagnetfel';
_fc_props.drs_magnet_missing_error = 'Magnet saknas fel';
_fc_props.android_location_disclosure_3p = 'TrackIt samlar in platsdata i bakgrunden för att möjliggöra automatisk status och kartsynlighet i realtid för utskick. När den har klockats ut från avsändaren delas inte platsen.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Välj antingen Full MDM eller Trackit Remote Support';
_fc_props.move_up = 'Flytta upp';
_fc_props.move_down = 'Flytta ner';
_fc_props.driver_not_found = 'Drivrutinen hittades inte.';
_fc_props.multiple = 'Flera olika';
_fc_props.water_meter_installed = 'Vattenmätare installerad';
_fc_props.delete_waypoint = 'Ta bort waypoint';
_fc_props.wash_sensor_installed = 'Tvättsensor installerad';
_fc_props.washing_start_time = 'Tvättstarttid';
_fc_props.end_washing = 'Sluta tvätta';
_fc_props.variable_rpms = 'Var RPM';
_fc_props.no_slump = 'Ingen svacka';
_fc_props.end_washing_duration = 'Slut på tvätttid';
_fc_props.privacy_policy = 'Integritetspolicy';
_fc_props.privacy_policy_text = 'Jag har läst och accepterar';
_fc_props.login_fail_accept_privacy_policy = 'Sekretesspolicy måste godkännas för att fortsätta.';
_fc_props.rotation_sensor = 'Rotationssensor';
_fc_props.pto_sensor = 'PTO Sensor';
_fc_props.pto_sensor_installed = 'Kraftuttagsgivare installerad';
_fc_props.polarity_reversed = 'Omvänd polaritet';
_fc_props.pto_sensor_type = 'Typ av kraftuttagsgivare';
_fc_props.sensor_type = 'Sensortyp';
_fc_props.prompt_select_pto_sensor_type = 'Du måste välja en "PTO-sensortyp" om du har valt en "PTO-sensor" som din huvudsensortyp.';
_fc_props.priming_turns_at_full_load = 'Primingsvarv vid full belastning';
_fc_props.priming_turns_at_ten_percent_load = 'Primingsvarv vid 10 % belastning';
_fc_props.weight_units = 'Viktenheter';
_fc_props.settings.send_status = 'Skicka status/plats till Event Bridge';
_fc_props.volume_difference = 'Volymskillnad';
_fc_props.mobile_ticket_iframe_url = 'Mobile Ticket IFrame URL';
_fc_props.mobile_ticket_user = 'Mobilbiljettanvändare';
_fc_props.mobile_ticket_password = 'Lösenord för mobilbiljett';
_fc_props.current_truck_status = 'Aktuell lastbilsstatus';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Utlöst av maxvarv';
_fc_props.alerts_by_type = 'Varningar per typ';
_fc_props.water_flow_meter = 'Vattenflödesmätare';
_fc_props.flow_meter_no_pulses_warning = 'Inga pulser - Varning';
_fc_props.flow_meter_no_pulses_critical = 'Inga pulser - Kritisk';
_fc_props.flow_meter_excessive_pulses = 'Överdrivna pulser';
_fc_props.flow_meter_constantly_flowing = 'Ständigt flytande';
_fc_props.flow_meter_good = 'Bra';
_fc_props.whats_new = 'Vad är nytt';
_fc_props.whats_new_widget_text = 'Visste du att vi uppdaterar TrackIt minst en gång i månaden? Få de senaste uppdateringarna och tipsen.';
_fc_props.get_the_scoop = 'Skaffa Scoop';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Affärsanalys som förbättrar ditt resultat, ingår nu i TrackIt';
_fc_props.find_out_more = 'Få reda på mer';
_fc_props.daily_breakdown = 'Daglig uppdelning';
_fc_props.question_summary = 'Sammanfattning av frågor';
_fc_props.trip_summary = 'Resesammanfattning';
_fc_props.manage_employees = 'Hantera anställda';
_fc_props.manage_hotspots = 'Hantera hotspots';
_fc_props.manage_devices = 'Hantera enheter';
_fc_props.manage_equipment = 'Hantera utrustning';
_fc_props.manage_users = 'Hantera användare';
_fc_props.manage_statuses = 'Hantera statusar';
_fc_props.manage_alerts = 'Hantera varningar';
_fc_props.popular_report_links = 'Populära rapportlänkar';
_fc_props.popular_settings_links = 'Populära inställningslänkar';
_fc_props.sidemenu_settings = 'inställningar';
_fc_props.loads_per_driver = 'Laster per förare';
_fc_props.please_select_application = 'Välj minst en applikation';
_fc_props.external_app_processing = 'Bearbetning på extern app';
_fc_props.external_app_sent = 'Skickat till extern app';
_fc_props.external_app_received = 'Mottaget av extern app';
_fc_props.wait_time_at_job = 'Väntetid på jobbet';
_fc_props.wait_time_at_plant = 'Väntetid på anläggningen';
_fc_props.loading_time = 'Laddtid';
_fc_props.unloading_time = 'Avlastningstid';
_fc_props.pre_post_trip = 'Före/efter resa';
_fc_props.update_thresholds = 'Uppdatera trösklar';
_fc_props.green = 'Grön';
_fc_props.yellow = 'Gul';
_fc_props.red = 'Röd';
_fc_props.between = 'Mellan';
_fc_props.num_of_loads = 'Antal laster';
_fc_props.num_of_drivers = 'Antal förare';
_fc_props.status_meaning_not_setup = 'Status Betydelse ej inställd';
_fc_props.or_less = 'Eller mindre';
_fc_props.or_greater = 'Eller Större';
_fc_props.probe_self_diagnostic_state = 'Självdiagnostiskt tillstånd för sond';
_fc_props.update_current_truck_status_settings = 'Uppdatera aktuella inställningar för lastbilsstatus';
_fc_props.default_behavior = 'Standard (topp 10 statusar)';
_fc_props.combine_similar_statuses = 'Kombinera samma status från alla typer (loopar)';
_fc_props.use_other_category = 'Använd kategorin "Övrigt".';
_fc_props.selected_statuses = 'Valda statusar';
_fc_props.ideas = 'Idéer';
_fc_props.air_content = 'Luftinnehåll';
_fc_props.air_measured_time = 'Luftmätt tid';
_fc_props.ten_max = '10 Max';
_fc_props.loads_per_plant = 'Laster per anläggning';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Ogiltig flödesmätarpuls per volym.';
_fc_props.coming_soon_title = 'Fler widgets kommer snart';
_fc_props.submit_your_ideas = 'Skicka in dina idéer till vår idéportal med hjälp av glödlampan på vänster navigeringsmeny.';
_fc_props.selected_plants = 'Utvalda växter';
_fc_props.update_loads_per_plant_settings = 'Uppdatera inställningar för belastningar per anläggning';
_fc_props.default_top_10_plants = 'Standard (topp 10 växter)';
_fc_props.phone_number_will_be_uploaded = 'Enhets-ID kommer att laddas upp för registreringsändamål.';
_fc_props.include_deleted = 'Inkludera borttagen';
_fc_props.primary_status_type = 'Primär statustyp';
_fc_props.additional_status_trigger = 'Ytterligare statusutlösare';
_fc_props.update_tickets_destination = 'Uppdatera destination på alla tillhörande biljetter';
_fc_props.settings.send_destination_changes_to_connex = 'Skicka destinationsändringar till Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Är biljetten aktiv när den tas emot av TrackIt (Standard \'alltid\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Autoaktivera nästa biljett vid föregående biljettslutförande';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Bör automatiskt aktivera nästa biljett när biljetten är klar (Standard "true")';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Status till viloläge när ingen aktiv biljett';
_fc_props.break_down = 'Bryta ner';
_fc_props.idle = 'På tomgång';
_fc_props.pto_work_maximum_speed_limit = 'Arbeta maximal hastighet';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Associera statusändring som kompletterar en biljett med den biljetten';
_fc_props.approaching = 'Närmar sig';
_fc_props.performing_task = 'Utför uppgift';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Är du säker på att du vill ta bort Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Uppdatera biljett {0}. Destinationen ändrades.';
_fc_props.trackit_return_destination_changed_to_0 = 'Uppdatera biljett {0}. Retur ändrad.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Lägg till Geogate';
_fc_props.driver_enabled = 'Drivrutin aktiverad';
_fc_props.dispatcher_enabled = 'Dispatcher aktiverad';
_fc_props.runsheet_number = 'Runsheetsnummer';
_fc_props.manual_load = 'Manuell laddning';
_fc_props.edit_geogate = 'Redigera Geogate';
_fc_props.handling = 'Hantering';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Geogate panel';
_fc_props.vehicle_is_not_logged_in = 'Meddelandet har inte skickats (fordonet är inte inloggat)';
_fc_props.air = 'Luft';
_fc_props.create_a_geogate = 'Skapa en Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Klicka på kartan för att ställa in geogate-punkterna.';
_fc_props.clock_out = 'Stämpla ut';
_fc_props.handling_operation = 'Hantering Operation';
_fc_props.view_geogates = 'Se Geogates';
_fc_props.select_a_geogate = 'Välj en Geogate';
_fc_props.failed_to_find_geogate = 'Det gick inte att hitta Geogate';
_fc_props.adjust_geogate = 'Justera Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate utgångsdatum Tid Standard Extra dagar';
_fc_props.menu_settings_title_retention = 'Datalagring';
_fc_props.category = 'Kategori';
_fc_props.telemetry = 'Telemetri';
_fc_props.ticket_data = 'Biljettdata';
_fc_props._30_days = '30 dagar';
_fc_props._6_months = '6 månader';
_fc_props._1_year = '1 år';
_fc_props._3_years = '3 år';
_fc_props.new_group = 'Ny grupp';
_fc_props.selected_vehicles = 'Utvalda fordon';
_fc_props.group_name_is_required_field = 'Gruppnamn är obligatoriskt fält';
_fc_props.at_least_vehicle_should_be_selected = 'Minst 1 fordon bör väljas';
_fc_props.groups = 'Grupper';
_fc_props.trucks = 'Lastbilar';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate har tagits bort. Uppdatera sidan.';
_fc_props.air_timestamp = 'Luft tidsstämpel';
_fc_props.fail = 'MISSLYCKAS';
_fc_props.stuck = 'FASTNAT';
_fc_props.dirty = 'SMUTSIG';
_fc_props.seasonal = 'Säsong';
_fc_props.category_details = 'Kategoridetaljer';
_fc_props.alert_events = 'Varna händelser';
_fc_props.driver_performance_infractions = 'Överträdelser av drivrutinsprestanda';
_fc_props.telemetry_details = 'Platsdata, hotspots, tid i hotspots, tid i regioner, DCM-data, sonddata, motordata';
_fc_props.ticket_data_details = 'Biljettdata';
_fc_props.general_details = 'Statusändringar, tidkort, scheman';
_fc_props.wash = 'Tvätta';
_fc_props.filter_options = 'Filteralternativ';
_fc_props.home_plants = 'Hemväxter';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO kräver att PTO Sensor Type ställs in.';
_fc_props.invalid_io_box_mac_address = 'Ogiltig I/O Box Mac-adress';
_fc_props.access_denied = 'Tillträde beviljas ej';
_fc_props.wash_events = 'Tvätta evenemang';
_fc_props.min_speed = 'Min hastighet';
_fc_props.temperature_change = 'Temperaturförändring';
_fc_props.degrees_cap = 'Grader';
_fc_props.washout_tracking = 'Washout Tracking';
_fc_props.approaching_wash = 'Närmar sig tvätt';
_fc_props.arrival_wash = 'Ankomst tvätt';
_fc_props.performing_wash = 'Utför tvätt';
_fc_props.wash_complete = 'Tvätt färdig';
_fc_props.must_be_positive_or_zero = 'Måste vara positivt eller noll!';
_fc_props.min_speed_greater_than_max = 'Min hastighet måste vara lägre än Max hastighet!';
_fc_props.clock_in = 'Klocka in';
_fc_props.mix_water_at_x_to_y_for_t = 'Blanda vatten vid {0} till {1} rpm i {2} varv.';
_fc_props.discharge_water_for_x_revolutions = 'Töm ut vatten i {0} varv.';
_fc_props.eod_drum_wash_complete = 'Trumtvätt färdig! <br> Stoppa trumman med sonden överst.';
_fc_props.washing_complete = 'Guidad tvättning komplett';
_fc_props.eod_washout_no_data_available = 'Sensordata är inte tillgänglig, vänligen slutför tvättprocessen.';
_fc_props.eod_washout_no_data_available_title = 'Guidad tvättning ej tillgänglig';
_fc_props.menu_reports_title_ticket_time_summary = 'Sammanfattning av biljetttid';
_fc_props.menu_reports_description_ticket_time_summary = 'Se information om biljetttid för en beställning eller ett fordon';
_fc_props.menu_reports_title_summary = 'Sammanfattning';
_fc_props.menu_reports_title_ticket_summary = 'Biljettsammanfattning';
_fc_props.menu_reports_description_summary1 = 'Visa en sammanfattning av tid i hotspots';
_fc_props.menu_dispatch_title_main = 'Main';
_fc_props.menu_dispatch_title_login_registry = 'Inloggningsregistret';
_fc_props.menu_reports_title_main = 'Main';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Är du säker på att du vill ta bort denna exportdatatyp?';
_fc_props.dvir_status_paper_logs = 'Pappersloggar';
_fc_props.menu_reports_title_billing_report = 'Faktureringsrapport';
_fc_props.menu_reports_description_billing_report = 'Faktureringsrapport';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status till biljettstatus vid Aktivera arbete';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status till biljettstatus vid aktivering av arbete (standard "False")';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Pund';
_fc_props.units_weight_kilogram_label = 'Kilogram';
_fc_props.settings_weight = 'Vikt';
_fc_props.units_pounds = 'Pund';
_fc_props.units_kilograms = 'Kilogram';
_fc_props.geo_gate_edit_modal_description = 'Beskrivning';
_fc_props.geo_gate_edit_modal_start_point = 'Startpunkt';
_fc_props.geo_gate_edit_modal_end_point = 'Slutpunkt';
_fc_props.geo_gate_edit_modal_vehicle_types = 'utrustnings typ';
_fc_props.geo_gate_edit_modal_heading_south = 'Söder till norr';
_fc_props.geo_gate_edit_modal_heading_north = 'Norr till söder';
_fc_props.geo_gate_edit_modal_driver_message = 'Förarmeddelande';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Skicka meddelande';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Utgångsdatum Tid';
_fc_props.geo_gate_edit_modal_permanent = 'Permanent';
_fc_props.geo_gate_edit_modal_critical = 'Kritisk';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Fel när Geogate skulle sparas: Beskrivningsfältet måste fyllas i.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Fel när Geogate skulle sparas: Startpunktsfältet måste fyllas i.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Fel när Geogate sparades: Startpunktsfältet innehåller ogiltigt dataformat.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Fel när Geogate sparades: Slutpunktsfältet innehåller ogiltigt dataformat.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Fel när Geogate skulle sparas: Fältet Utgångsdatum Tid måste fyllas i.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Fel när Geogate skulle sparas: Fältet för utgångsdatum Tid innehåller ogiltigt dataformat.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Fel när Geogate skulle sparas: Norr till söder - Fältet för leveransmeddelande måste fyllas i';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Fel när Geogate skulle sparas: Inga ändringar upptäcktes';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Fel när Geogate skulle sparas: Fältet för utgångsdatum Tid innehåller utgången datum-tid';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Fel när Geogate sparades: Beskrivningsfältet måste endast innehålla bokstäver och siffror';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Fel när Geogate skulle sparas: Fältet Utrustningstyp måste fyllas i';
_fc_props.geo_gate_table_message_label_north_to_south = 'Norr till söder';
_fc_props.geo_gate_table_message_label_south_to_north = 'Söder till norr';
_fc_props.geo_gate_table_message_label_both = 'Både';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Latitud longitud';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Om du markerar detta åsidosätter du inställningen för begränsning av enhetsrörelsemeddelanden';
_fc_props.geo_gate_edit_modal_seasonal = 'Säsong';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Fel när Geogate skulle sparas: Säsongsdatum måste fyllas i';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Fel när Geogate skulle sparas: Säsongsdatumfält innehåller ogiltigt dataformat';
_fc_props.test_js_test_javascript = 'Testa Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometer';
_fc_props.units_miles = 'Miles';
_fc_props.units_gallons_abbr = 'TJEJ';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Fel när Geogate skulle sparas: Söder till norr - Fältet för förarmeddelande måste fyllas i';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Fel när Geogate skulle sparas: Söder till norr - Fältet för sändningsmeddelande måste fyllas i';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Fel när Geogate skulle sparas: Nord till Syd - Fältet för förarmeddelande måste fyllas i';
_fc_props.geo_gate_heading = 'Rubrik';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Fel när Geogate skulle sparas: Slutpunktsfältet måste fyllas i';
_fc_props.units_mile = 'Mile';
_fc_props.units_kilometer = 'Kilometer';
_fc_props.cloud_dvir__exit = 'Utgång';
_fc_props.cloud_dvir__signature = 'Signatur';
_fc_props.cloud_dvir__inspection = 'Inspektion';
_fc_props.cloud_dvir__add_photos_notes = 'Lägg till foton/anteckningar';
_fc_props.cloud_dvir__odometer_reading = 'Vägmätaravläsning';
_fc_props.cloud_dvir__engine_hours = 'Motortimmar';
_fc_props.cloud_dvir__truck_details = 'Lastbilsdetaljer';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Bestäm fordonets övergripande skick';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Förarens fullständiga namn och underskrift';
_fc_props.cloud_dvir__sign = 'Skylt';
_fc_props.cloud_dvir__driver_note_from = 'Drivrutinsanteckning från';
_fc_props.cloud_dvir__add_notes = 'Lägg till anteckningar';
_fc_props.cloud_dvir__driver_notes = 'Drivrutinsanteckningar';
_fc_props.cloud_dvir__done = 'Gjort';
_fc_props.cloud_dvir__needs_repair = 'Behöver reparation';
_fc_props.cloud_dvir__not_applicable = 'Inte tillämpbar';
_fc_props.cloud_dvir__review_and_sign = 'Granska & Signera';
_fc_props.cloud_dvir__add_photos = 'Lägg till foto';
_fc_props.cloud_dvir__upload_photo_limit = 'Du kan ladda upp upp till 3 bilder';
_fc_props.cloud_dvir__mismatch_title = 'Felöverensstämmelsevarning';
_fc_props.cloud_dvir__ok = 'Ok';
_fc_props.cloud_dvir_template = 'Moln DVIR-mall';
_fc_props.cloud_dvir__needs_review = 'DVIR behöver granskas';
_fc_props.cloud_dvir__submitted = 'Lämnats';
_fc_props.cloud_dvir__completed_by = 'Fullgjord av';
_fc_props.cloud_dvir__review_btn = 'Recension';
_fc_props.cloud_dvir__truck_number = 'Lastbil {0}';
_fc_props.cloud_dvir__pending_review = 'Väntar på granskning';
_fc_props.cloud_dvir__start_dvir = 'Starta DVIR';
_fc_props.cloud_dvir__dvir_history = 'DVIR-historik';
_fc_props.cloud_dvir__view_all = 'Visa alla';
_fc_props.load_zone = 'Lastzon';
_fc_props.view_load_zones = 'Visa lastzoner';
_fc_props.edit_load_zone = 'Redigera laddningszon';
_fc_props.create_a_load_zone = 'Skapa en lastzon';
_fc_props.load_zone_panel = 'Ladda zonpanel';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Klicka på kartan för att ställa in lastzonens centrum.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Denna inspektion har indikerade defekter och du har valt att detta fordon är säkert att använda. Vänligen bekräfta innan du fortsätter';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Denna inspektion har inga indikerade defekter och du har valt att detta fordon är osäkert att använda. Vänligen bekräfta innan du fortsätter.';
_fc_props._90_days = '90 dagar';
_fc_props.cloud_dvir = 'Cloud DVIR';
_fc_props.cloud_dvir_details = 'Cloud DVIR-inlämningar';
_fc_props.show_load_zones = 'visa lastzoner';
_fc_props.hide_load_zones = 'dölja lastzoner';
_fc_props.no = 'Nej';
_fc_props.cloud_dvir__reset = 'Återställa';
_fc_props.cloud_dvir__clear = 'Klar';
_fc_props.mark_read = 'Markera Läs';
_fc_props.mark_unread = 'Markera som oläst';
_fc_props.read = 'Läsa';
_fc_props.unread = 'Oläst';
_fc_props.air_sensor_serial_number = 'Luftsensor serienummer';
_fc_props.air_sensor_mac_address = 'Luftsensor MAC-adress';
_fc_props.air_sensor_serial_number_invalid_format = 'Luftsensorns serienummer är inte i giltigt format: (alfanumeriskt och högst 32 tecken långt med streck)';
_fc_props.air_sensor_mac_address_invalid_format = 'Air Sensor MAC-adress är inte i giltigt hex-format som visas i platshållaren för fältet';
_fc_props.air_sensor_license = 'Luftsensor';
_fc_props.adjust_route_point = 'Justera ruttpunkt';
_fc_props.route_point_panel = 'Route Point Panel';
_fc_props.route_point = 'Ruttpunkt';
_fc_props.cloud_dvir__pre_trip = 'Pre Trip';
_fc_props.cloud_dvir__post_trip = 'Post resa';
_fc_props.cloud_dvir__other_insction_type = 'Övrig';
_fc_props.cloud_dvir__dvir_completed = 'Avslutad';
_fc_props.cloud_dvir__awaiting_mechanic = 'Väntar på mekaniker';
_fc_props.cloud_dvir__awaiting_driver = 'Väntar på föraravskrivning';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Det går inte att kontrollera luftsensorns serienummer, kontakta supporten för ytterligare hjälp.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Detta luftsensorserienummer finns redan, använd ett annat serienummer';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Det går inte att kontrollera luftsensorns MAC-adress, kontakta supporten för hjälp';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Det gick inte att skicka DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Var god försök igen';
_fc_props.ticket_integration_descr = 'Biljettintegrering gör det möjligt att ställa in inställningar för biljettintegrering såväl som startskärm / laddningsskärm / hårdmonterad display';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR har skickats';
_fc_props.cloud_dvir__inspection_trip_type = 'Typ av inspektionsresa';
_fc_props.menu_dvir_title_mechanic = 'Mekaniker';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Luftsensor firmware';
_fc_props.when_no_active_ticket = 'När ingen aktiv biljett';
_fc_props.used_by = 'Använd av';
_fc_props.fields = 'Fält';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Är du säker på att du vill ta bort biljettlayout "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Biljettlayout';
_fc_props.add_ticket_layout = 'Lägg till biljettlayout';
_fc_props.edit_ticket_layout = 'Redigera biljettlayout';
_fc_props.ticket_layout_edit_modal_name = 'namn';
_fc_props.ticket_layout_edit_modal_default = 'Standard';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Fel när biljettlayouten skulle sparas: Namnfältet måste fyllas i.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Fel när biljettlayouten skulle sparas: Namnfältet får endast innehålla bokstäver och siffror';
_fc_props.ticket_layout_edit_modal_fields = 'Fält';
_fc_props.ticket_layout_edit_modal_usage = 'Användande';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Biljettlayout har tagits bort. Uppdatera sidan.';
_fc_props.more_lower_case = 'Mer';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Fel när biljettlayouten skulle sparas: Ett eller flera fält måste väljas.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Fel när biljettlayouten skulle sparas: En eller flera statusar måste väljas.';
_fc_props.cloud_dvir__ad_hoc = 'Ad hoc';
_fc_props.radius_multiplier_should_be = 'Radiemultiplikatorn ska vara ett flytande tal';
_fc_props.ticket_max_activate_age_err = 'Ticket Max Activate Age Minuter ska vara ett heltal.';
_fc_props.max_hotspot_unused_days_errs = 'Max Hotspot Unused Days bör vara ett heltal.';
_fc_props.begin_pour_air_content = 'Börja Häll luftinnehåll';
_fc_props.arrival_air_content = 'Ankomst Air Content';
_fc_props.fully_mixed_air_content = 'Helt blandat luftinnehåll';
_fc_props.departure_air_content = 'Avgång Air Content';
_fc_props.popup_enabled = 'Popup aktiverat';
_fc_props.popup_disabled = 'Popup inaktiverad';
_fc_props.cloud_dvir__leave_confirmation_title = 'Varning';
_fc_props.cloud_dvir__leave_confirmation_description = 'Om du stänger sidan nu kommer alla ändringar att gå förlorade.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Bekräfta';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Annullera';
_fc_props.air_bt_ratio = 'Luftsensormätning';
_fc_props.cloud_dvir__view_photos_notes = 'Visa foton/anteckningar';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Om du lägger till statusen skapas en tom användning i en annan biljettlayout. Vill du fortsätta med tillägget?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Fordonet har inte luftsensorlicens.';
_fc_props.air_sensor_installed = 'Luftsensor installerad';
_fc_props.load_card = 'Ladda kort';
_fc_props.cloud_dvir__inspection_profile = 'Inspektionsprofil';
_fc_props.cloud_dvir__view_details = 'Visa detaljer';
_fc_props.cloud_dvir__certify_repairs = 'Intyga reparationer';
_fc_props.cloud_dvir__repairs_made = 'Reparationer gjorda';
_fc_props.cloud_dvir__repairs_not_necessary = 'Reparationer inte nödvändiga';
_fc_props.cloud_dvir__mechanic_note_from = 'Mekanikeranteckning från';
_fc_props.cloud_dvir__mechanic_assignment = 'Mekanikeruppdrag';
_fc_props.cloud_dvir__mechanic_signature_description = 'Markera alla tillämpliga punkter nedan och skylt som indikerar att fordonet har inspekterats på rätt sätt.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Skicket på detta fordon är tillfredsställande';
_fc_props.cloud_dvir__defects_corrected_label = 'Defekter har åtgärdats';
_fc_props.cloud_dvir__repair_not_required_label = 'Reparation krävs inte för säker drift av fordonet';
_fc_props.safety = 'Säkerhet';
_fc_props.cloud_dvir__awaiting_sign = 'Väntar på tecken';
_fc_props.cloud_dvir__dvir_review = 'DVIR recension';
_fc_props.settings.mobile_auto_start_navigation = 'Starta navigation automatiskt på mobilen';
_fc_props.cloud_dvir__edit = 'Redigera';
_fc_props.delete_custom_label = 'Ta bort anpassad etikett';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Din version av TrackIt är inaktuell och vissa DVIR-funktioner kanske inte fungerar korrekt. Uppdatera TrackIt innan du fortsätter.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Något gick fel under signaturuppladdningen. Var god försök igen';
_fc_props.max_hotspot_unused_days_error = 'Max Hotspot Unused Days har ett max på 1000 dagar.';
_fc_props.menu_dvir_title_admin_templates = 'Mallar';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Administration';
_fc_props.cloud_dvir__admin_templates = 'Mallar';
_fc_props.form_name = 'Form Namn';
_fc_props.form_status = 'Formulärstatus';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Ladda ner';
_fc_props.cloud_dvir__option_view = 'Se';
_fc_props.cloud_dvir__inspectortype_driver = 'Förare';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Visa/redigera/Skapa anpassade DVIR-formulär och inlämningar';
_fc_props.menu.dvir.description.mechanic = 'Visa/redigera handlingsbara DVIR-inlämningar';
_fc_props.not_in_use = 'Används inte';
_fc_props.logged_in = 'inloggad';
_fc_props.reviewing_driver = 'Granska drivrutinen';
_fc_props.cloud_dvir__report_title = 'Förarens inspektionsrapport för fordon';
_fc_props.cloud_dvir__report_signed = 'signerad';
_fc_props.cloud_dvir__report_page_counter = 'Sida {0} av {1}';
_fc_props.cloud_dvir__report_defects = 'Defekter';
_fc_props.cloud_dvir__report_comments = 'Kommentarer';
_fc_props.cloud_dvir__report_inspection_table_title = 'Fordonsinspektion';
_fc_props.cloud_dvir__report_driver_table_title = 'Förarinformation';
_fc_props.cloud_dvir__report_carrier = 'Bärare';
_fc_props.cloud_dvir__report_truck = 'Lastbil';
_fc_props.cloud_dvir__report_location = 'Plats';
_fc_props.cloud_dvir__report_odometer = 'Vägmätare';
_fc_props.cloud_dvir__report_hours = 'Timmar';
_fc_props.cloud_dvir__report_no_comments = 'Inga kommentarer';
_fc_props.include_unlicensed_vehicles = 'Inkludera olicensierade fordon';
_fc_props.cloud_dvir__search_by_form_name = 'Sök efter formulärnamn';
_fc_props.cloud_dvir__create_dvir = 'Skapa en DVIR';
_fc_props.cloud_dvir__update_dvir = 'Uppdatera DVIR';
_fc_props.cloud_dvir__form_name = 'Form Namn';
_fc_props.cloud_dvir__form_description = 'Formulärbeskrivning';
_fc_props.cloud_dvir__form_status = 'Formulärstatus';
_fc_props.information = 'Information';
_fc_props.cloud_dvir__search_categories = 'Sök kategorier';
_fc_props.cloud_dvir__create_new_category = 'Skapa ny kategori';
_fc_props.category_name = 'kategori namn';
_fc_props.sub_category = 'Underkategori';
_fc_props.sub_category_name = 'Underkategorinamn';
_fc_props.delete_category = 'Ta bort kategori';
_fc_props.cloud_dvir__dvir_information = 'DVIR-information';
_fc_props.cloud_dvir__dvir_choose_categories = 'Välj Kategorier';
_fc_props.cloud_dvir__create_category = 'Skapa kategori';
_fc_props.cloud_dvir__update_category = 'Uppdatera kategori';
_fc_props.delete_route_point = 'Ta bort ruttpunkt';
_fc_props.view_route_points = 'Visa ruttpunkter';
_fc_props.ignore_cancel_preload = 'Ignorera avbokningen, du är förladdad. Din homerun kommer att visas snart';
_fc_props.cloud_dvir__assigned_mechanic = 'Tilldelad mekaniker';
_fc_props.cloud_dvir__add_mechanic = 'Lägg till en mekaniker';
_fc_props.contact_type = 'Kontakt typ';
_fc_props.cloud_dvir__send_email_notifications = 'Skicka e-postmeddelanden';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Fel vid hämtning av DVIR-historikposter.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Det gick inte att hämta DVIR-posten.';
_fc_props.cloud_dvir__error_saving_submission = 'Det gick inte att spara inlämningen. Försök igen.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Det gick inte att hämta listan över mekaniker.';
_fc_props.cloud_dvir__template_saved = 'Mallen har sparats';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Det gick inte att hämta listan med mallar.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Det gick inte att spara mallen. Försök igen.';
_fc_props.dispatch_group = 'Utsändningsgrupp';
_fc_props.save_adjust = 'Spara och justera ruttpunkt';
_fc_props.reset_route_point = 'Återställ ruttpunkt';
_fc_props.no_records_stub_adjust_filters = 'Prova att justera dina sök- eller filterinställningar för att öka antalet resultat.';
_fc_props.no_dvir_records = 'Alla kom ikapp med DVIR!';
_fc_props.no_dvir_records_sub_title = 'Du har åstadkommit mycket';
_fc_props.settings.master_data_connex_x_api_key = 'Master Data Connex x-api-key';
_fc_props.slump_not_obtained_for_x = 'Ingen nedgångsläsning erhållen för: {0}';
_fc_props.please_select_a_sensor_status_type = 'Välj sensorstatustyper för att utlösa denna varning';
_fc_props.settings.master_data_connex_token = 'Master Data Connex Token';
_fc_props.error_validating_master_data_connex_credentials = 'Fel vid validering av Master Data Connex Credentials';
_fc_props.settings.master_data_connex_base_url = 'Master Data Connex Base URL';
_fc_props.settings.master_data_connex_exp_time = 'Master Data Connex utgångstid';
_fc_props.download_template = 'Ladda ner mall';
_fc_props.menu_settings_title_dispatch_group = 'Utskickningsgrupper';
_fc_props.settings.dispatch_groups = 'Utskickningsgrupper';
_fc_props.dispatch_group_name = 'Avsändningsgruppsnamn';
_fc_props.dispatch_group_plants = 'Utskick grupp anläggningar';
_fc_props.dispatch_group_plants_mapping_message = 'Ange vilka anläggningar som kommer att ingå i denna leveransgrupp.';
_fc_props.dispatch_group_users_mapping_message = 'Ange vilka användare som kommer att ingå i denna leveransgrupp.';
_fc_props.dispatch_group_plant_disassociation_message = 'Anläggningen bör vara under en avsändningsgrupp åt gången';
_fc_props.dispatch_group_search_by_plant = 'Sök efter växtnamn';
_fc_props.dispatch_group_search_by_username = 'Sök efter användarnamn';
_fc_props.search_by_dispatch_group_name = 'Sök efter avsändningsgruppsnamn';
_fc_props.create_dispatch_group = 'Skapa en leveransgrupp';
_fc_props.update_dispatch_group = 'Uppdatera avsändningsgrupp';
_fc_props.dispatch_group_saved = 'Utskicksgruppen har sparats';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Om du tar bort den här leveransgruppen skapas standardutskicksgrupper för de associerade anläggningarna.';
_fc_props.error_saving_dispatch_group = 'Det gick inte att spara sändningsgruppen. Försök igen.';
_fc_props.please_enter_a_valid_plant_num = 'Ange ett giltigt anläggningsnummer.';
_fc_props.cloud_dvir__report_no_defects = 'Inga defekter';
_fc_props.shoud_select_dispatch_group = 'Du måste välja minst en leveransgrupp';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Det gick inte att ta bort eftersom den här leveransgruppen innehåller användare som bara är associerade med denna leveransgrupp.';
_fc_props.cloud_dvir__username = 'Användarnamn';
_fc_props.cloud_dvir__password = 'Lösenord';
_fc_props.cloud_dvir__login = 'Logga in';
_fc_props.cloud_dvir__mechanic_sign_in = 'Mekaniker logga in';
_fc_props.delete_dispatch_group = 'Ta bort leveransgrupp';
_fc_props.cloud_dvir__driver = 'Förare';
_fc_props.cloud_dvir__equipment_pound = 'Utrustning #';
_fc_props.cloud_dvir__mechanic = 'Mekaniker';
_fc_props.dispatch_group_user_only_unassociate_message = 'Det går inte att ta bort användare eftersom användare måste vara associerade med minst en leveransgrupp.';
_fc_props.cloud_dvir__time_period_filter_today = 'I dag';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'I går';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Senaste 7 dagarna';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Senaste 30 dagarna';
_fc_props.cloud_dvir__date_range_filter = 'Filtrera efter datum';
_fc_props.cloud_dvir__download = 'Ladda ner';
_fc_props.dispatch_group_is_empty = 'Sändningsgruppen kan inte vara tom.';
_fc_props.dispatch_group_with_empty_plants = 'Minst en anläggning måste vara associerad med avsändningsgruppen.';
_fc_props.dispatch_group_with_empty_users = 'Minst en användare måste vara associerad med sändningsgruppen.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Det går inte att ta bort sändningsgruppen eftersom den innehåller användare som endast är associerade med denna sändningsgrupp.';
_fc_props.dispatch_group_not_exists = 'Sändningsgrupp finns inte.';
_fc_props.dispatch_group_contains_one_plant = 'Det gick inte att ta bort eftersom denna leveransgrupp bara innehåller en anläggning.';
_fc_props.dispatch_group_users = 'Skicka gruppanvändare';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Det gick inte att hämta DVIR-posten';
_fc_props.cloud_dvir__assign_mechanic = 'Tilldela mekaniker';
_fc_props.cloud_dvir__submit_review = 'Skicka recension';
_fc_props.error_deleting_dispatch_group = 'Det gick inte att ta bort sändningsgruppen.';
_fc_props.error_retrieving_dispatch_groups = 'Det gick inte att hämta listan över sändningsgrupper.';
_fc_props.cloud_dvir__undo_selection = 'Ångra val';
_fc_props.enable_master_data = 'Aktivera masterdata';
_fc_props.manually_sync = 'Synkronisera manuellt';
_fc_props.master_data_connex_token_expiration = 'Master Data Connex Tokens utgång';
_fc_props.cloud_dvir__time_period_filter_custom = 'Beställnings';
_fc_props.settings.zello_session_timeout_minutes = 'Zello-session timeout (minuter)';
_fc_props.menu_settings_title_master_data = 'Basdata';
_fc_props.settings.master_data = 'Basdata';
_fc_props.error_retrieving_master_data = 'Fel vid hämtning av masterdata';
_fc_props.master_data_sync_completed = 'Synkronisering av masterdata har slutförts';
_fc_props.master_data_successfully_updated = 'Data har uppdaterats';
_fc_props.master_data_failed_to_update = 'Det gick inte att spara.';
_fc_props.master_data_sync_initiated = 'Synkronisering av masterdata har initierats';
_fc_props.master_data_sync_failed = 'Synkronisering av masterdata misslyckades';
_fc_props.enabled_excluding_origin_on_deliveries = 'Sant (exklusive ursprung på leveransbiljetter)';
_fc_props.enabled_including_origin_on_deliveries = 'Sant (inklusive ursprung på leveransbiljetter)';
_fc_props.cloud_dvir__i_will_decide_later = 'Jag avgör senare';
_fc_props.returning = 'Återvändande';
_fc_props.leftover_concrete = 'Överbliven betong';
_fc_props.leftover_concrete_on_vehicle_x = 'Överbliven betong på fordon {0}';
_fc_props.leftover_concrete_alert = 'Överbliven betongvarning';
_fc_props.wc_ratio_exceeded = 'W/C-förhållande överskrids';
_fc_props.search_by_site_name = 'Sök på webbplatsens namn';
_fc_props.sites = 'Webbplatser';
_fc_props.token_expiration = 'Tokens utgång';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API Key Management';
_fc_props.settings.connex_api_key_management = 'Connex API Key Management';
_fc_props.error_retrieving_connex_api_key_data = 'Fel vid hämtning av connex api-nyckeldata';
_fc_props.qr_code = 'QR-kod';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informera förare och gränssnitt när fel anläggning går in';
_fc_props.enable_driver_message = 'Aktivera förarmeddelande';
_fc_props.enable_dispatch_message = 'Aktivera Dispatch Message';
_fc_props.enable_sending_message_to_interface = 'Aktivera sändning av meddelande till Interface';
_fc_props.ticket_qr_code = 'QR-kod för biljett';
_fc_props.leftover_concrete_alert_description = 'Varning när överbliven betong hittas efter gjutning.';
_fc_props.minimum_leftover_concrete_amount = 'Minsta överblivna betongmängd';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Fel vid validering av Master Data Connex Credentials Ej auktoriserad';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Fel vid validering av Master Data Connex Credentials behörigheter';
_fc_props.error_validating_master_data_connex_url_not_found = 'Det gick inte att validera huvuddataanslutningsadressen';
_fc_props.error_validating_master_data_connex_unknown_error = 'Fel vid validering av Master Data Connex Okänt fel';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Synkronisering av masterdata misslyckades Synkronisera fordonstyp.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Synkronisering av masterdata misslyckades Synkronisera fordonet.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Synkronisering av masterdata misslyckades. Synkronisera anläggningen.';
_fc_props.master_data_sync_failed_data_base_connection = 'Synkronisering av masterdata misslyckades med anslutning till databasen.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Fel vid validering av Master Data Connex Credentials Bearer Token Behörighet är otillräcklig';
_fc_props.settings.back_date_arrive_job = 'Tillbaka datum anländer jobb';
_fc_props.device_notifications = 'Aviseringar';
_fc_props.loading_number = 'Laddar nummer';
_fc_props.leftover_concrete_volume_temp_age = 'Överbliven volym={0}, Temp={1}, Ålder={2}';
_fc_props.total_ordered = 'Totalt beställd kvantitet';
_fc_props.total_ticketed = 'Levereras beställd';
_fc_props.load_number = 'Lastnummer';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Minsta överblivna betong måste fyllas i och vara större än {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Fel vid validering av stamdata. Inloggningsuppgifter kan inte vara desamma.';
_fc_props.use_aws_for_talk = 'Använd AWS för Talk';
_fc_props.air_readings_not_supported = 'Luftavläsningar stöds inte';
_fc_props.air_not_supported = 'Luft stöds inte';
_fc_props.mix_not_supported = 'Mix stöds inte';
_fc_props.air_default_and_none_not_supported = 'Kan inte välja standard och stöds inte samtidigt!';
_fc_props.notifications_enabled = 'Aviseringar aktiverade';
_fc_props.prevent_status_change = 'Förhindra statusändring';
_fc_props.message_to_interface = 'Meddelande till gränssnittet';
_fc_props.prevent_wrong_plant = 'Förhindra fel växt';
_fc_props.previous_tickets = 'Tidigare biljetter';
_fc_props.audit_ticket_number = 'Biljettnummer';
_fc_props.audit_date_created = 'Skapat datum';
_fc_props.audit_vehicle_number = 'Fordonsnummer';
_fc_props.cloud_dvir__table_ok = 'Säker att använda';
_fc_props.cloud_dvir__table_needs_repair = 'Osäker att använda';
_fc_props.missing_air_calibration = 'Inga luftkalibreringsdata';
_fc_props.reported_defects = 'Rapporterade defekter';
_fc_props.address_latitude = 'Adress Latitude';
_fc_props.address_longitude = 'Adress Longitud';
_fc_props.routing_latitude = 'Routing Latitude';
_fc_props.routing_longitude = 'Routing Longitud';
_fc_props.category_name_must_be_unique = 'Kategorinamnet måste vara unikt';
_fc_props.subcategory_name_must_be_unique = 'Underkategorinamn måste vara unikt';
_fc_props.menu_settings_tracking_device = 'Spårningsutrustning';
_fc_props.menu_settings_title_tracking_device = 'Spårningsutrustning';
_fc_props.menu_settings_title_personal_device_registration = 'Personlig enhetsregistrering';
_fc_props.registration_code = 'Registreringskod';
_fc_props.creation_date = 'Skapande';
_fc_props.expiration_date = 'Utgång';
_fc_props.trackitadmin_personal_device_registration = 'Personlig enhetsregistrering';
_fc_props.create_registration_code = 'Lägg till registreringskod';
_fc_props.remove_registration_code = 'Ta bort registreringskoden';
_fc_props.add_a_registration_code = 'Lägg till en registreringskod';
_fc_props.select_number_of_registration_codes_to_generate = 'Välj antalet registreringskoder du vill generera.';
_fc_props.registration_code_count = 'Antal registreringskoder';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Är du säker på att du vill ta bort den valda registrerade koden?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Är du säker på att du vill radera de valda registrerade koderna?';
_fc_props.add_personal_device = 'Lägg till personlig enhet';
_fc_props.menu_settings_title_personal_device = 'Personlig enhet';
_fc_props.cloud_dvir__approval_critical_alert = 'Fordonet har en kritisk defekt. Kan inte markeras som Säker att använda.';
_fc_props.export_registration_codes = 'Exportera registreringskoder';
_fc_props.download_format = 'Ladda ner format';
_fc_props.specify_download_format_x = 'Ange nedladdningsformatet för att exportera {0}-registreringskoder';
_fc_props.personal_device_download = 'Ladda ner';
_fc_props.add_personal_device_input_message = 'Ange ett personligt enhets-ID nedan. Förfallodatum tillämpas automatiskt efter att du har sparat';
_fc_props.error_creating_personal_device = 'Det gick inte att skapa en personlig enhet';
_fc_props.device_already_exists = 'Den personliga enheten finns redan';
_fc_props.export = 'Exportera';
_fc_props.error_can_not_change_connex_eid = 'Kan inte ändra connex eid eftersom det för närvarande används för masterdata';
_fc_props.remove_personal_device = 'Ta bort personlig enhet';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Är du säker på att du vill ta bort den valda personliga enheten?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Är du säker på att du vill ta bort de valda personliga enheterna?';
_fc_props.error_deleting_personal_device = 'Det gick inte att ta bort personlig enhet';
_fc_props.customerid = 'Kundnummer';
_fc_props.customername = 'Köparens namn';
_fc_props.ordernumber = 'Ordernummer';
_fc_props.orderdescription = 'Beställningsbeskrivning';
_fc_props.locationid = 'Plats-ID';
_fc_props.locationdescription = 'Platsbeskrivning';
_fc_props.productid = 'Serienummer';
_fc_props.productdescription = 'Produktbeskrivning';
_fc_props.vehicleid = 'Fordons-ID';
_fc_props.vehicledescription = 'Fordonsbeskrivning';
_fc_props.ticketnumber = 'Biljett #';
_fc_props.ticketdate = 'Biljettdatum/tid';
_fc_props.drivername = 'Förarens namn';
_fc_props.arriveregion = 'Ankomstregion (TIME)';
_fc_props.leaveregion = 'Lämna regionen (TIME)';
_fc_props.regionduration = 'Regionens varaktighet';
_fc_props.menu_reports_title_prevailing_wage = 'Rådande lön';
_fc_props.slump_calibration_x_not_found_for_y = 'Slumpkalibrering "{0}" hittades inte för biljett {1}';
_fc_props.slump_calibration_not_found = 'Slumpkalibrering hittades inte';
_fc_props.slump_calibration_not_found_description = 'Varning när Slump Calibration Not found';
_fc_props.error_getting_personal_device_list = 'Det gick inte att hämta personlig enhetslista';
_fc_props.batch_summary_date_range_note = '*OBS: Denna rapport är begränsad till 3 dagar om inga filter används. Datumintervallet kan gå upp till 31 dagar när det filtreras efter utrustning (max 10), anläggning (max 1), produkt (max 1) eller jobb (max 1)';
_fc_props.select_registration_code_expiration_time_days = 'Välj antalet dagar tills registreringskoderna ska upphöra att gälla och klicka sedan på Spara.';
_fc_props.add_personal_device_expiration = 'Utgångsdatum (i dagar)';
_fc_props.cloud_dvir__vehicle_no = 'Fordon nr';
_fc_props.cloud_dvir__trailer_no = 'Trailer nr';
_fc_props.cloud_dvir__vehicle = 'Fordon';
_fc_props.cloud_dvir__trailer = 'Trailer';
_fc_props.cloud_dvir__truck_number_label = 'Lastbilsnummer';
_fc_props.cloud_dvir__trailer_number = 'Trailernummer';
_fc_props.cloud_dvir__has_trailer = 'Kör inte med släp';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Ange ditt trailernummer här';
_fc_props.work_order = 'Arbetsorder';
_fc_props.unable_to_determine_slump = 'Det går inte att avgöra Slump';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Bestäm släpvagnens totala skick';
_fc_props.defaultTrailer = 'Standard trailer';
_fc_props.trailer_type_column_billable = 'Fakturerbar';
_fc_props.trailer_type_column_notes = 'Anteckningar';
_fc_props.trailer_type_column_date = 'Datum';
_fc_props.trailer_type_column_order = 'Beställa';
_fc_props.trailer_type_column_ticket = 'Biljett';
_fc_props.trailer_type_column_project = 'Projekt';
_fc_props.trailer_type_column_customer = 'Kund';
_fc_props.trailer_type_column_location = 'Plats';
_fc_props.trailer_type_column_vehicle_type = 'Fordonstyp';
_fc_props.trailer_type_column_product = 'Produkt';
_fc_props.trailer_type_action_rebill = 'Återfaktura';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Synkronisering av masterdata misslyckades ingen standardutrustningstyp har ställts in';
_fc_props.trailer_type = 'Typ av släp';
_fc_props.trailer_type_is_required = 'Typ av släp krävs';
_fc_props.trailer_number_already_exists = 'Trailernummer finns redan';
_fc_props.plate_number_already_exists = 'Skyltnummer finns redan';
_fc_props.trailer_vin_already_exists = 'Trailer Vin finns redan';
_fc_props.trailer_does_not_exist = 'Trailer finns inte';
_fc_props.trailer_number_is_required = 'Trailernummer krävs';
_fc_props.plate_number_is_required = 'Skyltnummer krävs';
_fc_props.plate_number = 'Plåtnummer';
_fc_props.menu_settings_title_trailer = 'Trailer';
_fc_props.add_trailer = 'Lägg till trailer';
_fc_props.edit_trailer = 'Redigera trailer';
_fc_props.menu_settings_title_equipment = 'Utrustning';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorera nya biljetter från Command Cloud äldre än X minuter';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignorera nya biljetter från Command Cloud med avsändningsdatum tid äldre än X minuter (standard är tom och av)';
_fc_props.edit_load_card = 'Redigera Ladda kort';
_fc_props.default_layout = 'Standardlayout';
_fc_props.load_card_empty_fields = 'Fel när du sparade Ladda kort: Ett eller flera fält måste väljas.';
_fc_props.trailers = 'Släpvagnar';
_fc_props.error_failed_to_save_data = 'Fel: Det gick inte att spara data';
_fc_props.slump_increased_plasticizer_added_question = 'Hur mycket mjukgörare tillsattes?';
_fc_props.add_trailer_type = 'Lägg till Trailer Type';
_fc_props.menu_settings_title_trailer_type = 'Typ av släp';
_fc_props.edit_trailer_type = 'Redigera trailertyp';
_fc_props.single_use_codes = 'Engångskoder';
_fc_props.multiple_use_codes = 'Flera användningskoder';
_fc_props.trailer_type_description_is_required = 'Beskrivning av släpvagnstyp krävs';
_fc_props.trailer_saved = 'Trailer sparad';
_fc_props.cloud_dvir__trailer_only_dvir = 'Trailer Only DVIR';
_fc_props.choose_file = 'Välj Arkiv';
_fc_props.no_file_chosen = 'Ingen fil vald';
_fc_props.please_choose_file = 'Välj fil';
_fc_props.no_filters = 'Inga filter har valts';
_fc_props.remaining_defects = 'Återstående defekter';
_fc_props.load_more = 'Ladda mer';
_fc_props.pagination_label = '{0}-{1} av {2}';
_fc_props.extend = 'Förlänga';
_fc_props.cloud_dvir__notes_or_photo_required = 'Antingen anteckningar eller ett foto krävs.';

export default _fc_props;
